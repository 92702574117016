import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-24-03 Contextual onboarding guides
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-a-24-03-contextual-onboarding/targeting?env=staging&env=test&env=production&selected-env=production
 * https://digitalcrew.teamwork.com/spaces/growth/page/54282-a-24-03-personalised-dynamic-in-app-onboarding
 */

export function useExperimentA2430() {
  const { contextHelpButtons } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const commonMetrics = ['account_id', 'company_size', 'company_user_role', 'company_sector', 'plan_id'];

  const { launchDarklyFlagKey, defaultValue } = contextHelpButtons;
  const ignoreInExperimentOnRuleMatch = true;

  const isExpA2430Variation = computed(() => contextHelpButtons.value);

  function trackExperimentA2430() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackGuideBadgeClicked(dataIdentifier) {
    trackPendoEvent({
      eventName: 'CONTEXTUAL_ONBOARDING_GUIDE_EVENT',
      commonMetrics,
      metadata: {
        event_action: 'guide_badge_clicked',
        badge_type: dataIdentifier,
      },
    });
  }

  return {
    launchDarklyFlagKey,
    isExpA2430Variation,
    trackExperimentA2430,
    trackGuideBadgeClicked,
  };
}
