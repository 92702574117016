import { DateTime } from 'luxon';

export function getToday() {
  return DateTime.now().startOf('day');
}

export function isSameDay(date1, date2) {
  return (date1 && date2 && date1.hasSame(date2, 'day')) || (date1 == null && date2 == null);
}

export function isSameMonth(date1, date2) {
  return (date1 && date2 && date1.hasSame(date2, 'month')) || (date1 == null && date2 == null);
}
