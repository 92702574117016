<script setup>
import { useBreakpoints, useI18n } from '@/util';
import { TeamworkLogoFullWhite } from '@/designsystem';
import { useCurrentUser, useCurrentAccount } from '@/api';
import { useAppShellSidebar } from './useAppShellSidebar';
import AppShellSidebarUserMenu from './userMenu/AppShellSidebarUserMenu.vue';

const { isSidebarOpenOnMobile } = useAppShellSidebar();
const user = useCurrentUser();
const breakpoints = useBreakpoints();
const isGreaterThanSm = breakpoints.greaterOrEqual('sm');
const account = useCurrentAccount();

const { t } = useI18n();

watch([isSidebarOpenOnMobile, isGreaterThanSm], () => {
  if (isSidebarOpenOnMobile.value && isGreaterThanSm.value) {
    isSidebarOpenOnMobile.value = false;
  }
});
</script>

<template>
  <div
    class="flex h-12 shrink-0 items-center justify-between bg-[--lsds-c-sidebar-drawer-color-surface] px-3 sm:hidden"
  >
    <button
      v-LsdTooltip="t('View menu')"
      type="button"
      class="flex shrink items-center justify-center rounded-full"
      :class="[
        'bg-[--lsds-c-sidebar-drawer-color-surface]',
        'h-[--lsds-c-button-size-sm] w-[--lsds-c-button-size-sm]',
        'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
        'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-close-panel-icon-color-on-hover]',
      ]"
      @click="isSidebarOpenOnMobile = !isSidebarOpenOnMobile"
    >
      <LscIcon size="sm" icon="lsi-burger-menu" />
    </button>
    <TeamworkLogoFullWhite v-if="account.allowTeamworkProjectsBrand" class="my-1" />
    <AppShellSidebarUserMenu location="bottom end">
      <template #activator="activator">
        <button type="button" v-bind="activator.props" class="p-1">
          <LscAvatar v-bind="getLsAvatarProps({ user })" size="sm" :showTooltip="!activator.isActive" />
        </button>
      </template>
    </AppShellSidebarUserMenu>
  </div>
</template>
