import './VTextField.variants.css';

export const VTextFieldPanelSearch = {
  class: 'VTextFieldPanelSearch',
  size: 14,
  singleLine: true,
  variant: 'outlined',
  hideDetails: true,
  clearable: true,
  density: 'compact',
  prependInnerIcon: 'lsi-search',
  clearIcon: 'lsi-close',
};

export const VTextFieldFilter = {
  class: 'VTextFieldFilter',
  singleLine: true,
  variant: 'outlined',
  hideDetails: true,
  clearable: true,
  density: 'compact',
  prependInnerIcon: 'lsi-search',
  clearIcon: 'lsi-close',
};

// #region techdebtFeatureSpecificPotentiallyUnnecessaryVariants
export const VTextFieldEstimateTimeDialog = {
  placeholder: '0',
  type: 'number',
  variant: 'outlined',
  density: 'compact',
  min: 0,
  class: 'bg-surface-emphasis-default',
};

export const VTextFieldCustomfieldsTaskDetails = {
  class: 'VTextFieldCustomfieldsTaskDetails',
  density: 'compact',
  variant: 'plain',
  hideDetails: true,
  autocomplete: 'off',
};

export const VTextFieldCustomfieldsUrlTaskDetails = {
  ...VTextFieldCustomfieldsTaskDetails,
  class: 'VTextFieldCustomfieldsTaskDetails VTextFieldCustomfieldsUrlTaskDetails',
  singleLine: true,
};

// #endregion techdebtFeatureSpecificPotentiallyUnnecessaryVariants

export const VTextFieldPlain = {
  class: 'VTextFieldPlain',
  singleLine: true,
  variant: 'plain',
  hideDetails: true,
  density: 'compact',
};

export const VTextFieldDurationInputSm = {
  class: 'VTextFieldDurationInputSm',
};

export const VTextFieldStepper = {
  class: 'VTextFieldStepper',
};

export const VTextFieldFullWidthMessage = {
  class: 'VTextFieldFullWidthMessage',
};

export const VTextFieldLargeRounded = {
  class: 'VTextFieldLargeRounded',
};
