import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useI18n } from '@/util';

/**
 * @typedef Tag
 * @property {number} id - The ID of the tag
 * @property {string} name - The tag
 * @property {string} color - A hex color code
 * @property {number} projectId - The ID of the project this tag belongs to, use 0 for global tags
 */

export function useTagActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();

  return {
    /**
     * @param {Omit<Tag, 'id'>} tag
     */
    createTag(tag) {
      const promise = api
        .post('/tags.json', tag, {
          errorMessage(error) {
            if (error.response?.status === 409) {
              return t('A tag with this name already exists');
            }
            return t('Failed to create the tag');
          },
          headers: { 'Socket-ID': socketId },
        })
        .then((response) => {
          const createdTag = {
            id: Number(response.data.id),
            name: response.data.name,
            color: response.data.color,
            projectId: Number(response.data.projectId) || 0,
          };
          emitRealTimeUpdate({
            type: 'tag',
            action: 'new',
            tagId: createdTag.id,
          });

          return createdTag;
        });
      return promise;
    },
    /**
     *
     * @param {Tag} tag
     */
    updateTag(tag) {
      const promise = api
        .put(
          `/tags/${tag.id}.json`,
          { tag },
          {
            errorMessage: t('Failed to update the tag'),
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'tag',
            action: 'edited',
            tagId: tag.id,
          });
        });

      emitOptimisticUpdate({
        type: 'tag',
        action: 'update',
        tag,
        promise,
      });

      return promise;
    },
  };
}
