<script setup>
import { useI18n, useUnsavedChanges } from '@/util';

const { t } = useI18n();
const { resolveNavigation, rejectNavigation, isNavigatingWithUnsavedChanges } = useUnsavedChanges();

const showDialog = shallowRef(false);
// We always give the user control over the dialog,
// There might be cases where the dialog opens and closes very quickly if a user navigates mid-upload for example
// We don't need to auto-resolve or reject the navigation if an upload finishes for example
watch(isNavigatingWithUnsavedChanges, (value) => {
  if (value) {
    showDialog.value = true;
  }
});
</script>

<template>
  <WidgetConfirmDialog
    v-model="showDialog"
    :title="t('You have unsaved changes')"
    :confirmButtonText="t('Yes, I\'m sure')"
    :cancelButtonText="t('No, stay here')"
    persistent
    critical
    :message="t('Are you sure you want to leave this page?')"
    @confirm="resolveNavigation"
    @cancel="rejectNavigation"
  />
</template>
