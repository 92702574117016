export const SCHEDULED_REPORTS_FEATURE_KEY = 'schedulereporting';
export const RETAINER_BUDGETS_FEATURE_KEY = 'retainerbudgets';
export const TASKLIST_BUDGETS_FEATURE_KEY = 'tasklistbudgets';
export const PROFITABILITY_FEATURE_KEY = 'profitability';
export const FINANCIAL_BUDGETS_FEATURE_KEY = 'projectfinancialbudgets';
export const FINANCIAL_INSIGHTS_FEATURE_KEY = 'financialinsights';
export const TIME_BUDGETS_FEATURE_KEY = 'projecttimebudgets';
export const FIXEDFEE_BUDGETS_FEATURE_KEY = 'projectfixedfeebudgets';
export const BUDGETS_FEATURE_KEY = 'budgets';
export const CUSTOM_REPORTS_FEATURE_KEY = 'customreporting';
export const COMPANY_TIMESHEETS_FEATURE_KEY = 'companytimesheets';
export const BUDGET_EXPENSES_FEATURE_KEY = 'projectbudgetexpenses';
export const CUSTOM_FIELDS_FEATURE_KEY = 'customfields';
export const PROOFS_FEATURE_KEY = 'proofs';
export const INTAKE_FORMS_FEATURE_KEY = 'intakeforms';
export const INTAKE_FORMS_LOGIC_FEATURE_KEY = 'intakeformsconditionallogic';
export const INTAKE_FORMS_ADVANCED_LOGIC_FEATURE_KEY = 'intakeformsadvancedconditionallogic';
export const INTAKE_FORMS_BRANDING_FEATURE_KEY = 'intakeformsbranding';
export const INTAKE_FORMS_FIELD_MAPPING_FEATURE_KEY = 'intakeformsfieldmapping';
export const AUTOMATIONS_QUOTA_LIMIT_FEATURE_KEY = 'accessFeatureAutomationsQuotaLimit';
export const PROJECT_TEMPLATES_FEATURE_KEY = 'projecttemplates';
export const ACTIVE_PROJECT_LIMIT_FEATURE_KEY = 'activeprojectlimit';
export const TIME_APPROVALS_FEATURE_KEY = 'timeapprovals';
export const WORKLOAD_PLANNER_FEATURE_KEY = 'workloadplanner';
export const CALENDAR_LOG_ON_GOOGLE_EVENT_FEATURE_KEY = 'googlecalendarlogtimeevents';
export const CALENDAR_READ_FEATURE_KEY = 'googlecalendarreadevents';
export const CALENDAR_EDIT_FEATURE_KEY = 'googlecalendareditevents';
export const CALENDAR_CREATE_TIME_BLOCK_FEATURE_KEY = 'timeblocks';
export const CALENDAR_LOG_ON_TIME_BLOCK_FEATURE_KEY = 'logtimeblocks';
export const TIME_REPORT_FEATURE_KEY = 'timereport';
export const USER_TASK_COMPLETION_REPORT_FEATURE_KEY = 'usertaskcompletionreport';
export const TASK_TIME_REPORT_FEATURE_KEY = 'tasktimereport';
export const PROJECT_TIME_REPORT = 'projecttimereport';
export const USER_LOGGED_TIME_REPORT = 'userloggedtimereport';
export const UTILIZATION_REPORT = 'utilizationreport';
export const PLANNED_VS_ACTUAL_REPORT = 'plannedvsactualreport';
export const PROJECT_HEALTH_REPORT_FEATURE_KEY = 'projectshealthreport';
export const WORKFLOWS_FEATURE_KEY = 'workflows';

export const SCHEDULED_REPORTS_FEATURE_TRIAL_LENGTH = 7;
export const RETAINER_BUDGETS_FEATURE_TRIAL_LENGTH = 7;
export const TASKLIST_BUDGETS_FEATURE_TRIAL_LENGTH = 7;
export const PROFITABILITY_FEATURE_TRIAL_LENGTH = 7;
export const CUSTOM_REPORTS_FEATURE_TRIAL_LENGTH = 7;
export const COMPANY_TIMESHEETS_FEATURE_TRIAL_LENGTH = 7;

export const CUSTOM_REPORTS_GROW_FEATURE_LIMIT_VALUE = 2;

export const GROW_PLAN_ID = 69;
