<script setup>
import { refDebounced } from '@vueuse/core';
import { useTemplateTasklistsV3Loader } from '@/api';
import TemplatesDrawerTaskListsList from './TemplatesDrawerTaskListsList.vue';
import TemplatesDrawerTaskListsBlankSlate from './TemplatesDrawerTaskListsBlankSlate.vue';
import TemplatesDrawerSearchBlankSlate from './TemplatesDrawerSearchBlankSlate.vue';
import { useI18n } from '@/util';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
  searchHasNoResultsForProjectsOrSamples: {
    type: Boolean,
    default: false,
  },
});
const { searchTerm } = toRefs(props);
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const { t } = useI18n();

const numOfItems = 50;
const count = shallowRef(numOfItems);
const state = useTemplateTasklistsV3Loader({
  count: Infinity,
  pageSize: 500,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    status: 'all',
    showCompleted: true,
    sort: 'displayOrder',
    include: 'tasklisttaskstats',
  })),
});
</script>

<template>
  <TemplatesDrawerTaskListsList v-model:count="count" :step="numOfItems" :state="state">
    <template #resultsIntro>
      <VListSubheader v-if="searchTerm" class="m-0 pb-0 pl-6 pt-5">
        {{ t('Your template task lists') }}
        &mdash; {{ t('results') }}
      </VListSubheader>
    </template>
    <template #blank>
      <TemplatesDrawerTaskListsBlankSlate v-if="!searchTerm" />
      <TemplatesDrawerSearchBlankSlate v-else-if="searchHasNoResultsForProjectsOrSamples" />
      <template v-else>&nbsp;</template>
    </template>
  </TemplatesDrawerTaskListsList>
</template>
