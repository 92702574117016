<script setup>
defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: null,
  },
  autofocus: {
    type: Boolean,
    default: null,
  },
});

defineEmits(['update:modelValue']);

const inputBlockInputRef = shallowRef(null);

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      inputBlockInputRef.value?.focus();
    });
  }
});
</script>
<template>
  <input
    ref="inputBlockInputRef"
    type="text"
    class="h-7 w-full rounded-sm pl-2 ring-1 ring-transparent hover:ring-bold focus-visible:ring-primary-default"
    :placeholder="placeholder"
    :value="modelValue"
    v-bind="$attrs"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
