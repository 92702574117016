<script setup>
import { usePreferences } from '@/api';
import AppShellSidebarProjectsRecent from './AppShellSidebarProjectsRecent.vue';
import AppShellSidebarProjectsStarred from './AppShellSidebarProjectsStarred.vue';

const { sidebarShowRecentProjects, sidebarShowStarredProjects } = usePreferences();
</script>

<template>
  <div
    class="flex min-h-0 flex-col overflow-auto border-y py-2 scrollbar-on-dark"
    :class="
      sidebarShowRecentProjects || sidebarShowStarredProjects
        ? 'border-[--lsds-c-sidebar-drawer-upgrade-now-color-surface]'
        : 'border-transparent'
    "
  >
    <AppShellSidebarProjectsRecent />
    <AppShellSidebarProjectsStarred />
  </div>
</template>
