<script setup>
import { useI18n } from '@/util';
import { useLscTable } from './useLscTable';
import LscTableHeaderCellContent from './LscTableHeaderCellContent.vue';

const { columns, calculateGridColumn, stickyFirstColumns, stickyLastColumns, getAriaSort } = useLscTable();
const { t } = useI18n();

const minIndex = computed(() => stickyFirstColumns.value);
const maxIndex = computed(() => columns.value.length - stickyLastColumns.value - 1);

let dragging = 0;
const draggingIndicator = document.createElement('div');

/**
 * @param event {DragEvent}
 */
function isDraggingColumn(event) {
  return event.dataTransfer.types.includes('teamwork/table-column');
}

/**
 * @param event {DragEvent}
 */
function isDroppable(event) {
  return event.currentTarget.dataset.droppable === 'true';
}

/**
 * @param event {DragEvent}
 * @return {LscTableColumn}
 */
function getDraggedColumn(event) {
  return JSON.parse(event.dataTransfer.getData('teamwork/table-column'));
}

function updateDragging(newDragging, event) {
  dragging = newDragging;
  if (!dragging && draggingIndicator.parentElement) {
    draggingIndicator.parentElement.removeChild(draggingIndicator);
  } else if (dragging && event) {
    if (!draggingIndicator.parentElement && isDroppable(event)) {
      document.body.appendChild(draggingIndicator);
    }

    const rect = event.currentTarget.getBoundingClientRect();

    draggingIndicator.style.top = `${rect.top}px`;
    draggingIndicator.style.height = `${rect.height}px`;
    draggingIndicator.style.transform = 'translateX(-50%)';
    draggingIndicator.className = 'pointer-events-none fixed z-20 border-2 border-primary-default';

    // place the indicator on the left or right side of the column
    if (event.clientX < rect.left + rect.width / 2) {
      draggingIndicator.style.left = `${rect.left}px`;
    } else {
      draggingIndicator.style.left = `${rect.right}px`;
    }
  }
}

function dragStart(event, column) {
  event.dataTransfer.setData('teamwork/table-column', JSON.stringify(column));
}

function dragEnter(event) {
  if (isDraggingColumn(event) && isDroppable(event)) {
    updateDragging(dragging + 1);
  }
}

function dragLeave(event) {
  if (isDraggingColumn(event) && isDroppable(event)) {
    updateDragging(dragging - 1);
  }
}

function dragOver(event) {
  if (isDraggingColumn(event) && isDroppable(event)) {
    updateDragging(1, event);

    event.preventDefault();

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  }
}

function drop(event, targetColumn) {
  if (isDraggingColumn(event) && isDroppable(event)) {
    updateDragging(0);
    event.preventDefault();

    const copy = [...columns.value];

    const draggedColumn = getDraggedColumn(event);
    const draggedIndex = copy.findIndex((el) => el.id === draggedColumn.id);
    const targetIndex = copy.findIndex((el) => el.id === targetColumn.id);
    if (draggedIndex === -1 || targetIndex === -1) {
      return;
    }
    const rect = event.currentTarget.getBoundingClientRect();
    const isHoveredOnLeftSide = event.clientX < rect.left + rect.width / 2;

    const newIndex = Math.min(Math.max(targetIndex + (isHoveredOnLeftSide ? 0 : 1), minIndex.value), maxIndex.value);

    if (newIndex === draggedIndex) {
      return;
    }

    if (newIndex < draggedIndex) {
      copy.splice(draggedIndex, 1);
      copy.splice(newIndex, 0, draggedColumn);
    } else {
      copy.splice(newIndex, 0, draggedColumn);
      copy.splice(draggedIndex, 1);
    }

    columns.value = copy;
  }
}

const resizingColumnId = shallowRef();
const resizingColumns = /** @type {ShallowRef<LscTableColumn[]|undefined>} */ (shallowRef());
let resizeTarget;
let resizeInitialState;
let resizeFrame;

/**
 * @param {LscTableColumn} column
 * @return {(event: PointerEvent) => void}
 */
function resizing(column) {
  return (event) => {
    resizeTarget.width = Math.max(
      column.minWidth,
      resizeInitialState.width + (event.clientX - resizeInitialState.clientX),
    );
    resizeFrame ??= requestAnimationFrame(() => {
      triggerRef(resizingColumns);
      resizeFrame = undefined;
    });
  };
}

/**
 * @param {PointerEvent} event
 */
function stopResize(event) {
  document.documentElement.style.cursor = '';

  const resized = [...resizingColumns.value];

  resizingColumns.value = undefined;
  resizingColumnId.value = undefined;

  resizeTarget = undefined;
  resizeInitialState = undefined;

  // eslint-disable-next-line no-param-reassign
  event.target.onpointerup = undefined;
  // eslint-disable-next-line no-param-reassign
  event.target.onpointermove = undefined;
  event.target.releasePointerCapture(event.pointerId);

  requestAnimationFrame(() => {
    columns.value = resized;
  });
}

/**
 * @param {PointerEvent} event
 * @param {LscTableColumn} column
 */
function startResize(event, column) {
  const copy = [...columns.value];

  document.documentElement.style.cursor = 'ew-resize';
  resizingColumnId.value = column.id;
  resizingColumns.value = copy;

  resizeInitialState = { clientX: event.clientX, width: column.width };
  resizeTarget = copy.find((el) => el.id === column.id);

  // eslint-disable-next-line no-param-reassign
  event.target.onpointermove = resizing(column);
  // eslint-disable-next-line no-param-reassign
  event.target.onpointerup = stopResize;

  event.target.setPointerCapture(event.pointerId);
}

const columnWidths = computed(() => {
  if (!resizingColumns.value) {
    return undefined;
  }
  return {
    '--lsc-table-grid-cols-resized': resizingColumns.value.map(calculateGridColumn).join(' '),
  };
});
</script>

<template>
  <tr
    class="group/LscTableHeader col-span-full grid"
    :class="columnWidths ? 'grid-cols-[--lsc-table-grid-cols-resized]' : 'grid-cols-subgrid'"
    :style="columnWidths"
    :data-resizing="resizingColumnId"
    :data-dragging="dragging"
  >
    <Component
      :is="column.name ? 'th' : 'td'"
      v-for="column in columns"
      :key="column.id"
      :class="[
        'group/LscTableHeaderCell relative flex h-9 items-center gap-2 bg-default px-2 transition-colors',
        // Dividers
        'after:pointer-events-none after:absolute after:inset-y-0 after:right-0 after:opacity-0',
        'after:border-r after:border-separator after:transition-opacity',
        'group-hover/LscTableHeader:after:opacity-100',
        'border-b border-b-separator bg-default',
        // Alignment
        'data-[align=left]:justify-start',
        'data-[align=center]:justify-center',
        'data-[align=right]:justify-end',
        // Draggable
        'data-[draggable=true]:cursor-grab',
        // First column sticky
        'data-[sticky-first-column=true]:sticky',
        'data-[sticky-first-column=true]:left-[--lsc-table-col-offset]',
        'data-[sticky-first-column=true]:z-[3]',
        'data-[sticky-first-column=true]:after:right-0',
        'group-data-[shadow-start=true]/LscTable:data-[shadow-start=true]:after:opacity-100',
        // Last column sticky
        'data-[sticky-last-column=true]:sticky',
        'data-[sticky-last-column=true]:right-[--lsc-table-col-offset]',
        'data-[sticky-last-column=true]:z-[3]',
        'data-[sticky-last-column=true]:after:right-auto',
        'data-[sticky-last-column=true]:after:left-0',
        'data-[sticky-last-column=true]:after:border-r-0',
        'data-[sticky-last-column=true]:after:border-l',
        'group-data-[shadow-end=true]/LscTable:data-[shadow-end=true]:after:opacity-100',
        // Resizing
        'data-[resizable=true]:hover:bg-surface-default',
        'data-[draggable=true]:hover:bg-surface-default',
        'group-data-[resizing=true]/LscTable:pointer-events-none',
        'group-data-[resizing=true]/LscTable:select-none',
      ]"
      :aria-sort="getAriaSort(column)"
      :data-align="column.align"
      :data-draggable="column.draggable && !resizingColumnId"
      :data-droppable="minIndex <= column.index && column.index <= maxIndex"
      :data-resizing-current="column.id === resizingColumnId"
      :data-index="column.index"
      :data-sticky-first-column="column.stickyFirstColumn"
      :data-sticky-last-column="column.stickyLastColumn"
      :data-shadow-start="column.applyShadowStart"
      :data-shadow-end="column.applyShadowEnd"
      :draggable="column.draggable && !resizingColumnId"
      :role="column.name ? 'columnheader' : 'presentation'"
      :style="{
        '--lsc-table-col-offset': `var(--lsc-table-col-offset-${column.index})`,
      }"
      @dragstart="dragStart($event, column)"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragover="dragOver"
      @drop="drop($event, column)"
    >
      <slot :name="column.slot" :column="column">
        <LscTableHeaderCellContent :column="column" />
      </slot>

      <button
        v-if="column.resizable"
        type="button"
        :aria-label="t('Resize column')"
        :class="[
          'flex items-stretch justify-center',
          'absolute left-full -translate-x-full',
          'z-[1] h-full select-none bg-transparent opacity-0 transition-all hover:cursor-ew-resize hover:opacity-100',
          'group-data-[resizing-current=true]/LscTableHeaderCell:border-[color:--lsds-c-table-cell-resize-active]',
          'group-data-[resizing-current=true]/LscTableHeaderCell:opacity-100',
        ]"
        @pointerdown="startResize($event, column)"
      >
        <div
          class="ml-3 w-1 bg-[--lsds-a-color-border-separator] group-data-[resizing-current=true]/LscTableHeaderCell:bg-action-primary-default"
        />
      </button>
    </Component>
  </tr>
</template>
