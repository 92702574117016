<script setup>
import { useI18n } from '@/util';
import LscButton from '../../../action/button/LscButton.vue';
import LscIcon from '../../../media/icon/LscIcon.vue';

defineOptions({ inheritAttrs: false });

const props = defineProps({
  /**
   * Whether to show a button on hover.
   * You can force the button to always show by setting this to `always`.
   * @type {PropType<Boolean|'always'>}
   * @default false
   */
  button: {
    type: [Boolean, String],
    default: false,
  },
  ariaExpanded: {
    type: [Boolean, String],
    default: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['click']);
const attrs = useAttrs();

const { t } = useI18n();

// Vuetify can gives us a string or undefined, so we need to convert it to a boolean.
const ariaExpanded = computed(() => String(props.ariaExpanded) === 'true');

const buttonText = computed(() => props.buttonText || t('Add field'));
const buttonAlwaysVisible = computed(() => props.button === 'always' || ariaExpanded.value);
</script>

<template>
  <div ref="$el" class="inline-flex items-center">
    <LscIcon
      v-if="button !== 'always' && !ariaExpanded"
      icon="lsi-remove"
      :class="{
        'group-hover/LscTableCell:hidden': button,
        'text-icon-subtle': !disabled,
        'text-icon-disabled': disabled,
      }"
      v-bind="attrs"
    />

    <div
      v-if="button"
      :class="{
        'hidden group-hover/LscTableCell:inline-flex': !buttonAlwaysVisible,
        'inline-flex': buttonAlwaysVisible,
      }"
      class="items-center"
      v-bind="attrs"
    >
      <slot name="action">
        <LscButton
          variant="plain-primary"
          :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-button` : undefined"
          :ariaExpanded="ariaExpanded"
          prependIcon="lsi-add"
          @click="emit('click')"
        >
          {{ buttonText }}
        </LscButton>
      </slot>
    </div>
  </div>
</template>
