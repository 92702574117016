<script setup>
import { useI18n } from '@/util';
import {
  PREVIEW_TAB_TABLE,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_PEOPLE,
  PREVIEW_TAB_FINANCE,
  PREVIEW_TAB_BUDGETS,
  STEP_SELECT_VIEW,
} from '../../constants';
import { useOnboardingWizardTracking } from '../../useOnboardingWizardTracking';
import PreviewTopbarButtons from './assets/preview-topbar-buttons.svg';
import PreviewSidebarTop from './assets/preview-sidebar-top.svg';
import PreviewSidebarBottom from './assets/preview-sidebar-bottom.svg';
import PreviewTable from './OnboardingWizardCommonPreviewTable.vue';
import PreviewBoard from './OnboardingWizardCommonPreviewBoard.vue';
import PreviewList from './OnboardingWizardCommonPreviewList.vue';
import PreviewFinance from './OnboardingWizardCommonPreviewFinance.vue';
import PreviewPeople from './OnboardingWizardCommonPreviewPeople.vue';
import PreviewBudgets from './OnboardingWizardCommonPreviewBudgets.vue';

const props = defineProps({
  projectName: {
    type: String,
    default: undefined,
  },
  clientCompanyName: {
    type: String,
    default: undefined,
  },
  preselectedTab: {
    type: String,
    required: true,
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  tabsClickable: {
    type: Boolean,
    default: true,
  },
  tasksData: {
    type: Object,
    default: () => {},
  },
  tasksDataFromTemplate: {
    type: Boolean,
    default: false,
  },
  peopleData: {
    type: Array,
    default: undefined,
  },
  financeData: {
    type: Object,
    default: () => {},
  },
  stepId: {
    type: String,
    default: '',
  },
  isPreviewDataLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['activeTabChanged']);

const { t } = useI18n();

const { trackOnboardingCommonPreviewClicked } = useOnboardingWizardTracking();

const tabMapping = {
  [PREVIEW_TAB_TABLE]: {
    value: 'table',
    label: t('Table'),
  },
  [PREVIEW_TAB_BOARD]: {
    value: 'board',
    label: t('Board'),
  },
  [PREVIEW_TAB_LIST]: {
    value: 'list',
    label: t('List'),
  },
  [PREVIEW_TAB_PEOPLE]: {
    value: 'people',
    label: t('People'),
  },
  [PREVIEW_TAB_FINANCE]: {
    value: 'finance',
    label: t('Finance'),
  },
};

const activeTab = shallowRef(props.preselectedTab);
const loadingCounter = shallowRef(0);
const loadingMessage = shallowRef('');
const percentage = shallowRef(0);
const loadingMessageInterval = shallowRef();
const percentageInterval = shallowRef();

const displayedTabs = computed(() => props.tabs.map((tab) => tabMapping[tab]));
const isBudgetsTab = computed(() => activeTab.value === PREVIEW_TAB_BUDGETS);

function onTabClick(tab) {
  if (props.stepId === STEP_SELECT_VIEW) {
    trackOnboardingCommonPreviewClicked(props.stepId, tab, 'example');
  }
}

watch(activeTab, (tab) => {
  if (props.stepId === STEP_SELECT_VIEW) {
    return;
  }
  trackOnboardingCommonPreviewClicked(props.stepId, tab);

  emit('activeTabChanged', tab);
});

watch(
  () => props.preselectedTab,
  (newTab) => {
    activeTab.value = newTab;
  },
);

watch(
  () => props.isPreviewDataLoading,
  (isLoading) => {
    if (isLoading) {
      loadingCounter.value = 0;
      percentage.value = 0;
      loadingMessageInterval.value = setInterval(() => {
        loadingCounter.value += 1;
        loadingMessage.value = [
          t("Hold tight, we're creating your tasks..."),
          t('Analyzing your project type...'),
          t('Analyzing your brief description...'),
          t('Generating accurate task titles...'),
          t('Generating detailed task descriptions...'),
          t('Creating your task lists and tasks...'),
          t('Prioritizing your tasks effectively...'),
          t('Setting deadlines for tasks...'),
        ][loadingCounter.value % 8];
      }, 3000);
      percentageInterval.value = setInterval(() => {
        percentage.value += Math.ceil((99 - percentage.value) * 0.07);
      }, 550);
    } else {
      clearInterval(loadingMessageInterval.value);
      clearInterval(percentageInterval.value);
    }
  },
);
</script>
<template>
  <div
    class="flex h-[--onboarding-wizard-preview-container-height] max-h-full w-[--onboarding-wizard-preview-container-width] flex-col overflow-hidden rounded-lg shadow-3"
  >
    <div class="flex h-4 w-full items-center bg-[--onboarding-wizard-preview-topbar-background] pl-1.5">
      <PreviewTopbarButtons />
    </div>
    <div class="flex min-h-0 flex-1">
      <div class="flex w-10 flex-col bg-[--onboarding-wizard-preview-sidebar-background] p-2">
        <div class="flex-1">
          <PreviewSidebarTop />
        </div>
        <div class="flex flex-col items-center">
          <PreviewSidebarBottom />
        </div>
      </div>
      <div class="flex flex-1 flex-col overflow-hidden bg-[--onboarding-wizard-preview-body-background] px-4 pt-4">
        <div class="flex items-end gap-2">
          <LscOverflowEllipsis v-if="projectName" class="max-w-96 text-h3 text-default">
            {{ projectName }}
          </LscOverflowEllipsis>
          <div v-else class="flex h-[--lsds-a-typography-heading-4-line-height] items-center">
            <LscSkeleton :animation="false" class="h-[--lsds-a-typography-heading-4-font-size] w-52" />
          </div>
          <LscOverflowEllipsis v-if="clientCompanyName" class="mb-0.5 max-w-44 text-body-1 text-subtle">
            {{ clientCompanyName }}
          </LscOverflowEllipsis>
        </div>

        <VTabs v-if="!isBudgetsTab" v-model="activeTab" v-bind="VTabsSmall" @click="onTabClick(activeTab)">
          <VTab
            v-for="tab in displayedTabs"
            :key="tab.value"
            :class="{ 'pointer-events-none': !tabsClickable }"
            :value="tab.value"
          >
            {{ tab.label }}
          </VTab>
        </VTabs>
        <hr v-if="!isBudgetsTab" class="-mx-4 border-t" />
        <span v-if="isPreviewDataLoading" class="-mt-10 flex h-full w-full flex-col items-center justify-center">
          <div class="flex flex-row gap-3 align-middle">
            <VProgressCircular indeterminate color="primary" :width="5" class="size-12">
              <span class="text-xs text-body-1 font-bold">{{ `${percentage}%` }}</span>
            </VProgressCircular>
            <div class="flex flex-col">
              <span class="text-subtitle-2 font-semibold text-default">
                {{ loadingMessage || t("Hold tight, we're creating your tasks...") }}
              </span>
              <span class="text-body-1 text-subtle">
                {{ t('We’ll notify you when the process is complete') }}
              </span>
            </div>
          </div>
        </span>
        <div
          v-else
          class="flex-1 overflow-auto"
          :class="{ 'mt-2': isBudgetsTab }"
          :style="{ scrollbarColor: 'var(--lsds-c-scrollbar-color-default) transparent' }"
        >
          <Transition
            enterFromClass="opacity-0"
            enterToClass="opacity-100"
            enterActiveClass="transition-opacity duration-300"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
            leaveActiveClass="transition-opacity duration-300"
            mode="out-in"
          >
            <PreviewTable
              v-if="activeTab == PREVIEW_TAB_TABLE"
              :data="tasksData?.tasklists"
              :dataFromTemplate="tasksDataFromTemplate"
            />
            <PreviewBoard
              v-else-if="activeTab == PREVIEW_TAB_BOARD"
              :data="tasksData"
              :dataFromTemplate="tasksDataFromTemplate"
            />
            <PreviewList
              v-else-if="activeTab == PREVIEW_TAB_LIST"
              :data="tasksData"
              :dataFromTemplate="tasksDataFromTemplate"
            />
            <PreviewPeople v-else-if="activeTab == PREVIEW_TAB_PEOPLE" :data="peopleData" />
            <PreviewFinance v-else-if="activeTab == PREVIEW_TAB_FINANCE" v-bind="financeData" />
            <PreviewBudgets v-else-if="activeTab == PREVIEW_TAB_BUDGETS" />
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>
