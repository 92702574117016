import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useI18n } from '@/util';

export function useAllocationsActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
      errorMessage: t('Allocation creation has failed, please try again'),
    };
  }

  const newAllocationId = (() => {
    let id = 0;
    return () => {
      id -= 1;
      return id;
    };
  })();

  function emitOptimisticUpdate(promise, action, allocation) {
    _emitOptimisticUpdate({
      promise,
      type: 'allocation',
      action,
      allocation,
    });
  }

  function createAllocation({ allocation, extraInfo = {} }) {
    const promise = api
      .post(`/projects/api/v3/allocations.json`, { allocation }, config())
      .then(({ data: { allocation: newAllocation } }) => {
        emitRealTimeUpdate({
          type: 'allocation',
          action: 'new',
          allocation: newAllocation,
        });

        return newAllocation;
      });

    emitOptimisticUpdate(promise, 'create', {
      ...allocation,
      ...extraInfo,
      id: newAllocationId(),
    });

    return promise;
  }

  function updateAllocation(allocation) {
    const { id, ...updatedFields } = allocation;
    const url = `/projects/api/v3/allocations/${id}.json`;
    const promise = api
      .patch(url, { allocation: updatedFields }, { errorMessage: t('Allocation update has failed, please try again') })
      .then(({ data: { allocation: newAllocation } }) => {
        emitRealTimeUpdate({
          type: 'allocation',
          action: 'edited',
          allocation: newAllocation,
        });

        return newAllocation;
      });

    emitOptimisticUpdate(promise, 'update', allocation);

    return promise;
  }

  function deleteAllocation(allocation) {
    const url = `/projects/api/v3/allocations/${allocation.id}.json`;
    const promise = api
      .delete(url, {
        errorMessage: t('Error when deleting "{allocationName}", please try again later', {
          allocationName: allocation.name,
        }),
      })
      .then(() => {
        emitRealTimeUpdate({
          type: 'allocation',
          action: 'deleted',
          allocation,
        });

        return allocation;
      });

    emitOptimisticUpdate(promise, 'delete', allocation);

    return promise;
  }

  function reassignAllocations({ toUserId, fromUserId }) {
    const promise = api
      .put(
        '/projects/api/v3/allocations/reassign.json',
        { toUserId, fromUserId },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to reaasign allocations'),
        },
      )
      .then(() => {
        // Todo, align with backend on the server event
        emitRealTimeUpdate({
          type: 'allocation',
          action: 'bulkreassign',
        });
      });
    return promise;
  }

  return {
    createAllocation,
    updateAllocation,
    deleteAllocation,
    reassignAllocations,
  };
}
