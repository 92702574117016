<script setup>
import { useI18n } from '@/util';
import { useSavedfilterActions } from '@/api';
import { useFilter } from './useFilter';
import FilterShareDialog from './FilterShareDialog.vue';
import FilterMenuOptions from './FilterMenuOptions.vue';
import FilterSavedFilters from './FilterSavedFilters.vue';

defineProps({
  allowSaveFilters: {
    type: Boolean,
    default: true,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
  section: {
    type: String,
    default: undefined,
  },
  hasQuickFilters: {
    type: Boolean,
    default: false,
  },
  savedFilterParams: {
    type: Object,
    default: undefined,
  },
});

const { t } = useI18n();
const toast = useLsToast();
const { params, activeFilter, clearSavedfilter, count, clear, chips } = useFilter();
const isDialogAddTasklistOpen = shallowRef(false);

const { updateSavedfilter, shareSavedfilter } = useSavedfilterActions();
const displaySavedfilters = shallowRef(false);
let shareFilter = null;
const displayFilterTitleInput = shallowRef(false);
// We need this check as some filters contibute to the 'count' but don't have a chip.
const chipsLength = computed(() => chips.value.length);

async function openShareFilterModal(filter) {
  isDialogAddTasklistOpen.value = true;
  shareFilter = filter;
}

async function shareFilterToUser(shareFilterData) {
  await shareSavedfilter(shareFilterData.filterID, {
    includeDetails: 0,
    shareToUsers: shareFilterData.userIDs,
  });
  isDialogAddTasklistOpen.value = false;
  toast.success(t('Shared Filter sent successfully'));
}

async function shareFilterTitle(shareTitleData) {
  await shareSavedfilter(shareTitleData.filterID, {
    savedFilter: {
      displayOrder: 0,
      includesSort: false,
      isProjectSpecific: false,
      projectId: 0,
      section: 'homeActivity',
      sharedState: shareTitleData.shareTitle ? 0 : 1,
    },
    updateDefault: true,
  });
}

async function updateFilter() {
  const editedFilter = {
    id: activeFilter.value?.id,
    parameters: { ...params.value },
  };
  await updateSavedfilter(editedFilter);
  toast.success(t('Filter updated'));
}

function clearFilter() {
  clear();
  if (activeFilter.value?.id) {
    clearSavedfilter();
  }
}

function updateDisplayOptions({ showTitleInput, showSavedfilters }) {
  displayFilterTitleInput.value = showTitleInput;
  displaySavedfilters.value = showSavedfilters;
}
</script>
<template>
  <WidgetMenu :eager="true" v-bind="$attrs">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LscSheet
      class="flex flex-col items-stretch !overflow-x-hidden !px-5 !pb-5 !pt-4"
      :class="
        hasQuickFilters ? '!w-[--filter-dropdown-wrapper-width-quick-filter]' : '!w-[--filter-dropdown-wrapper-width]'
      "
    >
      <div class="flex items-center justify-between">
        <div class="text-body-1 font-semibold text-default">
          <template v-if="count">
            <div class="flex gap-5">
              <div :class="hasQuickFilters && 'w-72'">
                {{ displaySavedfilters ? t('Saved filters') : `${t('Selected filters')}:` }}
              </div>
              <div v-if="hasQuickFilters && chipsLength === 0" class="flex flex-col gap-3">
                {{ t('Quick filters') }}:
              </div>
            </div>
          </template>
          <template v-else-if="displaySavedfilters">
            {{ t('Saved filters') }}
          </template>
          <template v-else>
            <div class="flex gap-5">
              <div :class="hasQuickFilters && 'w-72'">{{ t('Choose filters') }}:</div>
              <div v-if="hasQuickFilters" class="flex flex-col gap-3">{{ t('Quick filters') }}:</div>
            </div>
          </template>
        </div>
        <LscButton v-if="count" variant="plain-secondary" @click.stop="clearFilter">
          {{ t('Clear all') }}
        </LscButton>
        <slot name="menuHeader" />
      </div>

      <FilterMenuOptions
        v-show="!displaySavedfilters"
        :allowSaveFilters="allowSaveFilters"
        :hasQuickFilters="hasQuickFilters"
        @clearFilter="clearFilter"
        @updateFilter="updateFilter"
        @updateDisplayOptions="updateDisplayOptions"
      >
        <template #menuFilters><slot name="menuFilters" /></template>
        <template #menuQuickFilters><slot name="menuQuickFilters" /></template>
        <template #savedfilterTitle><slot name="savedfilterTitle" /></template>
        <template #menuOptions><slot name="menuOptions" /></template>
      </FilterMenuOptions>

      <FilterSavedFilters
        v-if="displaySavedfilters"
        :projectId="projectId"
        :section="section"
        :savedFilterParams="savedFilterParams"
        :displayFilterTitleInput="displayFilterTitleInput"
        @setSavefilterDisplay="(val) => (displaySavedfilters = val)"
        @clearFilter="clearFilter"
        @openShareFilterModal="(val) => openShareFilterModal(val)"
      />
    </LscSheet>
  </WidgetMenu>
  <FilterShareDialog
    v-if="isDialogAddTasklistOpen"
    v-model="isDialogAddTasklistOpen"
    mode="list"
    :shareFilter="shareFilter"
    @close="isDialogAddTasklistOpen = false"
    @shareFilterToUser="(val) => shareFilterToUser(val)"
    @shareFilterTitle="(val) => shareFilterTitle(val)"
  />
</template>
