import { useDebounceFn } from '@vueuse/core';
import { useInboxFilters } from './useInboxFilters';
import { inboxMapItemToPreview } from './inboxMapItemToPreview';

export function useInboxPreview({ items }) {
  if (!items || !items.value) {
    throw new Error('items must be an ref<array> of inbox items');
  }

  const filter = useInboxFilters();

  // Used to reload of TKO panel component
  const panelRefreshKey = ref(1);

  const activeItemId = ref(undefined);

  const activeItem = computed(() => {
    if (activeItemId.value === undefined) {
      return undefined;
    }

    return items.value.find((item) => activeItemId.value === item.id);
  });

  // Controls the preview component that should be rendered
  const activePreview = ref(undefined);

  const activeItemIndex = computed(() => {
    if (activeItem.value === undefined) {
      return undefined;
    }

    return items.value.findIndex((item) => activeItem.value.id === item.id);
  });

  const hasPrev = computed(() => {
    return activeItemIndex.value > 0;
  });

  const hasNext = computed(() => {
    return activeItemIndex.value < items.value.length - 1;
  });

  function setActiveItem(itemId) {
    activeItemId.value = itemId;
  }

  // debounce the setting of preview panel entity to avoid unnecessary changing of panels
  const setActivePreview = useDebounceFn(
    (item) => {
      activePreview.value = inboxMapItemToPreview(item);
    },
    300,
    { leading: true }, // set leading true to ensure best ux
  );

  /**
   * Set `activeItem` to the next item in the list
   * @returns {boolean} An indicator of whether or not the action was actually performed
   */
  function next() {
    if (items.value.length < 1) {
      return false;
    }

    if (!activeItem.value) {
      setActiveItem(items.value[0].id);
      return true;
    }

    if (!hasNext.value) {
      return false;
    }

    if (activeItemIndex.value < 0) {
      return false;
    }

    setActiveItem(items.value[activeItemIndex.value + 1].id);

    return true;
  }

  /**
   * Set `activeItem` to the previous item in the list
   * @returns {boolean} An indicator of whether or not the action was actually performed
   */
  function prev() {
    if (items.value.length < 1) {
      return false;
    }

    if (!activeItem.value) {
      setActiveItem(items.value[items.value.length - 1].id);
      return true;
    }

    if (!hasPrev.value) {
      return false;
    }

    if (activeItemIndex.value < 0) {
      return false;
    }

    setActiveItem(items.value[activeItemIndex.value - 1].id);
    return true;
  }

  const isLoadingPanel = ref(false);

  function resetPanelLoadingState() {
    isLoadingPanel.value = true;
  }

  function refreshPanel() {
    resetPanelLoadingState();
    panelRefreshKey.value += 1;
  }

  watch(activeItem, (newVal) => {
    if (!newVal) {
      return;
    }

    const shouldSetPanel = !activePreview.value || newVal.id !== activePreview.value.inboxItem.id;

    if (shouldSetPanel) {
      setActivePreview(activeItem.value);
      resetPanelLoadingState();
    }
  });

  watch(filter.queryParams, () => {
    setActiveItem(null);
  });

  return {
    activeItem,
    activeItemId: readonly(activeItemId),
    activePreview: readonly(activePreview),
    hasNext,
    hasPrev,
    isLoadingPanel,
    items,
    next,
    panelRefreshKey,
    prev,
    refreshPanel,
    setActiveItem,
  };
}
