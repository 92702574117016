<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Explore where you can see all of your assigned work and how you can understand what you need to get it done.',
        )
      }}
    </p>
    <p class="mt-4">
      {{ t('Over the next 2 minutes, discover how you can:') }}
    </p>
    <ul class="ml-4">
      <li>{{ t('Get a snapshot of your work') }}</li>
      <li>
        {{ t('Communicate with your colleagues in {teamwork}', { teamwork: 'Teamwork.com' }) }}
      </li>
    </ul>
  </div>
</template>
