<script setup>
defineProps({
  text: {
    type: String,
    default: undefined,
  },
  tooltip: {
    type: String,
    default: null,
  },
  /**
   * The icon to prepend to the text.
   * @type {PropType<{ icon: LscIcon, size: string }>}
   */
  prepend: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  critical: {
    type: Boolean,
    default: false,
  },
  hasChildren: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * The corresponding keyboard shortcut.
   */
  shortcut: {
    type: String,
    default: '',
  },
});

const listItemEl = shallowRef();

defineExpose({
  focus() {
    listItemEl.value?.$el.focus();
  },
});
</script>
<template>
  <VListItem ref="listItemEl" v-LsdTooltip="tooltip" :active="active" link :disabled="disabled">
    <template #prepend>
      <slot name="prepend">
        <VIcon v-if="prepend" :size="prepend.size || 'x-small'" :class="{ '!text-icon-critical': critical }">
          {{ prepend.icon }}
        </VIcon>
      </slot>
    </template>
    <span class="truncate text-body-1" :class="{ '!text-critical': critical }">
      <slot>{{ text }}</slot>
    </span>
    <template #append>
      <div class="ml-2 flex items-center gap-2">
        <div
          v-if="shortcut"
          class="min-w-5 rounded-sm border border-form-default px-1 text-center text-body-2 text-subtle"
        >
          {{ shortcut }}
        </div>
        <slot name="append" />
        <VIcon v-if="hasChildren" size="x-small" :class="{ '!text-critical': critical }" class="!mr-0">
          lsi-open-panel
        </VIcon>
      </div>
    </template>
  </VListItem>
</template>
