import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';

import { normalizeProof, useDefaultParams } from './util';

function responseToItems({ data }) {
  const { proofs, included } = data;

  const items = [...proofs].map((proof) => {
    return normalizeProof(proof, included);
  });

  return items;
}

function responseToMeta({ data: { meta } }) {
  return {
    canAddMore: meta.canAddMore,
    totalCount: meta.page?.count,
  };
}

function orderAscByTitle(item1, item2) {
  return item1.title.localeCompare(item2.title);
}

function orderDescByTitle(item1, item2) {
  return item2.title.localeCompare(item1.title);
}

function orderByAscStatus(item1, item2) {
  return item1.status.localeCompare(item2.status);
}

function orderByDescStatus(item1, item2) {
  return item2.status.localeCompare(item1.status);
}

function sortAscByDate(date1, date2) {
  // For consistency with the server, tasks without a startDate are ordered last.
  if (date1 && date1.isValid) {
    if (date2 && date2.isValid) {
      return date1.valueOf() - date2.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid) {
    return 1;
  }
  return 0;
}

function sortDescByDate(date1, date2) {
  // For consistency with the server, tasks without a startDate are ordered first.
  if (date2 && date2.isValid) {
    if (date1 && date1.isValid) {
      return date2.valueOf() - date1.valueOf();
    }
    return -1;
  }
  if (date1 && date1.isValid) {
    return 1;
  }
  return 0;
}

function orderAscByDueDate({ due: date1 }, { due: date2 }) {
  return sortAscByDate(date1, date2);
}

function orderDescByDueDate({ due: date1 }, { due: date2 }) {
  return sortDescByDate(date1, date2);
}

function orderByAscDateUpdated({ updatedAt: date1 }, { updatedAt: date2 }) {
  return sortAscByDate(date1, date2);
}

function orderByDescDateUpdated({ updatedAt: date1 }, { updatedAt: date2 }) {
  return sortDescByDate(date1, date2);
}

function orderByDefault({ updatedAt: date1 }, { updatedAt: date2 }) {
  return sortDescByDate(date1, date2);
}

function orderAscByProject(item1, item2) {
  return item1.parent.name.localeCompare(item2.parent.name);
}

function orderDescByProject(item1, item2) {
  return item2.parent.name.localeCompare(item1.parent.name);
}

export function useProofsLoader({ count, pageSize = 50, params: _params }) {
  const url = `/projects/api/v3/proofs.json`;

  const { params } = useDefaultParams(
    computed(() => {
      // eslint-disable-next-line no-underscore-dangle
      const __params = unref(_params) || {};

      return {
        ...__params,
        include: __params.include || ['users', 'projects', 'projects.permissions', 'companies', 'tasks'].join(','),
        orderBy: __params.orderBy || 'dateupdated',
        orderMode: __params.orderMode || 'desc',
      };
    }),
  );

  const order = computed(() => {
    const { orderBy, orderMode } = params.value;
    const desc = orderMode === 'desc';

    switch (orderBy.toLowerCase()) {
      case 'title':
        return desc ? orderDescByTitle : orderAscByTitle;
      case 'duedate':
        return desc ? orderDescByDueDate : orderAscByDueDate;
      case 'projectname':
        return desc ? orderDescByProject : orderAscByProject;
      case 'dateupdated':
        return desc ? orderByDescDateUpdated : orderByAscDateUpdated;
      case 'status':
        return desc ? orderByDescStatus : orderByAscStatus;
      default:
        // eslint-disable-next-line no-console
        console.warn(`useProofsLoader: unknown params.orderBy: ${orderBy}`);
        return orderByDefault;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
    order,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'proof') {
      refresh(event.proofId);
    }
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'proof') {
      return;
    }

    const actions = ['create', 'update', 'delete'];
    if (!actions.includes(event.action)) {
      return;
    }

    update((proofs) => {
      if (event.action === 'create') {
        return proofs.concat(event.proof);
      }

      if (event.action === 'update') {
        return proofs.map((proof) => (proof.id === event.proof.id ? { ...proof, ...event.proof } : proof));
      }

      if (event.action === 'delete') {
        return proofs.filter((proof) => proof.id !== event.proof.id);
      }

      return proofs;
    }, event.promise);
  });

  return state;
}
