<script setup>
import { useI18n, normalizeFile, downloadFile } from '@/util';

const props = defineProps({
  files: {
    type: Array,
    required: true,
  },
  limit: {
    type: Number,
    default: 0,
  },
  variant: {
    type: String,
    default: 'default',
    validator(prop) {
      return ['default', 'compact', 'thumbnail'].includes(prop);
    },
  },
  grid: {
    type: String,
    default: '2',
  },
  /**
   * Whether the user can download files or not. Ususally retrieved from the `project permissions`.
   */
  canDownloadFiles: {
    type: Boolean,
    required: false,
  },
  /**
   * Whether the user can preview files or not. Ususally retrieved from the `project permissions`.
   */
  canPreviewFiles: {
    type: Boolean,
    required: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['preview']);

const selectedModel = defineModel('selected', { type: [Array, undefined], default: undefined });

const { t } = useI18n();
// local pagination
const pageSize = shallowRef(props.limit);
const items = computed(() => {
  const pagination = props.limit > 0 ? props.files.slice(0, pageSize.value) : props.files;
  return pagination.map(normalizeFile);
});

const canLoadMore = computed(() => props.limit && pageSize.value < props.files.length);
const selectedItems = shallowRef([]);

function loadMore() {
  pageSize.value += props.limit;
}

// TODO Remove this in favour of a predefined grid setting
const gridClass = computed(() => {
  return {
    // tailwind classes needs to be declare pure
    'grid-cols-1': props.grid === '1',
    'grid-cols-2': props.grid === '2',
    'grid-cols-3': props.grid === '3',
    'grid-cols-4': props.grid === '4',
    'grid-cols-5': props.grid === '5',
    'grid-cols-6': props.grid === '6',
    'grid-cols-7': props.grid === '7',
    'grid-cols-8': props.grid === '8',
    'grid-cols-9': props.grid === '9',
    'grid-cols-10': props.grid === '10',
    'grid-cols-11': props.grid === '11',
    'grid-cols-12': props.grid === '12',
    'grid-cols-none': props.grid === 'none',
  };
});

const gapClass = computed(() => {
  return {
    'gap-1': props.variant === 'compact',
    'gap-2': props.variant === 'default' || props.variant === 'thumbnail',
  };
});

function onSelectedItems({ id }) {
  if (props.selected) {
    if (selectedItems.value.includes(id)) {
      selectedItems.value = [...selectedItems.value.filter((item) => item !== id)];
    } else {
      selectedItems.value = [...selectedItems.value, id];
    }
    selectedModel.value = props.files.filter((f) => selectedItems.value.includes(f.id || f.fileId));
  }
}

/**
 * Note: if a file is previewable or downloadable, it will be handled within the`LscFileItem`
 * component. This function is used as a fallback for files that are not previewable or
 * downloadable i.e. some externally hosted file types.
 */
function openFile(file) {
  window.open(`${window.location.origin}/?action=viewFile&fileId=${file.id}&display=true`, '_blank');
}
</script>
<template>
  <div class="grid max-h-80 w-full overflow-y-auto" :class="[gridClass, gapClass]">
    <LscFileItem
      v-for="file in items"
      :key="file.id"
      :canPreviewFiles="canPreviewFiles"
      :canDownloadFiles="canDownloadFiles && file.fileSource === 'teamworkpm'"
      :file="file"
      :variant="variant"
      :class="{ 'bg-surface-emphasis-selected': selectedItems.includes(file.id) }"
      :dataIdentifierPrefix="dataIdentifierPrefix"
      @preview="emit('preview', file)"
      @download="downloadFile(file)"
      @click="selected !== undefined ? onSelectedItems(file) : openFile(file)"
    >
      <template #menu="menu">
        <slot name="menu" v-bind="menu" />
      </template>
      <template #actions="actions">
        <slot name="actions" v-bind="actions" />
      </template>
    </LscFileItem>
    <LscButton v-if="canLoadMore" variant="plain-primary" @click="loadMore">
      {{
        files.length - pageSize < limit
          ? t('Show 1 remaining file | Show {n} remaining files', {
              n: files.length - pageSize,
            })
          : t('Show +{limit} more files', { limit })
      }}
    </LscButton>
    <slot />
  </div>
</template>
