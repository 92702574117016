<script setup>
const props = defineProps({
  /**
   * The icon to display on the button before the text.
   */
  icon: {
    type: String,
    default: '',
  },
  /**
   * Used to set the button's active state when the menu is open.
   */
  ariaExpanded: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to set the button's active state when a filter is selected.
   */
  ariaPressed: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to set the button's disabled state.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
});

const lswFilterOptionButtonVariantStyleConfig = tv({
  base: [
    'group/LswFilterOptionButton flex h-8 items-center gap-2 rounded-md border px-3 py-1',
    'border-form-default hover:border-form-hover aria-expanded:border-form-active aria-pressed:border-form-active',
    'focus:outline-focus-primary aria-disabled:cursor-not-allowed aria-disabled:border-form-disabled',
  ],
  slots: {
    icon: ['shrink-0 text-icon-subtle', 'group-aria-disabled/LswFilterOptionButton:text-icon-disabled'],
    label: ['w-full text-left text-body-1', 'group-aria-disabled/LswFilterOptionButton:text-disabled'],
    dropdownIcon: [
      'shrink-0 text-icon-subtle transition-transform',
      'group-aria-disabled/LswFilterOptionButton:text-icon-disabled group-aria-expanded/LswFilterOptionButton:rotate-180',
    ],
  },
});

const classes = computed(() => lswFilterOptionButtonVariantStyleConfig(props));
</script>

<template>
  <button
    :class="classes.base()"
    type="button"
    :aria-expanded="ariaExpanded"
    :aria-pressed="ariaPressed"
    :aria-disabled="disabled"
    :disabled="disabled"
  >
    <LscIcon v-if="icon" :icon="icon" :class="classes.icon()" size="sm" />
    <LscOverflowEllipsis :class="classes.label()">
      <slot />
    </LscOverflowEllipsis>
    <LscIcon :class="classes.dropdownIcon()" icon="lsi-dropdown" size="sm" />
  </button>
</template>
