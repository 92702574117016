<script setup>
// Embedded chat loads in after 1 second delay (in production) or when panel opened.
// Once the embedded chat is loaded, we want to keep it in-memory.
// However we do not want to show anything if the chat panel is not selected.

import { useI18n } from '@/util';
import { useEmbeddedChat } from '@/api';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { useLegacyBridge } from '@/module/legacy';

const ChatDrawerContent = defineAsyncComponent(() => import('./ChatDrawerContent.vue'));

const { t } = useI18n();
const { activeDrawerPanel } = useAppShellSidebar();
const embeddedChat = useEmbeddedChat();
const { setChatEnabled } = useLegacyBridge();

const shouldMountWithDelay = shallowRef(false);
const wasOpenedOnce = shallowRef(activeDrawerPanel.value === 'chat');

function markAllConversationsAsRead() {
  embeddedChat.markAllConversationsAsRead();
}

function toggleEmbeddedNotifications() {
  embeddedChat.setNotificationsEnabled(!embeddedChat.state.isNotificationsEnabled);
}

const isNotificationsEnabled = computed(() => embeddedChat.state.isNotificationsEnabled);

const notificationsEnabledLabel = computed(() => {
  return isNotificationsEnabled.value ? t('Disable embedded notifications') : t('Enable embedded notifications');
});

const notificationsEnabledIcon = computed(() => {
  return isNotificationsEnabled.value ? 'lsi-unmute-notification' : 'lsi-mute-notification';
});

watch(activeDrawerPanel, (newVal) => {
  if (newVal === 'chat') {
    wasOpenedOnce.value = true;
  }
});

watch(embeddedChat.state, ({ isEnabled }) => setChatEnabled(isEnabled), { immediate: true });

onMounted(() => {
  if (import.meta.env.PROD) {
    setTimeout(() => {
      shouldMountWithDelay.value = true;
    }, 1000);
  }
});
</script>

<template>
  <AppShellDrawerPanel
    v-if="wasOpenedOnce || shouldMountWithDelay"
    :class="{ invisible: activeDrawerPanel !== 'chat' }"
  >
    <template #header>
      <AppShellDrawerTitle title="Chat">
        <template #prepend-icons="{ buttonSize }">
          <LscIconButton
            v-LsdTooltip="t('Mark all as read')"
            :ariaLabel="t('Mark all as read')"
            :size="buttonSize"
            icon="lsi-mark-all-read"
            @click="markAllConversationsAsRead"
          />
          <LscIconButton
            v-LsdTooltip="notificationsEnabledLabel"
            :ariaLabel="notificationsEnabledLabel"
            :size="buttonSize"
            :icon="notificationsEnabledIcon"
            @click="toggleEmbeddedNotifications"
          />
        </template>
      </AppShellDrawerTitle>
    </template>
    <template #content>
      <ChatDrawerContent :isVisible="activeDrawerPanel === 'chat'" />
    </template>
  </AppShellDrawerPanel>
</template>
