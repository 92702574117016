const symbol = Symbol('useLscAccordion');

/**
 * @param {Object} options
 * @param {Ref<String>} options.items
 * @param {Ref<Boolean>} options.multiple
 */
function LscAccordion({ multiple }) {
  const shownItems = ref([]);

  watch(multiple, () => {
    if (!multiple.value && shownItems.value.length > 1) {
      shownItems.value = [shownItems.value[0]];
    }
  });

  function isExpanded(name) {
    return shownItems.value.includes(name);
  }

  function toggle(name) {
    if (isExpanded(name)) {
      shownItems.value = !multiple.value ? [] : shownItems.value.filter((i) => i !== name);
    } else {
      shownItems.value = !multiple.value ? [name] : [...shownItems.value, name];
    }
  }

  return {
    isExpanded,
    toggle,
  };
}

/**
 *
 * @param {Parameters<LscAccordion>} options
 */
export function provideLscAccordion(options) {
  provide(symbol, LscAccordion(options));
}

/**
 *
 * @returns {LscAccordion}
 */
export function useLscAccordion() {
  return inject(symbol);
}
