<script setup>
const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
  cards: {
    type: Array,
    default: () => [],
  },
});

const ProjectPortfolioManageBoardsDialogPanel = defineAsyncComponent(
  () => import('./ProjectPortfolioManageBoardsDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }"><ProjectPortfolioManageBoardsDialogPanel v-bind="props" @close="close" /></template>
  </WidgetDialog>
</template>
