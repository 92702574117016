import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useSavedfilterActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { socketId, emit: _emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  function emitOptimisticUpdate(promise, action, savedfilter) {
    _emitOptimisticUpdate({ promise, type: 'savedfilter', action, savedfilter });
  }

  /**
   *
   * @param {string} action
   * @param {object} project
   */
  function emitRealTimeUpdate(action, savedfilter) {
    _emitRealTimeUpdate({
      type: 'savedfilter',
      action,
      savedfilterId: savedfilter.id,
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  // To save a filter any `customField` key and value must be added to an array of `customFields` then
  // converted into a string. This function supports legacy filters which come from TWA and matches the
  // format.
  function parseCustomFieldsForSavedFilter(parameters, customFieldsKeys) {
    const params = parameters;
    const customFields = [];
    customFieldsKeys.forEach((key) => {
      if (params[key] || params[key] != null) {
        const match = key.match(/\[(.*?)\]/g);
        const splitKey = match.map((matched) => matched.slice(1, -1));
        const fieldId = splitKey[0];
        const existingFieldIndex = customFields.findIndex(({ fieldId: id }) => fieldId === id);
        if (existingFieldIndex === -1) {
          customFields.push({
            fieldId,
            operator: splitKey[1],
            value: params[key],
          });
        } else {
          const values = [customFields[existingFieldIndex].value, params[key]];
          customFields[existingFieldIndex].operator =
            customFields[existingFieldIndex].operator === 'lt' || customFields[existingFieldIndex].operator === 'gt'
              ? 'range'
              : 'custom';
          customFields[existingFieldIndex].value = values;
        }
      }
      delete params[key];
    });
    params.customFields = JSON.stringify(customFields);
    return params;
  }

  function createSavedfilter(savedfilter, { updateDefaults } = {}) {
    let parameters = { ...savedfilter.parameters };

    const customFieldsKeys = Object.keys(parameters).filter((key) => key.startsWith(`customField[`));

    if (customFieldsKeys.length) {
      parameters = parseCustomFieldsForSavedFilter(parameters, customFieldsKeys);
    }

    const promise = api
      .post(
        '/projects/api/v3/me/savedfilters.json',
        {
          savedfilter: {
            ...savedfilter,
            parameters,
          },
          updateDefaults,
        },
        { ...config(), errorMessage: t('Failed to create the saved filter') },
      )
      .then((response) => {
        emitRealTimeUpdate('new', response.data.savedfilter);
        return response.data.savedfilter;
      });

    emitOptimisticUpdate(promise, 'create', savedfilter);

    return promise;
  }

  function updateSavedfilter(savedfilter, { updateDefaults = false } = {}) {
    const { id, ...updatedFilter } = savedfilter;
    let parameters = { ...updatedFilter.parameters };
    const customFieldsKeys = Object.keys(parameters).filter((key) => key.startsWith(`customField[`));

    if (customFieldsKeys.length) {
      parameters = parseCustomFieldsForSavedFilter(parameters, customFieldsKeys);
    }

    const promise = api
      .patch(
        `/projects/api/v3/me/savedfilters/${id}.json`,
        {
          savedfilter: {
            ...updatedFilter,
            parameters,
          },
          updateDefaults,
        },
        { ...config(), errorMessage: t('Failed to update the saved filter') },
      )
      .then((response) => {
        emitRealTimeUpdate('updated', response.data.savedfilter);
        return response.data.savedfilter;
      });
    emitOptimisticUpdate(promise, 'update', savedfilter);
    return promise;
  }

  function setDefaultSavedfilter(savedfilter) {
    const { id, filterMeta, parameters } = savedfilter;
    let params = { ...parameters };

    // Delete any `customField` key from parameters before setting the default. The API Does not allow us to save
    // customField[id][operator] as part of the default saved filter.
    const customFieldsKeys = Object.keys(params).filter((key) => key.startsWith(`customField[`));
    if (customFieldsKeys.length) {
      params = parseCustomFieldsForSavedFilter(params, customFieldsKeys);
    }

    const promise = api.put(
      `/projects/api/v3/me/savedfilters/${id}.json`,
      {
        // Formatting for API.
        savedfilter: {
          ...filterMeta,
          parameters: params,
        },
        updateDefaults: true,
      },
      config(),
    );
    return promise;
  }

  function clearDefaultSavedfilter(savedfilter) {
    const { filterMeta, parameters } = savedfilter;
    const params = { ...parameters };

    // Delete any `customField` key from parameters before clearing the default. The API Does allow us to save
    // customField[id][operator] as part of the default saved filter.
    const customFieldsKeys = Object.keys(params).filter((key) => key.startsWith(`customField[`));
    if (customFieldsKeys.length) {
      customFieldsKeys.forEach((key) => {
        delete params[key];
      });
    }

    const promise = api.post(
      `/projects/api/v3/me/savedfilters.json`,
      {
        savedfilter: {
          ...filterMeta,
          parameters: params,
        },
      },
      config(),
    );
    return promise;
  }

  function deleteSavedfilter(savedfilter) {
    const promise = api
      .delete(`/projects/api/v3/me/savedfilters/${savedfilter.id}.json`, {
        ...config(),
        errorMessage: t('Failed to delete the saved filter'),
      })
      .then(() => {
        emitRealTimeUpdate('deleted', savedfilter);
        return savedfilter;
      });

    emitOptimisticUpdate(promise, 'delete', savedfilter);

    return promise;
  }

  function repositionSavedfilter(savedfilter, positionAfterTaskId) {
    const promise = api
      .put(
        `/projects/api/v3/me/savedfilters/${savedfilter.id}/reposition.json`,
        { positionAfter: positionAfterTaskId },
        { ...config(), errorMessage: t('Failed to move the saved filter') },
      )
      .then(() => {
        emitRealTimeUpdate('reposition', savedfilter, positionAfterTaskId);
      });

    emitOptimisticUpdate(promise, 'update', { ...savedfilter, positionAfterTaskId });

    return promise;
  }

  function shareSavedfilter(filterID, shareFilterData) {
    return api
      .post(`/sharefilter/${filterID}.json`, shareFilterData, {
        ...config(),
        errorMessage: t('Failed to create the shared filter'),
      })
      .then((res) => res);
  }

  return {
    createSavedfilter,
    updateSavedfilter,
    setDefaultSavedfilter,
    deleteSavedfilter,
    repositionSavedfilter,
    clearDefaultSavedfilter,
    shareSavedfilter,
  };
}
