<script setup>
import { useI18n } from '@/util';

const props = defineProps({
  projectTags: {
    type: Object,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'loadTags']);

const tags = defineModel('tags', {
  type: Array,
  default: () => [],
});

const { t } = useI18n();

const filteredTagsGroupByProject = computed(() => {
  const groupByObj = Object.groupBy(props.projectTags.result, ({ projectId }) => projectId);
  return Object.keys(groupByObj)
    .reverse()
    .map((key) => ({ ...groupByObj[key], key }));
});

function removeTag(tag) {
  tags.value = tags.value.filter(({ id }) => id !== tag.id);
}

function addTag(tag) {
  tags.value = [...tags.value, tag];
  emit('close');
}

function clearTags() {
  tags.value = [];
}

function getRandomInt(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
}

const skeletonItems = computed(() => {
  return Array(30)
    .fill(null)
    .map((_, id) => ({
      id,
      width: `${getRandomInt(50, 80)}px`,
    }));
});
</script>

<template>
  <div>
    <div v-if="tags.length" class="mb-4 overflow-hidden">
      <div class="mb-3 flex items-center gap-2 text-body-1">
        <span>{{ t('No selected tags | 1 selected tag | {n} selected tags', { n: tags.length }) }}</span>
        <LscButton variant="plain-primary" @click.stop="clearTags">
          {{ t('Clear') }}
        </LscButton>
      </div>
      <div class="flex flex-wrap gap-x-2 gap-y-3">
        <LscChip
          is="button"
          v-for="tag in tags"
          :key="tag.id"
          :color="tag.color"
          variant="tag"
          class="max-w-full"
          size="sm"
          :clearable="true"
          @clear="removeTag(tag)"
        >
          {{ tag.name }}
        </LscChip>
      </div>
    </div>
    <div data-test-id="tag-picker-select-tag-list" class="overflow-hidden">
      <WidgetLoadingState :state="state">
        <template #default>
          <div class="flex flex-col gap-4">
            <template v-for="groupTags of filteredTagsGroupByProject" :key="groupTags">
              <div class="flex flex-col gap-3">
                <h4 class="text-body-1 font-semibold text-default">
                  {{ groupTags[0].projectId === 0 ? t('Global') : t('Project') }}
                </h4>
                <div class="flex flex-wrap gap-x-2 gap-y-3">
                  <template v-for="tag of groupTags">
                    <LscChip
                      is="button"
                      v-if="!tag.selected && tag.name"
                      :key="tag.id"
                      class="max-w-full"
                      :color="tag.color"
                      variant="tag"
                      size="sm"
                      @click="addTag(tag)"
                    >
                      {{ tag.name }}
                    </LscChip>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template #loading>
          <div class="flex flex-wrap gap-x-2 gap-y-3">
            <LscSkeleton
              v-for="item in skeletonItems"
              :key="item.id"
              class="h-6 w-[--width] rounded-full"
              :style="{ '--width': item.width }"
            />
          </div>
        </template>
        <template #error>
          <LscEmptyState :title="t('Error loading tags')">
            <template #actions>
              <LscButton variant="primary" @click="emit('loadTags')">{{ t('Retry') }}</LscButton>
            </template>
          </LscEmptyState>
        </template>
      </WidgetLoadingState>
    </div>
  </div>
</template>
