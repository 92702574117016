import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';

let ID = 0;

export function useJobRoleActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  function emitOptimisticUpdate(promise, action, jobRole) {
    _emitOptimisticUpdate({ promise, type: 'jobRole', action, jobRole });
  }

  function emitRealTimeUpdate(action, jobRole) {
    _emitRealTimeUpdate({
      type: 'jobRole',
      action,
      jobRole,
    });
  }

  function createJobRole(jobRole) {
    const promise = api
      .post(
        '/projects/api/v3/jobroles.json',
        { jobRole },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage(error) {
            if (error.response?.status === 400) {
              return t('Role name must be unique');
            }
            return t('There was a problem creating the role');
          },
        },
      )
      .then((response) => {
        const newJobRole = response.data.jobRole;
        emitRealTimeUpdate('added', newJobRole);
        return newJobRole;
      });

    emitOptimisticUpdate(promise, 'create', {
      id: --ID,
      users: [],
      ...jobRole,
      createdAt: DateTime.now(),
      updatedAt: DateTime.now(),
    });

    return promise;
  }

  function updateJobRole(jobRole) {
    const { id, ...payload } = jobRole;
    const promise = api
      .patch(
        `/projects/api/v3/jobroles/${id}.json`,
        { jobRole: payload },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('There was a problem updating the role'),
        },
      )
      .then((response) => {
        const updated = response.data.jobRole;
        emitRealTimeUpdate('updated', updated);
        return updated;
      });

    emitOptimisticUpdate(promise, 'update', {
      id,
      ...jobRole,
    });

    return promise;
  }
  function attachUserToJobRole(role, userToAttach, isPrimary) {
    const promise = api
      .post(
        `/projects/api/v3/jobroles/${role.id}/people.json`,
        { users: userToAttach.id, isPrimary },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t("There was a problem updating the user's role"),
        },
      )
      .then((response) => {
        const updated = response.data.jobRole;
        emitRealTimeUpdate('updated', updated);
        return updated;
      });

    emitOptimisticUpdate(promise, 'update', {
      ...role,
      users: [userToAttach],
    });

    return promise;
  }

  function attachUsersToJobRole(role, usersToAttach) {
    const users = usersToAttach.map((user) => user.id);

    const promise = api
      .post(
        `/projects/api/v3/jobroles/${role.id}/people.json`,
        { users },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('There was a problem updating the role users'),
        },
      )
      .then((response) => {
        const updated = response.data.jobRole;
        emitRealTimeUpdate('updated', updated);
        return updated;
      });

    emitOptimisticUpdate(promise, 'update', {
      ...role,
      users,
    });

    return promise;
  }

  function detachUsersFromJobRole(role, usersToDetach) {
    const users = usersToDetach.map((user) => user.id);

    const promise = api
      .delete(`/projects/api/v3/jobroles/${role.id}/people.json`, {
        data: { users },
        headers: {
          'Socket-ID': socketId,
        },
        errorMessage: t('There was a problem updating the role users'),
      })
      .then(() => {
        emitRealTimeUpdate('updated', role);
      });

    emitOptimisticUpdate(promise, 'update', {
      ...role,
      users,
    });

    return promise;
  }

  function deleteJobRole(role) {
    const promise = api
      .delete(`/projects/api/v3/jobroles/${role.id}.json`, {
        headers: {
          'Socket-ID': socketId,
        },
        errorMessage: t('There was a problem deleting the role'),
      })
      .then((response) => {
        emitRealTimeUpdate('deleted', role);
        return response;
      });

    emitOptimisticUpdate(promise, 'delete', role);

    return promise;
  }

  return {
    createJobRole,
    updateJobRole,
    attachUserToJobRole,
    attachUsersToJobRole,
    detachUsersFromJobRole,
    deleteJobRole,
  };
}
