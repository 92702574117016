/* eslint-disable */
import { useCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentUserState } from '../user/useCurrentUser';
import { usePermissions } from '../permissions/usePermissions';
import { usePricePlan } from '../account/usePricePlan';
import { useFeatures } from './useFeatures';

const symbol = Symbol('useHotjar');

function Hotjar() {
  const { item: account, inSync: accountInSync } = useCurrentAccountState();
  const { item: user, inSync: userInSync } = useCurrentUserState();
  const { hotjarEnabled, initialized } = useFeatures();
  const {
    canAddProjects,
    canManagePeople,
    canManagePortfolio,
    canManageProjectTemplates,
    isOwnerAdmin,
    isSiteAdmin,
    isCollaborator,
  } = usePermissions();

  const {
    isInFreeTrial,
    isPaid,
    isPlanFree,
    paidUserCount: planPaidUserCount,
    pricePlanId,
    pricePlanType,
    isPlanPerUser,
  } = usePricePlan();

  function trackUserInfo() {
    if (!window.hj) return;
    const uid = account.value.id + '-' + user.value.id;
    let paidUserCount;
    if (isPlanPerUser.value) {
      paidUserCount = planPaidUserCount.value;
    } else {
      paidUserCount = user.value.stats?.users?.count ?? 0;
    }
    return window.hj('identify', uid, {
      installation_id: account.value.id,
      created_at: user.value.createdAt,
      teamwork_projects_user: true,
      teamwork_desk_user: account.value.deskEnabled,
      teamwork_chat_user: account.value.chatEnabled,
      teamwork_spaces_user: account.value.spacesEnabled,
      teamwork_crm_user: account.value.CRMEnabled,
      site_owner: isOwnerAdmin.value,
      site_admin: isSiteAdmin.value,
      site_baseUrl: account.value.URL,
      in_owner_company: user.value.inOwnerCompany,
      collaborator: isCollaborator.value,
      add_projects: canAddProjects.value,
      manage_people_and_companies: canManagePeople.value,
      manage_portfolios: canManagePortfolio.value,
      manage_task_templates: canManageProjectTemplates.value,
      is_trial: isInFreeTrial.value,
      is_paying: isPaid.value && !isPlanFree.value,
      company_id: account.value.id,
      company_name: account.value.name,
      company_plan_type: pricePlanType.value,
      company_plan_id: pricePlanId.value,
      company_trial_plan_id: account.value.pricePlanTrialData?.id,
      company_trial_plan_days_remaining: account.value.pricePlanTrialData?.remainingDays,
      company_license_count: paidUserCount,
      company_start_date: account.value.dateSignedUp.toUTC().toISO(),
      company_industry: account.value.industryCategoryName,
      company_site_baseUrl: account.value.URL,
      company_server_region: account.value.awsRegion,
      company_shard_no: account.value.shardNo,
      company_first_payment_date: account.value.installationDateFirstPayment,
      company_size_id: account.value.companySizeId,
      user_role_id: user.value.companyRoleId,
      icp_flag: account.value.isICP ? 1 : 0,
    });
  }

  function initHotjar() {
    ((h, o, t, j, a, r) => {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 266036, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    trackUserInfo();
  }

  const unwatch = watch(
    () =>
      Boolean(
        accountInSync.value &&
          userInSync.value &&
          initialized.value &&
          hotjarEnabled.value &&
          account.value.installationTrackingEnabled !== false &&
          !user.value.impersonating &&
          !user.value.twAdminId,
      ),
    (shouldInitHotjar) => {
      if (shouldInitHotjar) {
        initHotjar();
        queueMicrotask(() => unwatch());
      }
    },
    {
      immediate: true,
    },
  );

  /**
   *
   * @param {string} eventName
   */
  function trackClick(eventName) {
    if (!window.hj || !hotjarEnabled.value) return;
    try {
      window.hj('event', eventName);
    } catch (e) {
      console.error('Error tracking hj event', e);
    }
  }

  return {
    trackClick,
  };
}

/**
 * @returns {Hotjar}
 */
export function provideHotjar() {
  const hotjar = Hotjar();
  provide(symbol, hotjar);
}

/**
 * @returns {Hotjar}
 */
export function useHotjar() {
  return inject(symbol);
}
