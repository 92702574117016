<script setup>
import { debouncedWatch } from '@vueuse/core';
import { useWindowSize } from '@/util';

// Component that given an array, it display a list of chips with props combinations
const props = defineProps({
  tags: {
    type: Array,
    required: true,
  },
  limit: {
    type: [Number, String],
    default: 2,
    validator: (value) => (Number.isInteger(value) && value > 0) || value === 'auto',
  },
  /**
   * @type {PropType<'compact'|'default'>}
   */
  density: {
    validator: (value) => ['compact', 'default'].includes(value),
    default: 'default',
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  tooltipOffset: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['update:tags', 'clickTag']);

const autoLimit = shallowRef(props.tags.length);
const effectiveLimit = computed(() => (props.limit === 'auto' ? autoLimit.value : props.limit));
const tags = computed(() => props.tags.filter((tag) => tag.name));
const visibleTags = computed(() => tags.value.slice(0, effectiveLimit.value));
const hiddenTags = computed(() => tags.value.slice(effectiveLimit.value));

const element = shallowRef();
const minWidth = shallowRef();
const hover = shallowRef();
const { width } = useWindowSize();

function removeTag(tag) {
  if (!props.canEdit) {
    return;
  }
  emit(
    'update:tags',
    props.tags.filter(({ id }) => id !== tag.id),
  );
}

async function updateAutoLimit() {
  if (props.limit !== 'auto' || props.tags.length <= 1) {
    return;
  }

  autoLimit.value = props.tags.length;
  minWidth.value = undefined;

  await nextTick();

  const child = element.value?.firstElementChild;
  if (!child) {
    return;
  }

  // `+ 40` accounts for the width of the `+X` chip.
  minWidth.value = `${child.offsetWidth + 40}px`;

  await nextTick();

  while (autoLimit.value > 1 && element.value.scrollWidth > element.value.clientWidth) {
    autoLimit.value--;
    // eslint-disable-next-line no-await-in-loop
    await nextTick();
  }
}

onMounted(updateAutoLimit);
watch(() => props.tags, updateAutoLimit);
debouncedWatch(width, updateAutoLimit, { debounce: 500 });
</script>

<template>
  <div
    ref="element"
    class="items-center gap-1"
    :class="limit === Infinity ? 'flex flex-wrap' : 'inline-flex'"
    :style="{ minWidth }"
  >
    <VChip
      v-for="tag in visibleTags"
      :key="tag.id"
      v-LsdTooltip="tag.name"
      v-bind="VChipLabelTruncate"
      :color="tag.color"
      size="x-small"
      :closable="canEdit && hover === tag.id"
      :class="{
        'cursor-default': !canEdit,
        closable: canEdit,
      }"
      class="min-w-[max-content]"
      @mouseenter="hover = tag.id"
      @mouseleave="hover = undefined"
      @click="emit('clickTag', tag)"
      @click:close.stop="removeTag(tag)"
    >
      <span
        class="truncate text-body-2"
        :class="{
          'max-w-52': density === 'compact',
          'max-w-40': density === 'default',
        }"
      >
        {{ tag.name }}
      </span>
    </VChip>

    <WidgetMenu v-if="hiddenTags.length > 0" offset="8">
      <template #activator="activator">
        <VChip
          v-LsdTooltip="hiddenTags.map((tag) => tag.name).join('\n')"
          color="neutral-60"
          size="x-small"
          class="min-w-[max-content] cursor-pointer"
          v-bind="activator.props"
          @click.stop.prevent
        >
          +{{ hiddenTags.length }}
        </VChip>
      </template>
      <LscSheet class="flex w-80 flex-wrap items-center gap-2">
        <VChip
          v-for="tag in hiddenTags"
          :key="tag.id"
          v-LsdTooltip="density === 'compact' ? tag.name : undefined"
          :color="tag.color"
          size="small"
          :closable="canEdit"
          :class="{ 'cursor-default': !canEdit, closable: canEdit }"
          @click="emit('clickTag', tag)"
          @click:close.stop="removeTag(tag)"
        >
          <span
            class="truncate"
            :class="{
              'max-w-20': density === 'compact',
              'max-w-40': density === 'default',
            }"
          >
            {{ tag.name }}
          </span>
        </VChip>
      </LscSheet>
    </WidgetMenu>
  </div>
</template>
