import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { ONBOARDING_EVENT_NAME as eventName } from '@/module/onboarding';

/**
 * Constants, computed properties and functions related to Experiment G-23-07
 * https://app.launchdarkly.com/default/production/features/projects-g-23-07-onboarding-signup-source-survey/targeting
 * https://digitalcrew.teamwork.com/spaces/growth-and-acquisition/page/46394-g-23-07-signup-source-survey-howd-you-hear-about-us
 */

export function useExperimentG07() {
  const { onboardingSignupSourceSurveyStepEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const { launchDarklyFlagKey, defaultValue } = onboardingSignupSourceSurveyStepEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const EXP_G07_COMMON_METRICS = ['account_id'];

  function track(metadata) {
    trackPendoEvent({
      eventName,
      commonMetrics: EXP_G07_COMMON_METRICS,
      launchDarklyFlagKey,
      metadata,
    });
  }

  function trackExperimentG07() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackExpG07SignupSourceSubmitted(previousPage, signupType) {
    track({
      page_type: 'signup_source',
      previous_page: previousPage,
      event_action: 'signup_source_submitted',
      signup_type: signupType,
    });
  }

  return {
    onboardingSignupSourceSurveyStepEnabled,
    trackExperimentG07,
    trackExpG07SignupSourceSubmitted,
  };
}
