<script setup>
import { useFileDialog } from '@vueuse/core';
import { useI18n } from '@/util';
import { useLscDropzone } from './useLscDropzone';
import LscMessage from '../../infodisplay/message/LscMessage.vue';

const { t, formatList } = useI18n();
const { onChange, open } = useFileDialog();
const {
  size,
  multiple,
  selectFiles,
  isOverDropZone,
  error,
  errorMessage,
  showMessage,
  message,
  extensions,
  acceptedMimeTypesString,
} = useLscDropzone();

const computedMessage = computed(() => {
  if (message.value) {
    return message.value;
  }

  if (extensions.value.length) {
    return formatList(
      extensions.value.map((ext) => ext.toUpperCase()),
      { type: 'disjunction' },
    );
  }

  return '';
});

function openFileDialog() {
  open({
    accept: acceptedMimeTypesString.value,
    multiple: multiple.value,
  });
}

onChange((fileList) => {
  selectFiles(Array.from(fileList));
});
</script>
<template>
  <div
    class="flex h-full flex-col items-stretch gap-2"
    :class="{
      'min-h-18': size === 'sm',
      'min-h-36': size === 'md',
    }"
  >
    <div
      class="flex flex-auto cursor-pointer flex-col items-center justify-center rounded-sm border border-dashed border-form-default bg-default transition hover:border-form-hover hover:bg-hover active:border-form-active active:bg-selected"
      :class="{
        '!border-critical-default !bg-default': error,
        '!border-form-active !bg-selected': isOverDropZone,
      }"
      role="button"
      @click="openFileDialog"
    >
      <div
        class="flex flex-col items-center px-3 text-body-1 font-medium"
        :class="error ? 'text-critical' : 'text-default'"
      >
        <div class="flex items-center gap-1 text-pretty text-body-1">
          <LscIcon icon="lsi-upload" :class="error ? 'text-icon-critical' : 'text-icon-subtle'" />

          {{ multiple ? t('Drag and drop your files here') : t('Drag and drop your file here') }}.
          <LscButton variant="plain-primary" :class="error && '!text-critical'">
            {{ t('Browse files') }}
          </LscButton>
        </div>

        <p
          v-if="showMessage"
          class="text-balance text-center text-body-2"
          :class="error ? 'text-critical' : 'text-subtle'"
        >
          {{ computedMessage }}
        </p>
      </div>
    </div>
    <LscMessage v-if="!isOverDropZone && errorMessage" :message="errorMessage" />
  </div>
</template>
