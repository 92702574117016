<script setup>
import HelpCenterDrawerTitle from '../HelpCenterDrawerTitle.vue';
import HelpCenterDrawerWebinarCard from '../HelpCenterDrawerWebinarCard.vue';
import { useWebinarsLoader } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const activeTab = shallowRef('getting-started');
const state = useWebinarsLoader({ count: 20, params: {} });
const { items: webinars } = state;

const gettingStartedWebinars = computed(() =>
  webinars.value
    .filter((webinar) => webinar.category === 'getting-started')
    .map((webinar) => ({
      ...webinar,
      tags: [
        { label: t('Upcoming'), type: 'var(--lsds-a-color-decorative-4)' },
        { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
      ],
      actionText: t('Learn more'),
      actionPendoLabel: 'learn_more',
    }))
    .slice(0, 1),
);

const successStartedWebinars = computed(() =>
  webinars.value
    .filter((webinar) => webinar.category === 'success')
    .map((webinar) => ({
      ...webinar,
      tags: [
        { label: t('Upcoming'), type: 'var(--lsds-a-color-decorative-4)' },
        { label: t('Success'), type: 'var(--lsds-a-color-decorative-2)' },
      ],
      actionText: t('Learn more'),
      actionPendoLabel: 'learn_more',
    }))
    .slice(0, 1),
);

const onDemandGettingStartedWebinars = [
  {
    title: 'Get Started: Resource Management',
    description:
      "Dive into workload and portfolio management so you can not only plan work but understand how it's actually going.",
    tags: [
      { label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' },
      { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
    ],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 37,
    webinarLink: 'https://teamwork-videos.wistia.com/medias/lm2zf3wavb',
  },
  {
    title: 'Get Started: Streamline Work',
    description:
      'Build upon what you know and begin organizing your account, streamlining repeatable processes, and reporting.',
    tags: [
      { label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' },
      { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
    ],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 68,
    webinarLink:
      'https://www.teamwork.com/resource/onboarding-webinar-2-recorded/?utm_medium=email&utm_source=ad-hoc&utm_campaign=nov-onboarding-webinar-2&mkt_tok=MjQ0LUlYQy0wNTkAAAGBDB9KrL3HVhfObhQ73421dvifdlVAm9XI6ZAzPCCOUjoljtw4VCuk49IMMhIs65guDiXoF2ar2JZhdgMTaw&wvideo=s7e9v31a08',
  },
  {
    title: 'Get Started: The Fundamentals',
    description:
      "Learn Teamwork's building blocks (projects, task lists, tasks, and milestones) and how to use them to start working in Teamwork.",
    tags: [
      { label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' },
      { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
    ],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 54,
    webinarLink: 'https://www.teamwork.com/resource/sept-onboarding-webinar-recorded/?wvideo=ec1f24zwfu',
  },
  {
    title: 'Make the most of your project features',
    description:
      'Learn how to use more of the features in Teamwork. This is a fundamentals level webinar providing you with taste of how to use features such as Time, Billing and Risks etc.',
    tags: [
      { label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' },
      { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
    ],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 25,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/26dd121c311e4e409c13667532c6673e/watch',
  },
  {
    title: 'Set up your site for success',
    description:
      "Join this webinar to learn about teamwork fundamentals. You're going to walk away knowing how to set up your company's profile and other useful user-based tips.",
    tags: [
      { label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' },
      { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
    ],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 26,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/25bc7c5e27984daaa655f4d4c4a370c5/watch',
  },
  {
    title: 'Teamwork Hierarchy',
    description:
      'Join our webinar to learn about how to structure the projects on your site for maximum effect. We will be focusing on building out a project and showing how each level of this come...',
    tags: [
      { label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' },
      { label: t('Getting started'), type: 'var(--lsds-a-color-decorative-1)' },
    ],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 35,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/3ef9aefa19c04f2d9c0ee992cc03e3be/watch',
  },
];

const onDemandSuccessWebinars = [
  {
    title: '8 Ways to work from home like a pro with Teamwork',
    description:
      "Best practices using Teamwork when you're working remotely. We're going to share 8 ways to make you happy and productive when working from home.",
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 23,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/b2eaf6efa7694258b23b62accbad300f/watch',
  },
  {
    title: 'Workflow management with Teamwork board view',
    description:
      'Learn how to use Teamwork Boards to visualize and automate your workflows. Board view provides you with a visual representation of your workflow and allows you to automate some pro ...',
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 42,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/9de5389b73ee4baea9011de4f363d8e1/watch',
  },
  {
    title: 'Portfolio management with Teamwork',
    description: 'Learn how to master your Portfolio in Teamwork.',
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 27,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/8db0e6b5a61c4d01b080ec29525b650e/watch',
  },
  {
    title: 'A guide to working with Teams',
    description:
      'Teamwork Customer Champion, Jane Aston will go through how to group your users into Teams using our latest feature and give you advice on how to get started.',
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 30,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/7aeea5501c2c4686bc872f503f584c3e/watch',
  },
  {
    title: 'Working with the teamwork mobile app',
    description:
      'Join our webinar to learn about how to use our new mobile app. In this session we will cover all the main features and show you how to use teamwork on the go.',
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 25,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/69711eda08c84f1ba6ee5781cc8d92da/watch',
  },
  {
    title: 'Speed up you success with templates',
    description:
      'From task lists to complete Projects, you can replicate your work quickly. Templates are perfect for quickly duplicating your great work with just a couple of clicks.',
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 31,
    webinarLink:
      'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/28e2b5daef914c65b1a497dc1fbad118/watch',
  },
  {
    title: 'Customer Panel: Client Services Productivity Insights',
    description:
      "Collaboration and productivity have forever been a topic of discussion in the business world. Now, more than ever, it's a delicate balance to maintain employee productivity and avo ...",
    tags: [{ label: t('On demand'), type: 'var(--lsds-a-color-decorative-7)' }],
    actionText: t('Watch now'),
    actionPendoLabel: 'watch_now',
    webinarDuration: 62,
    webinarLink: 'https://www.teamwork.com/resource/customer-panel-webinar-recorded/',
  },
];
</script>
<template>
  <div class="flex min-h-0 shrink grow basis-0 flex-col">
    <div class="px-6">
      <h1 class="mb-4 text-subtitle-1 text-default">
        {{ t('Webinars') }}
      </h1>
      <VTabs v-bind="VTabsUnderline" v-model="activeTab">
        <VTab value="getting-started" data-identifier="webinar-panel-tab-getting-started">
          {{ t('Getting started') }}
        </VTab>
        <VTab value="success" data-identifier="webinar-panel-tab-success">{{ t('Success') }}</VTab>
      </VTabs>
    </div>
    <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
      <KeepAlive>
        <div v-if="activeTab === 'getting-started'" class="px-6">
          <HelpCenterDrawerTitle :title="t('Upcoming webinars')" class="mb-4 mt-6" />
          <WidgetLoadingState :state="state" :loadingMessage="t('Loading webinars')">
            <template #default>
              <HelpCenterDrawerWebinarCard
                v-for="webinar in gettingStartedWebinars"
                :key="webinar.id"
                class="mb-6"
                :title="webinar.title"
                :tags="webinar.tags"
                :description="webinar.description"
                :actionText="webinar.actionText"
                :actionPendoLabel="webinar.actionPendoLabel"
                :isUpcoming="true"
                :webinarLink="webinar.webinarLink"
              />
            </template>

            <template #blank>
              <LscEmptyState class="my-4" size="sm" :title="t('There are no upcoming webinars')" />
            </template>
          </WidgetLoadingState>

          <HelpCenterDrawerTitle :title="t('On demand webinars')" class="mb-4" />
          <HelpCenterDrawerWebinarCard
            v-for="webinar in onDemandGettingStartedWebinars"
            :key="webinar.title"
            class="mb-6"
            :title="webinar.title"
            :tags="webinar.tags"
            :description="webinar.description"
            :actionText="webinar.actionText"
            :actionPendoLabel="webinar.actionPendoLabel"
            :webinarDuration="webinar.webinarDuration"
            :webinarLink="webinar.webinarLink"
          />
        </div>

        <div v-else-if="activeTab === 'success'" class="px-6">
          <HelpCenterDrawerTitle :title="t('Upcoming webinars')" class="mb-4 mt-6" />
          <WidgetLoadingState :state="state">
            <template #default>
              <HelpCenterDrawerWebinarCard
                v-for="webinar in successStartedWebinars"
                :key="webinar.id"
                class="mb-6"
                :title="webinar.title"
                :tags="webinar.tags"
                :description="webinar.description"
                :actionText="webinar.actionText"
                :actionPendoLabel="webinar.actionPendoLabel"
                :isUpcoming="true"
                :webinarLink="webinar.webinarLink"
              />
            </template>

            <template #blank>
              <LscEmptyState class="my-4" size="sm" :title="t('There are no upcoming webinars')" />
            </template>
          </WidgetLoadingState>

          <HelpCenterDrawerTitle :title="t('On demand webinars')" class="mb-4 mt-6" />
          <HelpCenterDrawerWebinarCard
            v-for="webinar in onDemandSuccessWebinars"
            :key="webinar.title"
            class="mb-6"
            :title="webinar.title"
            :tags="webinar.tags"
            :description="webinar.description"
            :actionText="webinar.actionText"
            :actionPendoLabel="webinar.actionPendoLabel"
            :webinarDuration="webinar.webinarDuration"
            :webinarLink="webinar.webinarLink"
          />
        </div>
      </KeepAlive>
    </div>
  </div>
</template>
