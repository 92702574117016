import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useI18n } from '@/util';

export function useTrashcanActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();

  const realtimeEventMapping = {
    tasks: {
      eventType: 'task',
      action: 'reopened',
    },
    files: {
      eventType: 'file',
      action: 'reopened',
    },
  };

  /**
   * @param {objec} item
   * @param {string} item.type
   * @param {number} item.id
   */
  function restoreItem(item) {
    let normalizedType = item.type;
    if (item.type === 'files') {
      normalizedType = 'fileversions';
    }

    const promise = api
      .put(
        `/trashcan/${normalizedType}/${item.id}/restore.json`,
        { includeComments: true },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to restore item'),
        },
      )
      .then(() => {
        // @TODO: Align all item types with the server event name and action
        const { eventType, action } = realtimeEventMapping[item.type] || {
          eventType: item.type,
          action: 'added',
        };
        emitRealTimeUpdate({
          id: item.id,
          type: eventType,
          action,
        });
      });

    emitOptimisticUpdate({
      promise,
      type: 'trashCanItem',
      action: 'update',
      trashCanItem: {
        ...item,
        wasRestored: true,
      },
    });

    return promise;
  }

  return {
    restoreItem,
  };
}
