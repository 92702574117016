<script setup>
import { sanitizeUrl } from '@/util';
import { LscLinkVariants } from './constants';

const props = defineProps({
  /**
   * The variant of the link.
   * @type {PropType<typeof LscLinkVariants[number]>}
   */
  variant: {
    type: String,
    default: 'inline',
    validator: (value) => LscLinkVariants.includes(value),
  },
  /**
   * This icon will be rendered after the link's content.
   * @type {PropType<LscIcon>}
   */
  appendIcon: {
    type: String,
    default: '',
    validator(value, { variant, prependIcon }) {
      if (value) {
        if (variant !== 'standalone') {
          // eslint-disable-next-line no-console
          console.warn("Only variant=standalone should have 'appendIcon'.");
          return false;
        }
        if (prependIcon) {
          // eslint-disable-next-line no-console
          console.warn("Only 'prependIcon' or 'appendIcon' should be provided.");
          return false;
        }
      }
      return true;
    },
  },
  /**
   * This icon will be rendered before the link's content.
   * @type {PropType<LscIcon>}
   */
  prependIcon: {
    type: String,
    default: '',
    validator(value, { variant, appendIcon }) {
      if (value) {
        if (variant !== 'standalone') {
          // eslint-disable-next-line no-console
          console.warn("Only variant=standalone should have 'prependIcon'.");
          return false;
        }
        if (appendIcon) {
          // eslint-disable-next-line no-console
          console.warn("Only 'prependIcon' or 'appendIcon' should be provided.");
          return false;
        }
      }

      return true;
    },
  },
  /**
   * The external link to display.
   */
  href: {
    type: String,
    default: undefined,
    validator(value, { to }) {
      if (value && to) {
        // eslint-disable-next-line no-console
        console.warn('Only `href` or `to` should be provided');
        return false;
      }
      if (!value && !to) {
        // eslint-disable-next-line no-console
        console.warn('Either `href` or `to` should be provided');
        return false;
      }

      return true;
    },
  },
  /**
   * The internal link to display.
   */
  to: {
    type: String,
    default: undefined,
    validator(value, { href }) {
      if (value && href) {
        // eslint-disable-next-line no-console
        console.warn('Only `href` or `to` should be provided');
        return false;
      }
      if (!value && !href) {
        // eslint-disable-next-line no-console
        console.warn('Either `href` or `to` should be provided');
        return false;
      }

      return true;
    },
  },
  /**
   * The target of the link.
   */
  target: {
    type: String,
    default: undefined,
  },
});

const linkComponent = computed(() => (props.to ? resolveComponent('RouterLink') : 'a'));

const linkProps = computed(() => {
  if (props.to) {
    return {
      to: props.to,
      target: props.target,
    };
  }
  return {
    href: sanitizeUrl(props.href),
    target: props.target,
  };
});

const linkVariantStyleConfig = tv({
  base: 'inline-flex min-w-0 items-center gap-1 font-semibold no-underline hover:underline focus-visible:border-focus-primary focus-visible:underline active:underline',
  slots: {
    icon: '',
  },
  variants: {
    variant: {
      inline: {
        base: 'text-action-primary-default hover:text-action-primary-hover focus-visible:text-action-primary-pressed active:text-action-primary-pressed',
      },
      'inline-monochrome': {
        base: 'text-icon-default hover:text-default focus-visible:text-default active:text-default',
      },
      standalone: {
        base: 'text-body-1 text-action-primary-default',
        icon: 'text-icon-primary-default',
      },
    },
  },
  compoundVariants: [
    {
      variant: ['inline', 'inline-monochrome'],
      class: {
        base: 'hover:underline focus-visible:underline active:underline',
      },
    },
  ],
});

const classes = computed(() => linkVariantStyleConfig(props));
</script>

<template>
  <Component :is="linkComponent" v-bind="linkProps" :class="classes.base()">
    <slot v-if="variant === 'standalone'" name="prepend">
      <LscIcon v-if="prependIcon" :class="classes.icon()" :icon="prependIcon" />
    </slot>

    <slot />

    <slot v-if="variant === 'standalone'" name="append">
      <LscIcon v-if="appendIcon" :class="classes.icon()" :icon="appendIcon" />
    </slot>
  </Component>
</template>
