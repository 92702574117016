<script setup>
import { useI18n } from '@/util';
import { BudgetProjectInfo, useCurrentBudgetFormat } from '@/module/budget';
import { useLegacyBridge } from '@/module/legacy';

const props = defineProps({
  project: { type: Object, required: true },
  showOnHover: { type: Boolean, default: true },
  canEdit: { type: Boolean, default: true },
});

const emit = defineEmits(['trackPendoEvent']);

const { showLegacyModal } = useLegacyBridge();
const { formatPercentage, formatMinutes, t } = useI18n();
const budget = computed(() => props.project?.budget);
const {
  capacityLabel,
  capacityOverflow,
  capacityOverflowLabel,
  capacityAsPercentageWidth,
  capacityUsedLabel,
  capacityUsedPercent,
  color,
  repeatLabel,
} = useCurrentBudgetFormat(computed(() => budget.value));

const isFinancial = computed(() => {
  return budget.value.type.toLowerCase() === 'financial';
});

const budgetOverFlowText = computed(() => {
  if (!budget.value) {
    return '';
  }
  const capacityOverFlow = isFinancial.value
    ? capacityOverflowLabel.value
    : formatMinutes(budget.value.capacityUsed - budget.value.capacity);

  return t('Warning: {capacityOverFlow} over budget', { capacityOverFlow });
});

const budgetUsedText = computed(() => {
  if (!budget.value) {
    return '';
  }
  const capacity = isFinancial.value ? capacityLabel.value : formatMinutes(budget.value.capacity);
  const capacityUsed = isFinancial.value ? capacityUsedLabel.value : formatMinutes(budget.value.capacityUsed);

  return t('{capacityUsed} of {capacity}', { capacityUsed, capacity });
});

function addBudget() {
  emit('trackPendoEvent', 'project_row_interaction', 'advanced', 'project_row_add_budget_clicked');

  showLegacyModal({
    modalName: 'ProjectBudgetModal',
    params: {
      projectId: props.project.id,
      projectPermissions: props.project.permissions,
    },
  });
}

let hoverTimeout;

function handleMouseEnter() {
  hoverTimeout = setTimeout(() => {
    emit('trackPendoEvent', 'project_row_interaction', 'advanced', 'budget_hovered');
  }, 1500);
}

function handleMouseLeave() {
  clearTimeout(hoverTimeout);
}
</script>

<template>
  <LscTableCellEmpty v-if="!budget" :buttonText="t('Add budget')" button @click="addBudget" />
  <BudgetProjectInfo
    v-else
    :disabled="!showOnHover"
    openOnHover
    location="bottom center"
    :projectBudget="budget"
    :projectId="project.id"
    :canEdit="canEdit"
  >
    <template #activator="activator">
      <div
        class="flex w-full flex-col"
        v-bind="activator.props"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <div class="flex items-center gap-1">
          <LscOverflowEllipsis class="font-semibold">
            {{ formatPercentage(capacityUsedPercent / 100) }}
          </LscOverflowEllipsis>
          <LscIcon
            v-if="repeatLabel"
            v-LsdTooltip="t('Repeats')"
            icon="lsi-repeat"
            size="sm"
            class="text-icon-subtle"
          />
          <LscIcon
            v-if="budget && budget.isRetainer"
            v-LsdTooltip="t('Retainer')"
            icon="lsi-billable"
            size="sm"
            class="text-icon-subtle"
          />
          <LscIcon
            v-if="capacityOverflow"
            v-LsdTooltip="budgetOverFlowText"
            icon="lsi-priority-selected"
            size="sm"
            class="text-critical"
          />
        </div>
        <div class="relative flex h-2 overflow-hidden rounded-md bg-surface-selected">
          <div
            class="progress-animation absolute left-0 h-full w-[--width] rounded-md bg-[--bg]"
            :style="{ '--bg': color, '--width': capacityAsPercentageWidth }"
          />
        </div>
        <span class="truncate whitespace-nowrap text-body-2 text-subtle">
          {{ budgetUsedText }}
        </span>
      </div>
    </template>
  </BudgetProjectInfo>
</template>
