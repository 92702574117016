// customFields is saved as stringified array in the backend. This function is
// repsonsible for taking that value and converting it into a series of key[value]
// pairs that can be sent across to the /tasks.json endpoint and used for our filters.
export function parseDefaultFilterCustomFields(customFields = {}) {
  const customFieldsObj = {};
  const parsedCustomFields = typeof customFields === 'string' ? JSON.parse(customFields) : customFields;
  parsedCustomFields.forEach((customField) => {
    // Only numbers and dates can have an array of values - one greater than and one less than.
    if (Array.isArray(customField.value)) {
      if (customField.operator === 'range') {
        // Number
        customFieldsObj[`customField[${customField.fieldId}][lt]`] = customField.value[0];
        customFieldsObj[`customField[${customField.fieldId}][gt]`] = customField.value[1];
      }
      if (customField.operator === 'custom') {
        // Date range
        customFieldsObj[`customField[${customField.fieldId}][lte]`] = customField.value[0];
        customFieldsObj[`customField[${customField.fieldId}][gte]`] = customField.value[1];
      }
    } else {
      const key = `customField[${customField.fieldId}][${customField.operator}]`;
      customFieldsObj[key] = customField.value;
    }
  });
  return customFieldsObj;
}
