import { useUserRatesV3Loader, useProjectV3Loader, usePeopleV2Loader, usePreferences } from '@/api';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

/**
 * This composite loader is merging states from three Loaders into a single state to make sure it can be used in Goal Based Onboarding - Add your team and set team rates
 */

export function useCompositeProjectUsersAndRatesLoader({ projectId: _projectId, count: _count = -1 }) {
  const { trackGoalBasedProjectUsersAdded, trackGoalBasedProjectUsersInvited } = useOnboardingWizardTracking();
  const { sampleUserIds } = usePreferences();

  const projectId = shallowRef(_projectId);
  const count = shallowRef(_count);
  const userIds = shallowRef([]);
  const onboardingProject = shallowRef({});
  const initialUsers = shallowRef([]);
  const initialUserSet = shallowRef(false);

  const projectUsers = ref([]);
  const usersWithRates = ref([]);

  let alreadyProcessedUsers = [];

  const projectState = useProjectV3Loader({
    projectId: computed(() => projectId.value),
    params: {
      include: 'users',
    },
  });

  const { item: project, inSync: projectInSync } = projectState;

  const usersFromProjectState = usePeopleV2Loader({
    projectId: computed(() => onboardingProject.value.id),
    params: computed(() => ({
      ids: userIds.value.join(','),
    })),
    count: computed(() => (project.value && projectInSync.value ? project.value.users.length : -1)),
  });

  const userRatesFromProjectState = useUserRatesV3Loader({
    projectId: computed(() => onboardingProject.value.id),
    params: {
      include: 'users,costRates',
    },
    count: computed(() => (project.value && projectInSync.value ? project.value.users.length : -1)),
  });

  const { items: usersFromProject, inSync: usersFromProjectInSync } = usersFromProjectState;

  const { items: userRatesFromProject, inSync: userRatesFromProjectInSync } = userRatesFromProjectState;

  function updateOrAddUser(updatedProjectUsers, user, userRates) {
    const matchingUser = userRates.find((rate) => rate.id === user.id);
    const existingUserIndex = updatedProjectUsers.findIndex((u) => u.id === user.id);

    const userObject = {
      id: user.id,
      firstName: user.firstName,
      initial: user.firstName.charAt(0),
      canEditRates: !!matchingUser,
    };

    if (matchingUser) {
      Object.assign(userObject, matchingUser);
      if (userObject.user) {
        userObject.user.canEditRates = true;
      }
    }

    if (existingUserIndex === -1) {
      // add new user
      updatedProjectUsers.push(userObject);
    } else {
      // update existing user
      Object.assign(updatedProjectUsers[existingUserIndex], userObject);
    }
  }

  watch(projectInSync, (newValue) => {
    if (newValue) {
      const fetchedProject = project.value;

      if (fetchedProject && fetchedProject.users) {
        onboardingProject.value = fetchedProject;
        userIds.value = fetchedProject.users.map((user) => user.id);
      }
    }
  });

  watch(userRatesFromProjectInSync, (newValue) => {
    if (newValue) {
      usersWithRates.value = userRatesFromProject.value;
    }
  });

  // `watch` newly added users for sending specific tracking events related to goal-based onboarding.
  // it doesn't interact with other logic of the file
  watch(usersFromProject, (newValue, oldValue) => {
    if (oldValue && newValue.length > oldValue.length) {
      const usersToAdd = newValue.filter((user) => !oldValue.find((oldUser) => oldUser.id === user.id));
      const nonSampleUsersList = usersToAdd.filter((person) => !sampleUserIds.value.includes(person.id));

      if (usersFromProjectInSync.value && !initialUserSet.value) {
        nonSampleUsersList.forEach((user) => {
          initialUsers.value.push(user);
          initialUserSet.value = true;
        });
      }

      if (initialUserSet.value) {
        // don't count users that have already been added
        const newlyInvitedUsers = nonSampleUsersList.filter(
          (user) =>
            !alreadyProcessedUsers.includes(user.id) &&
            !initialUsers.value.find((initialUser) => initialUser.id === user.id),
        );

        if (newlyInvitedUsers.length > 0) {
          // check if all newly invited users have 'PENDING' invitation status
          // this way we can know if the user invite dialog was used
          const areUsersWithPendingStatus = newlyInvitedUsers.every((user) => user.userInvitedStatus === 'PENDING');
          if (areUsersWithPendingStatus) {
            trackGoalBasedProjectUsersInvited(newlyInvitedUsers.length, projectId.value);
          } else {
            trackGoalBasedProjectUsersAdded(newlyInvitedUsers.length, projectId.value);
          }

          alreadyProcessedUsers = alreadyProcessedUsers.concat(newlyInvitedUsers.map((user) => user.id));
        }
      }
    }
  });

  watchEffect(() => {
    const updatedProjectUsers = [...projectUsers.value];

    const filteredUsersFromProject = usersFromProject.value.filter((user) => userIds.value.includes(user.id));

    filteredUsersFromProject.forEach((user) => {
      updateOrAddUser(updatedProjectUsers, user, usersWithRates.value);
    });
    projectUsers.value = updatedProjectUsers;
  });

  return {
    items: computed(() => {
      if (count.value < 0) {
        return [];
      }
      if (count.value < projectUsers.value.length) {
        return projectUsers.value.slice(0, count.value);
      }
      return projectUsers.value;
    }),
    hasMore: computed(() => count.value < projectUsers.value.length),
    itemInSync: () =>
      usersFromProjectState.inSync.value && userRatesFromProjectState.inSync.value && projectState.inSync.value,
    inSync: computed(
      () => usersFromProjectState.inSync.value && userRatesFromProjectState.inSync.value && projectState.inSync.value,
    ),
    loaded: computed(
      () => usersFromProjectState.loaded.value || userRatesFromProjectState.loaded.value || projectState.loaded.value,
    ),
    meta: computed(() => null),
    error: computed(
      () => usersFromProjectState.error.value || userRatesFromProjectState.error.value || projectState.error.value,
    ),
    retry() {
      // `retry` does nothing if there's no error, so it can be called on all states
      usersFromProjectState.retry();
      userRatesFromProjectState.retry();
      projectState.retry();
    },
  };
}
