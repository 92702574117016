import { useCurrentAccount } from './useCurrentAccount';
import { useLaunchpadInfo } from '../launchpad/useLaunchpadInfo';
import { useCurrentUser } from '../user/useCurrentUser';

export function useEnabledApps() {
  const launchpadInfo = useLaunchpadInfo();
  const account = useCurrentAccount();
  const user = useCurrentUser();

  const isChatAvailable = computed(() => Boolean(account.value?.chatEnabled && launchpadInfo.value?.apps?.chat));
  const isCrmAvailable = computed(() => Boolean(account.value?.CRMEnabled && launchpadInfo.value?.apps.crm));
  const isDeskAvailable = computed(() =>
    Boolean(
      account.value?.deskEnabled &&
        user.value?.hasDeskAccount &&
        (user.value?.inOwnerCompany || !account.value?.strictBranding),
    ),
  );
  const isSpacesAvailable = computed(() => Boolean(account.value?.spacesEnabled && launchpadInfo.value?.apps.spaces));

  return {
    isCrmAvailable,
    isDeskAvailable,
    isSpacesAvailable,
    isChatAvailable,
  };
}
