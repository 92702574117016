<script setup>
import { useI18n } from '@/util';
import { provideCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentAccountV1Loader } from '../account/useCurrentAccountV1Loader';
import { provideHandle401 } from '../base/useHandle401';
import { provideBrandingState } from '../branding/useBranding';
import { useBrandingV1Loader } from '../branding/useBrandingV1Loader';
import { provideDeskCurrentUserState } from '../desk/useDeskCurrentUser';
import { provideTeamworkFeaturesState } from '../features/useTeamworkFeatures';
import { useTeamworkFeaturesV3Loader } from '../features/useTeamworkFeaturesV3Loader';
import { provideLaunchpadInfoState } from '../launchpad/useLaunchpadInfo';
import { useLaunchpadInfoV1Loader } from '../launchpad/useLaunchpadInfoV1Loader';
import { provideCurrentUserState } from '../user/useCurrentUser';
import { useCurrentUserV1Loader } from '../user/useCurrentUserV1Loader';
import ApiSetup2 from './ApiSetup2.vue';

provideHandle401();
provideBrandingState(useBrandingV1Loader());
provideLaunchpadInfoState(
  useLaunchpadInfoV1Loader({
    params: {
      suppressLoginModal: true,
      noErrorHandling: true,
    },
  }),
);
const currentAccountState = useCurrentAccountV1Loader({
  params: {
    getPreferences: true,
  },
});
provideCurrentAccountState(currentAccountState);
const currentUserState = useCurrentUserV1Loader({
  params: {
    fullprofile: true,
    getPreferences: true,
    cleanPreferences: true,
    getAccounts: true,
    includeAuth: true,
    includeClockIn: true,
    includeTeamIds: true,
    getDefaultFilters: true,
    includeCompanyTimesheetsPermissions: true,
    // Backend performance optimization: https://digitalcrew.teamwork.com/app/tasks/23834788
    getNumActiveProjects: false,
  },
});
provideCurrentUserState(currentUserState);
provideTeamworkFeaturesState(
  useTeamworkFeaturesV3Loader({
    count: Infinity,
  }),
);
provideDeskCurrentUserState();

const { languageCode, timeZoneName, dateFormatId, timeFormatId, ellipsis, weekStartsOnSunday, currency } = useI18n();
watch(
  [currentUserState.item, currentUserState.inSync],
  ([user, inSync]) => {
    if (user && inSync) {
      languageCode.value = user.localization.languageCode;
      timeZoneName.value = user.localization.timezoneJavaRefCode;
      dateFormatId.value = user.localization.dateFormatId;
      timeFormatId.value = user.localization.timeFormatId;
      ellipsis.value = user.localization.ellipsis;
      weekStartsOnSunday.value = user.localization.startOnSunday;
    }
  },
  { immediate: true },
);
watch(
  [currentAccountState.item, currentAccountState.inSync],
  ([account, inSync]) => {
    if (account && inSync) {
      currency.value = account.currency;
    }
  },
  { immediate: true },
);
</script>

<template>
  <ApiSetup2><slot /></ApiSetup2>
</template>
