import { usePendo } from '../features/usePendo';
import { useCurrentAccount } from '../account/useCurrentAccount';

/**
 * Constants, computed properties and functions related to Experiment A-23-22
 * https://digitalcrew.teamwork.com/spaces/growth/page/44174-a-23-22-reduce-new-business-trial-to-14-days
 */
export function useExperimentA22() {
  const account = useCurrentAccount();
  const { trackPendoEvent } = usePendo();

  const experimentKey = 'projects-exp-a-23-22-trial-to-14-days';
  const webSignupVariantArray = account.value?.webSignupVariant?.split('_');
  const key = webSignupVariantArray?.[0];
  const variant = webSignupVariantArray?.[1];

  const isExperiment = computed(() => key === experimentKey);
  const isExpA22Control = computed(() => isExperiment.value && variant === 'control');
  const isExpA22Variation = computed(() => isExperiment.value && variant === 'variation');

  function trackExperimentA22() {
    if (isExperiment.value) {
      trackPendoEvent({
        eventName: 'EXPERIMENT',
        metadata: {
          experiment_id: experimentKey,
          experiment_variation_value: variant,
          experiment_variation_name: variant,
        },
      });
    }
  }

  return {
    trackExperimentA22,
    isExpA22Variation,
    isExpA22Control,
  };
}
