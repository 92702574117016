<script setup>
import { useFeatureTrial } from '@/module/featureTrial';
import { useI18n } from '@/util';
import { useExperimentE2304 } from '@/api';
import { useFeatureTrialBundle } from './useFeatureTrialBundle';
import FeatureTrialBundleBannerMessage from './FeatureTrialBundleBannerMessage.vue';
import Tada from './assets/tada.svg';
import Fire from './assets/fire.svg';

const { t } = useI18n();
const { trackEventE2304 } = useExperimentE2304();
const { bannerState, bannerInfo, planDiscounted, closeBanner, featureTrialsActivated, loading } =
  useFeatureTrialBundle();
const { trialDaysRemaining } = featureTrialsActivated.value[0]?.featureKey
  ? useFeatureTrial(featureTrialsActivated.value[0].featureKey)
  : { trialDaysRemaining: 0 };
const bannerMessage = computed(() => {
  switch (bannerState.value) {
    case 'sale':
      return {};
    case 'confirm':
      if (planDiscounted.value.title === 'Grow') {
        return t('today and start maximizing the profitability of your projects.');
      }
      return t('today so you never miss a billable minute!');
    case 'remind':
      if (planDiscounted.value.title === 'Grow') {
        return t('Start maximizing the profitability of your projects!');
      }
      return '';
    default:
      return {};
  }
});

onMounted(() => {
  if (!bannerInfo.value.showBanner) {
    return;
  }
  switch (bannerState.value) {
    case 'confirm':
      trackEventE2304({ eventAction: 'feature_trial_activated_banner_displayed' });
      break;
    case 'remind':
      trackEventE2304({ eventAction: 'feature_trial_is_active_banner_displayed' });
      break;
    case 'sale':
      trackEventE2304({ eventAction: 'feature_trial_sale_banner_displayed' });
      break;
    default:
  }
});

function handleFeatureClick(feature) {
  switch (bannerState.value) {
    case 'confirm':
      trackEventE2304({
        eventAction: 'feature_clicked_on',
        meta: { banner: 'feature_trial_activated_banner', feature: feature.featureKey },
      });
      break;
    case 'remind':
      trackEventE2304({
        eventAction: 'feature_clicked_on',
        meta: { banner: 'feature_trial_activated_banner', feature: feature.featureKey },
      });
      break;
    default:
  }
}
</script>

<template>
  <LscAlert
    v-if="bannerInfo.showBanner"
    layout="banner"
    closable
    :title="bannerInfo.title"
    :variant="bannerInfo.bannerVariant"
    @close="closeBanner"
  >
    <template #media>
      <Fire v-if="bannerState === 'sale'" class="w-min min-w-9" />
      <Tada v-else class="w-min min-w-9" />
    </template>

    <p v-if="bannerState === 'confirm'">
      <FeatureTrialBundleBannerMessage
        :featureTrialsActivated="featureTrialsActivated"
        :bannerMessage="bannerMessage"
        :featureText="t('Set up our {featureTrials} {bannerMessage}')"
        @featureClick="handleFeatureClick"
      />
    </p>
    <p v-if="bannerState === 'remind'">
      <FeatureTrialBundleBannerMessage
        :featureTrialsActivated="featureTrialsActivated"
        :bannerMessage="bannerMessage"
        :days="trialDaysRemaining"
        :featureText="t('You have {days} left to trial {featureTrials}. {bannerMessage}')"
        @featureClick="handleFeatureClick"
      />
    </p>
    <p v-if="bannerState === 'sale'">
      <LscReplace v-if="bannerState === 'sale'" :text="planDiscounted.discountMessage">
        <template #discount>
          <span class="font-semibold">{{ planDiscounted.discountHighlight }}</span>
        </template>
      </LscReplace>
    </p>
    <template #action>
      <LscAlertButton v-if="bannerState === 'sale' && !loading" @click="planDiscounted.ctaAction">
        {{ planDiscounted.ctaText }}
      </LscAlertButton>
      <p v-if="loading" class="text-button-1">{{ t('Reedeming...') }}</p>
    </template>
  </LscAlert>
</template>
