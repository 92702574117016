import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem({ data }) {
  return data?.integrations;
}

export function useUserIntegrationsV2Loader() {
  const { state, refresh } = useItemLoader({
    url: '/synthesis/api/v2/integrations/user.json',
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'calendar' && event.action === 'sync-enabled') {
      refresh();
    }
  });

  return state;
}
