<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useCompaniesV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const count = shallowRef(10);
const filterType = 'companies';

const pageSize = 20;
const state = useCompaniesV3Loader({
  params: computed(() => ({
    ...props.loaderParams,
    orderBy: 'name',
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});

const { items } = state;

const missingCompanyIds = shallowRef([]);
const missingCompaniesState = useCompaniesV3Loader({
  params: computed(() => ({
    ...props.loaderParams,
    ids: missingCompanyIds.value.join(','),
  })),
  count: computed(() => missingCompanyIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  items,
  missingCompaniesState.items,
);

const companyIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const companies = computeAll(companyIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(companyIds), missingCompanyIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    companies.value.map((company) => ({
      name: company.name,
      icon: 'lsi-client',
      type: filterType,
      delete: () => {
        companyIds.value = companyIds.value.filter((id) => id !== company.id);
      },
    })),
  ),
);

useFilterCount(computed(() => companyIds.value.length));

function onClick({ id }) {
  if (companyIds.value.includes(id)) {
    companyIds.value = companyIds.value.filter((companyId) => companyId !== id);
  } else {
    companyIds.value = [...companyIds.value, id];
  }
}

function icon(id) {
  return companyIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}
</script>
<template>
  <WidgetMenu :closeOnContentClick="false" location="bottom left" offset="12">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="companyIds.length > 0"
        icon="lsi-client"
        :data-identifier="`${dataIdentifierPrefix}-company-picker-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <VCard v-bind="VCardRounded" class="w-[--filter-dropdown-width]">
      <div class="pl-2 pr-2">
        <VTextField
          v-model.trim="searchTerm"
          v-bind="VTextFieldFilter"
          class="mt-2 !min-w-full"
          :placeholder="t('Search companies')"
          prependInnerIcon="lsi-search"
          :active="Boolean(searchTerm)"
          autofocus
        />
      </div>
      <WidgetLoadingState :state="state" :blankTitle="t('No companies found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <template v-for="company in items" :key="company.id">
              <VListItem
                :active="companyIds.includes(company.id)"
                class="my-1 rounded-sm"
                :data-identifier="`${dataIdentifierPrefix}-company-picker-item`"
                @click="onClick(company)"
              >
                <div class="flex items-center justify-between text-body-1">
                  <LscOverflowEllipsis class="ml-2 max-w-32 text-body-1">
                    {{ company.name }}
                  </LscOverflowEllipsis>
                  <LscIcon :icon="icon(company.id)" class="text-icon-subtle" size="sm" />
                </div>
              </VListItem>
            </template>
            <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
    </VCard>
  </WidgetMenu>
</template>
