<script setup>
import { useI18n } from '@/util';
import { useProjectsV3Loader, usePreferences } from '@/api';
import AppShellSidebarProjectsListStart from './list/AppShellSidebarProjectsListStart.vue';

const { t } = useI18n();
const { sidebarShowRecentProjects } = usePreferences();

const step = 15;
const count = shallowRef(-1); // Don't load yet
const state = useProjectsV3Loader({
  count,
  pageSize: 15,
  params: {
    searchTerm: null,
    include: 'companies,projectCategories',
    includeProjectUserInfo: true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage,categoryId,logoIcon,logoColor',
    orderBy: 'lastWorkedOn',
    orderMode: 'desc',
    onlyStarredProjects: false,
    onlyProjectsWithExplicitMembership: 1,
  },
});

provide('count', count);
provide('allowLoadMore', false);

// Start loading if open already, or it opens and not already loaded
watch(
  sidebarShowRecentProjects,
  (show) => {
    if (show && count.value <= -1) {
      count.value = step;
    }
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <button
      type="button"
      class="mx-6 my-1 inline-flex items-center gap-1 text-body-2 text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]"
      data-identifier="ls-nav-sidebar-projects-recent"
      @click="sidebarShowRecentProjects = !sidebarShowRecentProjects"
    >
      {{ t('Recent') }}
      <LscIcon
        size="xs"
        icon="lsi-collapse"
        class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] transition-transform duration-300"
        :class="{ '-rotate-90': sidebarShowRecentProjects, '-rotate-180': !sidebarShowRecentProjects }"
      />
    </button>
    <AppShellSidebarProjectsListStart
      :show="sidebarShowRecentProjects"
      :step="step"
      :state="state"
      :noProjectsMessage="t('No recent projects')"
      projectListType="recent"
    />
  </div>
</template>
