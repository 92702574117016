import { useEventListener } from '@vueuse/core';
import { isEventOnTextInput } from './isEventOnTextInput';
import { useDevtools } from '../other/useDevtools';

const symbol = Symbol('useKeyboardShortcut');

function addItem(list, item) {
  const index = list.indexOf(item);
  if (index < 0) {
    list.push(item);
  }
}

function removeItem(list, item) {
  const index = list.indexOf(item);
  if (index >= 0) {
    list.splice(index, 1);
  }
}

/**
 * Orders shortcuts, so that those which are activated on hover are first.
 */
function orderOnHoverFirst(item1, item2) {
  const priority1 = item1.activeOnHoverElement !== undefined;
  const priority2 = item2.activeOnHoverElement !== undefined;

  if (priority1 === priority2) {
    return 0;
  }
  if (priority1) {
    return -1;
  }
  return 1;
}

/**
 * Indicates if the given shortcut can be activated.
 */
function canActivate({ activeOnInput, activeOnHoverElement }) {
  if (!unref(activeOnInput) && isEventOnTextInput(this)) {
    return false;
  }

  if (activeOnHoverElement !== undefined && !unref(activeOnHoverElement)?.matches(':hover')) {
    return false;
  }

  return true;
}

/**
 * Sets up the keyboard shortcut system.
 * @param {import('vue').App<Element>} app
 */
export function keyboardShortcutPlugin(app) {
  const shortcutsMap = new Map();
  const shortcutsMaps = [shortcutsMap];

  const timelineLayerId = 'keyboard-shortcuts';
  const devtools = useDevtools();
  devtools?.addTimelineLayer?.({
    id: timelineLayerId,
    label: 'Keyboard Shortcuts',
    color: 0xffa0dd,
  });

  useEventListener('keydown', (event) => {
    // Fix https://digitalcrew.teamwork.com/app/tasks/22812833.
    // Based on https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
    // `event.key` should be always a string but it is sometimes `undefined` for some reason.
    if (typeof event.key !== 'string') {
      return;
    }

    const keyParts = [];

    if (event.altKey) {
      keyParts.push('ALT');
    }

    if (event.ctrlKey || event.metaKey) {
      keyParts.push('COMMAND');
    }

    if (event.shiftKey && event.key.toUpperCase() !== event.key.toLowerCase()) {
      keyParts.push('SHIFT');
    }

    keyParts.push(event.key.toUpperCase());

    const key = keyParts.join(' ');

    const listener = shortcutsMaps.at(-1).get(key)?.filter(canActivate, event)?.sort(orderOnHoverFirst)?.[0]?.listener;

    if (listener) {
      event.preventDefault();
      event.stopPropagation();
      listener();
      devtools?.addTimelineEvent?.({
        layerId: timelineLayerId,
        event: {
          time: devtools.now(),
          title: keyParts.join(' + '),
          data: {
            rawEvent: event,
            keyParts,
            listener: listener.toString(),
          },
        },
      });
    }
  });

  app.provide(symbol, { shortcutsMap, shortcutsMaps });
}

/**
 * Creates a new context for the keyboard shortcuts registered in descendant components using `useKeyboardShortcut`.
 * Only shortcuts from active contexts can be activated, however, when a descendant context is active,
 * the shortcuts from all ancestor contexts are ignored.
 * @param {Ref<boolean>} contextActive Indicates if the context is active.
 */
export function provideKeyboardShortcut(contextActive) {
  const shortcutsMap = new Map();
  const { shortcutsMaps } = inject(symbol);

  watch(
    contextActive,
    (shouldAdd) => {
      if (shouldAdd) {
        addItem(shortcutsMaps, shortcutsMap);
      } else {
        removeItem(shortcutsMaps, shortcutsMap);
      }
    },
    { immediate: true },
  );

  onUnmounted(() => {
    removeItem(shortcutsMaps, shortcutsMap);
  });

  provide(symbol, { shortcutsMap, shortcutsMaps });
}

/**
 * Registers a keyboard shortcut listener.
 * @param {string} shortcutKey The shortcut to listen for.
 *   It is a space-separated list of modifier keys (`Alt`, `Shift`, `Command`)
 *   followed by the value of the `key` property of the `KeyboardEvent`.
 *   The `Command` modifier stands for `Ctrl` or `Meta`.
 *   The lettercase and the order of the modifiers are insignificant.
 *   For example: 'Escape', 'Command Shift P', etc.
 * @param {Listener} listener The shortcut event listener.
 * @param {Object} options Additional options.
 * @param {MaybeRef<boolean>} options.activeOnInput Whether the shortcut should be active even when a text input has focus.
 *   Defaults to `false`.
 * @param {MaybeRef<HTMLElement>} options.activeOnHoverElement Only fire this event if the given element is in the hover state.
 *   Defaults to `null`.
 */
export function useKeyboardShortcut(shortcutKey, listener, { activeOnInput = false, activeOnHoverElement } = {}) {
  const { shortcutsMap } = inject(symbol);

  // Normalize the shortcut key:
  // - modifiers in alphabetical order followed by the main key
  // - one space between modifiers and the main key
  // - all uppercase
  const keyParts = shortcutKey.toUpperCase().split(' ').filter(Boolean);
  const lastKeyPart = keyParts.pop();

  if (lastKeyPart === lastKeyPart.toLowerCase() && keyParts.includes('SHIFT')) {
    // eslint-disable-next-line no-console
    console.warn(
      `useKeyboardShortcut: Please remove the SHIFT modifier to make "${shortcutKey}" work regardless of different keyboard layouts.`,
    );
  }

  keyParts.sort();
  keyParts.push(lastKeyPart);
  const key = keyParts.join(' ');

  if (!shortcutsMap.has(key)) {
    shortcutsMap.set(key, []);
  }

  const shortcuts = shortcutsMap.get(key);
  const newItem = { listener, activeOnInput, activeOnHoverElement };

  shortcuts.unshift(newItem);

  onUnmounted(() => {
    const index = shortcuts.indexOf(newItem);

    if (index >= 0) {
      shortcuts.splice(index, 1);
    }
  });
}

/**
 * A simple component wrapper for the `useKeyboardShortcut` composable.
 *
 * It assumes that `shortcut` will not change, which makes sense in case of this component,
 * is consistent with the `useKeyboardShortcut` composable and allows us to keep the code simple.
 */
export const UseKeyboardShortcut = defineComponent({
  name: 'UseKeyboardShortcut',
  props: { shortcut: { type: String, required: true } },
  emits: ['activate'],
  setup({ shortcut }, { emit }) {
    useKeyboardShortcut(shortcut, () => emit('activate'));
    return () => [];
  },
});
