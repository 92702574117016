<script setup>
import { getLuminance } from 'color2k';
import { getAccessibleTextColor, useI18n } from '@/util';

import { useLscColorPicker } from './useLscColorPicker';

const props = defineProps({
  /**
   * The color to display
   */
  color: {
    type: String,
    default: null,
  },
  /**
   * Used to set the button's active state for toggle buttons.
   */
  ariaPressed: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Used to set the button's active state for popovers and dropdown menus.
   */
  ariaExpanded: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Used to define the buttons popup role for popovers and dropdown menus.
   */
  ariaHasPopup: {
    type: [Boolean, String],
    default: false,
    validator: (value) => typeof value === 'boolean' || ['menu', 'listbox', 'dialog'].includes(value),
  },
  /**
   * Disables the button.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * An accessible label for the button.
   */
  ariaLabel: {
    type: String,
    default: undefined,
  },
  /**
   * The size of the button.
   * @type {PropType<'sm' | 'md' | 'lg'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  /**
   * The variant of the button.
   */
  variant: {
    type: String,
    default: 'default',
    validator: (value) => ['default'].includes(value),
  },
});

const { showIcon } = useLscColorPicker();
const { t } = useI18n();

const shouldShowEmptyColorIcon = computed(() => showIcon && !props.color);

const shouldShowSelectedColorIcon = computed(() => showIcon && props.color && props.ariaPressed);

const ariaLabel = computed(() => props.ariaLabel ?? t('Choose a color'));

const icon = computed(() => {
  if (shouldShowEmptyColorIcon.value) {
    return 'lsi-forward-slash';
  }
  if (shouldShowSelectedColorIcon.value) {
    return 'lsi-selected';
  }
  return '';
});

const colorPickerButtonVariantStyleConfig = tv({
  base: [
    'relative inline-flex items-center justify-center rounded-full border bg-[--lsc-color-picker-button-bg-color] text-[--lsc-color-picker-button-icon-color] outline-none ring-2 ring-transparent ring-offset-2 transition-colors hover:ring-bold focus-visible:ring-2 disabled:ring-transparent',
  ],
  slots: {
    icon: '',
  },
  variants: {
    hasBorder: {
      true: {
        base: ['border-bold'],
      },
      false: {
        base: ['border-transparent'],
      },
    },
    emptyColor: {
      true: {
        base: ['border-dashed !border-bold'],
      },
    },
    shouldShowEmptyColorIcon: {
      true: {
        icon: ['!text-icon-subtle'],
      },
    },
    size: {
      sm: {
        base: 'size-[--lsds-c-color-picker-button-size-sm]',
      },
      md: {
        base: 'size-[--lsds-c-color-picker-button-size-md]',
      },
      lg: {
        base: 'size-[--lsds-c-color-picker-button-size-lg]',
      },
    },
  },
});

const classes = computed(() =>
  colorPickerButtonVariantStyleConfig({
    ...props,
    emptyColor: !props.color,
    hasBorder: props.color ? getLuminance(props.color ?? 'white') > 0.9 : false,
    shouldShowEmptyColorIcon: shouldShowEmptyColorIcon.value,
  }),
);

const styles = computed(() => ({
  '--lsc-color-picker-button-bg-color': props.color ?? 'transparent',
  '--lsc-color-picker-button-icon-color': getAccessibleTextColor(props.color ?? 'white'),
}));

const iconSizes = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};
</script>

<template>
  <button
    :class="classes.base()"
    type="button"
    :aria-label="ariaLabel"
    :aria-pressed="ariaPressed"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHasPopup || ariaExpanded"
    :disabled="disabled"
    :style="styles"
  >
    <LscIcon :icon="icon" :class="classes.icon()" :size="iconSizes[size]" />
  </button>
</template>
