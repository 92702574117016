import { usePendo } from '@/api';

/**
 * Provides tracking functions for app shell sidebar components
 */

const EVENT_NAME = 'NAVIGATION_EVENT';
const COMMON_METRICS = ['account_id'];

/**
 * @param {Object} sharedFields Fields shared across most tracking functions.
 * @param {Ref<boolean>} sharedFields.isSidebarExpanded
 * @returns Tracking functions for app shell sidebar components
 */
export function useAppShellSidebarTracking({ isSidebarExpanded = shallowRef(true) } = {}) {
  const { trackPendoEvent } = usePendo();

  const navigationState = computed(() => (isSidebarExpanded.value ? 'expanded' : 'collapsed'));

  function track(metadata) {
    trackPendoEvent({
      eventName: EVENT_NAME,
      commonMetrics: COMMON_METRICS,
      metadata,
    });
  }

  function trackLeftNavigationClicked(navigationItem) {
    track({
      event_action: 'left_navigation_clicked',
      navigation_item: navigationItem,
      navigation_state: navigationState.value,
    });
  }

  function trackQuickAddMenuItemClicked(quickAddItem) {
    track({
      event_action: 'quick_add_item_clicked',
      quick_add_item: quickAddItem,
      navigation_state: navigationState.value,
    });
  }

  function trackShortcutKeyPressed(navigationItem) {
    track({
      event_action: 'shortcut_key_pressed',
      navigation_item: navigationItem,
      navigation_state: navigationState.value,
    });
  }

  function trackLeftNavigationItemPinning(actionType, navigationItem) {
    track({
      event_action: 'left_navigation_item_pinning',
      action_type: actionType,
      navigation_item: navigationItem,
      navigation_state: navigationState.value,
    });
  }

  function trackProfileMenuItemClicked(navigationItem) {
    track({
      event_action: 'profile_menu_item_clicked',
      navigation_item: navigationItem,
      navigation_state: navigationState.value,
    });
  }

  function trackLeftNavigationHiding() {
    track({
      event_action: 'left_navigation_hiding',
      action_type: navigationState.value,
    });
  }

  function trackLeftNavigationLayout(navigationList) {
    track({
      event_action: 'left_navigation_layout',
      navigation_list: navigationList,
      navigation_state: navigationState.value,
    });
  }

  return {
    trackLeftNavigationClicked,
    trackQuickAddMenuItemClicked,
    trackShortcutKeyPressed,
    trackLeftNavigationItemPinning,
    trackProfileMenuItemClicked,
    trackLeftNavigationHiding,
    trackLeftNavigationLayout,
  };
}
