<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import {
  STEP_PROJECT_TYPE_SELECTION,
  STEP_BOARD_COLUMNS,
  STEP_ADD_TASKS,
  PREVIEW_TAB_TABLE,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PROJECT_TYPE_SCRATCH,
  PROJECT_TYPE_TEMPLATE,
  STEP_ADD_CLIENT,
} from '../constants';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import OnboardingWizardCommonTemplateSelect from '../common/OnboardingWizardCommonTemplateSelect.vue';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */

const emit = defineEmits(['nextStep']);

const { t } = useI18n();
const { required } = useValidators();

/** use stateData to check if there's old values for this state and if they have changed */
const {
  [STEP_PROJECT_TYPE_SELECTION]: stateData,
  [STEP_BOARD_COLUMNS]: boardColumnsState,
  [STEP_ADD_TASKS]: addTasksState,
  [STEP_ADD_CLIENT]: addClientState,
} = props.state;

const projectName = shallowRef(stateData?.name ?? '');
const projectSelectedType = shallowRef(stateData?.projectSelectedType ?? PROJECT_TYPE_SCRATCH);
const localData = shallowRef({});
const selectedTemplate = shallowRef(stateData?.selectedTemplate ?? {});
const inputEl = shallowRef(null);
const activeView = shallowRef(PREVIEW_TAB_TABLE);

const rules = {
  projectName: {
    required,
  },
};

const v$ = useVuelidate(rules, { projectName }, { $autoDirty: false });

const projectNameDisplayed = computed(() =>
  projectSelectedType.value === PROJECT_TYPE_TEMPLATE ? selectedTemplate.value.name : projectName.value,
);

const tasksDataFromTemplateDisplayed = computed(() => !!selectedTemplate.value?.content);

const nextButtonTextDisplayed = computed(() => {
  return projectSelectedType.value === PROJECT_TYPE_TEMPLATE ? t('Continue with this template') : t('Continue');
});

function onTemplateSelection(template) {
  selectedTemplate.value = template;
  const { boardColumns, tasklists, tagsCollection } = selectedTemplate.value.content;
  localData.value = { boardColumns, tasklists, tagsCollection };
}

function hasChanged() {
  if (projectSelectedType.value === PROJECT_TYPE_TEMPLATE) {
    return (
      !stateData ||
      selectedTemplate.value !== stateData?.selectedTemplate ||
      projectSelectedType.value !== stateData?.projectSelectedType
    );
  }
  return !stateData || projectName.value !== stateData?.name;
}

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    emit('nextStep', hasChanged(), {
      name: projectSelectedType.value === PROJECT_TYPE_TEMPLATE ? selectedTemplate.value.name : projectName.value,
      projectSelectedType,
      selectedTemplate,
      selectedView: activeView.value,
    });
  }
}

function handleActiveTabChanged(tab) {
  activeView.value = tab;
}

function onProjectTypeSelection() {
  v$.value.$reset();
  projectName.value = '';
  selectedTemplate.value = {};
  localData.value = {};

  projectSelectedType.value =
    projectSelectedType.value === PROJECT_TYPE_SCRATCH ? PROJECT_TYPE_SCRATCH : PROJECT_TYPE_TEMPLATE;
}

function capitalizeProjectName(event) {
  const input = event.target;
  const inputValue = input.value;
  input.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
}

watch(selectedTemplate, (newValue) => {
  if (newValue?.name) {
    projectName.value = newValue.name;
  }
});

onMounted(() => {
  if (stateData?.projectSelectedType === PROJECT_TYPE_SCRATCH) {
    localData.value =
      addTasksState && !boardColumnsState ? addTasksState?.tasklistsPreviewData : boardColumnsState?.columnsPreviewData;
  }
  if (stateData?.selectedTemplate && stateData?.projectSelectedType === PROJECT_TYPE_TEMPLATE) {
    selectedTemplate.value = stateData?.selectedTemplate;
  }
  if (projectSelectedType.value === PROJECT_TYPE_SCRATCH && !projectName.value) {
    inputEl.value.focus();
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Create your first project')"
    :description="t('How would you like to start your first project?')"
  >
    <LscChoiceList
      v-model="projectSelectedType"
      :multiple="false"
      class="mb-4"
      @update:modelValue="onProjectTypeSelection"
    >
      <LscChoice :value="PROJECT_TYPE_SCRATCH" hasCheck :label="t('Start from scratch')" icon="lsi-copy" />
      <LscChoice
        :value="PROJECT_TYPE_TEMPLATE"
        hasCheck
        :label="t('Start from template')"
        icon="lsi-multiple-templates"
      />
    </LscChoiceList>

    <VTextField
      v-if="projectSelectedType === PROJECT_TYPE_SCRATCH"
      ref="inputEl"
      v-model.trim="projectName"
      :label="t('Give your project a name')"
      :placeholder="t('e.g. Website Redesign')"
      :errorMessages="v$.projectName.$errors.map((error) => error.$message)"
      :maxLength="255"
      :autoFocus="true"
      class="my-2"
      @keyup="capitalizeProjectName"
    />
    <div v-if="projectSelectedType === PROJECT_TYPE_TEMPLATE">
      <OnboardingWizardCommonTemplateSelect
        :selectedTemplate="selectedTemplate"
        @templateSelected="onTemplateSelection"
      />
    </div>

    <slot name="underFieldsButtons" :nextButtonText="nextButtonTextDisplayed" :nextStep="nextStep" />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectNameDisplayed"
        :clientCompanyName="addClientState?.name"
        :preselectedTab="PREVIEW_TAB_TABLE"
        :tasksData="localData"
        :tasksDataFromTemplate="tasksDataFromTemplateDisplayed"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :stepId="STEP_PROJECT_TYPE_SELECTION"
        @activeTabChanged="handleActiveTabChanged"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
