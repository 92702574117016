import { useCurrentUser } from '../user/useCurrentUser';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
/**
 * Constants, computed properties and functions related to Experiment A-23-30
 * https://app.launchdarkly.com/default/production/features/projects-a-23-30-personalised-icp-onboarding-improvements/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/44779-a-23-30-personalised-icp-onboarding-improvements
 */
export function useExperimentA30() {
  const user = useCurrentUser();
  const { icpOnboardingImprovementsEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = icpOnboardingImprovementsEnabled;
  const ignoreInExperimentOnRuleMatch = true;
  const WORKER_BEE_ROLES = [5, 6];

  const expA30AppLevelTargeting = computed(
    () =>
      user.value?.inOwnerCompany &&
      !WORKER_BEE_ROLES.includes(user.value?.companyRoleId) &&
      !['contact', 'collaborator'].includes(user.value?.userType),
  );

  const isExpA30Variation = computed(() => icpOnboardingImprovementsEnabled.value && expA30AppLevelTargeting.value);
  const isExpA30Control = computed(() => !isExpA30Variation.value);

  function trackExperimentA30() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    isExpA30Variation,
    isExpA30Control,
    trackExperimentA30,
  };
}
