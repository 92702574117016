<script setup>
import { useI18n } from '@/util';
import { usePendo } from '@/api';
import { useHelpCenter } from '../useHelpCenter';
import { STATE_CONTACT_SUPPORT, STATE_WEBINARS, STATE_WHATS_NEW, STATE_KNOWLEDGE_BASE_CATEGORIES } from '../constants';
import HelpCenterDrawerMenuLarge from './HelpCenterDrawerMenuLarge.vue';

const { trackPendoEvent } = usePendo();
const { pushState, HELP_CENTER_COMMON_METRICS } = useHelpCenter();
const { t } = useI18n();

const resources = [
  {
    id: 'webinars',
    title: t('Webinars'),
    description: t('In-depth training & walkthroughs'),
    icon: 'lsi-webinars',
    iconCss: 'bg-[--help-center-webinar-icon-color]',
    pendoLabel: 'webinars',
  },
  {
    id: 'knowledgeBase',
    title: t('Knowledge base'),
    description: t('Tutorials and help docs'),
    icon: 'lsi-resources',
    iconCss: 'bg-[--help-center-knowledgebase-icon-color]',
    pendoLabel: 'knowledge_base',
  },
  {
    id: 'whatsNew',
    title: t("What's new?"),
    description: t("See what's new in {teamwork}", { teamwork: 'Teamwork.com' }),
    icon: 'lsi-whats-new',
    iconCss: 'bg-[--help-center-whatsnew-icon-color]',
    pendoLabel: 'whats_new',
  },
  {
    id: 'teamworkacademy',
    title: 'Teamwork Academy',
    description: t('Learn all about {teamwork}', { teamwork: 'Teamwork.com' }),
    icon: 'lsi-academy',
    iconCss: 'bg-[--help-center-academy-icon-color]',
    href: 'https://academy.teamwork.com/',
    pendoLabel: 'teamwork_academy',
  },
  {
    id: 'contactSupport',
    title: t('Contact support'),
    description: t('Raise a ticket or ask a question'),
    icon: 'lsi-email',
    iconCss: 'bg-[--help-center-contact-icon-color]',
    pendoLabel: 'contact_support',
  },
];

function handleItemClicked(item) {
  let stateToSet;
  switch (item.id) {
    case 'webinars':
      stateToSet = STATE_WEBINARS;
      break;
    case 'knowledgeBase':
      stateToSet = STATE_KNOWLEDGE_BASE_CATEGORIES;
      break;
    case 'whatsNew':
      stateToSet = STATE_WHATS_NEW;
      break;
    case 'contactSupport':
      stateToSet = STATE_CONTACT_SUPPORT;
      break;
    default:
  }

  trackPendoEvent({
    eventName: 'HELP_EVENT',
    commonMetrics: HELP_CENTER_COMMON_METRICS,
    metadata: {
      event_action: 'resources_category_clicked',
      event_label: item.pendoLabel,
    },
  });

  if (stateToSet) {
    pushState(stateToSet);
  }
}
</script>
<template>
  <HelpCenterDrawerMenuLarge :title="t('Resources')" :items="resources" @itemClicked="handleItemClicked" />
</template>
