import { useFeatures, useBudgetsCountsV3Loader } from '@/api';

/**
 * @returns {import('./useFeaturesLimit').FeatureLimitObject}
 */
export function useTasklistBudgetsLimit() {
  const { tasklistBudgetsLimit: featureLimit, tasklistBudgetsEnabled: isFeatureEnabled } = useFeatures();

  const { item: budgetCounts, inSync, loaded } = useBudgetsCountsV3Loader({ params: { skipCounts: false } });

  const loading = computed(() => !loaded.value || !inSync.value);

  const isOverFeatureLimit = computed(() => {
    if (loading.value) {
      return null;
    }
    return featureLimit.value !== -1 && budgetCounts.value.tasklist?.active >= featureLimit.value;
  });

  return {
    featureLimit,
    isFeatureEnabled,
    isOverFeatureLimit,
    loading,
  };
}
