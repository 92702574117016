<script setup>
import { useCurrentUserState, useSavedfilterActions } from '@/api';
import { provideFilter } from './useFilter';
import { useLocalFilters } from './useLocalFilters';
import FilterMenu from './FilterMenu.vue';
import FilterMenuButton from './FilterMenuButton.vue';

const props = defineProps({
  allowSaveFilters: {
    type: Boolean,
    default: true,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
  section: {
    type: String,
    default: undefined,
  },
  dataIdentifierPrefix: {
    type: String,
    required: true,
  },
  allowMenu: {
    type: Boolean,
    default: true,
  },
  allowWrap: {
    type: Boolean,
    default: false,
  },
  savedFilterParams: {
    type: Object,
    default: undefined,
  },
});

const { dataIdentifierPrefix } = toRefs(props);
const modelValue = defineModel({
  type: Object,
  required: true,
});
const slots = useSlots();
const { clearDefaultSavedfilter } = useSavedfilterActions();
const { item: user } = useCurrentUserState();
const activeFilter = shallowRef();

function clearSavedfilter() {
  activeFilter.value = undefined;

  const defaultMeta = {
    isDefault: true,
    title: '',
    description: '',
    color: '',
    isTemporary: true,
    isProjectSpecific: false,
    includesSort: false,
    displayOrder: 0,
    section: props.section,
  };

  clearDefaultSavedfilter({
    filterMeta: defaultMeta,
    parameters: modelValue.value,
  });
}

const { normalize } = provideFilter({
  params: modelValue,
  activeFilter,
  clearSavedfilter,
  dataIdentifierPrefix,
});

const shouldShowMenu = computed(() => props.allowMenu && Boolean(slots.menuFilters || slots.menuOptions));
const hasQuickFilters = computed(() => Boolean(slots.menuQuickFilters));

const localFilters = useLocalFilters({
  filterKey: props.section,
  projectId: props.projectId,
  defaultFilters: modelValue.value,
});

onMounted(normalize);
watch(modelValue, normalize, { flush: 'sync' });

watch(modelValue, () => {
  localFilters.value = {
    ...modelValue.value,
  };
});

// Init default filter.
if (
  user.value.defaultFilters &&
  user.value.defaultFilters[props.section] &&
  user.value.defaultFilters[props.section].title !== '' &&
  user.value.defaultFilters[props.section].projectId === props.projectId
) {
  activeFilter.value = user.value.defaultFilters[props.section];
  modelValue.value = activeFilter.value.parameters;
} else {
  modelValue.value = localFilters.value;
}
</script>

<template>
  <div :class="allowWrap ? 'flex flex-wrap gap-y-4' : 'inline-flex'" class="items-center gap-2">
    <slot name="default" />
    <FilterMenu
      v-if="shouldShowMenu"
      :allowSaveFilters="allowSaveFilters"
      :closeOnContentClick="false"
      :projectId="projectId"
      :section="section"
      :savedFilterParams="savedFilterParams"
      :hasQuickFilters="hasQuickFilters"
      :location="hasQuickFilters ? 'bottom center' : 'bottom end'"
      offset="12"
    >
      <template #activator="activator">
        <slot name="activator" v-bind="activator">
          <FilterMenuButton v-bind="activator.props" />
        </slot>
      </template>
      <template #menuFilters><slot name="menuFilters" /></template>
      <template v-if="$slots.menuQuickFilters" #menuQuickFilters><slot name="menuQuickFilters" /></template>
      <template #menuOptions><slot name="menuOptions" /></template>
    </FilterMenu>
  </div>
</template>
