<script setup>
/*
  This file is a place holder - we don't have global forms yet but we will
  soon so please leave this for mow.
*/
import { useFormsV3Loader } from '@/api';
import FormDrawerFormsList from './FormDrawerFormsList.vue';
import FormDrawerGlobalBlankSlate from './FormDrawerGlobalBlankSlate.vue';

const step = 30;
const count = shallowRef(step);
const state = useFormsV3Loader({
  count,
  pageSize: step,
  params: {
    orderBy: 'name',
    onlyProjectsWithExplicitMembership: true,
    include: 'projects.companies',
    'content.state': 'latest',
  },
});
</script>

<template>
  <FormDrawerFormsList :count="count" :step="step" :state="state">
    <template #blank>
      <FormDrawerGlobalBlankSlate />
    </template>
  </FormDrawerFormsList>
</template>
