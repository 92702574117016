import { getLastChildPath } from '../lastChildPath';

export const peopleRoutes = [
  {
    path: '/people',
    component: () => import('./RoutePeople.vue'),
    children: [
      {
        path: '',
        redirect() {
          return `/people/${getLastChildPath('people', 'people')}`;
        },
      },
      {
        path: 'companies',
        component: () => import('./RoutePeopleCompanies.vue'),
      },
      {
        path: 'people',
        component: () => import('./RoutePeoplePeople.vue'),
      },
      {
        path: 'teams',
        component: () => import('./RoutePeopleTeams.vue'),
      },
      {
        path: 'roles',
        component: () => import('./RoutePeopleJobRoles.vue'),
      },
      {
        path: 'statuses',
        component: () => import('./RoutePeopleStatuses.vue'),
      },
      {
        path: 'loginHistory',
        component: () => import('./RoutePeopleLoginHistory.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/people/people',
      },
    ],
  },
];
