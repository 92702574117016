<script setup>
import { useI18n } from '@/util';
import {
  Filter,
  FilterCategoryPicker,
  FilterCheckbox,
  FilterCompanyPicker,
  FilterCustomFields,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterStatusTypes,
  FilterTagPicker,
  FilterTasklistPicker,
  FilterTeamPicker,
} from '@/module/filter';
import { useReportBuilderFilters } from '../useReportBuilderFilters';
import { useCurrentAccount } from '@/api';

const { t } = useI18n();
const account = useCurrentAccount();
const { filterParams } = useReportBuilderFilters();
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter"
    section="reportBuilderFilter"
    :allowSaveFilters="false"
    class="flex flex-col"
  >
    <template #menuFilters>
      <FilterPeoplePicker :title="t('Assignees')" name="userIds" />
      <FilterTagPicker v-if="account.tagsEnabled" :title="t('Tags')" name="tagIds" />
      <FilterTeamPicker
        :title="t('Teams')"
        name="teamIds"
        :loaderParams="{
          includeCompanyTeams: true,
          includeSubteams: true,
          includeProjectTeams: true,
        }"
      />
      <FilterCompanyPicker :title="t('Companies')" name="companyIds" />
      <FilterTasklistPicker :title="t('Task lists')" name="tasklists" />
      <FilterProjectPicker :title="t('Projects')" name="projectIds" />
      <FilterStatusTypes :title="t('Task status')" name="status" />
      <FilterCategoryPicker :title="t('Categories')" name="categoryIds" />
      <FilterCustomFields :title="t('Custom fields')" name="customFieldFilters" entities="task" />
    </template>
    <template #menuOptions>
      <FilterCheckbox name="includeCompletedTasks" :label="t('Include completed tasks')" />
      <FilterCheckbox name="includeArchivedProjects" :label="t('Include data from archived projects')" />
    </template>
  </Filter>
</template>
