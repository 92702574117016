<script setup>
import { useI18n } from '@/util';
import { usePendo } from '@/api';
import { useHelpCenter } from '../useHelpCenter';
import { STATE_KEYBOARD_SHORTCUTS } from '../constants';
import HelpCenterDrawerMenuMedium from './HelpCenterDrawerMenuMedium.vue';

const { t } = useI18n();
const { pushState, HELP_CENTER_COMMON_METRICS } = useHelpCenter();
const { trackPendoEvent } = usePendo();

const footerMenuItems = [
  {
    id: 'apps',
    title: t('Apps'),
    icon: 'lsi-integrations',
    hoverAppendIcon: 'lsi-external-link',
    href: 'https://integrations.teamwork.com/',
    trackingActionLabel: 'apps_clicked',
  },
  {
    id: 'keyboardShortcuts',
    title: t('Keyboard shortcuts'),
    icon: 'lsi-keyboard-shortcut',
    hoverAppendIcon: 'lsi-open-panel',
    trackingActionLabel: 'keyboard_shortcuts_clicked',
  },
  {
    id: 'browseOurSupportWebsite',
    title: t('Browse our support website'),
    icon: 'lsi-website',
    hoverAppendIcon: 'lsi-external-link',
    href: 'https://www.teamwork.com/support/',
    trackingActionLabel: 'browse_our_support_website_clicked',
  },
];

function handleItemClicked(id, trackingLabel) {
  trackPendoEvent({
    eventName: 'HELP_EVENT',
    commonMetrics: HELP_CENTER_COMMON_METRICS,
    metadata: {
      event_action: trackingLabel,
    },
  });

  if (id === 'keyboardShortcuts') {
    pushState(STATE_KEYBOARD_SHORTCUTS);
  }
}
</script>

<template>
  <HelpCenterDrawerMenuMedium
    :items="footerMenuItems"
    @itemClicked="handleItemClicked($event.id, $event.trackingActionLabel)"
  />
</template>
