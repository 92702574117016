import { useLocalStorage } from '@/util';

/**
 * Use filters that are saved in local storage.
 * Pretty much a wrapper around useLocalStorage, but will be extended in the future.
 *
 * @param {object} options - The options for the local filters.
 * @param {string} options.filterKey - The key to use for the local storage.
 * @param {number=} options.projectId - The project ID to use for the local storage key.
 * @param {object} options.defaultFilters - The default filters to use.
 */
export function useLocalFilters({ filterKey, projectId = 0, defaultFilters }) {
  const filters = useLocalStorage(`teamwork/useLocalFilters/${projectId}/${filterKey}`, defaultFilters, {
    mergeDefaults: true,
  });
  return filters;
}
