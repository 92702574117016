const symbol = Symbol('useLsDialog');

function LsDialog({ shouldCloseOnClickOutside: _shouldCloseOnClickOutside = true } = {}) {
  const shouldCloseOnClickOutside = shallowRef(_shouldCloseOnClickOutside);
  return {
    shouldCloseOnClickOutside,
  };
}

export function provideLsDialog() {
  const lsDialog = LsDialog();
  provide(symbol, lsDialog);
  return lsDialog;
}

/**
 * @returns {LsDialog}
 */
export function useLsDialog() {
  return inject(symbol);
}
