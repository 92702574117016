import { useFeatures, useCustomfieldsV3Loader } from '@/api';

/**
 * @returns {import('./useFeaturesLimit').FeatureLimitObject}
 */
export function useCustomFieldsLimit() {
  const { customfieldsLimit, customfieldsProjectsEnabled, customfieldsTasksEnabled } = useFeatures();

  const isFeatureEnabled = computed(() => Boolean(customfieldsProjectsEnabled.value || customfieldsTasksEnabled.value));

  const { meta, inSync, loaded } = useCustomfieldsV3Loader({
    params: {
      showDeleted: false,
      onlySiteLevel: false,
      skipCounts: false,
    },
    count: 0,
  });

  const loading = computed(() => !loaded.value || !inSync.value);

  const isOverFeatureLimit = computed(() => {
    if (loading.value) {
      return null;
    }

    return customfieldsLimit.value !== -1 && meta.value?.totalCount >= customfieldsLimit.value;
  });

  return {
    isFeatureEnabled,
    featureLimit: customfieldsLimit,
    isOverFeatureLimit,
    loading,
  };
}
