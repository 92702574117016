<script setup>
import Draggable from 'vuedraggable';
import { useI18n } from '@/util';
import { useSavedfiltersV3Loader, useSavedfilterActions, useFeatures } from '@/api';
import { useFilter } from './useFilter';

const props = defineProps({
  displayFilterTitleInput: {
    type: Boolean,
    default: false,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
  section: {
    type: String,
    default: undefined,
  },
  savedFilterParams: {
    type: Object,
    default: undefined,
  },
});

const emit = defineEmits(['setSavefilterDisplay', 'clearFilter', 'openShareFilterModal']);
const { t } = useI18n();
const toast = useLsToast();
const newFilterTitle = shallowRef('');
const { params, activeFilter } = useFilter();
const { createSavedfilter, deleteSavedfilter, setDefaultSavedfilter, repositionSavedfilter } = useSavedfilterActions();

// The addition of the share filters was done for this feature but needs QA testing before a full release.
const { sharingSavedFilterLinksEnabled } = useFeatures();
const count = shallowRef(10);
const pageSize = 20;

const state = useSavedfiltersV3Loader({
  pageSize,
  count,
  params: props.savedFilterParams ?? {
    sort: 'displayOrder',
    include: 'projects,projectColumns,projectCategories,users,companies,tags,calendarEventTypes,tasklists,teams',
    sections: props.section,
    projectIds: props.projectId,
  },
});

const { items: filters } = state;

function payload(title) {
  return {
    title,
    includesSort: false,
    isTemporary: false,
    isProjectSpecific: false,
    section: props.section,
    projectId: props.projectId,
    parameters: { ...params.value },
  };
}

async function deleteFilter(filter) {
  if (filter.id === activeFilter.value?.id) {
    emit('clearFilter');
  }
  await deleteSavedfilter(filter);
  toast.success(t('Filter deleted'));
}

async function setFilter(filter) {
  params.value = filter.parameters;

  const defaultSavedfilter = {
    id: filter.id,
    filterMeta: {
      title: filter.title,
      description: filter.description || '',
      color: filter.color || '',
      isTemporary: undefined,
      displayOrder: filter.displayOrder,
      includesSort: filter.includesSort,
      section: props.section,
      isDefault: true,
    },
    parameters: { ...filter.parameters },
  };
  await setDefaultSavedfilter(defaultSavedfilter);
  emit('setSavefilterDisplay', false);
  activeFilter.value = filter;
}

async function createFilter(title) {
  if (title === '') {
    return;
  }
  const newFilter = await createSavedfilter({ ...payload(title) });
  setFilter({
    ...newFilter,
    parameters: params.value,
  });
  newFilterTitle.value = '';
  toast.success(t('Filter saved'));
}

function afterReorder(e) {
  const movedSavedfilter = filters.value[e.newIndex];
  let positionAfterTaskId = 0;
  if (e.newIndex !== filters.value.length - 1) {
    positionAfterTaskId = filters.value[e.newIndex + 1].id;
  }
  repositionSavedfilter(movedSavedfilter, positionAfterTaskId);
}
</script>

<template>
  <div class="flex flex-col items-stretch gap-2">
    <VListItem v-if="displayFilterTitleInput" class="bg-action-primary rounded-sm !px-1">
      <VTextField
        v-model.trim="newFilterTitle"
        :placeholder="t('Filter name')"
        autofocus
        @keyup.enter="createFilter(newFilterTitle)"
      />
    </VListItem>
    <WidgetLoadingState :state="state" :blankTitle="t('No saved filters found')">
      <VList density="compact" maxHeight="384" class="w-full">
        <Draggable :list="filters" itemKey="id" @end="afterReorder">
          <template #item="{ element }">
            <VListItem
              :key="element.id"
              class="group mb-3 rounded-sm bg-surface-default !pl-0"
              :active="element.id === activeFilter?.id"
              activeClass="text-primary"
              @click="setFilter(element)"
            >
              <template #prepend>
                <div class="inline-flex w-6 cursor-move justify-center">
                  <LscIcon v-show="filters.length > 1" icon="lsi-drag" class="hidden group-hover:flex" />
                </div>
              </template>
              <VListItemTitle class="text-body-1 font-semibold">
                {{ element.title }}
              </VListItemTitle>
              <template #append>
                <WidgetOptionsMenu location="bottom end" origin="auto" :closeOnContentClick="true">
                  <WidgetOptionsMenuItem @click="deleteFilter(element)">
                    {{ t('Delete') }}
                  </WidgetOptionsMenuItem>
                  <WidgetOptionsMenuItem
                    v-if="sharingSavedFilterLinksEnabled"
                    @click="emit('openShareFilterModal', element)"
                  >
                    {{ t('Share') }}
                  </WidgetOptionsMenuItem>
                </WidgetOptionsMenu>
              </template>
            </VListItem>
          </template>
        </Draggable>
      </VList>
      <WidgetLoadingLazy v-model:count="count" :state="state" :step="state.pageSize" />
    </WidgetLoadingState>
    <div class="flex flex-wrap justify-between gap-2">
      <LscButton variant="tertiary" size="md" prependIcon="lsi-back" @click="emit('setSavefilterDisplay', false)">
        {{ t('Back') }}
      </LscButton>
      <LscButton v-if="displayFilterTitleInput" size="md" variant="primary" @click="createFilter(newFilterTitle)">
        {{ t('Save filter') }}
      </LscButton>
    </div>
  </div>
</template>
