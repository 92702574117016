<script setup>
import { useLocalStorage } from '@/util';
import ProjectJumpToDrawerProjectsListTasklistsLoader from './ProjectJumpToDrawerProjectsListTasklistsLoader.vue';
import { useAppShellSidebar } from '@/appShell';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  navItems: {
    type: Array,
    required: true,
  },
});

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const openSubMenuItems = useLocalStorage(`teamwork/sidenav/project/${props.project?.id}/openSubMenuItems`, []);
const itemsThatCanBeOpened = ['list', 'table'];

function toggleOpenSubitem(item) {
  const pos = openSubMenuItems.value.indexOf(item.id);
  if (pos < 0) {
    openSubMenuItems.value.push(item.id);
  } else {
    openSubMenuItems.value.splice(pos, 1);
  }
}

function closePanels(hasSubItems) {
  if (!hasSubItems) {
    clearActiveDrawerPanelIfNotPinned();
  }
}
</script>
<template>
  <ProjectNavItem v-for="item in navItems" :key="item.name">
    <VListItem
      :to="item.path"
      link
      density="compact"
      class="group"
      v-bind="VListItemSidebarProjectsDrawer"
      @click="closePanels(itemsThatCanBeOpened.includes(item.id))"
    >
      <template #prepend>
        <button
          v-if="itemsThatCanBeOpened.includes(item.id)"
          type="button"
          size="sm"
          class="hidden h-[24px] w-[24px] items-center justify-center hover:rounded-full hover:bg-[#fff] group-hover:flex"
          @click.prevent="toggleOpenSubitem(item)"
        >
          <LscIcon
            size="md"
            icon="lsi-collapse"
            class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] transition-transform duration-300"
            :class="{
              '-rotate-90': openSubMenuItems.indexOf(item.id) > -1,
              '-rotate-180': openSubMenuItems.indexOf(item.id) < 0,
            }"
          />
        </button>
        <div
          class="flex h-[24px] w-[24px] items-center justify-center"
          :class="{ 'group-hover:hidden': itemsThatCanBeOpened.includes(item.id) }"
        >
          <LscIcon size="md" :icon="item.icon" />
        </div>
      </template>
      <VListItemTitle :title="item.name" class="ml-2 text-body-1">{{ item.name }}</VListItemTitle>
    </VListItem>
    <template v-if="openSubMenuItems.indexOf(item.id) > -1">
      <ProjectJumpToDrawerProjectsListTasklistsLoader
        v-if="item.id === 'list' || item.id === 'table'"
        :navItem="item"
        :project="project"
      />
    </template>
  </ProjectNavItem>
</template>
