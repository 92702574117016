<script setup>
import { useI18n } from '@/util';
import { useReportBuilder } from '../useReportBuilder.js';

const emit = defineEmits(['handleCustomReportTrack']);

const { t } = useI18n();
const { currentReport } = useReportBuilder();

const assigneePickerText = computed(() => (currentReport.value.users.length ? '' : t('Add user or team')));

const avatars = computed(() => {
  return currentReport.value.users.map((assignee) =>
    getLsAvatarProps({
      [assignee.entityType ?? assignee.assigneeType]: assignee,
    }),
  );
});

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}

const previousUsers = shallowRef([...currentReport.value.users]);
const numOfUsersAdded = shallowRef(0);

watch(
  () => currentReport.value.users,
  (newUsers, oldUsers) => {
    if (newUsers.length > oldUsers.length) {
      numOfUsersAdded.value += newUsers.length - oldUsers.length;
    } else if (newUsers.length < oldUsers.length) {
      numOfUsersAdded.value = 0;
    }
    previousUsers.value = [...newUsers];
  },
  { deep: true },
);

function handleAfterLeave() {
  if (numOfUsersAdded.value > 0) {
    handleCustomReportTrack('custom_reports', 'custom_report_users_added', 'activation_event', numOfUsersAdded.value);
    numOfUsersAdded.value = 0;
  }
}
</script>

<template>
  <div class="mb-4 flex items-center gap-1 text-body-1 text-subtle">
    <LscIcon icon="lsi-file-locked" size="md" class="mr-1" />
    <span>{{ t('Admins') }}</span>
    <LscIcon v-LsdTooltip="t('Site admins can see reports by default')" icon="lsi-tooltip" class="ml-auto" size="sm" />
    <span>{{ t('Can view and edit') }}</span>
  </div>
  <div class="flex items-center gap-1 text-body-1 text-subtle">
    <LswAssigneePicker
      v-model:assignees="currentReport.users"
      class="flex-start"
      :editable="true"
      includeProjectTeams
      includeSubteams
      includeCompanyTeams
      @afterLeave="handleAfterLeave"
    >
      <template #activator="activator">
        <LscButton
          v-bind="activator.props"
          variant="plain-secondary"
          class="flex gap-1"
          @click="handleCustomReportTrack('custom_reports', 'custom_report_add_users_viewed', 'activation_event')"
        >
          <LscAvatarStack v-if="currentReport.users.length" :avatars="avatars" />
          <LscAvatar v-else :showTooltip="false" />
          <span>{{ assigneePickerText }}</span>
        </LscButton>
      </template>
    </LswAssigneePicker>
    <span class="ml-auto">{{ t('Can view') }}</span>
  </div>
</template>
