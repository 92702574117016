import * as validators from '@vuelidate/validators';
import { Interval } from 'luxon';
import { useI18n } from '../i18n/useI18n';
import { getExtensionFromMimeType } from '../file/mime';

const { withMessage, req } = validators.helpers;
/**
 * @param {Parameters<typeof withMessage>[0]} message
 * @param {Parameters<typeof withMessage>[1]} validator
 * @returns {import('@vuelidate/core').ValidationRule}
 */
function withI18nMessage(message, validator) {
  if (typeof validator === 'function') {
    return (...args) => withMessage(message, validator(...args));
  }
  return withMessage(message, validator);
}

export function useValidators() {
  const { t, formatDate, formatDateRange, formatFileSize, formatList } = useI18n();

  /**
   * @param {DateTime=} minDate
   * @type {import('@vuelidate/core').ValidationRuleWithParams<{ minDate: DateTime }, DateTime>}
   */
  function minDateValidator(minDate) {
    return {
      $params: { minDate, type: 'minDate' },
      $message: ({ $params }) => {
        return t('Please select a date after {minDate}', { minDate: formatDate($params.minDate) });
      },
      $validator: (dateTime) => {
        return !req(dateTime) || !minDate || dateTime >= minDate;
      },
    };
  }

  /**
   * @param {DateTime=} maxDate
   * @type {import('@vuelidate/core').ValidationRuleWithParams<{ maxDate: DateTime }, DateTime>}
   */
  function maxDateValidator(maxDate) {
    return {
      $params: { maxDate, type: 'maxDate' },
      $message: ({ $params }) => {
        return t('Please select a date before {maxDate}', { maxDate: formatDate($params.maxDate) });
      },
      $validator: (dateTime) => {
        return !req(dateTime) || !maxDate || dateTime <= maxDate;
      },
    };
  }

  /**
   * @param {DateTime=} minDate
   * @param {DateTime=} maxDate
   * @type {import('@vuelidate/core').ValidationRuleWithParams<{ maxDate: DateTime }, DateTime>}
   */
  function betweenDatesValidator(minDate, maxDate) {
    return {
      $params: { minDate, maxDate, type: 'betweenDates' },
      $message: ({ $params }) => {
        if ($params.minDate && !$params.maxDate) {
          return t('Please select a date after {minDate}', { minDate: formatDate($params.minDate) });
        }
        if (!$params.minDate && $params.maxDate) {
          return t('Please select a date before {maxDate}', { maxDate: formatDate($params.maxDate) });
        }
        return t('Please select a date between {dateRange}', {
          dateRange: formatDateRange(Interval.fromDateTimes($params.minDate, $params.maxDate)),
        });
      },
      $validator: (dateTime) => {
        if (!req(dateTime) || (!minDate && !maxDate)) {
          return true;
        }
        if (minDate && !maxDate) {
          return dateTime >= minDate;
        }
        if (!minDate && maxDate) {
          return dateTime <= maxDate;
        }
        return dateTime >= minDate && dateTime <= maxDate;
      },
    };
  }

  function mimeTypeValidator(acceptedMimeTypes = []) {
    return {
      $params: { acceptedMimeTypes, type: 'mimeType' },
      $message: ({ $params }) => {
        return `${t('Accepted file types')}: ${formatList($params.acceptedMimeTypes.map(getExtensionFromMimeType))}`;
      },
      /**
       * @param {File} file
       * @returns {boolean}
       */
      $validator: (file) => {
        if (!req(file) || !acceptedMimeTypes.length) {
          return true;
        }
        return acceptedMimeTypes.includes(file.type);
      },
    };
  }

  function maxFileSizeValidator(maxFileSize) {
    return {
      $params: { maxFileSize, type: 'maxFileSize' },
      $message: ({ $params }) => {
        return t('The size must be at most {maxFileSize}', { maxFileSize: formatFileSize($params.maxFileSize) });
      },
      /**
       * @param {File} file
       * @returns {boolean}
       */
      $validator: (file) => !req(file) || !maxFileSize || file.size <= maxFileSize,
    };
  }

  return {
    alpha: withI18nMessage(t('The value must be alphabetical'), validators.alpha),
    alphaNum: withI18nMessage(t('The value must be alphanumeric'), validators.alphaNum),
    between: withI18nMessage(
      ({ $params }) => t('The value must be between {min} and {max}', { min: $params.min, max: $params.max }),
      validators.between,
    ),
    decimal: withI18nMessage(t('The value must be a number'), validators.decimal),
    email: withI18nMessage(t('The value must be a valid email address'), validators.email),
    integer: withI18nMessage(t('The value must be a whole number'), validators.integer),
    ipAddress: withI18nMessage(t('The value must be a valid IP address'), validators.ipAddress),
    macAddress: withI18nMessage(t('The value must be a valid MAC address'), validators.macAddress),
    numeric: withI18nMessage(t('The value must be a number'), validators.numeric),
    maxLength: withI18nMessage(
      ({ $params }) => t('This field must be at most {max} characters long', { max: $params.max }),
      validators.maxLength,
    ),
    minLength: withI18nMessage(
      ({ $params }) => t('This field must be at least {min} characters long', { min: $params.min }),
      validators.minLength,
    ),
    maxValue: withI18nMessage(
      ({ $params }) => t('The maximum value allowed is {max}', { max: $params.max }),
      validators.maxValue,
    ),
    minValue: withI18nMessage(
      ({ $params }) => t('The minimum value allowed is {min}', { min: $params.min }),
      validators.minValue,
    ),
    required: withI18nMessage(t('Value is required'), validators.required),
    requiredIf: withI18nMessage(t('Value is required'), validators.requiredIf),
    requiredUnless: withI18nMessage(t('Value is required'), validators.requiredUnless),
    sameAs: withI18nMessage(
      ({ $params }) => t('Value must be equal to {otherName}', { otherName: $params.otherName }),
      validators.sameAs,
    ),
    url: withI18nMessage(t('The value must be a valid URL'), validators.url),
    or: validators.or,
    and: validators.and,
    not: validators.not,
    helpers: validators.helpers,
    // Custom validators
    minDate: minDateValidator,
    maxDate: maxDateValidator,
    betweenDates: betweenDatesValidator,
    maxFileSize: maxFileSizeValidator,
    mimeType: mimeTypeValidator,
  };
}
