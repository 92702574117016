import { DateTime } from 'luxon';
import { isValidId, useI18n } from '@/util';
import { useCurrentUser } from '../user/useCurrentUser';
import { useAxios } from '../base/useAxios';
import { useLoaders } from '../base/useLoaders';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { toAssigneeString } from './toAssigneeString';
import { normalizeRepeatOptions } from './normalizeRepeatOptions';

const newTaskId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

function normalizeFollowerIds(followers) {
  return followers
    .map(({ id, assigneeType }) => {
      if (assigneeType === 'teams') {
        return `t${id}`;
      }

      if (assigneeType === 'companies') {
        return `c${id}`;
      }

      return id;
    })
    .filter(Boolean)
    .join(',');
}

function createToDoItem(task) {
  const toDoItem = {
    id: task.id,
  };

  // allow-update flag is used to be able to update completed tasks
  if (Object.hasOwn(task, 'allowUpdate')) {
    toDoItem['allow-update'] = true;
  }

  if (Object.hasOwn(task, 'name')) {
    toDoItem.content = task.name;
  }
  if (Object.hasOwn(task, 'startDate')) {
    // If we want to clear a defaulted start date
    if (task.startDate === '00000000') {
      toDoItem['start-date'] = task.startDate;
    } else {
      toDoItem['start-date'] =
        DateTime.isDateTime(task.startDate) && task.startDate.isValid ? task.startDate.toFormat('yyyyMMdd') : '';
    }
  }
  if (Object.hasOwn(task, 'dueDate')) {
    // If we want to clear a defaulted due date
    if (task.dueDate === '00000000') {
      toDoItem['due-date'] = task.dueDate;
    } else {
      toDoItem['due-date'] =
        DateTime.isDateTime(task.dueDate) && task.dueDate.isValid ? task.dueDate.toFormat('yyyyMMdd') : '';
    }
  }
  if (Object.hasOwn(task, 'originalDueDate')) {
    toDoItem['original-due-date'] =
      DateTime.isDateTime(task.originalDueDate) && task.originalDueDate.isValid
        ? task.originalDueDate.toFormat('yyyyMMdd')
        : '';
  }
  if (Object.hasOwn(task, 'pushSubtasks')) {
    toDoItem['push-subtasks'] = task.pushSubtasks;
  }
  if (Object.hasOwn(task, 'pushDependents')) {
    toDoItem['push-dependents'] = task.pushDependents;
  }
  if (Object.hasOwn(task, 'skipWeekends')) {
    toDoItem['skip-weekends'] = task.skipWeekends;
  }
  if (Object.hasOwn(task, 'notify')) {
    toDoItem.notify = task.notify;
  }
  if (Object.hasOwn(task, 'progress')) {
    toDoItem.progress = task.progress;
  }
  if (Object.hasOwn(task, 'estimateMinutes')) {
    toDoItem['estimated-minutes'] = task.estimateMinutes;
  }
  if (Object.hasOwn(task, 'priority')) {
    toDoItem.priority = task.priority ?? 'none';
  }
  if (Object.hasOwn(task, 'description')) {
    toDoItem.description = task.description;
  }
  if (Object.hasOwn(task, 'column')) {
    if (isValidId(task.column?.id)) {
      toDoItem.columnId = task.column.id;
    } else {
      // Note: we set a different value for new and existing tasks here. This is to ensure that
      // task list defaults do not overide the value when removing a task's column.
      toDoItem.columnId = isValidId(task.id) ? 0 : -1;
    }
  }
  if (Object.hasOwn(task, 'customFields')) {
    toDoItem.customFields = task.customFields;
  }
  if (Object.hasOwn(task, 'tags') && Array.isArray(task.tags)) {
    toDoItem.tagIds = task.tags.map((tag) => tag.id).join(',');
  }
  if (Object.hasOwn(task, 'tasklistId')) {
    toDoItem.taskListId = task.tasklistId;
  } else if (Object.hasOwn(task, 'taskListId')) {
    toDoItem.taskListId = task.taskListId;
  }
  if (Object.hasOwn(task, 'positionAfterTaskId')) {
    toDoItem.positionAfterTask = task.positionAfterTaskId;
  }
  if (Object.hasOwn(task, 'creatorId')) {
    toDoItem['creator-id'] = task.creatorId;
  }
  if (Object.hasOwn(task, 'completerId')) {
    toDoItem['completer-id'] = task.completerId;
  }
  if (Object.hasOwn(task, 'completedOn')) {
    toDoItem['completed-on'] =
      DateTime.isDateTime(task.completedOn) && task.completedOn.isValid
        ? task.completedOn.toFormat('yyyyMMddHHmmss')
        : '';
  }
  if (Object.hasOwn(task, 'assignees')) {
    toDoItem['responsible-party-id'] = !task.assignees ? '' : toAssigneeString(task.assignees);
  }
  if (Object.hasOwn(task, 'grantAccessTo')) {
    toDoItem['grant-access-to'] = task.grantAccessTo;
  }
  if (Object.hasOwn(task, 'private')) {
    toDoItem.private = task.private;
  }
  if (Object.hasOwn(task, 'parentTaskId')) {
    toDoItem.parentTaskId = task.parentTaskId;
  }
  if (Object.hasOwn(task, 'pendingFileAttachments')) {
    toDoItem.pendingFileAttachments = task.pendingFileAttachments;
  }
  if (Object.hasOwn(task, 'changeFollowers')) {
    toDoItem.changeFollowerIds = !task.changeFollowers ? '' : normalizeFollowerIds(task.changeFollowers);
  }
  if (Object.hasOwn(task, 'commentFollowers')) {
    toDoItem.commentFollowerIds = !task.commentFollowers ? '' : normalizeFollowerIds(task.commentFollowers);
  }
  if (Object.hasOwn(task, 'repeatOptions')) {
    toDoItem.repeatOptions = task.repeatOptions;
  }
  if (Object.hasOwn(task, 'predecessors')) {
    toDoItem.predecessors = task.predecessors;
  }
  if (Object.hasOwn(task, 'attachments')) {
    toDoItem.attachments = !task.attachments ? '' : task.attachments.map((file) => file.id).join(',');
    toDoItem.updateFiles = true;
    toDoItem.removeOtherFiles = true;
  }
  if (Object.hasOwn(task, 'templateRoleName')) {
    toDoItem.templateRoleName = task.templateRoleName;
    toDoItem['responsible-party-id'] = task.templateRoleName === '' ? '0' : '-1';
  }
  if (Object.hasOwn(task, 'stageId')) {
    toDoItem.stageId = task.stageId;
  }
  if (Object.hasOwn(task, 'workflowId')) {
    toDoItem.workflowId = task.workflowId;
  }
  return toDoItem;
}

export function useTaskActions() {
  const api = useAxios();
  const { t, dateFormat } = useI18n();
  const toast = useLsToast();
  const user = useCurrentUser();
  const { getItem } = useLoaders();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function emitOptimisticUpdate(promise, action, task) {
    const {
      // We remove tasklist and project from the task included in optimistic updates
      // to keep it simple and avoid potential inconsistency between
      // `tasklistId` / `tasklist.id`  and `projectId` / `project.id`.
      tasklist,
      project,
      // TODO Remove `taskList` when no longer used.
      taskList,
      // TODO Remove `taskListId` when no longer used.
      taskListId,
      ...normalizedTask
    } = task;
    // TODO Remove taskListId when no longer used.
    normalizedTask.tasklistId ??= taskListId;
    // TODO Remove taskListId when no longer used.
    normalizedTask.taskListId = normalizedTask.tasklistId;
    _emitOptimisticUpdate({
      promise,
      type: 'task',
      action,
      task: normalizedTask,
    });
  }

  function emitRealTimeUpdate(action, newTask, oldTask, detail) {
    const taskId = newTask.id ?? oldTask.id;
    const workflowStages = [...(newTask.workflowStages || []), ...(oldTask.workflowStages || [])];
    const affectedWorkflowIds = workflowStages.map(({ workflowId }) => workflowId);
    const affectedStageIds = workflowStages.map(({ stageId }) => stageId || 0); // 0 => Backlog

    _emitRealTimeUpdate({
      type: 'task',
      action,
      taskId,
      detail: newTask.pendingFileAttachments ? 'task-fileattached' : detail,
      parentTaskId: newTask.parentTaskId ?? oldTask.parentTaskId,
      previousParentTaskId: oldTask.parentTaskId,
      tasklistId: newTask.tasklistId ?? oldTask.tasklistId,
      previousTaskListId: oldTask.tasklistId,
      oldTasklistId: oldTask.tasklistId,
      projectId: newTask.projectId ?? oldTask.projectId,
      previousProjectId: oldTask.projectId,
      hasEstimatedTime: Boolean(newTask?.estimateMinutes),
      hasDependents: Boolean(newTask?.dependencyIds),
      affectedWorkflowIds: [...new Set(affectedWorkflowIds)], // unique array of ids
      affectedStageIds: [...new Set(affectedStageIds)],
    });
  }

  function updateTask(updatedTask, options = {}) {
    const task = getItem('task', updatedTask.id);
    if (!task) {
      return Promise.reject(new Error('useTaskActions: cannot find the task to update'));
    }

    const promise = api
      .put(
        `/tasks/${updatedTask.id}.json`,
        {
          ...options,
          'todo-item': createToDoItem(updatedTask),
        },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (error?.response?.data?.MESSAGE === 'The task name cannot be blank') {
              return t('The task name cannot be blank');
            }
            return t('Failed to update task');
          },
        },
      )
      .then(() => emitRealTimeUpdate('edited', updatedTask, task));
    emitOptimisticUpdate(promise, 'update', {
      ...task,
      ...updatedTask,
    });
    return promise;
  }

  return {
    updateTask,

    createTask(newTask) {
      const taskPostURL =
        newTask.tasklistId || newTask.taskListId
          ? `/tasklists/${newTask.tasklistId || newTask.taskListId}/tasks.json`
          : `/projects/${newTask.projectId}/tasks.json`;
      const promise = api
        .post(
          taskPostURL,
          {
            'todo-item': createToDoItem(newTask),
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(
          ({ data: { id } }) => {
            const taskListId = newTask.taskListId ?? newTask.tasklistId;
            emitRealTimeUpdate('new', { ...newTask, taskListId, id: Number(id) }, {});
            return { id: Number(id) };
          },
          (error) => {
            toast.critical(t('Failed to create a task'));
            return Promise.reject(error);
          },
        );
      emitOptimisticUpdate(promise, 'create', { ...newTask, id: newTaskId(), updatedAt: DateTime.now() });
      return promise;
    },

    createTaskV3(newTask) {
      // temporary until found a generic way to handle v3 task object => { task: { ... }, workflows:{ ... }, taskOptions:{ ... }, tags:{ ... }}
      // Backend task for this => https://digitalcrew.teamwork.com/app/tasks/22722007

      // Remove some properties that are not supported by backend but we need to keep them for optimistic updates
      const {
        // Remove from payload
        assignees,
        tasklist,
        tags,
        workflowStages,
        projectId,
        project,
        estimateMinutes,
        userPermissions,
        // Keep Rest of the props
        ...newTaskCopy
      } = newTask;

      const assigneeUserIds = assignees.filter((item) => item.entityType === 'user').map((item) => item.id);
      const assigneeTeamIds = assignees.filter((item) => item.entityType === 'team').map((item) => item.id);
      const assigneeCompanyIds = assignees.filter((item) => item.entityType === 'company').map((item) => item.id);

      const payload = {
        ...newTaskCopy,
        estimatedMinutes: estimateMinutes,
        assignees: {
          userIds: assigneeUserIds,
          teamIds: assigneeTeamIds,
          companyIds: assigneeCompanyIds,
        },
      };

      if (DateTime.isDateTime(payload.startAt) && payload.startAt.isValid) {
        payload.startAt = payload.startAt.toFormat('yyyy-MM-dd');
      }
      if (DateTime.isDateTime(payload.dueAt) && payload.dueAt.isValid) {
        payload.dueAt = payload.dueAt.toFormat('yyyy-MM-dd');
      }

      const {
        stageTaskDisplayOrder, // Do no send to backend
        ...workflows
      } = workflowStages[0];

      const promise = api
        .post(
          `/projects/api/v3/tasklists/${newTask.tasklistId}/tasks.json`,
          {
            task: payload,
            workflows,
          },
          {
            headers: { 'Socket-ID': socketId },
            errorMessage(error) {
              if (error?.response?.data?.MESSAGE === 'The task name cannot be blank') {
                return t('The task name cannot be blank');
              }
              return t('Failed to create the task');
            },
          },
        )
        .then(({ data: { task } }) => {
          emitRealTimeUpdate('new', { ...newTask, id: Number(task.id) }, {});
          return { id: Number(task.id) };
        });

      // map data differences between v2 and v3 endpoints
      // temporary until found a generic way to handle v3 task object
      const mappedTask = {
        ...newTask,
        id: newTaskId(),
        startDate: newTask.startAt ? DateTime.fromISO(newTask.startAt) : null,
        dueDate: newTask.dueAt ? DateTime.fromISO(newTask.dueAt) : null,
        estimateMinutes: newTask.estimateMinutes,
        assigneeUserIds,
        assigneeTeamIds,
        assigneeCompanyIds,
        project,
      };

      // We need tasklist and project to be included in optimistic updates
      _emitOptimisticUpdate({
        promise,
        type: 'task',
        action: 'create',
        task: mappedTask,
      });
      return promise;
    },

    updateTaskTemplateRoleName(updatedTask) {
      const task = getItem('task', updatedTask.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to update'));
      }

      const promise = api
        .patch(
          `/projects/api/v3/tasks/${updatedTask.id}.json`,
          {
            task: {
              templateRoleName: updatedTask.templateRoleName,
              ...(updatedTask.assignees?.length
                ? { assignees: { userIds: null, teamIds: null, companyIds: null } }
                : {}),
            },
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
      });
      return promise;
    },

    updateTaskProperties(updatedTask) {
      const task = getItem('task', updatedTask.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to update'));
      }

      const promise = api
        .put(
          `/tasks/${updatedTask.id}/properties.json`,
          {
            'allow-update': true,
            'todo-item': createToDoItem(updatedTask),
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
      });
      return promise;
    },

    updateTaskRepeatOptions(taskId, { sequence, dueDate }) {
      return updateTask({
        id: taskId,
        repeatOptions: normalizeRepeatOptions(sequence, dateFormat.value),
        sequenceId: sequence?.id,
        dueDate,
        allowUpdate: true,
      });
    },

    deleteTask(taskToDelete, options = {}) {
      const task = getItem('task', taskToDelete.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to delete'));
      }

      const promise = api
        .delete(`/projects/api/v3/tasks/${task.id}.json`, { ...options, headers: { 'Socket-ID': socketId } })
        .then(() => emitRealTimeUpdate('deleted', task, task));
      emitOptimisticUpdate(promise, 'delete', task);
      return promise;
    },

    undeleteTask(taskToUnDelete, options = {}) {
      const promise = api
        .put(`/trashcan/tasks/${taskToUnDelete.id}/restore.json`, { ...options, headers: { 'Socket-ID': socketId } })
        .then(() => emitRealTimeUpdate('reopened', taskToUnDelete, taskToUnDelete));
      return promise;
    },

    completeTask(taskToComplete) {
      const task = getItem('task', taskToComplete.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to complete'));
      }

      const promise = api
        .put(`/tasks/${task.id}/complete.json`, null, {
          headers: { 'Socket-ID': socketId },
        })
        .then(() => emitRealTimeUpdate('completed', task, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        progress: 100,
        status: 'completed',
      });
      return promise;
    },

    uncompleteTask(taskToUncomplete) {
      const task = getItem('task', taskToUncomplete.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to uncomplete'));
      }

      const promise = api
        .put(`/tasks/${task.id}/uncomplete.json`, null, {
          headers: { 'Socket-ID': socketId },
        })
        .then(() => emitRealTimeUpdate('reopened', task, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        progress: 0,
        status: 'reopened',
      });
      return promise;
    },

    copyTask(task, options) {
      const promise = api.put(`/tasks/${task.id}/copy.json`, options).then(({ data: { id } }) => {
        return { id: Number(id) };
      });
      return promise;
    },

    /**
     * A specialization of `updateTask` which moves a task only and does not update any other proprties.
     * It provides better UX using optimistic updates when dragging tasks.
     *
     * For optimal UX provide a whole new task object as `updatedTask` and a whole old task object as `task`.
     */
    moveTask(task, updatedTask) {
      const { parentTaskId, tasklistId, projectId } = updatedTask;
      const promise = api
        .put(
          `/tasks/${task.id}/move.json`,
          {
            taskId: task.id,
            projectId,
            taskListId: tasklistId,
            parentTaskId,
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
        previousParentTaskId: task.parentTaskId,
        previousTaskListId: task.taskListId ?? task.tasklistId,
        previousProjectId: task.tasklist?.project?.id,
      });
      return promise;
    },

    repositionTask(task, updatedTask) {
      const { parentTaskId, tasklistId, projectId, positionAfterTaskId } = updatedTask;

      const promise = api
        .put(
          `/tasks/${task.id}.json`,
          {
            'todo-item': {
              id: task.id,
              projectId,
              taskListId: tasklistId,
              parentTaskId,
              positionAfterTask: positionAfterTaskId,
            },
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));

      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
        previousParentTaskId: task.parentTaskId,
        oldTasklistId: task.taskListId,
        previousProjectId: task.projectId,
      });

      return promise;
    },

    /**
     * Updates the tags of a task.
     * @param {{id: number}} task
     * @param {(number|string)[]} tagIds
     * @returns
     */
    updateTaskTags(task, tags = []) {
      const promise = api
        .put(
          `/task/${task.id}/tags.json`,
          { tagIds: tags.map((tag) => tag.id).join(',') },
          {
            params: { getTags: true, replaceExistingTags: true },
            errorMessage: t('Failed to update the tags'),
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => {
          _emitRealTimeUpdate({
            type: 'task',
            action: 'updated',
            taskId: task.id,
            projectId: task.projectId,
            tasklistId: task.tasklistId,
            parentTaskId: task.parentTaskId,
          });
        });

      emitOptimisticUpdate(promise, 'update', {
        ...task,
        tags,
        tagIds: tags.map((tag) => tag.id),
      });

      return promise;
    },

    /**
     * Very similar way to `updateTask` but only updates progress property.
     * We use this because `/task` endpoint doesn't support updating progress when the user is collaborator
     */
    updateTaskProgress(taskId, progress) {
      const task = getItem('task', taskId);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to update'));
      }

      const updatedTask = {
        ...task,
        progress,
      };

      const promise = api
        .put(
          `/tasks/${updatedTask.id}/progress.json`,
          {
            progress,
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));

      emitOptimisticUpdate(promise, 'update', updatedTask);
      return promise;
    },

    followTask(taskToUpdate) {
      const task = getItem('task', taskToUpdate.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to update follow status'));
      }

      const commentFollowers = [...task.commentFollowers, { id: user.value.id, type: 'users' }].filter(Boolean);
      const changeFollowers = [...task.changeFollowers, { id: user.value.id, type: 'users' }].filter(Boolean);

      const updatedTask = {
        id: taskToUpdate.id,
        commentFollowers,
        changeFollowers,
      };

      const promise = api
        .put(
          `/tasks/${taskToUpdate.id}/follow.json`,
          { 'follow-comments': true, 'follow-changes': true },
          { headers: { 'Socket-ID': socketId } },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));

      emitOptimisticUpdate(promise, 'update', updatedTask);
      return promise;
    },

    unfollowTask(taskToUpdate) {
      const task = getItem('task', taskToUpdate.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to update follow status'));
      }

      const commentFollowers = taskToUpdate.commentFollowers
        .filter(Boolean)
        .filter((follower) => follower.id !== user.value.id);
      const changeFollowers = taskToUpdate.changeFollowers
        .filter(Boolean)
        .filter((follower) => follower.id !== user.value.id);

      const updatedTask = {
        id: taskToUpdate.id,
        commentFollowers,
        changeFollowers,
      };

      const promise = api
        .put(
          `/tasks/${taskToUpdate.id}/unfollow.json`,
          { 'follow-comments': false, 'follow-changes': false },
          { headers: { 'Socket-ID': socketId } },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));

      emitOptimisticUpdate(promise, 'update', updatedTask);
      return promise;
    },

    attachFilesToTask(updatedTask, files) {
      const task = getItem('task', updatedTask.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to attach file to'));
      }

      const promise = api
        .put(
          `/tasks/${updatedTask.id}/files.json`,
          {
            objectId: updatedTask.id,
            objectType: 'task',
            task: { attachments: files.map(({ id }) => id).join(',') },
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task, 'task-fileattached'));

      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
      });

      return promise;
    },

    removeFileFromTask(updatedTask, file) {
      const task = getItem('task', updatedTask.id);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to remove file from'));
      }

      const promise = api
        .delete(`/projects/api/v3/tasks/${updatedTask.id}/files/${file.id}.json`, {
          headers: { 'Socket-ID': socketId },
        })
        .then(() => emitRealTimeUpdate('edited', updatedTask, task, 'task-filedetached'));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
      });

      return promise;
    },

    makeTaskDefault(task) {
      return api.put(`/tasks/${task.id}/makedefault.json`);
    },

    duplicateTask(task) {
      const promise = api.post(`/tasks/${task.id}/duplicate.json`).then((response) => {
        const duplicatedTask = { ...task, id: Number(response.data.id) };
        emitRealTimeUpdate('new', duplicatedTask, {});
        return duplicatedTask;
      });

      const newTask = {
        ...task,
        id: newTaskId(),
        displayOrder: Number.MAX_SAFE_INTEGER,
        workflowStages: (task.workflowStages ?? []).map((workflowStage) => ({
          ...workflowStage,
          stageTaskDisplayOrder: Number.MAX_SAFE_INTEGER,
        })),
      };

      emitOptimisticUpdate(promise, 'create', newTask);

      return promise;
    },

    createRecurringTask(recurringTask) {
      const task = { ...getItem('task', recurringTask.id), dueDate: recurringTask?.dueDate };

      const promise = api
        .post(
          `/tasks/${task.id}/recurring.json`,
          {
            dueDate: task.dueDate.toFormat('yyyyMMdd'),
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(
          ({ data: { taskId } }) => {
            emitRealTimeUpdate('added', { ...task, id: Number(taskId) }, {});
            return {
              id: Number(taskId),
            };
          },
          (error) => {
            toast.critical(t('Failed to create a task'));
            return Promise.reject(error);
          },
        );
      emitOptimisticUpdate(promise, 'create', { ...task, id: newTaskId(), displayOrder: null });
      return promise;
    },

    addSubtasksFromTaskTemplate(task, templateId, data) {
      const promise = api
        .post(`/tasks/${task.id}/template/${templateId}.json`, data, {
          headers: { 'Socket-ID': socketId },
        })
        .then(() => {
          emitRealTimeUpdate('new', { ...task }, {});
        });
      return promise;
    },

    updateEstimatedTime(updatedTask) {
      const task = getItem('task', updatedTask.id);

      const promise = api
        .put(
          `/tasks/${updatedTask.id}/estimatedtime.json`,
          {
            taskEstimatedMinutes: updatedTask.estimateMinutes,
            taskId: updatedTask.id,
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => {
          emitRealTimeUpdate('edited', updatedTask, task);

          // Time totals loader needs the local event to reload estimate time value on top of the each task list.
          _emitRealTimeUpdate({
            type: 'time',
            action: 'new',
            taskId: updatedTask.id,
            tasklistId: updatedTask.tasklistId,
            projectId: updatedTask.projectId,
            parentTaskId: updatedTask.parentTaskId,
          });
        });
      return promise;
    },

    changeTaskWorkflowStage(taskId, updatedWorkflowStage) {
      const task = getItem('task', taskId);
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to reposition'));
      }

      const { stageId, workflowId, positionAfterTaskId } = updatedWorkflowStage;

      const updatedTask = {
        ...task,
        workflowStages: task.workflowStages.map((workflowStage) =>
          workflowStage.workflowId === workflowId ? { ...workflowStage, ...updatedWorkflowStage } : workflowStage,
        ),
      };

      const promise = api
        .patch(
          `/projects/api/v3/tasks/${task.id}/workflows/${workflowId}.json`,
          {
            workflowId,
            stageId,
            positionAfterTask: positionAfterTaskId || 0, // Add to bottom if not specified
          },
          { headers: { 'Socket-ID': socketId } },
        )
        .then(() => {
          emitRealTimeUpdate('reordered', updatedTask, task);
        });

      // We need tasklist and project to be included in optimistic updates
      _emitOptimisticUpdate({
        promise,
        type: 'task',
        action: 'reorder',
        task: updatedTask,
      });

      return promise;
    },
  };
}
