<script setup>
import { useI18n } from '@/util';
import PreviewAvatar from './OnboardingWizardCommonPreviewAvatar.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        tagsCollection: [],
        tasklists: [
          {
            tasks: [
              { identifier: '1' },
              { identifier: '2' },
              { identifier: '3' },
              { identifier: '4' },
              { identifier: '5' },
              { identifier: '6' },
              { identifier: '7' },
              { identifier: '8' },
              { identifier: '9' },
              { identifier: '10' },
            ],
          },
        ],
      };
    },
  },
  dataFromTemplate: {
    type: Boolean,
    default: true,
  },
});
const { t } = useI18n();

function getBackgroundColorByTagName(tagName) {
  if (!props.data.tagsCollection.length) {
    return undefined;
  }

  return props.data.tagsCollection.find((tag) => tag.name === tagName)?.color;
}
</script>

<template>
  <div>
    <template v-for="(tasklist, i) in data.tasklists" :key="i">
      <div v-if="tasklist.name" class="flex h-10 items-center gap-2 border border-b">
        <LscIcon icon="lsi-accordion-expanded" size="sm" />
        <LscOverflowEllipsis class="flex-1 text-body-2 font-semibold text-default">
          {{ tasklist.name }}
        </LscOverflowEllipsis>
      </div>
      <div
        v-for="task in tasklist.tasks"
        :key="task.identifier"
        class="flex h-10 items-center gap-2 border border-b text-body-1"
      >
        <LscIcon icon="lsi-teams-check-circle" size="sm" class="shrink-0 text-icon-subtle" />

        <template v-if="dataFromTemplate">
          <div v-if="task.identifier && task.name" class="shrink-0">
            <PreviewAvatar :id="task.identifier" />
          </div>
        </template>
        <div
          v-else
          class="flex h-6 w-20 shrink-0 items-center px-2"
          :class="{
            'bg-[--onboarding-wizard-preview-assignee-you-background] text-success': task.assignee === 'You',
            'bg-surface-default text-subtle': task.assignee !== 'You',
          }"
        >
          <LscOverflowEllipsis v-if="task.assignee && task.name" class="text-body-2">
            {{ task.assignee }}
          </LscOverflowEllipsis>
          <LscSkeleton v-else :animation="false" class="h-[--lsds-a-typography-body-2-regular-font-size] w-12" />
        </div>

        <LscOverflowEllipsis v-if="task.name" class="grow-1 text-default">
          {{ task.name }}
        </LscOverflowEllipsis>
        <div v-else class="flex h-[--lsds-a-typography-body-1-regular-line-height] items-center justify-center">
          <LscSkeleton
            :animation="false"
            :class="['h-[--lsds-a-typography-body-1-regular-font-size]', task.identifier % 2 ? 'w-60' : 'w-52']"
          />
        </div>

        <template v-if="dataFromTemplate">
          <LscChip
            v-if="task.tags"
            class="text-body-2 font-semibold"
            size="sm"
            :style="{ backgroundColor: getBackgroundColorByTagName(task.tags) }"
          >
            {{ task.tags }}
          </LscChip>
          <LscIcon v-if="task.priority" icon="lsi-priority-selected" class="shrink-0 text-icon-subtle" />
        </template>
        <LscOverflowEllipsis v-else-if="task.due && task.name" class="block shrink-0 text-subtle">
          {{ t('Due: {date}', { date: task.due }) }}
        </LscOverflowEllipsis>
      </div>
    </template>
  </div>
</template>
