<script setup>
import { VExpandTransition } from 'vuetify/components';
import ReportBuilderDialogPanel from './ReportBuilderDialogPanel.vue';
import { provideReportBuilderFilters } from './useReportBuilderFilters.js';

defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
  copyingReport: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['updated', 'handleCustomReportTrack']);

provideReportBuilderFilters();

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <WidgetDialog :transition="{ component: VExpandTransition }" fullscreen>
    <template #activator="activator"><slot v-bind="activator" name="activator" /></template>
    <template #default="{ close }">
      <ReportBuilderDialogPanel
        :report="report"
        :copyingReport="copyingReport"
        @close="close"
        @updated="emit('updated')"
        @handleCustomReportTrack="handleCustomReportTrack"
      />
    </template>
  </WidgetDialog>
</template>
