<script setup>
import { useI18n } from '@/util';
import {
  Filter,
  FilterCompanyPicker,
  FilterCheckbox,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterStatusTypes,
  FilterTeamPicker,
} from '@/module/filter';
import { useReportBuilderFilters } from '../useReportBuilderFilters';

const { t } = useI18n();

const { filterParams } = useReportBuilderFilters();
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter"
    section="reportBuilderFilter"
    class="flex flex-col"
    :allowSaveFilters="false"
  >
    <template #menuFilters>
      <FilterPeoplePicker :title="t('Assignees')" name="assigneeUserIds" />
      <FilterCompanyPicker :title="t('Companies')" name="companyIds" />
      <FilterTeamPicker
        :title="t('Teams')"
        name="teamIds"
        :loaderParams="{
          includeCompanyTeams: true,
          includeSubteams: true,
          includeProjectTeams: true,
        }"
      />
      <FilterProjectPicker :title="t('Projects')" name="projectIds" />
      <FilterStatusTypes :title="t('Milestone status')" name="status" />
    </template>
    <template #menuOptions>
      <FilterCheckbox name="includeCompletedMilestones" :label="t('Include completed milestones')" />
      <FilterCheckbox name="includeArchivedProjects" :label="t('Include data from archived projects')" />
    </template>
  </Filter>
</template>
