<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Adding an assignee, a start date, and an end date to your tasks takes an extra 15 seconds, but means you can:',
        )
      }}
    </p>
    <ul class="my-4 ml-4">
      <li>{{ t('Manage workload') }}</li>
      <li>{{ t('Leverage tracked time') }}</li>
      <li>{{ t('Plan more effectively') }}</li>
    </ul>
    <p class="pt-6">
      {{ t("Moral of the story: take the extra seconds to add some data, it's more than worth it.") }}
    </p>
  </div>
</template>
