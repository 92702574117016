/**
 * Check that incoming items match any applied filters
 * This will need to be kept up to date when additional filters get added
 *
 * Note: it's imperative that the `isRead` property is not considered in this function as
 * we currently expect changes to an items `isRead` property to behave differently (with regards
 * to the list UI) than changes to others. Realistically this shouldn't be a concern of this
 * function and the associated behaviours aren't explicit, more side-effects of the omission
 * from this function.
 */
export function inboxFiltersMatchItem(params, item) {
  const { isArchived, entities, projectIDs } = params.value;
  const allowArchived = isArchived || isArchived === undefined;
  const allowUnarchived = !isArchived || isArchived === undefined;

  if (item?.isArchived && !allowArchived) {
    return false;
  }

  if (!item?.isArchived && !allowUnarchived) {
    return false;
  }

  if (entities?.length && !entities.includes(item?.entity.type)) {
    return false;
  }

  if (projectIDs?.length && !projectIDs.includes(String(item?.project.id))) {
    return false;
  }

  return true;
}
