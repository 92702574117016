<script setup>
import { Info } from 'luxon';
import { useI18n, useNowByMinute } from '@/util';
import { useDatePicker } from './useDatePicker';

const { languageCode, t } = useI18n();
const { hasDateProp, hasDatesProp, date, dates, clearable, updatingStartDate, enableWeekends } = useDatePicker();

const now = useNowByMinute();
const today = computed(() => now.value.startOf('day'));

function setDateToday() {
  if (hasDateProp.value) {
    date.value = today.value;
  } else if (updatingStartDate.value) {
    dates.value = [today.value, dates.value?.[1]];
  } else {
    dates.value = [dates.value?.[0], today.value];
  }
}

const shouldShowNextMondayShortcut = computed(() => {
  return [5, 6, 7].includes(today.value.weekday);
});

const mondayString = computed(() => Info.weekdays('short', { locale: languageCode.value }).at(0));

/* Should hide the today button if skipWeekends is turned on and the current day is a weekend day */
const isTodayButtonVisible = computed(() => enableWeekends.value || !(enableWeekends.value && today.value.isWeekend));

function setDateNone() {
  if (hasDateProp.value) {
    date.value = null;
  } else if (updatingStartDate.value) {
    dates.value = [null, dates.value?.[1]];
  } else {
    dates.value = [dates.value?.[0], null];
  }
}

function addDays(currentDate, increment) {
  let newDate = currentDate.plus({ day: increment });
  if (!enableWeekends.value && [6, 7].includes(newDate.weekday)) {
    newDate = newDate.plus({ day: 8 - newDate.weekday });
  }
  return newDate;
}

function incrementDate(increment) {
  if (hasDateProp.value) {
    date.value = addDays(date.value || today.value, increment);
  }
  if (hasDatesProp.value) {
    const [startDate, endDate] = dates.value;
    if (updatingStartDate.value) {
      dates.value = [addDays(startDate || today.value, increment), endDate];
    } else {
      dates.value = [startDate, addDays(endDate || today.value, increment)];
    }
  }
}

function setDateToNextMonday() {
  const nextMonday = today.value.plus({ day: 8 - today.value.weekday });
  if (hasDateProp.value) {
    date.value = nextMonday;
  } else {
    const [startDate, endDate] = dates.value;
    if (updatingStartDate.value) {
      dates.value = [nextMonday, endDate];
    } else {
      dates.value = [startDate, nextMonday];
    }
  }
}
</script>

<template>
  <div class="flex justify-center divide-x divide-separator">
    <button
      v-if="isTodayButtonVisible"
      type="button"
      class="px-3 text-body-2 font-semibold text-primary"
      @click="setDateToday"
    >
      {{ t('Today') }}
    </button>
    <button type="button" class="px-3 text-body-2 font-semibold text-primary" @click="incrementDate(1)">
      {{ `+${t('{n} day | {n} days', { n: 1 })}` }}
    </button>
    <button type="button" class="px-3 text-body-2 font-semibold text-primary" @click="incrementDate(7)">
      {{ `+${t('{n} day | {n} days', { n: 7 })}` }}
    </button>
    <button
      v-if="shouldShowNextMondayShortcut"
      type="button"
      class="px-3 text-body-2 font-semibold text-primary"
      @click="setDateToNextMonday"
    >
      {{ mondayString }}
    </button>
    <button v-if="clearable" type="button" class="px-3 text-body-2 font-semibold text-primary" @click="setDateNone">
      {{ t('None') }}
    </button>
  </div>
</template>
