import { generateExport } from '../base/generateExport';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useI18n } from '@/util';

export function usePersonActions() {
  const api = useAxios();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { t } = useI18n();

  /**
   * Emit an optimistic update
   * @param {Promise<any>} promise
   * @param {"create"|"update"|"delete"} action
   * @param {Number} personId
   */
  function emitOptimisticUpdate(promise, action, person) {
    _emitOptimisticUpdate({
      promise,
      type: 'person',
      action,
      person,
    });
  }

  /**
   * Reassign person items
   * @param {Object} payload
   */
  function reassignPersonItems(payload) {
    const promise = api.post('/?action=people2.ajaxLBActionReassignUserItems()', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Socket-ID': socketId,
      },
      errorMessage: t('Failed to reassign items'),
    });

    return promise;
  }

  /**
   * Send invite
   * @param {Object} user
   */
  function sendInvite(user) {
    return api
      .post('/people.json', user, {
        errorMessage(error) {
          if (
            error.response?.status === 422 &&
            (error?.response?.data?.MESSAGE.includes('in use') ||
              error?.response?.data?.MESSAGE.includes('Email address already used by another user'))
          ) {
            return t('Email {address} is already used by another user', { address: user.person['email-address'] });
          }
          if (
            error.response?.status === 422 &&
            error?.response?.data?.MESSAGE.includes('Client users can not have the same email domain as the site owner')
          ) {
            return t('Invite not sent to {address}. Client users cannot have the same email domain as the site owner', {
              address: user.person['email-address'],
            });
          }
          return t('Failed to send invite');
        },
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Resend invite
   * @param {Object} user
   */
  function resendInvite(user) {
    return api
      .put(`/users/${user.id}/sendinvite.json`, user, {
        errorMessage: t('Failed to resend invite'),
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * Get the invite link for a user
   * @param {Object} user
   * @returns {Promise<string>} the invite link
   */
  function getInviteLink(user) {
    const url = `/users/${user.id}/getinvitelink.json`;
    return api.get(url).then((response) => response.data.userInviteUrl);
  }

  /**
   *  Bulk update permissions for users
   * @param {number[]} userIds
   * @param  {object } permissions
   */
  function applyPermissionsToPeople(userIds, permissions) {
    return api
      .put(
        `/people/permissions.json`,
        { userIds: userIds.toString(), permissions },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update permissions'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'edited',
          userId: userIds[0].id,
        });
        return response;
      });
  }

  /**
   * Bulk update permissions for users on a project
   * @param {number} projectId
   * @param {number[]} userIds
   * @param  {object } permissions
   */
  function applyPermissionsToPeopleOnProject(projectId, userIds, permissions) {
    const url = `/projects/${projectId}/people/permissions.json`;
    return api
      .put(
        url,
        { userIds: userIds.toString(), permissions },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update permissions'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'edited',
          userId: userIds[0].id,
        });
        return response;
      });
  }

  /**
   * Delete users
   * @param {number[]} userIds
   * @param {boolean} unassignFromAll
   * @returns {Promise}
   */
  function deletePeople(userIds, unassignFromAll = true) {
    const promise = api
      .delete(`/people.json`, {
        // We need to send a data payload with the delete request, axios does
        // not support data on delete so we must do it manually with config
        data: {
          userIds: userIds.toString(),
          unassignFromAll,
        },
        headers: {
          'Socket-ID': socketId,
        },
        errorMessage: t('Failed to remove users'),
      })
      .then(() => {
        // No realtime updates on server side so need to emit this event
        emitRealTimeUpdate({
          type: 'person',
          action: 'deleted',
          userId: userIds[0].id,
        });
      });

    userIds.forEach((userId) => {
      emitOptimisticUpdate(promise, 'delete', { id: userId });
    });

    return promise;
  }

  /**
   * Restore user from trash
   * @param {number} userId
   */
  function restorePerson(person) {
    const promise = api
      .put(
        `/trashcan/people/${person.id}/restore.json`,
        {},
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to restore user'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'edited',
          userId: person.id,
        });
        return response;
      });

    emitOptimisticUpdate(promise, 'update', person);

    return promise;
  }

  /**
   * Update person
   * @param {object} person
   */
  function updatePerson(person) {
    const promise = api
      .put(
        `/people/${person.id}.json`,
        { person },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update person'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'edited',
          userId: person.id,
        });
        return response;
      });

    const normalizedPerson = { ...person };

    if (person['phone-number-mobile']) {
      normalizedPerson.phoneNumberMobile = person['phone-number-mobile'];
    }

    emitOptimisticUpdate(promise, 'update', normalizedPerson);

    return promise;
  }

  /**
   * Add and/or remove users from project
   * @param {Object} project
   * @param {Array} add
   * @param {Array} remove
   */
  function addRemoveFromProject(projectId, { add = [], remove = [] }) {
    const userIdsToAdd = add.map((u) => {
      return typeof u === 'number' ? u : u.id;
    });
    const userIdsToRemove = remove.map((u) => {
      return typeof u === 'number' ? u : u.id;
    });

    return api
      .put(
        `/projects/${projectId}/people.json`,
        { add: { userIdList: userIdsToAdd.toString() }, remove: { userIdList: userIdsToRemove.toString() } },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update people on project'),
        },
      )
      .then((response) => {
        const anyUserId = userIdsToAdd.length ? userIdsToAdd[0] : userIdsToRemove[0];
        emitRealTimeUpdate({
          type: 'project',
          action: 'permissions',
          projectId,
          userId: anyUserId,
        });
        return response;
      });
  }

  /**
   * Change permissions of user on project
   * @param {Object} user
   * @param {Object} project
   * @param {Object} permissions
   */
  function updatePersonProjectPermissions(user, project, permissions) {
    return api
      .put(
        `/projects/${project.id}/people/${user.id}.json`,
        { permissions },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to set permissions'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'project',
          action: 'permissions',
          projectId: project.id,
          userId: user.id,
        });
        return response;
      });
  }

  /**
   * Generates an excel export for people
   * Will open a new tab with the download
   * @param {object} params
   */
  function generateExcelExport(params) {
    generateExport('/?action=People_Export_To_Excel', params);
  }

  return {
    updatePerson,
    updatePersonProjectPermissions,
    reassignPersonItems,
    sendInvite,
    resendInvite,
    getInviteLink,
    applyPermissionsToPeople,
    applyPermissionsToPeopleOnProject,
    deletePeople,
    restorePerson,
    addRemoveFromProject,
    generateExcelExport,
  };
}
