import { useI18n } from '@/util';

export function useBudgetFormat() {
  const { convertFromCentValue, formatCurrency, t, formatMinutes } = useI18n();

  function getBudgetCapacityColor(percentage) {
    if (percentage < 50) {
      return 'var(--lsds-a-color-surface-success-default)';
    }
    if (percentage < 80) {
      return 'var(--lsds-a-color-surface-warning-default)';
    }
    return 'var(--lsds-a-color-surface-critical-default)';
  }

  /**
   * Returns a formatted string for the date range of a budget
   * @param {object} budget
   * @param {import('luxon').DateTime} budgetToFormat.startDateTime
   * @param {import('luxon').DateTime} budgetToFormat.endDateTime
   * @returns {String}
   */
  function formatBudgetDateRange(budget) {
    if (!budget) {
      return '';
    }

    const { startDateTime } = budget;
    const endDateTime = budget.endDateTime ? budget.endDateTime.minus({ days: 1 }) : null;
    if (!endDateTime) {
      return `${startDateTime.toFormat('d MMM')} - ${t('no end date')}`;
    }

    if (startDateTime.hasSame(endDateTime, 'day')) {
      return startDateTime.toFormat('d MMM');
    }
    if (startDateTime.hasSame(endDateTime, 'month')) {
      return `${startDateTime.toFormat('D')} - ${endDateTime.toFormat('d MMM')}`;
    }
    if (startDateTime.hasSame(endDateTime, 'year')) {
      return `${startDateTime.toFormat('d MMM')} - ${endDateTime.toFormat('d MMM')}`;
    }
    return `${startDateTime.toFormat('d MMM yy')} - ${endDateTime.toFormat('d MMM yy')}`;
  }

  /**
   * formats a currency value to use currency symbol
   * @param {Number} minutes value to get represented as shorthand
   * @param {'compact'|'standard'} notation
   * @returns {String}
   */
  function formatTimeBudgetCapacity(minutes) {
    return formatMinutes(minutes);
  }

  /**
   * formats a currency value to use currency symbol
   * @param {Number} capacity value to get represented as shorthand
   * @param {'compact'|'standard'} notation
   * @returns {String}
   */
  function formatFinanceBudgetCapacity(capacity, notation = 'standard') {
    const capacityAsNumber = Number(convertFromCentValue(capacity));
    return formatCurrency(capacityAsNumber, { notation });
  }

  function formatBudgetCapacity(budget) {
    if (!budget) {
      return () => '';
    }
    if (budget.type.toLowerCase() === 'time') {
      return formatTimeBudgetCapacity;
    }
    return formatFinanceBudgetCapacity;
  }

  return {
    getBudgetCapacityColor,
    formatBudgetCapacity,
    formatBudgetDateRange,
  };
}
