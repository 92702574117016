<script setup>
import { useCurrentUser } from '@/api';
import { useCurrentProject } from '@/route';
import { useI18n } from '@/util';
import { useAppShellSidebar } from '@/appShell';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
  shouldLoadOnScroll: {
    type: Boolean,
    default: true,
  },
});

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();

const user = useCurrentUser();
const currentProject = useCurrentProject();

const taskLists = computed(() => unref(props.state.items));
</script>

<template>
  <WidgetLoadingState :state="state" :loadingMessage="t('Loading tasklist templates')">
    <template #default>
      <slot name="resultsIntro" />
      <VList density="compact" class="m-0 !px-4 !pb-8 !pt-4">
        <VListItem
          v-for="project in taskLists"
          :key="project.id"
          :to="`/task-templates/${project.id}`"
          :active="currentProject?.id === project.id"
          v-bind="VListItemSidebarProjectsDrawer"
          data-identifier="side-nav-templates-panel-task-list-template-item"
          @click="clearActiveDrawerPanelIfNotPinned"
        >
          <template #prepend>
            <LscIcon icon="lsi-tasklist" class="mr-2" />
          </template>
          <VListItemTitle :title="project.name" class="text-body-1">{{ project.name }}</VListItemTitle>
          <VListItemSubtitle
            v-if="project.company?.name && user.companyId !== project.companyId"
            :title="project.company?.name"
            class="text-body-2"
          >
            {{ project.company.name }}
          </VListItemSubtitle>
        </VListItem>
      </VList>
      <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </WidgetLoadingState>
</template>
