<script setup>
import { useI18n } from '@/util';

defineProps({
  value: { type: [Number, String], default: 0 },
  /**
   * @type {PropType<'total' | 'avg'>}
   */
  totalType: { type: String, required: true },
});

const { t } = useI18n();

const totalTypeLabel = {
  total: t('Total'),
  avg: t('Avg'),
};
</script>

<template>
  <div class="inline-flex items-center">
    <span class="mr-1 text-subtle">{{ totalTypeLabel[totalType] }}</span>
    <LscOverflowEllipsis class="font-semibold">{{ value }}</LscOverflowEllipsis>
  </div>
</template>
