<script setup>
defineProps({
  expense: {
    type: Object,
    default: null,
  },
  budget: {
    type: Object,
    default: null,
  },
});

const modelValue = defineModel({ type: Boolean, default: false });
const hidePanel = shallowRef(false);

function refreshPanel() {
  hidePanel.value = true;
  setTimeout(() => {
    hidePanel.value = false;
  }, 200);
}

const FinanceExpenseAddOrEditDialogPanel = defineAsyncComponent(
  () => import('./FinanceExpenseAddOrEditDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceExpenseAddOrEditDialogPanel
        v-if="!hidePanel"
        :closing="!modelValue"
        :expense="expense"
        :budget="budget"
        @close="close"
        @addAnother="refreshPanel"
      />
    </template>
  </WidgetDialog>
</template>
