<script setup>
import { useI18n } from '@/util';
import { useAssigneePicker } from './useAssigneePicker';

import LscAvatar from '../../../components/media/avatar/LscAvatar.vue';
import LscIcon from '../../../components/media/icon/LscIcon.vue';
import LscOverflowEllipsis from '../../../components/infodisplay/overflow/LscOverflowEllipsis.vue';
import LscIconButton from '../../../components/action/button/LscIconButton.vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  assignTooltip: {
    type: String,
    required: true,
  },
  unassignTooltip: {
    type: String,
    required: true,
  },
  shouldShowToggleButton: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['toggle']);

const { t } = useI18n();

const { multiple, dataTestIdPrefix, clearable, assignees, mode } = useAssigneePicker();

function toggleItem(event, removeOthers = mode.value === 'reassign') {
  emit('toggle', { event, removeOthers });
}

const actionTooltip = computed(() => {
  if (props.active) {
    const canUnassignItem = assignees.value.length > 1 ? true : clearable.value;
    return canUnassignItem ? props.unassignTooltip : '';
  }
  return props.assignTooltip;
});

const canToggleItem = computed(() => multiple.value && props.shouldShowToggleButton && mode.value === 'reassign');
</script>

<template>
  <li class="group/LswAssigneePickerItem relative m-0 flex list-none items-center p-0">
    <button
      type="button"
      v-bind="$attrs"
      class="group/LswAssigneePickerButton flex w-full items-center justify-between gap-2 rounded-md bg-default px-2 py-1 pr-10 text-default ring-default transition-colors focus-visible:outline-none focus-visible:ring-2 group-hover/LswAssigneePickerItem:bg-hover aria-pressed:!bg-surface-emphasis-selected aria-pressed:font-semibold aria-pressed:text-on-emphasis"
      :aria-pressed="active"
      :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-assignee` : undefined"
      @click="toggleItem"
    >
      <div class="flex shrink-0 items-center">
        <slot name="prepend" :active="active" avatarSize="md">
          <LscAvatar size="md" />
        </slot>
      </div>
      <div class="flex grow flex-col overflow-hidden text-left">
        <LscOverflowEllipsis>
          <slot :active="active" />
        </LscOverflowEllipsis>
        <LscOverflowEllipsis
          v-if="$slots.secondary"
          class="text-body-2 font-normal text-subtle group-aria-pressed/LswAssigneePickerButton:text-primary"
        >
          <slot name="secondary" :active="active" />
        </LscOverflowEllipsis>
      </div>
      <slot name="append" :active="active" />
    </button>
    <div
      v-if="canToggleItem || active"
      class="group/LswAssigneePickerItem-action absolute bottom-0 right-0 top-0 inline-flex size-10 h-full shrink-0 items-center justify-center py-2"
      :class="{
        'pointer-events-none': mode === 'toggle',
      }"
    >
      <template v-if="canToggleItem">
        <LscIconButton
          v-LsdTooltip="actionTooltip"
          size="sm"
          :data-test-id="dataTestIdPrefix && `${dataTestIdPrefix}-select-assignee`"
          :icon="active ? 'lsi-selected' : 'lsi-add'"
          :ariaPressed="active"
          :ariaLabel="t('Assign')"
          @click.prevent="toggleItem($event, false)"
        />
        <div
          v-if="active"
          class="pointer-events-none absolute inset-0 flex h-full w-10 items-center justify-center opacity-0 transition-opacity ease-in group-hover/LswAssigneePickerItem-action:pointer-events-auto group-hover/LswAssigneePickerItem-action:opacity-100"
        >
          <LscIconButton
            v-LsdTooltip="unassignTooltip"
            size="sm"
            :data-test-id="dataTestIdPrefix && `${dataTestIdPrefix}-deselect-assignee`"
            :ariaPressed="active"
            icon="lsi-remove"
            :ariaLabel="t('Unassign')"
            @click.prevent="toggleItem($event, false)"
          />
        </div>
      </template>
      <LscIcon v-else-if="active" size="sm" icon="lsi-selected" class="text-icon-primary-default" />
    </div>
  </li>
</template>
