<script setup>
const props = defineProps({
  client: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['trackPendoEvent']);

const ClientAddDialogPanel = defineAsyncComponent(() => import('./ClientAddEditDialogPanel.vue'));

function handleTrack(pageType, eventAction, eventCategory, ...args) {
  emit('trackPendoEvent', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <WidgetDialog>
    <template #default="{ close }">
      <ClientAddDialogPanel v-bind="props" @close="close" @trackPendoEvent="handleTrack" />
    </template>
  </WidgetDialog>
</template>
