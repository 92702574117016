<script setup>
import { useI18n } from '@/util';
import WidgetDialog from './WidgetDialog.vue';
import LscDialogCard from './LscDialogCard.vue';

defineProps({
  /**
   * The title to display in the dialog.
   */
  title: {
    type: String,
    default: null,
  },
  /**
   * The message to display in the dialog.
   */
  message: {
    type: String,
    default: null,
  },
  /**
   * The icon to display on the left side of the dialog.
   * @type {PropType<LscIcon>}
   */
  icon: {
    type: String,
    default: undefined,
  },
  /**
   * Whether the dialog is critical.
   */
  critical: {
    type: Boolean,
    default: false,
  },
  /**
   * The text to display on the confirm button.
   */
  confirmButtonText: {
    type: String,
    default: null,
  },
  /**
   * The text to display on the cancel button.
   */
  cancelButtonText: {
    type: String,
    default: null,
  },
  /**
   * The data identifier prefix.
   */
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'sm',
  },
});

const emit = defineEmits(['cancel', 'confirm']);

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

// Focus the cancel button when the confirmation dialog opens up.
const cancelButton = shallowRef();
watch(cancelButton, () => {
  cancelButton.value?.$el?.focus();
});

// Focus the previously active element when the confirmation dialog closes.
let oldActiveElement;
watch(modelValue, () => {
  if (modelValue.value) {
    oldActiveElement = document.activeElement;
  } else {
    // We need some delay here to avoid Vuetify's `retainFocus` behavior.
    requestAnimationFrame(() => {
      if (!modelValue.value && oldActiveElement) {
        oldActiveElement.focus();
        oldActiveElement = undefined;
      }
    });
  }
});

const { t } = useI18n();

function cancel(close) {
  emit('cancel');
  close();
}

function confirm(close) {
  emit('confirm');
  close();
}
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <LscDialogCard :size="size" :title="title" @close="cancel(close)">
        <template #prependHeaderLeft>
          <slot name="prependHeaderLeft">
            <LscIcon v-if="icon" :icon="icon" size="lg" />
          </slot>
        </template>
        <div class="text-body-1">
          <slot>{{ message ?? t('Please confirm?') }}</slot>
        </div>
        <template #actions>
          <div class="flex flex-1 items-center justify-between">
            <LscButton
              ref="cancelButton"
              variant="tertiary"
              :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-cancel-button` : undefined"
              @click="cancel(close)"
            >
              {{ cancelButtonText ?? t('Cancel') }}
            </LscButton>

            <LscButton
              :variant="critical ? 'critical' : 'primary'"
              :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-confirm-button` : undefined"
              @click="confirm(close)"
            >
              {{ confirmButtonText ?? t('OK') }}
            </LscButton>
          </div>
        </template>
      </LscDialogCard>
    </template>
  </WidgetDialog>
</template>
