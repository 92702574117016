<script setup>
import { useClipboard, useI18n } from '@/util';
import FilterActiveChip from './FilterActiveChip.vue';
import FilterSharePeoplePicker from './FilterSharePeoplePicker.vue';

const props = defineProps({
  shareFilter: { type: Object, default: null },
});
const emit = defineEmits(['close', 'shareFilterToUser', 'shareFilterTitle']);

const shareTitle = defineModel('shareTitle', {
  type: Boolean,
  required: false,
  default: false,
});
const chips = ref([]);
const { copyToClipboard } = useClipboard();

const { t } = useI18n();

function close() {
  emit('close');
}

function shareFilterToUser() {
  emit('shareFilterToUser', {
    userIDs: chips.value.map((val) => val.id).join(','),
    filterID: props.shareFilter.id,
  });
}

function onClick(user) {
  if (!chips.value.some((chip) => chip.id === user.id.toString())) {
    chips.value.push({
      name: `${user.firstName} ${user.lastName}`,
      icon: user.avatarUrl,
      id: user.id.toString(),
      type: 'assignee',
      delete: () => {
        chips.value = chips.value.filter((chip) => chip.id !== user.id.toString());
      },
    });
  }
}

function copyLink() {
  copyToClipboard(props.shareFilter.shareLink);
}
</script>

<template>
  <WidgetDialog>
    <LscDialogCard
      size="xs"
      :title="t('Share a Filter')"
      :closeButtonText="t('Close')"
      :saveButtonText="t('Share Filter')"
      :saveButtonDisabled="!chips.length"
      @close="close"
      @save="shareFilterToUser"
    >
      <template #default>
        <LscSwitch
          v-model="shareTitle"
          :label="t('Share filter title?')"
          @click="emit('shareFilterTitle', { filterID: props.shareFilter.id, shareTitle })"
        />
        <div class="mt-2 flex h-10 w-full items-center justify-between">
          <span class="text-body-2">Sharing current filter (projects activity filter)</span>
          <LscButton
            variant="plain-primary"
            size="md"
            :title="t('Sharing current filter (projects activity filter)')"
            @click="copyLink"
          >
            <span class="text-body-2">{{ t('Copy link to clipboard') }}</span>
          </LscButton>
        </div>
        <hr class="-mx-6 my-2 border-b" />
        <div class="mt-6 flex w-full flex-col">
          <span class="text-body-1">Share link via email</span>
          <div class="flex-1">
            <div class="flex max-h-36 shrink-0 flex-wrap gap-2 overflow-auto">
              <FilterActiveChip v-for="(chip, index) in chips" :key="index" :chip="chip" />
            </div>
            <FilterSharePeoplePicker @onClick="(val) => onClick(val)" />
          </div>
        </div>
      </template>
      <template #actions>
        <div class="mt-3 flex min-h-10 w-full justify-between p-0">
          <LscButton variant="tertiary" @click="close">Close</LscButton>
          <LscButton
            type="submit"
            :variant="'primary'"
            form="dialogForm"
            :disabled="!chips.length"
            @click="shareFilterToUser"
          >
            Share Filter
          </LscButton>
        </div>
      </template>
    </LscDialogCard>
  </WidgetDialog>
</template>
