import { getLastChildPath } from '../lastChildPath';

export const homeRoutes = [
  {
    path: '/home',
    component: () => import('./RouteHome.vue'),
    children: [
      {
        path: '',
        redirect() {
          return `/home/${getLastChildPath('home', 'work')}`;
        },
      },
      {
        path: 'work',
        component: () => import('./RouteHomeWork.vue'),
        meta: {
          sampleProjectsBannerVisible: true,
          enableTaskDetailsListNavigation: true,
        },
      },
      {
        path: 'projects',
        component: () => import('./RouteHomeProjects.vue'),
        meta: {
          sampleProjectsBannerVisible: true,
        },
      },
      {
        path: 'inbox',
        component: () => import('./RouteHomeInbox.vue'),
      },
      {
        path: 'activity',
        component: () => import('./RouteHomeActivity.vue'),
      },
      {
        path: 'events',
        component: () => import('./RouteHomeEvents.vue'),
      },
      {
        path: 'dashboards',
        component: () => import('./RouteHomeDashboards.vue'),
      },
      {
        path: 'shortcuts',
        component: () => import('./RouteHomeShortcuts.vue'),
      },
      {
        path: 'comments',
        component: () => import('./RouteHomeComments.vue'),
      },
      {
        path: 'messages',
        component: () => import('./RouteHomeMessages.vue'),
      },
      {
        path: 'calendar',
        component: () => import('./RouteHomeCalendar.vue'),
      },
      {
        path: 'mytimesheet',
        redirect: '/home/timesheet',
      },
      {
        path: 'timesheet',
        component: () => import('./RouteHomeMyTimesheet.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/home/work',
      },
    ],
  },
  {
    path: '/dashboard/:pathMatch(.*)*',
    redirect: '/home',
  },
];
