<script setup>
import { useDebounceFn } from '@vueuse/core';
import { useTagActions, useTagsV3Loader } from '@/api';
import { useI18n } from '@/util';
import LswTagPickerForm from './LswTagPickerForm.vue';
import LswTagPickerList from './LswTagPickerList.vue';

const props = defineProps({
  projectId: {
    type: Number,
    default: undefined,
  },
});

const emit = defineEmits(['close']);

const tags = defineModel('tags', {
  type: Array,
  default: () => [],
});

const { t } = useI18n();
const { createTag } = useTagActions();

const searchInput = shallowRef('');
const searchTerm = shallowRef('');
const createTagOnEnter = shallowRef(false);

const state = useTagsV3Loader({
  params: computed(() => {
    return {
      orderMode: 'asc',
      orderBy: 'projectdatelastused',
      projectIds: props.projectId ? `0,${props.projectId}` : '0',
      searchTerm: searchTerm.value.trim(),
    };
  }),
  pageSize: 500,
  count: 500,
});
const { items: projectTags, loaded } = state;

const tagsNames = computed(() => props.tags.map((tag) => tag.name));
const filteredAndSelectedTags = computed(() => {
  let selectedCount = 0;
  const result = projectTags.value.map((tag) => {
    const selected = tagsNames.value.includes(tag.name);
    if (selected) {
      selectedCount += 1;
    }
    return { selected, ...tag };
  });
  return {
    result,
    selectedCount,
  };
});

const isSearchResultEmpty = computed(() => filteredAndSelectedTags.value.result.length === 0);

const showNewTagForm = computed(() => (isSearchResultEmpty.value && loaded.value) || createTagOnEnter.value);

function isTagCreated(name) {
  return filteredAndSelectedTags.value.result.find((tag) => tag.name === name);
}

function loadProjectsTags() {
  if (searchInput.value === '' || isTagCreated(searchInput.value)) {
    createTagOnEnter.value = false;
  }
  searchTerm.value = searchInput.value;
}

const debouncedParams = useDebounceFn(
  () => {
    loadProjectsTags();
  },
  800,
  { maxWait: 5000 },
);

async function createNewTag(tag) {
  searchInput.value = '';
  loadProjectsTags();
  const createdTag = await createTag({ tag });
  tags.value = [...tags.value, createdTag];
}

function onEnterSearch() {
  createTagOnEnter.value = loaded.value && searchInput.value !== '' && !isTagCreated(searchInput.value);
}

function closeTagForm() {
  createTagOnEnter.value = false;
  searchInput.value = '';
  loadProjectsTags();
}
</script>

<template>
  <LswTagPickerForm
    v-if="showNewTagForm"
    :tag="{ name: searchInput }"
    :projectId="projectId"
    @submit="createNewTag"
    @close="closeTagForm"
  />
  <LscSheet v-else class="flex max-h-80 w-72 flex-col !p-0">
    <LscSearchBar
      v-model.trim="searchInput"
      :placeholder="showNewTagForm ? t('New tag name') : t('Search tags')"
      data-test-id="tag-picker-input"
      class="m-3"
      :active="Boolean(searchInput)"
      autofocus
      @input="debouncedParams"
      @keyup.enter="onEnterSearch"
      @click:clear="loadProjectsTags"
    />
    <LswTagPickerList
      v-model:tags="tags"
      class="mb-3 overflow-y-auto px-3"
      :projectTags="filteredAndSelectedTags"
      :state="state"
      @close="emit('close')"
      @loadTags="loadProjectsTags"
    />
  </LscSheet>
</template>
