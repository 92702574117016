import { DateTime } from 'luxon';
import { getEntity } from '@/util';
import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  return response.data.searchResults.map((searchResult) => {
    return {
      ...searchResult,
      id: `${searchResult.type}-${searchResult.id}`,
      entityId: searchResult.id,
      entityType: searchResult.type,
      date: DateTime.fromISO(searchResult.date),

      entity: getEntity({
        type: searchResult.type?.toLowerCase(),
        link: searchResult.url?.toLowerCase(),
      }),
    };
  });
}

export function useQuickSearchV2Loader({ params: _params, count, pageSize = 20 } = {}) {
  const loaderParams = computed(() => {
    const params = unref(_params) || {};

    const searchTerm = params.searchTerm ?? '';
    const projectId = params.projectId ?? 0;
    const searchAllProjects = params.searchAllProjects ?? projectId === 0;
    const includeCompletedItems = params.includeCompletedItems ?? false;
    const includeArchivedProjects = params.includeArchivedProjects ?? false;

    return {
      returnExtras: true,
      skipES: false,
      searchTerm,
      projectId,
      searchAllProjects,
      includeCompletedItems,
      includeArchivedProjects,
      skipKeyword: true,
      ...params,
    };
  });

  const { state } = useListLoader({
    url: `/projects/api/v2/quickSearch.json`,
    params: loaderParams,
    count,
    responseToItems,
    pageSize,
  });

  return state;
}
