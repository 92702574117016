<script setup>
import { useCurrentElement } from '@vueuse/core';
import { useAppShellSidebar } from '@/appShell';
import { useLegacyBridge } from '@/module/legacy';
import { usePendo } from '@/api';
import { useQuickView, useRoute } from '@/route';
import { useI18n, useKeyboardShortcut } from '@/util';
import { EntityBadge } from '@/module/entity';

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  secondaryText: {
    type: String,
    required: true,
  },
  tooltipText: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['toggleRead']);
const { t } = useI18n();
const { openQuickView, isQuickViewLocked } = useQuickView();

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { closeAllLegacyQuickViews, showLegacyQuickView, shouldUseLightspeedTaskDetails } = useLegacyBridge();
const { trackPendoEvent } = usePendo();
const route = useRoute();

const rootEl = useCurrentElement();

const SUPPORTED_QUICKVIEW_ENTITIES = ['file', 'message', 'milestone', 'link', 'notebook', 'task', 'taskList'];

function getItemObject() {
  const linkParts = /^\/(?:shared\/)?(\w+)\/(\d+)\?c=(\d+).*/.exec(props.notification.entity.link);

  if (props.notification.entity.link.startsWith('/shared/proofs')) {
    return { itemType: 'proof' };
  }

  if (!linkParts) {
    return false;
  }

  const [itemType, itemId, commentId] = linkParts.slice(1);
  let fixedItemType = itemType.endsWith('s') ? itemType.slice(0, -1) : itemType;
  fixedItemType = fixedItemType === 'tasklist' ? 'taskList' : fixedItemType;

  return { itemType: fixedItemType, itemId: Number(itemId), commentId: Number(commentId) };
}

const itemHasQuickView = computed(() => {
  const { itemType } = getItemObject();
  return !!SUPPORTED_QUICKVIEW_ENTITIES.includes(itemType);
});

function triggerNotificationQuickView(markAsRead = true) {
  const { itemType, itemId, commentId } = getItemObject();

  if (itemHasQuickView.value) {
    closeAllLegacyQuickViews();

    if (itemType === 'task') {
      if (shouldUseLightspeedTaskDetails.value) {
        openQuickView(`/tasks/${itemId}`, commentId > 0 ? { query: { c: commentId } } : {});
      } else {
        showLegacyQuickView({
          name: itemType,
          params: {
            id: itemId,
            projectId: props.notification.projectId,
            [`${itemType}Id`]: itemId,
          },
        });
      }
    }

    trackPendoEvent({
      eventName: 'NOTIFICATION_QUICK_VIEW',
      commonMetrics: ['plan_name', 'user_role'],
      metadata: {
        itemType,
      },
    });

    if (markAsRead && !props.notification.read) {
      // This timeout is to slow down the removal of the list item from the notification panel.
      // This gives the quick view a moment to open before removing the notification from the list
      // Design has requested this change
      setTimeout(() => {
        emit('toggleRead', props.notification.id);
      }, 1500);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('Unsupported notification type', props.notification);
  }
}

function onListItemClicked(event) {
  const commandKeyPressed = event.metaKey || event.ctrlKey;
  // no longer redirect on item clicked we only do anything here if there is a quick view
  if (itemHasQuickView.value && !commandKeyPressed) {
    triggerNotificationQuickView();
  } else if (itemHasQuickView.value && commandKeyPressed) {
    window.open(`/app${props.notification.entity.link}`, '_blank');
  }
  if (shouldUseLightspeedTaskDetails.value) {
    closeAllLegacyQuickViews();

    // If full task details is active, we need to close the notification drawer
    if (isQuickViewLocked.value) {
      clearActiveDrawerPanelIfNotPinned();
    }
  }
}

watch(
  () => route.path,
  () => {
    if (shouldUseLightspeedTaskDetails.value) {
      clearActiveDrawerPanelIfNotPinned();
    }
  },
);

useKeyboardShortcut(
  'V',
  () => {
    if (itemHasQuickView.value) {
      triggerNotificationQuickView(false);
    }
  },
  {
    activeOnHoverElement: rootEl,
  },
);
</script>

<template>
  <VListItem
    data-identifier="notification-list-item"
    class="!hover:bg-surface-hover mt-1 flex !min-h-8 w-[--notification-drawer-item-width] !rounded-xl !p-2 !px-3 !py-1.5"
    :class="[itemHasQuickView || getItemObject()?.itemType === 'proof' ? 'cursor-pointer' : 'cursor-default']"
    :to="!itemHasQuickView ? notification.entity.link : false"
    :target="getItemObject()?.itemType === 'proof' ? '_blank' : false"
    persistDrawer
    @click="onListItemClicked"
  >
    <template #prepend>
      <EntityBadge v-bind="notification.entity">
        <LscAvatar
          v-if="notification.entity.group === 'timereminder'"
          size="lg"
          bgColor="var(--time-reminders-avatar-color-background)"
          iconColor="var(--lsds-a-color-icon-default)"
          icon="lsi-time"
        />
        <LscAvatar v-else size="lg" :name="notification.user" :src="notification.avatar" />
      </EntityBadge>
    </template>
    <div class="pl-3">
      <div class="flex items-center">
        <div class="grow truncate text-body-2 text-subtle">
          {{ title }}
        </div>
        <div class="shrink-0 grow-0 justify-self-end px-1 text-right text-body-2 text-subtle">
          <LscDateTime :dateTime="notification.date" />
        </div>
        <div class="shrink-0 grow-0">
          <LscIconButton
            v-LsdTooltip="tooltipText"
            size="sm"
            variant="plain-secondary"
            :ariaPressed="notification.read"
            :ariaLabel="notification.read ? t('Mark as unread') : t('Mark as read')"
            :icon="notification.read ? 'lsi-mark-unread' : 'lsi-mark-read'"
            @click.prevent.stop="emit('toggleRead')"
          />
        </div>
      </div>

      <VListItemTitle class="flex text-body-1 font-medium text-on-secondary">
        <!-- Proofs special case. Currently need to open /shared/ routes in a new tab -->
        <RouterLink
          :target="getItemObject()?.itemType === 'proof' ? '_blank' : false"
          :to="notification.entity.link"
          :title="description"
          data-identifier="notification-list-item__title-redirect"
          class="cursor-pointer truncate text-inherit no-underline hover:underline"
        >
          {{ description || t('View here') }}
        </RouterLink>
      </VListItemTitle>

      <VListItemSubtitle class="flex text-body-2 text-subtle">
        <span :title="secondaryText" class="truncate">
          {{ secondaryText }}
        </span>
      </VListItemSubtitle>
    </div>
  </VListItem>
</template>

<style scoped>
:deep(.v-list-item__content) {
  @apply w-full !important;
}

:deep(.v-list-item__overlay) {
  @apply opacity-0 !important;
}
</style>
