import { usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to forms.
 */
export function useFormPendoEvents() {
  const { trackPendoEvent, EVENT_CATEGORIES } = usePendo();
  const { DISCOVERY, ADVANCED_DISCOVERY, ACTIVATION } = EVENT_CATEGORIES;

  function track(data) {
    trackPendoEvent({
      eventName: 'FORMS_EVENT',
      commonMetrics: ['user_role', 'plan_name', 'page', 'on_trial', 'is_icp', 'is_paid'],
      includeMetrics: ['total_active_forms'],
      metadata: {
        ...data,
      },
    });
  }

  /**
   * Track the viewing of the forms panel
   * * @param {string} formsCount
   * * @param {boolean} isProjectTemplate
   */
  function trackFormsPanelViewed(formsCount, isProjectTemplate) {
    track({
      event_action: 'forms_panel_viewed',
      event_category: DISCOVERY,
      visible_forms: formsCount,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the viewing of the project forms page
   * * @param {string} formsCount
   * * @param {boolean} isProjectTemplate
   */
  function trackProjectFormsPageViewed(formsCount, isProjectTemplate) {
    track({
      event_action: 'forms_page_viewed',
      event_category: DISCOVERY,
      page_type: 'project_forms',
      visible_forms: formsCount,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of the add form button
   * * @param {boolean} isProjectTemplate
   */
  function trackAddFormButtonClicked(isProjectTemplate) {
    track({
      event_action: 'add_form_clicked',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of the publish form button
   * * @param {'create' | 'edit'} editType
   * * @param {boolean} isProjectTemplate
   */
  function trackPublishFormButtonClicked(editType, isProjectTemplate) {
    track({
      event_action: 'publish_form_clicked',
      event_category: ADVANCED_DISCOVERY,
      edit_type: editType,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track a form published activation event
   * * @param {'create' | 'edit'} editType
   * * @param {boolean} taskAssigned
   * * @param {boolean} conditionalLogicIncluded
   * * @param {boolean} taskMappingIncluded
   * * @param {boolean} isProjectTemplate
   */
  function trackFormPublishedEvent(
    editType,
    taskAssigned,
    conditionalLogicIncluded,
    taskMappingIncluded,
    isProjectTemplate,
  ) {
    track({
      event_action: 'form_published',
      event_category: ACTIVATION,
      edit_type: editType,
      page_type: 'project_forms_editor',
      task_assigned: taskAssigned,
      conditional_logic_included: conditionalLogicIncluded,
      task_mapping_included: taskMappingIncluded,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track a form validation errorx
   * * @param {object} errors
   * * @param {'create' | 'edit'} editType
   * * @param {boolean} isProjectTemplate
   */
  function trackFormValidationError(errors, editType, isProjectTemplate) {
    let errorStr = '';

    if ('content.name' in errors) {
      errorStr = 'title';
    }

    if ('content.definition' in errors) {
      errorStr = errorStr.length ? (errorStr += ' | question') : 'question';
    }

    track({
      event_action: 'form_error_displayed',
      event_category: ADVANCED_DISCOVERY,
      edit_type: editType,
      page_type: 'project_forms_editor',
      error_fields: errorStr, // "title", "question" or "title | question"
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of an option in more menu of form on project forms page
   * @param {string} option
   * @param {boolean} isProjectTemplate
   */
  function trackFormOptionClicked(option, isProjectTemplate) {
    track({
      event_action: `${option}_clicked`,
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track content added to a form
   * @param {string} contentType
   * @param {boolean} isProjectTemplate
   */
  function trackFormContentAdded(contentType, isProjectTemplate) {
    track({
      event_action: 'form_content_added',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      content_type: contentType,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track form toggled active or inactive
   * @param {string} toggleStatus
   * @param {boolean} isProjectTemplate
   */
  function trackFormActiveToggle(toggleStatus, isProjectTemplate) {
    track({
      event_action: 'form_active_toggled',
      event_category: ACTIVATION,
      page_type: 'project_forms_editor',
      toggle_type: toggleStatus,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of the copy link button
   * @param {boolean} isProjectTemplate
   */
  function trackFormCopyLinkClicked(isProjectTemplate) {
    track({
      event_action: 'form_link_copied',
      event_category: ACTIVATION,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of the form embed code copy button
   * @param {boolean} isProjectTemplate
   */
  function trackFormCopyEmbedCodeClicked(isProjectTemplate) {
    track({
      event_action: 'form_embed_link_copied',
      event_category: ACTIVATION,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when send responses to option selected
   * @param (boolean) isProjectTemplate
   */
  function trackSendResponsesToSelected(isProjectTemplate) {
    track({
      event_action: 'send_responses_to_created',
      event_category: ACTIVATION,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when conditional logic switch is toggled
   * * @param {string} toggleStatus
   * * @param {boolean} isProjectTemplate
   */
  function trackConditionalLogicToggled(toggleStatus, isProjectTemplate) {
    track({
      event_action: 'conditional_logic_toggled',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      toggle_type: toggleStatus,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of the preview button
   * * @param {boolean} isProjectTemplate
   */
  function trackPreviewButtonClicked(isProjectTemplate) {
    track({
      event_action: 'preview_clicked',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of the new to logic link
   * * @param {boolean} isProjectTemplate
   */
  function trackNewToLogicLinkClicked(isProjectTemplate) {
    track({
      event_action: 'new_to_logic_clicked',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track the clicking of buttons in conditional logic modal
   * * @param {string} action
   * * @param {boolean} isProjectTemplate
   */
  function trackConditionalLogicModalAction(action, isProjectTemplate) {
    track({
      event_action: action,
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_video_modal',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when form editor is viewed
   * * @param {'create' | 'edit'} editType
   * * @param {boolean} isProjectTemplate
   */
  function trackFormsEditorViewed(editType, isProjectTemplate) {
    track({
      event_action: 'project_forms_editor_viewed',
      event_category: ADVANCED_DISCOVERY,
      edit_type: editType,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when a task assignee is chosen
   * * @param {boolean} isProjectTemplate
   */
  function trackTaskAssigneeChosen(isProjectTemplate) {
    track({
      event_action: 'choose_task_assignees',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the confirmation message is updated
   * * @param {boolean} isProjectTemplate
   */
  function trackConfirmationMessageUpdated(isProjectTemplate) {
    track({
      event_action: 'confirmation_message_updated',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the submit another switch is toggled
   * * @param {string} toggleSubmit
   * * @param {boolean} isProjectTemplate
   */
  function trackSubmitAnotherToggled(toggleSubmit, isProjectTemplate) {
    track({
      event_action: 'submit_another_toggled',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      toggle_type: toggleSubmit,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the Teamwork branding switch is toggled
   * * @param {string} toggleBranding
   * * @param {boolean} isProjectTemplate
   */
  function trackTeamworkBrandingToggled(toggleBranding, isProjectTemplate) {
    track({
      event_action: 'Teamwork.com_branding_toggled',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      toggle_type: toggleBranding,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the show progress bar switch is toggled
   * * @param {string} toggleBranding
   * * @param {boolean} isProjectTemplate
   */
  function trackShowProgressBarToggled(toggleProgressBar, isProjectTemplate) {
    track({
      event_action: 'show_progress_bar_toggled',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      toggle_type: toggleProgressBar,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the redirect URL is updated
   * * @param {boolean} isProjectTemplate
   * */
  function trackRedirectUrlUpdated(isProjectTemplate) {
    track({
      event_action: 'redirect_url_updated',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when an email respondent is updated
   * * @param {boolean} isProjectTemplate
   * */
  function trackEmailRespondentUpdated(isProjectTemplate) {
    track({
      event_action: 'email_form_respondent_updated',
      event_category: 'advanced_discovery_event',
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track text content edited on a form
   * @param {string} contentType
   * @param {boolean} isProjectTemplate
   */
  function trackFormTextContentEdited(contentType, isProjectTemplate) {
    track({
      event_action: 'form_content_text_edited',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      content_type: contentType,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the task title field is selected
   * @param {boolean} isProjectTemplate
   */
  function trackTaskTitleSelected(isProjectTemplate) {
    track({
      event_action: 'select_task_title',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when a logo is added
   * @param {boolean} isProjectTemplate
   */
  function trackLogoAdded(isProjectTemplate) {
    track({
      event_action: 'add_logo_clicked',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the form style tab is viewed
   * @param {boolean} isProjectTemplate
   */
  function trackFormsStyleTabViewed(isProjectTemplate) {
    track({
      event_action: 'forms_style_tab_viewed',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the submit button text is edited
   * @param {boolean} isProjectTemplate
   */
  function trackSubmitButtonTextEdited(isProjectTemplate) {
    track({
      event_action: 'submit_button_text_edited',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the form colour is edited
   * @param {'background' | 'button'} contentType
   * @param {boolean} isProjectTemplate
   */
  function trackFormColourEdited(contentType, isProjectTemplate) {
    track({
      event_action: 'form_colour_edited',
      event_category: ADVANCED_DISCOVERY,
      content_type: contentType,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the form cover is edited
   * @param {'colour' | 'image'} contentType
   * @param {boolean} isProjectTemplate
   */
  function trackFormCoverEdited(contentType, isProjectTemplate) {
    track({
      event_action: 'form_cover_edited',
      event_category: ADVANCED_DISCOVERY,
      content_type: contentType,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the form logo is added
   * @param {boolean} isProjectTemplate
   */
  function trackFormLogoAdded(isProjectTemplate) {
    track({
      event_action: 'form_logo_added',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when the public form is viewed
   * @param {'true' | 'false'} isTeamworkUser
   */
  function trackFormPublicPageViewed(isTeamworkUser) {
    track({
      event_action: 'project_forms_public_page_viewed',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_public_page',
      teamwork_user: isTeamworkUser,
    });
  }

  /**
   * Track when the form is submitted
   * @param {'true' | 'false'} isTeamworkUser
   */
  function trackFormSubmitted(isTeamworkUser) {
    track({
      event_action: 'task_created_from_form_submission',
      event_category: ACTIVATION,
      page_type: 'project_forms',
      teamwork_user: isTeamworkUser,
    });
  }

  /**
   * Track when field mapping modal is displayed
   * @param {'create' | 'edit'} editType
   * @param { string } contentType
   * @param {boolean} isProjectTemplate
   */
  function trackFieldMappingModalDisplayed(editType, contentType, isProjectTemplate) {
    track({
      event_action: 'field_mapping_modal_displayed',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_create_or_edit: editType,
      content_type: contentType,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when user clicks "save changes" in link task field modal
   * @param {'create' | 'edit'} editType
   * @param { string } contentType
   * @param { string } fieldType
   * @param {boolean} isProjectTemplate
   */
  function trackFieldMappingModalSaveChanges(editType, contentType, fieldType, isProjectTemplate) {
    track({
      event_action: 'save_changes_clicked',
      event_category: ACTIVATION,
      page_type: 'project_forms_field_mapping_modal',
      is_create_or_edit: editType,
      content_type: contentType,
      field_type: fieldType,
      is_project_template: isProjectTemplate,
    });
  }

  /**
   * Track when field mapping modal is deleted
   * @param {boolean} isProjectTemplate
   */
  function trackFieldMappingModalDeleted(isProjectTemplate) {
    track({
      event_action: 'field_mapping_deleted',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'project_forms_editor',
      is_project_template: isProjectTemplate,
    });
  }

  return {
    trackFormsPanelViewed,
    trackProjectFormsPageViewed,
    trackAddFormButtonClicked,
    trackPublishFormButtonClicked,
    trackFormPublishedEvent,
    trackFormValidationError,
    trackFormOptionClicked,
    trackFormContentAdded,
    trackFormActiveToggle,
    trackFormCopyLinkClicked,
    trackFormCopyEmbedCodeClicked,
    trackSendResponsesToSelected,
    trackConditionalLogicToggled,
    trackPreviewButtonClicked,
    trackNewToLogicLinkClicked,
    trackConditionalLogicModalAction,
    trackFormsEditorViewed,
    trackTaskAssigneeChosen,
    trackConfirmationMessageUpdated,
    trackSubmitAnotherToggled,
    trackTeamworkBrandingToggled,
    trackShowProgressBarToggled,
    trackRedirectUrlUpdated,
    trackEmailRespondentUpdated,
    trackFormTextContentEdited,
    trackTaskTitleSelected,
    trackLogoAdded,
    trackFormsStyleTabViewed,
    trackSubmitButtonTextEdited,
    trackFormColourEdited,
    trackFormCoverEdited,
    trackFormLogoAdded,
    trackFormPublicPageViewed,
    trackFormSubmitted,
    trackFieldMappingModalDisplayed,
    trackFieldMappingModalSaveChanges,
    trackFieldMappingModalDeleted,
  };
}
