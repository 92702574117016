<script setup>
import { useCohort, useExperimentA30 } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { isPaid, isPaymentOverdue, isClientUser, isCollaborator, isSiteAdmin } = useCohort();
const { isExpA30Variation } = useExperimentA30();

const isICPOnboarding = computed(
  () =>
    ((isPaid.value || isPaymentOverdue.value) && !isClientUser.value && !isCollaborator.value && isSiteAdmin.value) ||
    isExpA30Variation.value,
);
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Log time, store billing information, and never miss a payment. Over the next 2 minutes, discover how you can:',
        )
      }}
    </p>
    <ul class="mx-10">
      <!-- Never miss a payment -->
      <li v-if="isICPOnboarding">{{ t('Set billable rates') }}</li>
      <li v-if="isICPOnboarding">{{ t('Manage recurring revenues automatically') }}</li>
      <li v-if="isICPOnboarding">{{ t('Budget at a granular level') }}</li>

      <li>{{ t('Track every billable minute') }}</li>
      <li v-if="isICPOnboarding">{{ t('My timesheets') }}</li>
      <li v-else>{{ t('Set billable rates') }}</li>
      <li>{{ t('Bill accurately') }}</li>
    </ul>
  </div>
</template>
