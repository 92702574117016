<script setup>
import { useDatePicker } from './useDatePicker';
import LscDatePickerRangeInput from './LscDatePickerRangeInput.vue';
import { useI18n } from '@/util';

const {
  dates,
  selectDates,
  updatingStartDate,
  dataTestIdPrefix,
  startDateLabel,
  endDateLabel,
  keepFocus,
  shouldShowStartDateField,
  showStartDateField,
} = useDatePicker();
const { t } = useI18n();

const computedStartDateLabel = computed(() => startDateLabel.value ?? t('Start date'));
const computedEndDateLabel = computed(() => endDateLabel.value ?? t('Due date'));

setTimeout(() => {
  keepFocus();
});
</script>

<template>
  <div class="grid grid-cols-2 gap-2">
    <LscDatePickerRangeInput
      v-if="shouldShowStartDateField"
      ref="startDateInputRef"
      :modelValue="dates?.[0]"
      :isStartDate="true"
      :label="computedStartDateLabel"
      :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-start-date-input` : undefined"
      @update:modelValue="selectDates"
      @focus="updatingStartDate = true"
      @blur="keepFocus"
    />
    <div v-else class="flex items-center justify-center">
      <LscButton variant="plain-secondary" prependIcon="lsi-add" @click="showStartDateField">
        {{ t('Start date') }}
      </LscButton>
    </div>
    <LscDatePickerRangeInput
      :modelValue="dates?.[1]"
      :isStartDate="false"
      :label="computedEndDateLabel"
      :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-due-date-input` : undefined"
      @update:modelValue="selectDates"
      @focus="updatingStartDate = false"
      @blur="keepFocus"
    />
  </div>
</template>
