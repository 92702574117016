import { useRouter } from 'vue-router';
import { useDocumentVisibility } from '@vueuse/core';

import { useCurrentAccount, usePermissions } from '@/api';
import { useCurrentProject, useRoute } from '@/route';
import { useI18n } from '@/util';

import { MIGRATION_STATUS } from './constants';
import { useWorkflows } from '../useWorkflows';

export function useWorkflowMigrationToast() {
  const router = useRouter();
  const route = useRoute();
  const toast = useLsToast();
  const { t } = useI18n();
  const account = useCurrentAccount();
  const currentProject = useCurrentProject();
  const { canManageGlobalWorkflows } = usePermissions();
  const { openHelpDocs } = useWorkflows();

  const documentVisibility = useDocumentVisibility();

  const hasDisplayedWorkflowMigrationInProgressToast = shallowRef(false);

  const workflowsMigrationStatus = computed(() => account.value?.workflowsMigrationStatus);

  const isProjectBoardRoute = computed(() => {
    return /^\/projects\/\d+\/tasks\/board$/.test(route.path);
  });

  const inProgressToast = shallowRef(null);

  // Only register the watcher when the migration is not already complete
  if (workflowsMigrationStatus.value === MIGRATION_STATUS.SUCCESS) {
    return;
  }

  const unwatch = watch(
    [workflowsMigrationStatus, currentProject, documentVisibility],
    () => {
      // This composable should only operate within a project context
      if (!currentProject.value) {
        hasDisplayedWorkflowMigrationInProgressToast.value = false;
        return;
      }

      // Only fire toasts when the page is visible
      if (documentVisibility.value !== 'visible') {
        return;
      }

      // Remove the 'in progress' toast when migration status changes
      if (workflowsMigrationStatus.value !== MIGRATION_STATUS.IN_PROGRESS) {
        if (inProgressToast.value) {
          toast.removeToast(inProgressToast.value);
          inProgressToast.value = null;
        }
      }

      if (workflowsMigrationStatus.value === MIGRATION_STATUS.IN_PROGRESS) {
        // Don't show toast if already the 'Board' route (we show a state for this)
        if (isProjectBoardRoute.value) {
          return;
        }

        // Don't show again for this 'session' within the project context
        if (hasDisplayedWorkflowMigrationInProgressToast.value) {
          return;
        }

        if (inProgressToast.value) {
          toast.pauseToast(inProgressToast.value);
          toast.resumeToast(inProgressToast.value);
          return;
        }

        inProgressToast.value = toast.info({
          title: t('Board update in progress'),
          message: canManageGlobalWorkflows.value
            ? t("We're adding new functionalities and upgrading the UI.")
            : t("We're upgrading your boards to a new UI."),
          actionText: t('Learn more'),
          action: openHelpDocs,
          duration: 30 * 1000,
          onRemove: () => {
            inProgressToast.value = null;
          },
        });

        return;
      }

      if (workflowsMigrationStatus.value === MIGRATION_STATUS.SUCCESS) {
        toast.success({
          title: t('Your boards are ready!'),
          message: t('Explore the new interface and features.'),
          actionText: !isProjectBoardRoute.value ? t('Go to Board') : undefined,
          action: !isProjectBoardRoute.value
            ? () => {
                router.push(`/projects/${currentProject.value.id}/tasks/board`);
              }
            : undefined,
          duration: 30 * 1000,
        });

        unwatch();
      }
    },
    { immediate: true },
  );
}
