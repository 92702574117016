<script setup>
import { provideCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentAccountV3Loader } from '../account/useCurrentAccountV3Loader';
import { publicShareToken } from '../base/publicShareToken';
import { provideHandle401 } from '../base/useHandle401';
import { useNoopListLoader } from '../base/useNoopListLoader';
import { provideTeamworkFeaturesState } from '../features/useTeamworkFeatures';
import { provideCurrentUserState } from '../user/useCurrentUser';
import { useCurrentUserV3Loader } from '../user/useCurrentUserV3Loader';

provideHandle401();

provideCurrentAccountState(
  useCurrentAccountV3Loader({
    params: {
      token: publicShareToken,
    },
  }),
);

provideCurrentUserState(
  useCurrentUserV3Loader({
    params: {
      token: publicShareToken,
      includeAuth: true,
    },
  }),
);

provideTeamworkFeaturesState(useNoopListLoader());
</script>
<template>
  <slot />
</template>
