<script setup>
import { usePendo, usePricePlan } from '@/api';
import { useLegacyBridge } from './useLegacyBridge';
import { useI18n } from '@/util';
import { useLegacySwitches } from './useLegacySwitches';

const { isInFreeTrial } = usePricePlan();
const enabledSwitches = useLegacySwitches();
const { t } = useI18n();

const { trackPendoEvent } = usePendo();

const { activeLegacyViewSwitch } = useLegacyBridge();

const switchState = computed({
  get() {
    return enabledSwitches[activeLegacyViewSwitch.value]?.value;
  },
  set(val) {
    if (enabledSwitches[activeLegacyViewSwitch.value]) {
      enabledSwitches[activeLegacyViewSwitch.value].value = val;
    }
  },
});

const shouldShowToggle = computed(
  () =>
    Object.keys(enabledSwitches).includes(activeLegacyViewSwitch.value) &&
    activeLegacyViewSwitch.value !== 'listView' &&
    (activeLegacyViewSwitch.value !== 'scheduler' || !isInFreeTrial.value),
);

const dataIdentifier = computed(() => {
  if (activeLegacyViewSwitch.value) {
    if (activeLegacyViewSwitch.value === 'listview') {
      return `app-nav__project-navigation__header__enable-lightspeed--listview${switchState.value ? '--on' : '--off'}`;
    }
    return `app-nav__project-navigation__header__enable-lightspeed--${activeLegacyViewSwitch.value.toLowerCase()}`;
  }
  return '';
});

function onToggleLightspeed() {
  setTimeout(() => {
    if (switchState.value) {
      trackPendoEvent({ eventName: `TOGGLE_${activeLegacyViewSwitch.value.toUpperCase()}_IN_LIGHTSPEED_ON` });
    } else {
      trackPendoEvent({ eventName: `TOGGLE_${activeLegacyViewSwitch.value.toUpperCase()}_IN_LIGHTSPEED_OFF` });
    }
  }, 1000);
}
</script>
<template>
  <LscSwitch
    v-if="shouldShowToggle"
    v-model="switchState"
    v-LsdTooltip="
      switchState
        ? t('New version of this page is ON')
        : t('New version of this page is OFF.') +
          '\r\n\r\n' +
          t('Click to try the new version. This affects just you and you can revert at any time.')
    "
    :data-identifier="dataIdentifier"
    class="ml-2"
    @click="onToggleLightspeed"
  />
</template>
