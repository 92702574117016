<script setup>
import { ClientAddDialog } from '@/module/client';
import { useLegacyBridge } from '@/module/legacy';
import { ProjectAddPeopleDialog } from '@/module/project';
import { TaskAddDialog } from '@/module/task';
import { TimeTimelogAddOrEditDialog, TimeTimerAddOrEditDialog } from '@/module/time';
import { UserInviteDialog } from '@/module/user';
import { useCurrentProject, useQuickView } from '@/route';

defineOptions({ inheritAttrs: false });

const modelValue = defineModel({ type: Boolean, default: false });

const CommandCenterDialogPanel = defineAsyncComponent(() => import('./CommandCenterDialogPanel.vue'));

const project = useCurrentProject();
const { showLegacyModal } = useLegacyBridge();
const { isQuickViewOpen, isQuickViewLocked } = useQuickView();

// Dialog management
// It is necessary because `CommandCenterDialog` needs to trigger another dialog and close itself
// when a user selects a command like add task, add project, etc.
const isCommandCenterOpen = shallowRef(modelValue.value);
const isDialogOpen = shallowRef(false);
const dialogName = shallowRef('');

watch(modelValue, () => {
  isCommandCenterOpen.value = modelValue.value;
  isDialogOpen.value = false;
});

watch(
  () => isCommandCenterOpen.value || isDialogOpen.value,
  (newModelValue) => {
    modelValue.value = newModelValue;
  },
);

function openDialog(newDialogName) {
  isCommandCenterOpen.value = false;
  isDialogOpen.value = !newDialogName.startsWith('legacy:');

  if (isDialogOpen.value) {
    dialogName.value = newDialogName;
  } else {
    showLegacyModal({
      modalName: newDialogName.slice('legacy:'.length),
      params: { projectId: project.value?.id },
    });
  }
}

const alignLeft = computed(() => isQuickViewOpen.value && !isQuickViewLocked.value);
</script>

<template>
  <WidgetDialog
    v-bind="$attrs"
    v-model="isCommandCenterOpen"
    :retainFocus="false"
    :height="700"
    :class="{ alignLeft }"
    data-identifier="ls-command-center-dialog"
  >
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <CommandCenterDialogPanel @close="close" @openDialog="openDialog" />
    </template>
  </WidgetDialog>

  <LscSlotSwitch :name="dialogName">
    <template #TaskAddDialog>
      <TaskAddDialog v-model="isDialogOpen" pendoSource="command_center" />
    </template>
    <template #TimeTimelogAddOrEditDialog>
      <TimeTimelogAddOrEditDialog v-model="isDialogOpen" openSource="command_center" />
    </template>
    <template #UserInviteDialog>
      <UserInviteDialog v-model="isDialogOpen" panelSource="quick_add_people" />
    </template>
    <template #ProjectAddPeopleDialog>
      <ProjectAddPeopleDialog v-model="isDialogOpen" :project="project" />
    </template>
    <template #TimeTimerAddOrEditDialog>
      <TimeTimerAddOrEditDialog v-model="isDialogOpen" />
    </template>
    <template #ClientAddEditDialog>
      <ClientAddDialog v-model="isDialogOpen" />
    </template>
  </LscSlotSwitch>
</template>
