<script setup>
import { FeedbackButton, useFeedbackDataIdentifier } from '@/module/feedback';
import { TourButton, useTourDataIdentifier } from '@/module/onboarding';
import { LegacyViewSwitch } from '@/module/legacy';
import { useCurrentBreadcrumbs } from '@/route';

const breadcrumbs = useCurrentBreadcrumbs();
const feedbackDataIdentifier = useFeedbackDataIdentifier();
const { tourDataIdentifier, tourFeatureName } = useTourDataIdentifier();
</script>

<template>
  <div class="flex h-8 min-w-0 items-center justify-between gap-4 border-b border-b-subtle px-5">
    <div class="flex items-center gap-1">
      <LscIcon icon="lsi-home" size="xs" class="shrink-0 text-subtle" />
      <LscBreadcrumbs size="sm" class="flex-1" :items="breadcrumbs" :expandedOverflow="false" />
    </div>
    <div class="flex shrink-0 items-center gap-3">
      <TourButton
        v-if="tourDataIdentifier"
        :data-identifier="tourDataIdentifier"
        :featureName="tourFeatureName"
        size="sm"
      />
      <FeedbackButton v-if="feedbackDataIdentifier" :data-identifier="feedbackDataIdentifier" size="sm" />
      <LegacyViewSwitch class="ml-auto" />
    </div>
  </div>
</template>
