<script setup>
defineProps({
  prependIcon: {
    type: String,
    default: undefined,
  },
  appendIcon: {
    type: String,
    default: undefined,
  },
});

const alertButtonVariantStyleConfig = tv({
  base: [
    'flex items-center gap-1 whitespace-nowrap rounded-full',
    'text-button-1 font-semibold text-default',
    'px-[--lsds-c-button-spacing-outer-md]',
    'h-[--lsds-c-button-size-md]',
    'gap-[--lsds-c-button-spacing-gap-md]',
    'bg-[--lsds-c-alert-button-color-bg-default] hover:bg-[--lsds-c-alert-button-color-bg-hover]',
  ],
});

const classes = computed(() => alertButtonVariantStyleConfig());
</script>

<template>
  <button type="button" :class="classes">
    <slot name="prepend">
      <LscIcon v-if="prependIcon" :icon="prependIcon" size="sm" />
    </slot>
    <slot />
    <slot name="append">
      <LscIcon v-if="appendIcon" :icon="appendIcon" size="sm" />
    </slot>
  </button>
</template>
