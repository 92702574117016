import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { useLaunchDarkly } from '../features/useLaunchDarkly';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment E2401
 * https://app.launchdarkly.com/default/production/features/
 * https://digitalcrew.teamwork.com/spaces/growth/page/52373-e-24-01-implement-a-discounted-offer-to-trials-and-free-forever-january
 */
export function useExperimentE2401() {
  const { projectsJan2024SaleEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const account = useCurrentAccount();
  const { client } = useLaunchDarkly();
  const { isOwnerAdmin } = usePermissions();

  const EXP_E2401_FLAG_KEY = 'projects-exp-e-24-01-jan-sale';
  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;
  const SUPPORTED_PLAN_IDS = [6, 18]; // Free Forever
  const variation1 = 'variation-1';
  const variation2 = 'variation-2';
  const control = 'control';

  const EXP_E2401_COMMON_METRICS = ['plan_name', 'page', 'user_role'];
  const EXP_E2401_EVENT_NAME = 'JAN2024_SALE_EVENT';

  const expE2401AppLevelTargeting = computed(
    () => isOwnerAdmin?.value && Boolean(SUPPORTED_PLAN_IDS.includes(account.value?.pricePlanId)),
  );
  const isExpE2401Variation1 = computed(
    () =>
      projectsJan2024SaleEnabled.value &&
      client.value.variation(EXP_E2401_FLAG_KEY) === variation1 &&
      expE2401AppLevelTargeting.value,
  );
  const isExpE2401Variation2 = computed(
    () =>
      projectsJan2024SaleEnabled.value &&
      client.value.variation(EXP_E2401_FLAG_KEY) === variation2 &&
      expE2401AppLevelTargeting.value,
  );
  const isExpE2401Control = computed(
    () =>
      (!projectsJan2024SaleEnabled.value || client.value.variation(EXP_E2401_FLAG_KEY) === control) &&
      expE2401AppLevelTargeting.value,
  );

  function trackExperimentE2401() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_E2401_FLAG_KEY,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expE2401AppLevelTargeting.value,
    });
  }

  function trackEventE2401({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: EXP_E2401_EVENT_NAME,
      commonMetrics: EXP_E2401_COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExpE2401Variation1,
    isExpE2401Variation2,
    isExpE2401Control,
    trackExperimentE2401,
    projectsJan2024SaleEnabled,
    trackEventE2401,
  };
}
