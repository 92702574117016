<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import LscButton from '../../../components/action/button/LscButton.vue';
import LscCheckbox from '../../../components/input/checkbox/LscCheckbox.vue';
import LscForm from '../../../components/utility/form/LscForm.vue';
import LscIcon from '../../../components/media/icon/LscIcon.vue';
import LscSheet from '../../../components/structure/sheet/LscSheet.vue';

const props = defineProps({
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: Object,
    default: null,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
});

const emit = defineEmits(['submit', 'close']);
const { t } = useI18n();
const { required, helpers } = useValidators();

const submitButtonText = computed(() => (props.tag?.id ? t('Save changes') : t('Create new tag')));
const name = shallowRef(props.tag?.name ?? '');
const selectedColor = shallowRef(props.tag?.color ?? '');
const isProjectSpecific = shallowRef(props.tag?.projectId != null);
const colors = [
  '#d84640',
  '#f78233',
  '#f3bd38',
  '#b1da33',
  '#53c843',
  '#37cecf',
  '#2e8de3',
  '#9b7cdb',
  '#f47fbd',
  '#a6a6a6',
  '#4d4d4d',
  '#9d6857',
];

const projectId = computed(() => (isProjectSpecific.value ? (props.tag?.projectId ?? props.projectId ?? 0) : 0));

const v$ = useVuelidate(
  {
    name: {
      required: helpers.withMessage(t('You must enter a tag name'), required),
    },
    selectedColor: {
      required: helpers.withMessage(t('You must select a tag color'), required),
    },
  },
  { name, selectedColor },
);

function selectColor(newColor) {
  selectedColor.value = newColor;
}

function submit() {
  v$.value.$touch();
  if (v$.value.$error) {
    return;
  }
  emit('submit', {
    name: name.value,
    color: selectedColor.value,
    projectId: projectId.value,
  });
}
</script>
<template>
  <LscSheet sheetClass="w-72">
    <LscForm class="grid grid-flow-row justify-items-start gap-4" @submit="submit">
      <VTextField
        v-model="name"
        :autofocus="true"
        class="w-full"
        :label="t('Tag name')"
        :errorMessages="v$.name.$errors.map((error) => error.$message)"
      >
        <template #append-inner>
          <LscIcon
            v-if="name"
            class="cursor-pointer text-icon-default"
            icon="lsi-close"
            size="sm"
            @click="$emit('close')"
          />
        </template>
      </VTextField>
      <div class="grid grid-flow-row gap-2">
        <h4 class="text-body-1 font-semibold">{{ t('Tag color') }}</h4>
        <div class="grid grid-cols-6 justify-center justify-items-center gap-3">
          <button
            v-for="color of colors"
            :key="color"
            type="button"
            class="inline-flex size-8 items-center justify-center"
            @click.stop="selectColor(color)"
          >
            <LscIcon size="xl" :color="color" :icon="selectedColor === color ? 'lsi-mark-read' : 'lsi-color'" />
          </button>
        </div>
        <LscMessage v-for="error in v$.selectedColor.$errors" :key="error.$message" :message="error.$message" />
      </div>

      <LscCheckbox v-model="isProjectSpecific" density="compact" :trueValue="true" :label="t('Project specific')" />
      <LscButton variant="primary" :loading="isSubmitting" type="submit" class="place-self-center">
        {{ submitButtonText }}
      </LscButton>
    </LscForm>
  </LscSheet>
</template>
