<script setup>
import { useI18n } from '@/util';
import { useLscDateStepper } from './useLscDateStepper';

const props = defineProps({
  prevLabel: {
    type: String,
    default: undefined,
  },
  nextLabel: {
    type: String,
    default: undefined,
  },
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['prev', 'next']);
const { t } = useI18n();
const { size } = useLscDateStepper();

const buttonSizeConfig = { sm: 'xs', lg: 'sm' };

const buttonSize = computed(() => buttonSizeConfig[size.value]);
const previousButtonLabel = computed(() => props.prevLabel ?? t('Go back'));
const nextButtonLabel = computed(() => props.nextLabel ?? t('Go forwards'));
</script>

<template>
  <div class="flex items-center">
    <LscIconButton
      v-LsdTooltip="previousButtonLabel"
      :ariaLabel="previousButtonLabel"
      icon="lsi-previous"
      :size="buttonSize"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-date-stepper-nav-button-prev` : undefined"
      @click="emit('prev')"
    />
    <LscIconButton
      v-LsdTooltip="nextButtonLabel"
      :ariaLabel="nextButtonLabel"
      icon="lsi-next"
      :size="buttonSize"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-date-stepper-nav-button-next` : undefined"
      @click="emit('next')"
    />
  </div>
</template>
