import { createVuetify } from 'vuetify';
import { Icon } from '@iconify/vue/dist/offline';
import LsiAliases from '@teamwork/lightspeed-icons/vuetify-aliases';
import { DEFAULT_THEME, main } from '@/designsystem/config/themes';
import { defaults } from '@/designsystem/config/defaults';

const icons = {
  sets: {
    lsi: {
      component: (props) =>
        h(Icon, {
          icon: props.icon,
          mode: 'svg',
        }),
    },
  },
  defaultSet: 'lsi',
  aliases: LsiAliases,
};

export const vuetify = createVuetify({
  // https://next.vuetifyjs.com/en/getting-started/installation/#usage
  defaults,
  icons,
  theme: {
    defaultTheme: DEFAULT_THEME,
    themes: {
      main,
    },
  },
});
