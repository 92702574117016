import { useCurrentUser, useCurrentAccount, useCohort, useExperimentA18, useFeatures, usePreferences } from '@/api';

export function useOnboardingChecklist() {
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const {
    isPaid,
    isPaymentOverdue,
    isClientUser,
    isCollaborator,
    isSiteOwner,
    isCompanyRoleContributorGrp,
    isInOwnerCompany,
    isContact,
    firstLoginMoreThan30DaysAgo,
  } = useCohort();
  const {
    projectTimeBudgetsEnabled,
    projectFinancialBudgetsEnabled,
    utilizationReportEnabled,
    workloadPlannerEnabled,
  } = useFeatures();
  const { isExpA18Variation } = useExperimentA18();

  const { onboardingChecklistDismissed } = usePreferences();

  const isAdminTrialUser = computed(() => {
    return (
      user.value.administrator &&
      user.value.inOwnerCompany &&
      // account is in free trial state
      account.value.trialDaysRemaining > 0 &&
      account.value.paymentStatus !== 'paid'
    );
  });

  const productOutcomeFlowEnabled = computed(() => {
    return (
      projectFinancialBudgetsEnabled.value &&
      projectTimeBudgetsEnabled.value &&
      workloadPlannerEnabled.value &&
      utilizationReportEnabled.value
    );
  });

  const isChecklistFlowEnabled = computed(() => {
    if (onboardingChecklistDismissed.value) {
      return false;
    }

    if (isExpA18Variation.value) {
      return true;
    }

    if (
      isCompanyRoleContributorGrp.value &&
      !isSiteOwner.value &&
      isInOwnerCompany.value &&
      !isContact.value &&
      !isCollaborator.value
    ) {
      return true;
    }

    if (
      (isPaid.value || isPaymentOverdue.value) &&
      !isClientUser.value &&
      !isCollaborator.value &&
      !isSiteOwner.value &&
      !firstLoginMoreThan30DaysAgo.value
    ) {
      return true;
    }

    return productOutcomeFlowEnabled.value && isAdminTrialUser.value;
  });

  return {
    isChecklistFlowEnabled,
  };
}
