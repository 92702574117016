<script setup>
import { useI18n } from '@/util';
import { useAppShellSidebar } from '../sidebar/useAppShellSidebar';
import { useProjectJumpToDrawerTracking } from '@/module/project';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  pinDataIdentifier: {
    type: String,
    default: '',
  },
  closeDataIdentifier: {
    type: String,
    default: '',
  },
});

const { clearActiveDrawerPanel, activeDrawerPanel, isDrawerPanelPinned } = useAppShellSidebar();
const { t } = useI18n();
const { trackJumpToDrawerPinning, trackJumpToDrawerClosed } = useProjectJumpToDrawerTracking();

function toggleIsSidePanelPinned() {
  isDrawerPanelPinned.value = isDrawerPanelPinned.value === activeDrawerPanel.value ? null : activeDrawerPanel.value;
}

function handleSidePanelPinned() {
  toggleIsSidePanelPinned();
  trackJumpToDrawerPinning(props.pinDataIdentifier, isDrawerPanelPinned.value, activeDrawerPanel.value);
}

function handleSidePanelClosed() {
  clearActiveDrawerPanel();
  trackJumpToDrawerClosed(props.closeDataIdentifier);
}
</script>

<template>
  <div class="m-6 flex w-full justify-between">
    <h4 class="text-subtitle-1 font-semibold">
      <slot name="prepend-title" />
      <span>{{ title }}</span>
      <slot name="append-title" />
    </h4>

    <div class="inline-flex items-center gap-1">
      <slot name="prepend-icons" buttonSize="sm" />

      <LscIconButton
        v-LsdTooltip="isDrawerPanelPinned ? t('Panel is pinned') : t('Pin this panel')"
        :ariaLabel="isDrawerPanelPinned ? t('Panel is pinned') : t('Pin this panel')"
        size="sm"
        :icon="isDrawerPanelPinned ? 'lsi-unpin' : 'lsi-pin'"
        :data-identifier="pinDataIdentifier"
        @click="handleSidePanelPinned"
      />

      <LscIconButton
        v-LsdTooltip="t('Close panel')"
        :ariaLabel="t('Close panel')"
        size="sm"
        icon="lsi-close"
        :data-identifier="closeDataIdentifier"
        @click="handleSidePanelClosed"
      />
    </div>
  </div>
</template>
