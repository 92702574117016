<script setup>
import { useI18n } from '@/util';
import { useAssigneePicker } from './useAssigneePicker';
import LswAssigneePickerItem from './LswAssigneePickerItem.vue';
import LscAvatar from '../../../components/media/avatar/LscAvatar.vue';
import LswAssigneePickerGroupHeader from './LswAssigneePickerGroupHeader.vue';
import { useCurrentAccount } from '@/api';

const { t } = useI18n();
const { companies, selectedCompanyIds, toggleCompany } = useAssigneePicker();
const account = useCurrentAccount();
</script>

<template>
  <LswAssigneePickerGroupHeader v-if="companies.length">
    {{ account.useClientView ? t('Clients') : t('Companies') }}
  </LswAssigneePickerGroupHeader>
  <LswAssigneePickerItem
    v-for="company in companies"
    :key="`assignee-picker-team-${company.id}`"
    :assignTooltip="account.useClientView ? t('Assign client') : t('Assign company')"
    :unassignTooltip="account.useClientView ? t('Unassign client') : t('Unassign company')"
    :active="selectedCompanyIds.includes(company.id)"
    @toggle="toggleCompany(company, $event)"
  >
    <template #prepend="{ avatarSize }">
      <LscAvatar v-bind="getLsAvatarProps({ company })" :size="avatarSize" />
    </template>
    <template #append="appendProps">
      <slot name="append" v-bind="appendProps" :company="company" />
    </template>
    <template #default>
      {{ company.name }}
    </template>
  </LswAssigneePickerItem>
</template>
