<script setup>
import { useI18n } from '@/util';
import NotificationDrawerEmptyStateUnread from './assets/NotificationDrawerEmptyStateUnread.svg';

const { t } = useI18n();
</script>

<template>
  <LscEmptyState class="h-full" :title="t('Woohoo!')" :message="t('You\'re all up to date!')">
    <template #image>
      <NotificationDrawerEmptyStateUnread />
    </template>
  </LscEmptyState>
</template>
