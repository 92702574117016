<script setup>
import { debouncedRef } from '@vueuse/core';
import { useProjectsV3Loader } from '@/api';
import { useI18n } from '@/util';
import PickersNoStarredProjectsFound from '../../../assets/PickersNoStarredProjectsFound.svg';
import PickersNoProjectsFound from '../../../assets/PickersNoProjectsFound.svg';

const props = defineProps({
  /**
   * The selected project.
   */
  modelValue: {
    type: Object,
    default: null,
  },
  /**
   * Whether the input should be focused when the component is mounted.
   */
  autofocus: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the input is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * The label for the input.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * Extra params to pass to the useProjectsV3Loader
   */
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  /**
   * The label tooltip text to display within the autocomplete.
   */
  labelTooltip: {
    type: String,
    default: '',
  },
  /**
   * Whether the input is in an error state.
   */
  error: {
    type: Boolean,
    default: false,
  },
  /**
   * A list of error messages.
   * @type {PropType<string[]>}
   */
  errorMessages: {
    type: Array,
    default: () => [],
  },
  /**
   * The tabs to display in the input menu.
   * @type {PropType<('recent' | 'starred' | 'all')[]>}
   */
  tabs: {
    type: Array,
    default: () => ['starred', 'all'],
    validator: (value) => value.every((tab) => ['recent', 'starred', 'all'].includes(tab)) && value.length !== 1,
  },
  /**
   * The data identifier prefix used for Pendo/testing
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * Additional project fields
   * @type {PropType<string[]>}
   */
  extraFields: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const autocompleteRef = shallowRef(null);
const searchTerm = shallowRef('');
const debouncedSearchTerm = debouncedRef(searchTerm, 300);
const count = shallowRef(10);
const pageSize = shallowRef(10);
const selectedTabId = shallowRef(props.tabs[0] ?? 'all');

const displayedTabs = computed(() => {
  if (props.tabs.length === 1) {
    // eslint-disable-next-line no-console
    console.warn('LswProjectPicker: "tabs" should contain at least 2 elements');
    return [];
  }
  return [
    {
      id: 'recent',
      label: t('Recent'),
      dataIdentifier: props.dataIdentifierPrefix
        ? `${props.dataIdentifierPrefix}-project-picker-recent-tab`
        : undefined,
    },
    {
      id: 'starred',
      label: t('Starred'),
      dataIdentifier: props.dataIdentifierPrefix
        ? `${props.dataIdentifierPrefix}-project-picker-starred-tab`
        : undefined,
    },
    {
      id: 'all',
      label: t('All'),
      dataIdentifier: props.dataIdentifierPrefix ? `${props.dataIdentifierPrefix}-project-picker-all-tab` : undefined,
    },
  ].filter(({ id }) => props.tabs.includes(id));
});

const tabsParams = computed(() => {
  if (debouncedSearchTerm.value) {
    return {
      orderBy: 'companyname',
      orderMode: 'asc',
    };
  }

  switch (selectedTabId.value) {
    case 'recent': {
      return {
        orderBy: 'lastWorkedOn',
        orderMode: 'desc',
      };
    }
    case 'starred': {
      return {
        onlyStarredProjects: true,
        orderBy: 'companyname',
        orderMode: 'asc',
      };
    }
    default: {
      return {
        orderBy: 'companyname',
        orderMode: 'asc',
      };
    }
  }
});

const defaultFields = ['id', 'name', 'isStarred', 'companyId', 'allowNotifyAnyone', 'notifyTaskAssignee', 'isBillable'];

const state = useProjectsV3Loader({
  count,
  pageSize,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
    includeProjectUserInfo: true, // needed for isStarred
    'fields[projects]': defaultFields.concat(props.extraFields).join(','),
    onlyProjectsWithExplicitMembership: 1,
    searchCompanies: true,
    ...props.loaderParams,
    ...tabsParams.value,
    include: (props.loaderParams.include?.split(',') ?? []).concat('companies').join(','),
  })),
});

const showTabs = computed(() => Boolean(displayedTabs.value.length > 0 && !searchTerm.value));

const shouldShowCompanyHeaders = computed(() => Boolean(searchTerm.value || selectedTabId.value === 'all'));

const { items: projects, loaded, inSync } = state;

const emptyStateTitle = computed(() => {
  if (searchTerm.value) {
    return t('There are no projects that match your search');
  }
  if (selectedTabId.value === 'recent') {
    return t('No recent projects');
  }
  if (selectedTabId.value === 'starred') {
    return t('No starred projects');
  }
  return t('No projects');
});

const emptyStateDescription = computed(() => {
  if (searchTerm.value) {
    return t('Please try again with a different term');
  }
  if (selectedTabId.value === 'recent') {
    return t('Once you start interacting with projects, they will be shown here');
  }
  if (selectedTabId.value === 'starred') {
    return t('Star any project and you can easily access it from this tab');
  }
  return t('Add some projects and they’ll be displayed here');
});

const items = computed(() => {
  const results = [];
  // Creates required structure for vautocomplete with company header separating projects
  for (let i = 0; i < projects.value.length; i++) {
    if (
      shouldShowCompanyHeaders.value &&
      (i === 0 || projects.value[i - 1].companyId !== projects.value[i].companyId)
    ) {
      results.push({ header: projects.value[i].company.name });
    }
    results.push(projects.value[i]);
  }
  return results;
});

/**
 * Prevent Vuetify attempting to search for a nullish name and handle null modelValue / modelValue.id
 */
const modelValue = computed({
  get() {
    if (props.modelValue?.id) {
      return {
        id: props.modelValue.id,
        name: props.modelValue.name || '',
      };
    }
    return null;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const clearable = computed(() => Boolean(props.modelValue || searchTerm.value));

const shouldShowEmptyState = computed(() => loaded.value && projects.value.length === 0 && inSync.value);

function search(val) {
  const trimmedVal = val?.trim();
  if (trimmedVal === props.modelValue?.name) {
    return;
  }
  searchTerm.value = trimmedVal;
}

function keydown() {
  setTimeout(() => {
    autocompleteRef?.value.blur();
  }, 100);
}
</script>

<template>
  <VAutocomplete
    ref="autocompleteRef"
    v-bind="VAutocompleteLswPickers"
    v-model="modelValue"
    :autofocus="autofocus"
    :items="items"
    :disabled="disabled"
    :error="error"
    :errorMessages="errorMessages"
    noFilter
    returnObject
    itemValue="id"
    itemTitle="name"
    :clearable="clearable"
    clearIcon="lsi-clear"
    :menuProps="{
      ...VAutocompleteLswPickers.menuProps,
      class: {
        [VAutocompleteLswPickers.menuProps.class]: true,
        'VAutocompleteLswPickersMenu--has-tabs': showTabs,
      },
    }"
    openOnClear
    :loading="!inSync"
    :label="label || t('Select a project')"
    @update:search="search"
    @update:menu="search('')"
  >
    <template #prepend-item>
      <div v-if="showTabs" class="advanced-options">
        <LscTabs variant="segmented" justified>
          <LscTab
            v-for="tab in displayedTabs"
            :key="tab.id"
            :dataIdentifier="tab.dataIdentifier"
            :active="tab.id === selectedTabId"
            @click="selectedTabId = tab.id"
          >
            {{ tab.label }}
          </LscTab>
        </LscTabs>
      </div>
    </template>
    <template #item="{ item, props: { onClick } }">
      <VListItem v-if="item.value.header" class="v-list-item--header">
        <VListItemTitle class="mb-0">
          <LscOverflowEllipsis class="w-full font-semibold">
            {{ item.value.header }}
          </LscOverflowEllipsis>
        </VListItemTitle>
      </VListItem>
      <VListItem v-else v-bind="{ onClick }" :active="modelValue?.id === item.value" @click.enter.space="keydown">
        <VListItemTitle class="flex items-center gap-2">
          <LscIcon class="shrink-0" icon="lsi-project" />
          <p class="flex w-full items-center gap-1 overflow-hidden" :class="{ 'pr-7': !item.raw.isStarred }">
            <LscOverflowEllipsis class="text-body-1">
              {{ item.title }}
            </LscOverflowEllipsis>
            <LscOverflowEllipsis
              v-if="!shouldShowCompanyHeaders"
              class="mt-0.5 shrink-0 grow basis-1/4 text-body-2"
              :class="modelValue?.id === item.value ? 'text-primary-default' : 'text-subtle'"
            >
              {{ item.raw.company.name }}
            </LscOverflowEllipsis>
          </p>
          <div :class="{ 'pr-7': !item.raw.isStarred }">
            <slot name="itemAppend" :project="item.raw" />
          </div>

          <LscIcon
            v-if="item.raw.isStarred"
            class="shrink-0 !text-[color:--project-starred-color]"
            icon="lsi-favorite"
          />
        </VListItemTitle>
      </VListItem>
    </template>
    <template #append-item>
      <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
    </template>
    <template #append-inner>
      <div v-if="labelTooltip && !disabled" v-LsdTooltip="labelTooltip" class="flex items-center">
        <LscIcon class="pointer-events-auto cursor-pointer text-icon-subtle" size="sm" icon="lsi-tooltip" />
      </div>
    </template>
    <template #no-data>
      <LscEmptyState
        v-if="shouldShowEmptyState"
        size="md"
        class="mt-5"
        :title="emptyStateTitle"
        :message="emptyStateDescription"
      >
        <template v-if="!searchTerm" #image>
          <LscSlotSwitch :name="selectedTabId">
            <template #recent>
              <PickersNoProjectsFound />
            </template>
            <template #starred>
              <PickersNoStarredProjectsFound />
            </template>
            <template #all>
              <PickersNoProjectsFound />
            </template>
          </LscSlotSwitch>
        </template>
      </LscEmptyState>
    </template>
  </VAutocomplete>
</template>
