import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import {
  usePreferences,
  usePricePlan,
  useFeedbackActions,
  useCurrentUser,
  useCurrentAccount,
  useExperimentE2304,
  useFeatures,
} from '@/api';
import { useRoute } from '@/route';
import { useI18n } from '@/util';
import { useCheckoutData } from '@/module/checkout';

export function useFeatureTrialBundle() {
  const { t } = useI18n();
  const { blackFridayPromotionBannersDismissed } = usePreferences();
  const route = useRoute();
  const { projectsBlackFridayOffer } = useFeatures();
  const { pricePlanId } = usePricePlan();
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const { postFeedback } = useFeedbackActions();
  const { isExp2304Variation, trackEventE2304 } = useExperimentE2304();
  const router = useRouter();
  const toast = useLsToast();
  const { canAccessCheckout } = useCheckoutData();

  const supportedGrowPlanIds = [22, 42, 48, 65, 69];
  const supportedDeliverPlanIds = [21, 41, 47, 64];
  const offerStartDate = DateTime.fromISO('2023-11-17');
  const offerEndDate = DateTime.fromISO('2023-12-10');

  const featureTrialsBundle = [
    // Grow
    {
      id: 62,
      plan: 'scale',
      featureKey: 'profitability',
      title: t('Profitability Report'),
      path: `/reports/profitability`,
    },
    {
      id: 74,
      plan: 'scale',
      featureKey: 'schedulereporting',
      title: t('Scheduled Reports'),
      path: '',
    },
    {
      id: 65,
      plan: 'scale',
      featureKey: 'resourceschedulingpeople',
      title: t('Resource Scheduler (people)'),
      path: `/planning/schedule/people`,
    },
    // Deliver
    {
      id: 75,
      plan: 'grow',
      featureKey: 'timereport',
      title: t('Time Report'),
      path: `/reports/time`,
    },
    {
      id: 86,
      plan: 'grow',
      featureKey: 'companytimesheets',
      title: t('Company Timesheets'),
      path: `/time/company`,
    },
    {
      id: 76,
      plan: 'grow',
      featureKey: 'retainerbudgets',
      title: t('Retainer Budgets'),
      path: `/lastproject/finance/budgets`,
    },
    {
      id: 69,
      plan: 'grow',
      featureKey: 'tasklistbudgets',
      title: t('Tasklist Budgets'),
      path: `/lastproject/finance/budgets`,
    },
    {
      id: 70,
      plan: 'grow',
      featureKey: 'projectbudgetexpenses',
      title: t('Project Budget Expenses'),
      path: `/lastproject/finance/budgets`,
    },
  ];

  const displayConfirmBanner = ref(!!route.query.blackFridayOptin);
  const loading = ref(false);

  const blackFridayFeatureTrialsStarted = computed(() => {
    if (!account.value.pricePlanData?.trials) {
      return [];
    }
    const withinOfferRange = (featureKey) => {
      const feature = account.value.pricePlanData.trials[featureKey];
      const isWithinOfferDate =
        DateTime.fromISO(feature.startDate).startOf('day') >= offerStartDate.startOf('day') &&
        DateTime.fromISO(feature.startDate).startOf('day') <= offerEndDate.startOf('day');
      return isWithinOfferDate;
    };
    return Object.keys(account.value.pricePlanData?.trials)
      .filter(withinOfferRange)
      .map((featureKey) => parseInt(account.value.pricePlanData.trials[featureKey].featureId, 10));
  });

  const bannerState = computed(() => {
    if (projectsBlackFridayOffer.value) {
      return 'sale';
    }
    if (displayConfirmBanner.value) {
      return 'confirm';
    }
    if (blackFridayFeatureTrialsStarted.value.length) {
      return 'remind';
    }
    return null;
  });

  const featureTrialsActivated = computed(() =>
    featureTrialsBundle.filter((f) => blackFridayFeatureTrialsStarted.value.includes(f.id)),
  );

  const generalShowBannerCheck = computed(
    () => (featureTrialsActivated.value.length > 0 || projectsBlackFridayOffer.value) && isExp2304Variation.value,
  );

  const bannerInfo = computed(() => {
    switch (bannerState.value) {
      case 'sale':
        return {
          showBanner:
            generalShowBannerCheck.value && !blackFridayPromotionBannersDismissed.value.saleUpgradeBannerFinal,
          title: t('Black Friday Sale!'),
          bannerVariant: 'info-subdued',
        };
      case 'confirm':
        return {
          showBanner: generalShowBannerCheck.value && !blackFridayPromotionBannersDismissed.value.confirmBanner,
          title: t('Congratulations on starting your 14-day trial!'),
          bannerVariant: 'success-subdued',
        };
      case 'remind':
        return {
          showBanner: generalShowBannerCheck.value && !blackFridayPromotionBannersDismissed.value.remindBanner,
          title: t("Don't forget!"),
          bannerVariant: 'success-subdued',
        };
      default:
        return {
          showBanner: false,
          title: '',
          bannerVariant: '',
        };
    }
  });

  function redirectToCheckout() {
    trackEventE2304({ eventAction: 'upgrade_clicked', meta: { banner: 'black_friday_sale_banner' } });
    router.push({ path: '/redirect/checkout', query: { recommendedPlan: 'grow' } });
  }

  function contactSales() {
    const salesExpansionInbox = 2749;

    const body = {
      feedback: {
        source: 'Teamwork Projects',
        type: 'Black Friday Discount',
        summary: 'Black Friday Discount - Scale Upgrade',
        installationId: account.value.id,
        email: user.value.emailAddress,
        inboxId: salesExpansionInbox,
        sendToHubspot: true,
        message: `
                    Name: ${user.value.firstName} ${user.value.lastName} \n
                    Installation: ${account.value.id} \n
                    Email: ${user.value.emailAddress} \n
                    Message Content: The user is interested in upgrade to Scale using the Black Friday discount. \n
                `,
      },
    };
    loading.value = true;
    postFeedback(body)
      .then(() => {
        toast.success('A member of our team will be in touch shortly.');
      })
      .finally(() => {
        loading.value = false;
        blackFridayPromotionBannersDismissed.value = {
          ...blackFridayPromotionBannersDismissed.value,
          saleUpgradeBannerFinal: true,
        };
      });
    trackEventE2304({ eventAction: 'contact_us_clicked', meta: { banner: 'black_friday_sale_banner' } });
  }
  const planDiscounted = computed(() => {
    if (supportedDeliverPlanIds.includes(pricePlanId.value)) {
      return {
        title: 'Deliver',
        ctaText: t('Upgrade now'),
        discountMessage:
          t('Time is running out... {discount} for 3 months, offer ends Friday 15 December.') +
          (featureTrialsActivated.value.length > 0
            ? ` ${t(
                'Once your trial ends you will lose access to our time report, company timesheets, retainer & tasklist budgets and budget expenses. Upgrade to our Grow plan today so you can keep maximizing your billable time.',
              )}`
            : ''),
        discountHighlight: t('50% off {plan}', { plan: 'Grow' }),
        ctaAction: canAccessCheckout.value ? redirectToCheckout : contactSales,
      };
    }

    if (supportedGrowPlanIds.includes(pricePlanId.value)) {
      return {
        title: 'Grow',
        ctaText: t('Redeem now'),
        discountMessage:
          t('Time is running out... {discount} for 3 months, offer ends Friday 15 December.') +
          (featureTrialsActivated.value.length > 0
            ? ` ${t(
                'Once your trial ends you will lose access to our profitability reports, advanced resource scheduler, and scheduled reports. Upgrade to our Scale plan today so you can keep optimizing your business capacity and profitability.',
              )}`
            : ''),
        discountHighlight: t('50% off {plan}', { plan: 'Scale' }),
        ctaAction: contactSales,
      };
    }
    return '';
  });

  function closeBanner() {
    switch (bannerState.value) {
      case 'sale':
        blackFridayPromotionBannersDismissed.value = {
          ...blackFridayPromotionBannersDismissed.value,
          saleUpgradeBannerFinal: true,
        };
        trackEventE2304({ eventAction: 'feature_trial_sale_banner_dismissed' });
        return;
      case 'confirm':
        blackFridayPromotionBannersDismissed.value = {
          ...blackFridayPromotionBannersDismissed.value,
          confirmBanner: true,
        };
        trackEventE2304({ eventAction: 'feature_trial_activated_banner_displayed' });
        return;
      case 'remind':
        blackFridayPromotionBannersDismissed.value = {
          ...blackFridayPromotionBannersDismissed.value,
          remindBanner: true,
        };
        trackEventE2304({ eventAction: 'feature_trial_is_active_banner_dismissed' });
        break;
      default:
        break;
    }
  }

  return {
    featureTrialsBundle,
    bannerState,
    bannerInfo,
    planDiscounted,
    featureTrialsActivated,
    closeBanner,
    displayConfirmBanner,
    loading,
  };
}
