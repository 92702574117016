import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-23-63
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-63-budgets-ux-improvements/variations
 * https://digitalcrew.teamwork.com/spaces/growth/page/52244-a-23-62-budgets-ux-improvements
 */
export function useExperimentA63() {
  const { budgetsUxImprovementsEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const COMMON_METRICS = ['plan_name', 'user_role', 'page'];
  const { launchDarklyFlagKey, defaultValue } = budgetsUxImprovementsEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const isExpA63Variation = computed(() => budgetsUxImprovementsEnabled.value);

  function trackExperimentA63() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackUserRatesEvent(metadata) {
    trackPendoEvent({
      eventName: 'USER_RATES_EVENT',
      commonMetrics: COMMON_METRICS,
      metadata: {
        page_type: 'project_budget_rates',
        ...metadata,
      },
    });
  }

  function trackInfoBannerClosed() {
    const metadata = {
      event_action: 'info_banner_closed',
    };

    trackUserRatesEvent(metadata);
  }

  function trackUserRatesCellEvent(eventAction, eventLabel, projectSpecific) {
    const metadata = {
      event_action: eventAction,
      event_label: eventLabel,
      project_specific: projectSpecific,
    };

    trackUserRatesEvent(metadata);
  }

  function trackExpA63EditUserRateClicked(eventLabel, projectSpecific) {
    trackUserRatesCellEvent('edit_user_rate_clicked', eventLabel, projectSpecific);
  }

  function trackExpA63SetUserRateClicked(eventLabel, projectSpecific) {
    trackUserRatesCellEvent('set_user_rate_clicked', eventLabel, projectSpecific);
  }

  function trackExpA63UserRateSaved(eventLabel, projectSpecific) {
    trackUserRatesCellEvent('user_rate_saved', eventLabel, projectSpecific);
  }

  function trackExpA63DeleteUserRateClicked(eventLabel, projectSpecific) {
    trackUserRatesCellEvent('delete_user_rate_clicked', eventLabel, projectSpecific);
  }

  return {
    isExpA63Variation,
    trackExperimentA63,
    trackInfoBannerClosed,
    trackExpA63EditUserRateClicked,
    trackExpA63SetUserRateClicked,
    trackExpA63UserRateSaved,
    trackExpA63DeleteUserRateClicked,
  };
}
