<script setup>
const props = defineProps({
  message: {
    type: String,
    default: undefined,
  },
  entryPoint: {
    type: String,
    default: '',
  },
  planId: {
    type: Number,
    default: undefined,
  },
  feature: {
    type: String,
    default: undefined,
  },
  featureLimitReached: {
    type: Boolean,
    default: true,
  },
});

const ContactUpgradeRequestFormDialogPanel = defineAsyncComponent(
  () => import('./ContactUpgradeRequestFormDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog>
    <template #default="{ close }">
      <ContactUpgradeRequestFormDialogPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
