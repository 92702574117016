import { usePendo } from '../features/usePendo';
import { useFeatures } from '../features/useFeatures';
import { useCurrentAccount } from '../account/useCurrentAccount';

/**
 * Constants, computed properties and functions related to experiment A57
 * https://digitalcrew.teamwork.com/spaces/growth/page/50843-a-23-57-promote-grow-plan-during-trial
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-57-promote-grow-plan-during-trial/targeting
 */
export function useExperimentA57() {
  const { trackExperimentInPendo } = usePendo();
  const { promoteGrowPlanDuringTrialEnabled } = useFeatures();
  const { launchDarklyFlagKey, defaultValue } = promoteGrowPlanDuringTrialEnabled;
  const account = useCurrentAccount();
  const ignoreInExperimentOnRuleMatch = true;
  const EXP_A57_COMMON_METRICS = ['plan_name', 'user_role', 'page'];

  const GROW_PLAN_IDS = [48, 65, 69];

  const expA57AppLevelTargeting = computed(() => GROW_PLAN_IDS.includes(account.value?.pricePlanId));

  const isExpA57Variation = computed(() => promoteGrowPlanDuringTrialEnabled.value && expA57AppLevelTargeting.value);

  function trackExperimentA57() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA57AppLevelTargeting,
    });
  }

  return {
    trackExperimentA57,
    EXP_A57_COMMON_METRICS,
    isExpA57Variation,
  };
}
