export function useLsSelection({
  selection: _selection,
  mandatory: _mandatory = false,
  multiple: _multiple = false,
  limit: _limit = Infinity,
} = {}) {
  const selection = ref(_selection);
  const mandatory = ref(_mandatory);
  const multiple = ref(_multiple);
  const limit = ref(_limit);

  const isAtLimit = computed(() => {
    if (!multiple.value) {
      return false;
    }
    return selection.value?.length >= limit.value;
  });

  function isSelected(value) {
    return multiple.value ? selection.value?.includes(value) : selection.value === value;
  }

  function isDisabled(value) {
    return isAtLimit.value && !isSelected(value);
  }

  function shouldPreventToggle(value) {
    if (!mandatory.value) {
      return false;
    }
    if (multiple.value && selection.value?.length > 1) {
      return false;
    }
    return isSelected(value);
  }

  function toggle(value) {
    if (shouldPreventToggle(value)) {
      return;
    }

    if (multiple.value) {
      selection.value = isSelected(value)
        ? selection.value?.filter((v) => v !== value)
        : [...(Array.isArray(selection.value) ? selection.value : []), value];
    } else {
      selection.value = isSelected(value) ? null : value;
    }
  }

  return {
    selection,
    isSelected,
    isDisabled,
    toggle,
  };
}
