import { usePendo } from '../features/usePendo';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';

/**
 * Constants and functions related to experiment A27
 * https://digitalcrew.teamwork.com/spaces/growth/page/46171-a-23-27-measurement-doc
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-23-27-dummy-data/targeting
 */
export function useExperimentA27() {
  const EXP_A27_COMMON_METRICS = ['plan_name', 'user_role', 'page'];
  const EXP_A27_TEST_GROUP_EVENT_NAME = 'A-23-27_TEST_GROUP';

  const { trackPendoEvent, trackExperimentInPendo } = usePendo();
  const { isICP, isCompanySizeAboveTen } = useCohort();
  const { projectsDummyDataEnabled, projectsDummyDataV3Enabled } = useFeatures();

  const { launchDarklyFlagKey, defaultValue } = projectsDummyDataEnabled;

  const expA27AppLevelTargeting = computed(() => isICP.value && isCompanySizeAboveTen.value);

  const isExpA27Variation = computed(() => projectsDummyDataEnabled.value && expA27AppLevelTargeting.value);
  const isExpA27V3Variation = computed(() => projectsDummyDataV3Enabled.value && isExpA27Variation.value);

  function trackDummyDataEvent(eventAction) {
    trackPendoEvent({
      eventName: 'DUMMY_DATA_EVENT',
      metadata: { event_action: eventAction },
      commonMetrics: EXP_A27_COMMON_METRICS,
    });
  }

  function trackExperimentA27() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch: true,
      commonMetrics: EXP_A27_COMMON_METRICS,
      appLevelTargeting: expA27AppLevelTargeting,
    });
  }

  function trackExperimentA27Variation() {
    if (isExpA27Variation.value) {
      trackPendoEvent({
        eventName: EXP_A27_TEST_GROUP_EVENT_NAME,
        metadata: { event_action: 'entered_test_group_a-23-27' },
        commonMetrics: EXP_A27_COMMON_METRICS,
      });
    }
  }

  return {
    EXP_A27_COMMON_METRICS,
    trackDummyDataEvent,
    isExpA27V3Variation,
    isExpA27Variation,
    trackExperimentA27,
    trackExperimentA27Variation,
  };
}
