/**
 * @typedef {object} LscAvatar
 * @property {string=} src
 * @property {string=} name
 * @property {string=} alt
 * @property {string=} icon
 * @property {string=} bgColor
 * @property {string=} iconColor
 * @property {string=} textOverride
 */

export const LscAvatarSizes = /** @type {const} */ (['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']);

export const LscAvatarVariants = /** @type {const} */ (['default', 'on-surface']);
