import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function toApiBudgetPayload(formState) {
  const payload = {
    capacity: formState.value.capacity,
    budgetCategory:
      formState.value.budgetCategoryId === 'retainer' ? 'STANDARD' : formState.value.budgetCategoryId.toUpperCase(),
    projectId: formState.value.project.id || formState.value.project,
    startDateTime: formState.value.startDateTime?.toISODate(),
    type: formState.value.type.toUpperCase(),
    timelogType: formState.value.timelogType.toUpperCase(),
    isRepeating: formState.value.isRepeating,
    isRetainer: formState.value.isRetainer,
  };
  // if (Object.hasOwn(formState.value, 'startDateTime') && )
  if (Object.hasOwn(formState.value, 'endDateTime') && !formState.value?.isRepeating) {
    payload.endDateTime = formState.value.endDateTime?.plus({ days: 1 }).toISODate();
  }
  if (Object.hasOwn(formState.value, 'completedAt') && formState.value?.isRepeating) {
    payload.completedAt = formState.value.completedAt?.plus({ days: 1 }).toISODate();
  }
  if (Object.hasOwn(formState.value, 'budgetProfitMargin') && formState.value?.budgetProfitMargin) {
    payload.budgetProfitMargin = formState.value.budgetProfitMargin;
  }
  if (Object.hasOwn(formState.value, 'shouldUpdateProjectRate') && formState.value?.projectRate) {
    payload.projectRate = formState.value.projectRate;
  }
  if (Object.hasOwn(formState.value, 'repeatPeriod') && formState.value?.isRepeating) {
    payload.repeatPeriod = formState.value.repeatPeriod;
  }
  if (Object.hasOwn(formState.value, 'repeatUnit') && formState.value?.isRepeating) {
    payload.repeatUnit = formState.value.repeatUnit;
  }
  if (Object.hasOwn(formState.value, 'id') && formState.value?.id) {
    payload.id = formState.value.id;
  }
  if (Object.hasOwn(formState.value, 'carryUnderspend') && formState.value?.isRetainer) {
    payload.carryUnderspend = formState.value.carryUnderspend;
  }
  if (Object.hasOwn(formState.value, 'carryOverspend') && formState.value?.isRetainer) {
    payload.carryOverspend = formState.value.carryOverspend;
  }
  if (Object.hasOwn(formState.value, 'enableNotifications') && formState.value?.enableNotifications) {
    payload.notifications = formState.value.notifications.map((notification) => {
      return {
        capacityThreshold: notification.capacityThreshold,
        notificationMedium: notification.notificationMedium,
        companyIds: [],
        teamIds: notification.teams.map((team) => team.id),
        userIds: notification.users.map((notificationUser) => notificationUser.id),
      };
    });
  }
  return payload;
}

export function useProjectBudgetActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  return {
    createProjectBudget(formState) {
      const budget = toApiBudgetPayload(formState);
      const promise = api
        .post(
          '/projects/api/v3/projects/budgets.json',
          { budget },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to create a project budget'),
          },
        )
        .then(({ data: { budget: createdBudget } }) => {
          emitRealTimeUpdate({
            type: 'budget',
            action: 'new',
            budgetId: createdBudget.id,
          });
        });

      emitOptimisticUpdate({
        promise,
        type: 'budget',
        action: 'create',
        budget: {
          ...budget,
          id: -1,
          capacityUsed: 0,
        },
      });

      return promise;
    },
    updateProjectBudget(formState) {
      const budget = toApiBudgetPayload(formState);
      const promise = api
        .patch(
          `/projects/api/v3/projects/budgets/${budget.id}.json`,
          {
            budget: {
              ...budget,
              notifications: budget.notifications.map((notification) => {
                return {
                  id: notification.id,
                  capacityThreshold: notification.capacityThreshold,
                  notificationMedium: notification.notificationMedium,
                  userIds: notification.userIds,
                };
              }),
            },
          },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to update a project budget'),
          },
        )
        .then(({ data: { budget: updatedBudget } }) => {
          emitRealTimeUpdate({
            type: 'budget',
            action: 'updated',
            budgetId: updatedBudget.id,
          });
        });

      emitOptimisticUpdate({
        promise,
        type: 'budget',
        action: 'update',
        budget,
      });

      return promise;
    },
  };
}
