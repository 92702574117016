<script setup>
import { useElementBounding, syncRef } from '@vueuse/core';
import { useQuickView, DEFAULT_QUICK_VIEW_WIDTH } from './useQuickView';
import { useLegacyBridge } from '@/module/legacy';
import { useAppShellSidebar } from '@/appShell';

const props = defineProps({
  resizable: {
    type: Boolean,
    default: true,
  },
  attach: {
    type: String,
    default: 'body',
  },
});

const emit = defineEmits(['close']);

const SNAP_PADDING_WIDTH = 12;

const {
  quickViewWidth,
  isQuickViewExpanded,
  isQuickViewOpen,
  isQuickViewLocked,
  isQuickViewResizing,
  quickViewTransition,
  quickViewBounds,
} = useQuickView();
const { enableLegacyInteraction, disableLegacyInteraction } = useLegacyBridge();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();

const dragPositionX = shallowRef();

const containerEl = computed(() => document.querySelector(props.attach));
const {
  width: containerWidth,
  left: containerLeft,
  right: containerRight,
  top: containerTop,
} = useElementBounding(containerEl);

watchEffect(() => {
  if (!props.resizable || !dragPositionX.value || !containerWidth.value) {
    return;
  }
  const width = containerWidth.value - dragPositionX.value;
  quickViewWidth.value = width <= DEFAULT_QUICK_VIEW_WIDTH ? DEFAULT_QUICK_VIEW_WIDTH : width;
  isQuickViewLocked.value = dragPositionX.value <= SNAP_PADDING_WIDTH;
});

// Lock the quick view when it reaches the snap padding width
watch(
  [quickViewWidth, containerWidth],
  () => {
    if (!quickViewWidth.value || !containerWidth.value) {
      return;
    }
    isQuickViewLocked.value = quickViewWidth.value >= containerWidth.value - SNAP_PADDING_WIDTH;
  },
  { immediate: true },
);

const isDrawerOpen = computed({
  get: () => isQuickViewOpen.value,
  set: (value) => {
    if (!value) {
      emit('close');
    }
  },
});

watch(isDrawerOpen, (isOpen) => {
  if (isOpen) {
    clearActiveDrawerPanelIfNotPinned();
  }
});

// sync main content bounds with quick view bounds
syncRef(quickViewBounds.left, containerLeft);
syncRef(quickViewBounds.right, containerRight);
</script>

<template>
  <LscDrawer
    v-model="isDrawerOpen"
    :closeOnBack="false"
    :persistent="resizable && isQuickViewExpanded"
    :retainFocus="false"
    :noClickAnimation="true"
    :transition="quickViewTransition"
    :class="{
      '!z-[12]': resizable && isQuickViewLocked && quickViewWidth && containerWidth,
      'pointer-events-none select-none': isQuickViewResizing,
    }"
    :style="{ top: `${containerTop}px` }"
  >
    <LscDrawerCard :size="`${resizable ? quickViewWidth : DEFAULT_QUICK_VIEW_WIDTH}px`">
      <template v-if="resizable" #expandButton>
        <LscResizer
          v-model:position="dragPositionX"
          v-model:dragging="isQuickViewResizing"
          :container="containerEl"
          @dragStart="disableLegacyInteraction"
          @dragEnd="enableLegacyInteraction"
        />
      </template>
      <template #container>
        <slot v-if="isDrawerOpen" />
      </template>
    </LscDrawerCard>
  </LscDrawer>
</template>
