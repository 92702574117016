<script setup>
import { useI18n } from '@/util';
import { useDrawer } from './useDrawer';

/**
 * @typedef {typeof import('./constants').LscDrawerSizes[number]} LscDrawerSizes
 */

const props = defineProps({
  /**
   * The size of the drawer.
   * @type {PropType<LscDrawerSizes | string>}
   */
  size: {
    type: String,
    default: 'md',
  },
  title: {
    type: String,
    default: '',
  },
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
  infoTooltip: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();

const LscDrawerCardVariantStyleConfig = tv({
  base: 'relative flex h-full',
  slots: {
    container: 'flex h-full min-h-0 flex-col bg-default shadow-4',
    header: 'flex grow-0 items-center gap-2 border-b border-separator px-8 py-4',
    title: 'grow-0 text-h4 font-semibold',
    infoTooltip: 'shrink-0 text-icon-subtle',
    main: 'flex min-h-0 flex-1 flex-col items-stretch',
    content: 'flex-auto overflow-y-auto overflow-x-hidden px-8 py-4 [scrollbar-gutter:stable]',
    actions: 'flex grow-0 items-center gap-2 border-t border-separator px-8 py-4',
  },
  variants: {
    size: {
      xs: {
        container: 'w-[--lsds-c-drawer-size-xs]',
        header: 'px-4 py-2',
        title: 'text-subtitle-2',
        content: 'px-4 py-2',
        actions: 'px-4 py-2',
      },
      sm: {
        container: 'w-[--lsds-c-drawer-size-sm]',
      },
      md: {
        container: 'w-[--lsds-c-drawer-size-md]',
      },
      lg: {
        container: 'w-[--lsds-c-drawer-size-lg]',
      },
    },
    customWidth: {
      true: {
        container: 'w-[--lsc-drawer-card-custom-width]',
      },
    },
  },
});

const buttonSize = computed(() => {
  if (['xs'].includes(props.size)) {
    return 'sm';
  }
  return 'md';
});

const customWidth = computed(() => {
  if (['xs', 'sm', 'md', 'lg'].includes(props.size)) {
    return undefined;
  }
  // Otherwise, use the size as a string
  return props.size;
});

const classes = computed(() =>
  LscDrawerCardVariantStyleConfig({
    customWidth: Boolean(customWidth.value),
    size: props.size,
  }),
);

const { close } = useDrawer();
</script>

<template>
  <div :class="classes.base()" :style="{ '--lsc-drawer-card-custom-width': customWidth }">
    <slot name="expandButton" />
    <div :class="classes.container()">
      <slot name="container">
        <div :class="classes.header()">
          <slot name="prependHeaderLeft" :buttonSize="buttonSize" />
          <slot name="title">
            <LscOverflowEllipsis
              is="h4"
              :class="classes.title()"
              :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-title` : undefined"
            >
              {{ title }}
            </LscOverflowEllipsis>
          </slot>
          <slot name="appendHeaderLeft" :buttonSize="buttonSize">
            <LscIcon
              v-if="infoTooltip"
              v-LsdTooltip="infoTooltip"
              :class="classes.infoTooltip()"
              icon="lsi-tooltip"
              size="sm"
            />
          </slot>

          <div class="flex-auto" />

          <slot name="prependHeaderRight" :buttonSize="buttonSize" />
          <LscIconButton
            v-LsdTooltip="t('Close')"
            class="shrink-0"
            :size="buttonSize"
            variant="primary"
            icon="lsi-close"
            :ariaLabel="t('Close')"
            @click="close"
          />
          <slot name="appendHeaderRight" :buttonSize="buttonSize" />
        </div>

        <div :class="classes.main()">
          <slot name="content">
            <div :class="classes.content()">
              <slot />
            </div>
          </slot>
        </div>

        <div v-if="$slots.actions" :class="classes.actions()"><slot name="actions" /></div>
      </slot>
    </div>
  </div>
</template>
