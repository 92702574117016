<script setup>
import { STEP_GETTING_STARTED } from '../constants';
import { useI18n } from '@/util';
import { useProductOutcomesV3Loader } from '@/api';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonChoice from '../common/OnboardingWizardCommonChoice.vue';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_GETTING_STARTED]: stateData } = props.state;

const { t } = useI18n();
const { trackGettingStartedStepContinueWithZeroSelection } = useOnboardingWizardTracking();

const outcomesState = useProductOutcomesV3Loader({ count: Infinity });

const choiceIconsMap = {
  1: {
    id: 1,
    displayLabel: t('Manage projects'),
    icon: 'lsi-project',
  },
  2: {
    id: 2,
    displayLabel: t('Working with clients'),
    icon: 'lsi-group',
  },
  3: {
    id: 3,
    displayLabel: t('Tracking billable time'),
    icon: 'lsi-time-reminder',
  },
  4: {
    id: 4,
    displayLabel: t('Managing team capacity'),
    icon: 'lsi-date',
  },
  5: {
    id: 5,
    displayLabel: t('Billing clients for work'),
    icon: 'lsi-billable',
  },
  6: {
    id: 6,
    displayLabel: t('Reporting of profitability'),
    icon: 'lsi-growth',
  },
};

const productOutcomes = computed(() =>
  outcomesState.items.value.map((outcome) => ({
    ...outcome,
    ...choiceIconsMap[outcome.id],
  })),
);

const selectedItems = shallowRef(stateData?.selectedItems ?? []);
const showSelectionWarning = shallowRef(stateData?.alertShown ?? false);
const alertShown = shallowRef(false);

function hasChanged() {
  return !stateData || stateData?.selectedItems?.filter((x) => !selectedItems.value.includes(x))?.length !== 0;
}

function nextStep() {
  const productOutcomesSelected = selectedItems.value.map((itemId) => {
    const matchedProduct = productOutcomes.value.find((product) => product.id === itemId);
    return matchedProduct ? matchedProduct.description : null;
  });

  if (selectedItems.value.length === 0 && !showSelectionWarning.value) {
    trackGettingStartedStepContinueWithZeroSelection();
    showSelectionWarning.value = true;
    alertShown.value = true;
    return;
  }

  emit('nextStep', hasChanged(), {
    selectedItems: selectedItems.value,
    alertShown: alertShown.value,
    outcomesSelected: productOutcomesSelected.length === 0 ? 'none' : productOutcomesSelected.join(' | '),
    outcomesSelectedCount: selectedItems.value.length,
  });
}
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Help us personalize your experience...')"
    :description="t('What do you want to do with {teamwork}? Select all that apply', { teamwork: 'Teamwork.com' })"
  >
    <WidgetLoadingState :state="outcomesState" :errorMessage="t('Unable to load options')">
      <LscChoiceList v-model="selectedItems" :multiple="true" :horizontal="true" class="flex-wrap gap-4">
        <OnboardingWizardCommonChoice
          v-for="outcome in productOutcomes"
          :key="outcome.id"
          :label="outcome.displayLabel"
          :icon="outcome.icon"
          :value="outcome.id"
          hasCheck
          class="w-full sm:max-w-[--onboarding-wizard-flex-basis-third-with-gap] sm:basis-[--onboarding-wizard-flex-basis-third-with-gap]"
          @click="showSelectionWarning = false"
        />
      </LscChoiceList>

      <section v-if="showSelectionWarning && selectedItems.length == 0" class="pt-4 text-critical">
        {{
          t(
            'Telling us how you plan to work with {teamwork} will help us to provide you with a better trial experience. Are you sure you want to continue without making a selection?',
            { teamwork: 'Teamwork.com' },
          )
        }}
      </section>

      <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />
    </WidgetLoadingState>
  </OnboardingWizardCommonStep>
</template>
