import { DateTime } from 'luxon';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useListLoader } from '../base/useListLoader';
import { useCurrentUser } from '../user/useCurrentUser';

/* eslint-disable no-param-reassign */
function responseToItems(response) {
  const { timers = [], included = {} } = response.data;
  const { projects = {}, tasks = {}, tasklists = {} } = included;

  timers.forEach((timer) => {
    timer.createdAt = timer.createdAt ? DateTime.fromISO(timer.createdAt) : null;
    timer.updatedAt = timer.updatedAt ? DateTime.fromISO(timer.updatedAt) : null;
    timer.isBillable = timer.isBillable ?? timer.billable;
    timer.serverTime = DateTime.fromISO(timer.serverTime);
    // Correct 'lastStartedAt' by offsetting local machine time against server time to address potential time manipulation.
    const secondsOffset = timer.serverTime.diffNow().as('seconds');
    timer.lastStartedAt = DateTime.fromISO(timer.lastStartedAt).minus({
      seconds: Math.abs(secondsOffset) >= 1 ? secondsOffset : 0,
    });

    if (timer.projectId && projects[timer.projectId]) {
      timer.project = projects[timer.projectId];
    }

    if (timer.taskId && tasks[timer.taskId]) {
      timer.task = tasks[timer.taskId];

      if (timer.task.tasklistId && tasklists[timer.task.tasklistId]) {
        timer.task.tasklist = tasklists[timer.task.tasklistId];

        if (timer.task.tasklist.projectId && projects[timer.task.tasklist.projectId]) {
          timer.task.tasklist.project = projects[timer.task.tasklist.projectId];
        }
      }
    }
  });

  return timers;
}
/* eslint-enable no-param-reassign */

/**
 * Loads a list of timers from the Teamwork v3 API.
 */
export function useTimersV3Loader({ params, count, pageSize } = {}) {
  const url = computed(() => '/projects/api/v3/me/timers.json');
  const currentUser = useCurrentUser();
  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'timer') {
      update((timers) => {
        if (event.action === 'update') {
          const updatedTimerRunning = event.timer.running;
          return timers.map((timer) => {
            if (timer.id === event.timer.id) {
              return {
                ...timer,
                ...event.timer,
              };
            }
            // If the updated timer is running, stop any other timer that is running.
            if (updatedTimerRunning && timer.running) {
              return {
                ...timer,
                duration: timer.duration + Math.floor(DateTime.now().diff(timer.lastStartedAt).as('seconds')),
                running: false,
                lastStartedAt: DateTime.now(),
              };
            }
            return timer;
          });
        }

        if (event.action === 'delete') {
          return timers.filter((timer) => timer.id !== event.timer.id);
        }

        if (event.action === 'create') {
          return timers.concat(event.timer);
        }

        return timers;
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'timer' && event.userId === currentUser.value.id) {
      refresh();
    }
  });

  return state;
}
