<script setup>
import { useI18n } from '@/util';
import NotificationDrawerEmptyStateRead from './assets/NotificationDrawerEmptyStateRead.svg';

const { t } = useI18n();
</script>

<template>
  <LscEmptyState class="h-full" :title="t('All quiet for now')" :message="t('You do not have any read notifications')">
    <template #image>
      <NotificationDrawerEmptyStateRead />
    </template>
  </LscEmptyState>
</template>
