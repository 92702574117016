import { DateTime } from 'luxon';

import { hydrateMeta, hydrateProperty } from './hydrate';
import { normalizeProofObject } from './normalizeProofObject.js';

export function normalizeProof(proof, included) {
  const p = { ...proof };

  hydrateMeta(p, included);

  hydrateProperty(p, 'parent', included);

  // Special case: add property to ensure project permissions are available (separate include)
  p.parent.permissions = included.projectPermissions?.[p.parent.id];

  hydrateProperty(p, 'entity', included);

  hydrateProperty(p, 'company', included);

  p.objects = p.objects.map((object) => {
    return normalizeProofObject(object, included);
  });

  p.assignees = p.assignees.map((assignee) => {
    // eslint-disable-next-line no-underscore-dangle
    const _assignee = assignee;

    hydrateProperty(_assignee, 'user', included);

    return _assignee;
  });

  p.due = DateTime.fromISO(proof.due);

  return p;
}
