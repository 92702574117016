export const reportBuilderSampleData = [
  {
    activeProjects: 4,
    actualUtilization: 88,
    availableTime: 2400,
    billableTime: 1800,
    billableTotal: 3000,
    billableUtilization: 75,
    completedTasks: 10,
    loggedTimeVsEstimatedTime: -120,
    estimatedTime: 2400,
    estimatedUtilization: 100,
    health: 3,
    loggedTime: 2100,
    nonBillableTime: 2000,
    nonBillableUtilization: 13,
    overdueTasks: 0,
    profitNumber: 1775,
    profitPercentage: 78,
    numberOfActiveTasks: 40,
    taskCompletion: 45,
    taskTotal: 50,
    timeEstimate: 240,
    timeLeft: 56,
    timeLogged: 2100,
    totalCost: 1225,
    unavailableTime: 0,
    availableTimePercentage: 34,
    totalWorkingHours: 44,
    unavailableTimePercentage: 22,
    billableTimePercentage: 42,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 54,
    estimatedBillableUtilization: 100,
    jobRole: 'Developer',
    numberOfClients: 7,
    allocatedTimePercentage: 80,
  },
  {
    activeProjects: 2,
    actualUtilization: 80,
    availableTime: 2250,
    billableTime: 900,
    billableTotal: 1500,
    billableUtilization: 40,
    completedTasks: 4,
    loggedTimeVsEstimatedTime: 4,
    estimatedTime: 1200,
    estimatedUtilization: 1500,
    health: 3,
    loggedTime: 2100,
    nonBillableTime: 2000,
    nonBillableUtilization: 13,
    overdueTasks: 0,
    profitNumber: 975,
    profitPercentage: 45,
    numberOfActiveTasks: 15,
    taskCompletion: 45,
    tasksCompleted: 10,
    taskTotal: 25,
    timeEstimate: 240,
    timeLeft: 7,
    timeLogged: 2100,
    totalCost: 525,
    unavailableTime: 0,
    availableTimePercentage: 32,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Marketing',
    numberOfClients: 2,
    allocatedTimePercentage: 53,
  },
  {
    activeProjects: 6,
    actualUtilization: 75,
    availableTime: 1600,
    billableTime: 1200,
    billableTotal: 1500,
    billableUtilization: 68,
    completedTasks: 6,
    loggedTimeVsEstimatedTime: 30,
    estimatedTime: 1600,
    estimatedUtilization: 100,
    health: 0,
    loggedTime: 2100,
    nonBillableTime: 2000,
    nonBillableUtilization: 13,
    overdueTasks: 0,
    profitNumber: 1650,
    profitPercentage: 89,
    numberOfActiveTasks: 40,
    taskCompletion: 20,
    tasksCompleted: 6,
    taskTotal: 10,
    timeEstimate: 90,
    timeLeft: 4,
    timeLogged: 2100,
    totalCost: 1050,
    unavailableTime: 0,
    availableTimePercentage: 44,
    totalWorkingHours: 353,
    unavailableTimePercentage: 23,
    billableTimePercentage: 35,
    estimatedNonBillableUtilization: 65,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 77,
    jobRole: 'Designer',
    numberOfClients: 3,
    allocatedTimePercentage: 70,
  },
  {
    activeProjects: 7,
    actualUtilization: 95,
    availableTime: 1600,
    billableTime: 2700,
    billableTotal: 2500,
    billableUtilization: 88,
    completedTasks: 15,
    loggedTimeVsEstimatedTime: 120,
    estimatedTime: 2400,
    estimatedUtilization: 100,
    health: 0,
    loggedTime: 2100,
    nonBillableTime: 2000,
    nonBillableUtilization: 13,
    overdueTasks: 9,
    profitNumber: 2150,
    profitPercentage: 32,
    numberOfActiveTasks: 10,
    taskCompletion: 0,
    tasksCompleted: 15,
    taskTotal: 45,
    timeEstimate: 240,
    timeLeft: 21,
    timeLogged: 2100,
    totalCost: 1330,
    unavailableTime: 0,
    availableTimePercentage: 98,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Marketing',
    numberOfClients: 3,
    allocatedTimePercentage: 90,
  },
  {
    activeProjects: 3,
    actualUtilization: 93,
    availableTime: 2250,
    billableTime: 1800,
    billableTotal: 3000,
    billableUtilization: 75,
    completedTasks: 67,
    loggedTimeVsEstimatedTime: 120,
    estimatedTime: 2100,
    estimatedUtilization: 93,
    health: 2,
    loggedTime: 2100,
    nonBillableTime: 2000,
    nonBillableUtilization: 13,
    overdueTasks: 6,
    profitNumber: 1775,
    profitPercentage: 98,
    numberOfActiveTasks: 40,
    taskCompletion: 45,
    tasksCompleted: 67,
    taskTotal: 100,
    timeEstimate: 60,
    timeLeft: 56,
    timeLogged: 1800,
    totalCost: 1225,
    unavailableTime: 90,
    availableTimePercentage: 44,
    totalWorkingHours: 353,
    unavailableTimePercentage: 23,
    billableTimePercentage: 35,
    estimatedNonBillableUtilization: 65,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 77,
    jobRole: 'Designer',
    numberOfClients: 5,
    allocatedTimePercentage: 80,
  },
  {
    activeProjects: 3,
    actualUtilization: 40,
    availableTime: 1500,
    billableTime: 600,
    billableTotal: 1000,
    billableUtilization: 40,
    completedTasks: 3,
    loggedTimeVsEstimatedTime: -200,
    estimatedTime: 1500,
    estimatedUtilization: 100,
    health: 3,
    loggedTime: 600,
    nonBillableTime: 0,
    nonBillableUtilization: 0,
    overdueTasks: 0,
    profitNumber: 650,
    profitPercentage: 100,
    numberOfActiveTasks: 10,
    taskCompletion: 27,
    tasksCompleted: 3,
    taskTotal: 30,
    timeEstimate: 30,
    timeLeft: 28,
    timeLogged: 1800,
    totalCost: 350,
    unavailableTime: 1500,
    availableTimePercentage: 50,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Developer',
    numberOfClients: 1,
    allocatedTimePercentage: 80,
  },
  {
    activeProjects: 4,
    actualUtilization: 88,
    availableTime: 2400,
    billableTime: 1800,
    billableTotal: 3000,
    billableUtilization: 75,
    completedTasks: 16,
    loggedTimeVsEstimatedTime: 500,
    estimatedTime: 1600,
    estimatedUtilization: 100,
    health: 0,
    loggedTime: 2100,
    nonBillableTime: 3000,
    nonBillableUtilization: 13,
    overdueTasks: 0,
    profitNumber: 1775,
    profitPercentage: 100,
    numberOfActiveTasks: 10,
    taskCompletion: 4,
    tasksCompleted: 16,
    taskTotal: 64,
    timeEstimate: 4800,
    timeLeft: 84,
    timeLogged: 0,
    totalCost: 1225,
    unavailableTime: 0,
    availableTimePercentage: 50,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Developer',
    numberOfClients: 8,
    allocatedTimePercentage: 55,
  },
  {
    activeProjects: 8,
    actualUtilization: 95,
    availableTime: 2400,
    billableTime: 2280,
    billableTotal: 3800,
    billableUtilization: 95,
    completedTasks: 8,
    loggedTimeVsEstimatedTime: 60,
    estimatedTime: 2400,
    estimatedUtilization: 100,
    health: 0,
    loggedTime: 2280,
    nonBillableTime: 0,
    nonBillableUtilization: 0,
    overdueTasks: 7,
    profitNumber: 2470,
    profitPercentage: 90,
    numberOfActiveTasks: 8,
    taskCompletion: 12,
    tasksCompleted: 8,
    taskTotal: 16,
    timeEstimate: 240,
    timeLeft: -2,
    timeLogged: 2280,
    totalCost: 1330,
    unavailableTime: 0,
    availableTimePercentage: 50,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Developer',
    numberOfClients: 3,
    allocatedTimePercentage: 75,
  },
  {
    activeProjects: 3,
    actualUtilization: 40,
    availableTime: 1500,
    billableTime: 560,
    billableTotal: 900,
    billableUtilization: 36,
    completedTasks: 7,
    loggedTimeVsEstimatedTime: 30,
    estimatedTime: 1500,
    estimatedUtilization: 100,
    health: 1,
    loggedTime: 600,
    nonBillableTime: 60,
    nonBillableUtilization: 4,
    overdueTasks: 0,
    profitNumber: 550,
    profitPercentage: 75,
    numberOfActiveTasks: 30,
    taskCompletion: 3,
    tasksCompleted: 7,
    taskTotal: 28,
    timeEstimate: 600,
    timeLeft: 7,
    timeLogged: 600,
    totalCost: 350,
    unavailableTime: 900,
    availableTimePercentage: 50,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Developer',
    numberOfClients: 3,
    allocatedTimePercentage: 60,
  },
  {
    activeProjects: 4,
    actualUtilization: 88,
    availableTime: 2400,
    billableTime: 1800,
    billableTotal: 3000,
    billableUtilization: 75,
    completedTasks: 10,
    loggedTimeVsEstimatedTime: 0,
    estimatedTime: 2400,
    estimatedUtilization: 100,
    health: 2,
    loggedTime: 2100,
    nonBillableTime: 2000,
    nonBillableUtilization: 13,
    overdueTasks: 0,
    profitNumber: 1775,
    profitPercentage: 56,
    numberOfActiveTasks: 40,
    taskCompletion: 45,
    tasksCompleted: 10,
    taskTotal: 50,
    timeEstimate: 240,
    timeLeft: 56,
    timeLogged: 2100,
    totalCost: 1225,
    unavailableTime: 0,
    availableTimePercentage: 50,
    totalWorkingHours: 10,
    unavailableTimePercentage: 50,
    billableTimePercentage: 50,
    estimatedNonBillableUtilization: 140,
    utilizationPercentTarget: 60,
    estimatedBillableUtilization: 100,
    jobRole: 'Developer',
    numberOfClients: 6,
    allocatedTimePercentage: 95,
  },
];
export const reportBuilderSampleDataTotalRow = {
  activeProjects: 47,
  actualUtilization: 73,
  availableTime: 17450,
  billableTime: 16040,
  billableTotal: 22900,
  billableUtilization: 61,
  completedTasks: 121,
  loggedTimeVsEstimatedTime: 574,
  estimatedTime: 16700,
  estimatedUtilization: 1076,
  loggedTime: 19880,
  nonBillableTime: 12060,
  nonBillableUtilization: 84,
  overdueTasks: 22,
  profitNumber: 17095,
  profitPercentage: 87,
  numberOfActiveTasks: 40,
  taskCompletion: 80,
  tasksCompleted: 129,
  taskTotal: 353,
  timeEstimate: 1390,
  timeLeft: 259,
  totalCost: 11585,
  unavailableTime: 3390,
  availableTimePercentage: 50,
  totalWorkingHours: 10,
  unavailableTimePercentage: 50,
  billableTimePercentage: 50,
  estimatedNonBillableUtilization: 140,
  utilizationPercentTarget: 60,
  estimatedBillableUtilization: 100,
  jobRole: null,
  numberOfClients: 6,
  allocatedTimePercentage: 60,
};

export const reportBuilderFilterApiMapping = {
  milestone: {
    companyIds: { filterName: 'projectCompanyIds', convertToInt: true },
    projectIds: { filterName: 'projectIds', convertToInt: true },
    status: { filterName: 'milestoneStatuses', convertToInt: false },
    teamIds: { filterName: 'teamIds', convertToInt: true },
    assigneeUserIds: { filterName: 'assigneeUserIds', convertToInt: true },
  },
  project: {
    categoryIds: { filterName: 'projectCategoryIds', convertToInt: true },
    companyIds: { filterName: 'projectCompanyIds', convertToInt: true },
    ownerId: { filterName: 'projectOwnerIds', convertToInt: true },
    projectIds: { filterName: 'projectIds', convertToInt: true },
    status: { filterName: 'projectStatuses', convertToInt: false },
    tagIds: { filterName: 'projectTagIds', convertToInt: true },
    userIds: { filterName: 'projectOwnerIds', convertToInt: true },
  },
  task: {
    categoryIds: { filterName: 'projectCategoryIds', convertToInt: true },
    companyIds: { filterName: 'projectCompanyIds', convertToInt: true },
    projectIds: { filterName: 'projectIds', convertToInt: true },
    status: { filterName: 'taskStatuses', convertToInt: false },
    tagIds: { filterName: 'tagIds', convertToInt: true },
    tasklists: { filterName: 'tasklistIds', convertToInt: true },
    teamIds: { filterName: 'teamIds', convertToInt: true },
    userIds: { filterName: 'assigneeUserIds', convertToInt: true },
  },
  user: {
    companyIds: { filterName: 'companyIds', convertToInt: true },
    projectIds: { filterName: 'projectIds', convertToInt: true },
    teamIds: { filterName: 'teamIds', convertToInt: true },
    userIds: { filterName: 'userIds', convertToInt: true },
    jobRoleIds: { filterName: 'jobRoleIds', convertToInt: true },
  },
};

// maps from ALL_DATE_OPTIONS from twa useReportDates
export const reportBuilderDatePeriods = {
  day: 8,
  week: 0,
  month: 2,
  quarter: 4,
  custom: 8,
};
