import plugin from 'tailwindcss/plugin.js';

/**
 * A Tailwind CSS plugin to style the scrollbar.
 * @param {object} options
 * @param {string} options.className
 * @param {'thin' | 'auto' | 'none'} options.scrollbarWidth
 * @param {'base'|'utitlity'} options.layer
 * @param {string} options.scrollbarColor
 */
export function scrollbarPlugin({ className, scrollbarWidth, scrollbarColor, layer = 'utitlity' } = {}) {
  const webkitWidth = scrollbarWidth === 'thin' ? 'var(--lsds-g-spacing-3)' : 'var(--lsds-g-spacing-4)';
  return plugin((utils) => {
    const tailwindFn = layer === 'base' ? utils.addBase : utils.addUtilities;
    tailwindFn({
      [className]: {
        scrollbarWidth,
        scrollbarColor: 'transparent transparent',
        '&:hover': {
          scrollbarColor: `${scrollbarColor} transparent`,
        },
        '@supports selector(::-webkit-scrollbar)': {
          '&::-webkit-scrollbar': {
            width: webkitWidth,
            height: webkitWidth,
          },
          '&::-webkit-scrollbar-thumb': {
            border: `var(--lsds-g-spacing-1) solid rgba(0,0,0,0)`,
            borderRadius: 'var(--lsds-g-border-rounded-full)',
            backgroundClip: 'padding-box',
            backgroundColor: scrollbarColor,
            visibility: 'hidden',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            visibility: 'visible',
          },
          '&:hover::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'var(--lsds-c-scrollbar-color-on-dark-hover)',
            visibility: 'visible',
          },
        },
      },
    });
  });
}
