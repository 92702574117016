import { useAxios } from './useAxios';

export function useAxiosResponseInterceptor(fulfilled, rejected, options) {
  const axios = useAxios();

  const id = axios.interceptors.response.use(fulfilled, rejected, options);

  function stop() {
    axios.interceptors.response.eject(id);
  }

  onScopeDispose(stop);

  return stop;
}
