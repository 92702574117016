<script setup>
import { generateUuid, isMacOS } from '@/util';

const props = defineProps({
  /**
   * The ID of the form.
   * @type {PropType<string>}
   */
  id: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['submit']);
const formRef = shallowRef(null);

const id = computed(() => props.id || generateUuid());

function handleKeyboardSubmit(event) {
  // Submit the form on Ctrl/Meta+Enter.
  if (
    event.key === 'Enter' &&
    event.metaKey === isMacOS &&
    event.ctrlKey !== isMacOS &&
    !event.altKey &&
    !event.shiftKey
  ) {
    formRef.value.requestSubmit();
  }
}
</script>

<template>
  <form :id="id" ref="formRef" @keydown="handleKeyboardSubmit" @submit.prevent="emit('submit', $event)">
    <slot :id="id" />
  </form>
</template>
