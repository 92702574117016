<script setup>
import { useRoute, useQuickViewRoute } from './useRoute';
import { provideCurrentProjectState } from './useCurrentProject';
import { useCurrentProjectLoader } from './useCurrentProjectLoader';
import RouteSetupCurrentProjectBudget from './RouteSetupCurrentProjectBudget.vue';
import RouteSetupCurrentProjectPermissions from './RouteSetupCurrentProjectPermissions.vue';
import RouteSetupCurrentProjectNavigation from './RouteSetupCurrentProjectNavigation.vue';

const props = defineProps({
  routeType: {
    type: String,
    default: 'mainView',
    validator: (value) => ['mainView', 'quickView'].includes(value),
  },
  itemType: {
    type: String,
    default: undefined,
  },
  itemId: {
    type: Number,
    default: undefined,
  },
});

const itemType = computed(() => props.itemType);
const itemId = computed(() => props.itemId);
const quickViewRoute = useQuickViewRoute();
const route = useRoute();

if (itemType.value && itemId.value) {
  provideCurrentProjectState(useCurrentProjectLoader({ itemType, itemId, route }));
} else if (props.routeType === 'quickView') {
  provideCurrentProjectState(useCurrentProjectLoader({ route: quickViewRoute }));
} else if (props.routeType === 'mainView') {
  provideCurrentProjectState(useCurrentProjectLoader({ route }));
}
</script>
<template>
  <RouteSetupCurrentProjectBudget>
    <RouteSetupCurrentProjectPermissions>
      <RouteSetupCurrentProjectNavigation>
        <slot />
      </RouteSetupCurrentProjectNavigation>
    </RouteSetupCurrentProjectPermissions>
  </RouteSetupCurrentProjectBudget>
</template>
