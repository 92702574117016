<script setup>
import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import { useDatePicker } from './useDatePicker';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  /**
   * The date to display as a Luxon DateTime object.
   * @type {PropType<DateTime>}
   */
  modelValue: {
    type: Object,
    default: undefined,
    validator: (value) => value === undefined || DateTime.isDateTime(value),
  },
  isStartDate: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'blur', 'focus']);

const { clearable, startTextField, endTextField } = useDatePicker();

const { parseDate, formatDate, t } = useI18n();

const formattedDate = shallowRef(formatDate(props.modelValue));
const isTyping = shallowRef(false);

function setTextFieldRef(el) {
  if (props.isStartDate) {
    startTextField.value = el;
  } else {
    endTextField.value = el;
  }
}

async function onFocus(event) {
  emit('focus', event);
}

function onBlur(event) {
  isTyping.value = false;
  formattedDate.value = formatDate(props.modelValue);
  emit('blur', event);
}

function onUpdateModelValue() {
  isTyping.value = true;
  if (!formattedDate.value) {
    emit('update:modelValue', undefined);
    return;
  }
  const parsedDate = parseDate(formattedDate.value);

  if (parsedDate.isValid && parsedDate !== props.modelValue) {
    emit('update:modelValue', parsedDate);
  }
}

function onClear() {
  formattedDate.value = '';
  emit('update:modelValue', undefined);
}

watch(
  () => props.modelValue,
  () => {
    if (!isTyping.value && parseDate(formattedDate.value) !== props.modelValue) {
      formattedDate.value = formatDate(props.modelValue);
    }
  },
);
</script>

<template>
  <VTextField
    :ref="setTextFieldRef"
    v-model="formattedDate"
    v-bind="$attrs"
    @blur="onBlur"
    @focus="onFocus"
    @update:modelValue="onUpdateModelValue"
  >
    <template #append-inner>
      <LscIconButton
        v-if="formattedDate && clearable"
        size="xs"
        class="-mr-1"
        icon="lsi-close"
        :ariaLabel="t('Clear date')"
        @click="onClear"
      />
    </template>
  </VTextField>
</template>
