<script setup>
import useVuelidate from '@vuelidate/core';
import { useExperimentA2301 } from '@/api';
import { capitalizeFirstLetter, useI18n, useValidators } from '@/util';
import {
  PREVIEW_TAB_TABLE,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_PEOPLE,
  STEP_ADD_TASKS,
  STEP_CLIENT_PROJECT,
  STEP_SELECT_VIEW,
  SAMPLE_USER_TASKS_DATA,
} from '../constants';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  nextButtonText: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['nextStep']);

const {
  [STEP_CLIENT_PROJECT]: stateData,
  [STEP_ADD_TASKS]: tasksState,
  [STEP_SELECT_VIEW]: selectViewState,
} = props.state;

const { t } = useI18n();
const { requiredIf, maxLength } = useValidators();
const { trackProjectTypeSelected } = useExperimentA2301();

const clientProject = shallowRef(stateData?.clientProject === undefined ? 1 : Number(stateData?.clientProject));
const companyName = shallowRef(stateData?.name);
const inputEl = shallowRef(null);

const rules = {
  companyName: {
    requiredIf: requiredIf(() => Boolean(clientProject.value)),
    maxLength: maxLength(255),
  },
};
const v$ = useVuelidate(rules, { companyName }, { $autoDirty: false });

const tasksData = computed(() => {
  const combinedTasks = SAMPLE_USER_TASKS_DATA.tasklists.map((tasklist) => ({
    ...tasklist,
    tasks: tasklist.tasks.map((task, taskIndex) => ({
      ...task,
      name: tasksState?.tasksList[taskIndex] ?? '',
    })),
  }));

  return {
    ...SAMPLE_USER_TASKS_DATA,
    tasklists: combinedTasks,
  };
});

function hasDataChanged() {
  return (
    !stateData || stateData?.name !== companyName.value || stateData?.clientProject !== Boolean(clientProject.value)
  );
}

function nextStep() {
  v$.value.$touch();

  if (!v$.value.$error) {
    trackProjectTypeSelected(clientProject.value ? 'client' : 'normal');

    emit('nextStep', hasDataChanged(), {
      name: companyName.value,
      clientProject: Boolean(clientProject.value),
    });
  }
}

watch(clientProject, async (newValue, oldValue) => {
  if (!newValue) {
    companyName.value = undefined;
  }

  if (inputEl.value && !oldValue && newValue) {
    v$.value.$reset();
    await nextTick();
    inputEl.value.focus();
  }
});

watch(companyName, (newValue) => {
  if (newValue) {
    companyName.value = capitalizeFirstLetter(newValue);
  }
});

onMounted(() => {
  if (!companyName.value) {
    inputEl.value.focus();
  }
});
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Is this a client project?')"
    :description="t('Add your client\'s company name below')"
  >
    <LscChoiceList v-model="clientProject" :multiple="false" mandatory horizontal>
      <LscChoice hasCheck icon="lsi-client" :value="1" :label="t('Yes')" />
      <LscChoice hasCheck icon="lsi-client" :value="0" :label="t('No')" />
    </LscChoiceList>

    <VTextField
      v-show="clientProject"
      ref="inputEl"
      v-model.trim="companyName"
      class="mt-4"
      :label="t('Add company name')"
      :placeholder="t('Company name')"
      :errorMessages="v$.companyName.$errors.map((error) => error.$message)"
      :maxLength="255"
      :autoFocus="true"
    />
    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />
    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="selectViewState.name"
        :clientCompanyName="companyName"
        :preselectedTab="selectViewState.view"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_PEOPLE]"
        :tabsClickable="false"
        :tasksData="tasksData"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
