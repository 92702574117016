<script setup>
import ProjectJumpToDrawerProjectsInCategory from './ProjectJumpToDrawerProjectsInCategory.vue';
import { useI18n } from '@/util';
import { useProjectJumpToDrawer } from './useProjectJumpToDrawer';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
});

const { state } = props;
const { items } = props.state;

const { t } = useI18n();
const { lastOpenCategories: open } = useProjectJumpToDrawer();

const generateTreeItem = (item) => {
  const newItem = {
    id: item.id,
    title: item.name,
    count: item.count,
  };

  // Set color - note api color doesn't have the hash in front
  let { color } = item;
  if (color !== '') {
    color = `#${color}`;
  } else {
    color = null;
  }
  newItem.iconColor = color;
  newItem.to = `/projects/list?catid=${item.id}`;

  // Add on the children categories
  const children = items.value.filter((i) => i.parentId === newItem.id);
  if (children.length) {
    newItem.children = children.map((i) => generateTreeItem(i));
  }

  return newItem;
};

/**
 * Note on id of -1 vs 0 for 'No category'
 * We use 0 to represent 'No category' in API calls so why is
 * the id of it -1 here instead of 0?
 * We did but there was a bizarre bug that the 'No category'
 * list would open and then close itself again. It was repeatable.
 * I tried everything it made no sense. The only thing
 * different about No Category was the id:0. Changing it to
 * -1 fixed the glitch. I'd love to know why this is the
 * case but I'm stumped. id:-1 works fine.
 */
const treeFriendlyItems = computed(() => {
  // Get the top level categories...
  const topLevelItems = items.value.filter((item) => item.parentId === 0);
  // ...and convert them to tree items
  const treeItems = topLevelItems.map((item) => generateTreeItem(item));
  // Add on the 'No category' option based on meta data
  if (state.meta.value && state.meta.value.totalUncategorizedItems > 0) {
    treeItems.push({
      id: -1, // see note above
      title: t('No category'),
      count: state.meta.value.totalUncategorizedItems,
      to: '/projects/list?catid=0',
    });
  }

  return treeItems;
});
</script>

<template>
  <LscTreeview v-model="open" :items="treeFriendlyItems">
    <!-- Show projects in category, if open -->
    <template #appendCategoryList="slotProps">
      <template v-if="slotProps.parentItem">
        <ProjectJumpToDrawerProjectsInCategory
          v-if="open.includes(slotProps.parentItem.id)"
          :projectCategoryId="slotProps.parentItem.id > 0 ? slotProps.parentItem.id : 0"
        />
      </template>
    </template>
  </LscTreeview>
</template>
