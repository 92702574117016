import { DateTime } from 'luxon';
import { useAxios } from '../base/useAxios';
import { useCurrentUser } from '../user/useCurrentUser';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useI18n } from '@/util';

export function useBadgesActions() {
  const { t } = useI18n();
  const api = useAxios();
  const user = useCurrentUser();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  /** @param {number} badgeId */
  function completeBadge(badgeId) {
    const userId = user.value.id;
    const promise = api
      .post(
        `/projects/api/v3/user/${userId}/badges.json`,
        { id: badgeId },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to complete badge'),
        },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'badge',
          action: 'achieved',
          badgeId,
          userId,
        });
      });

    emitOptimisticUpdate({
      promise,
      type: 'badge',
      action: 'update',
      badge: { id: badgeId, isAchieved: true, achievedAt: DateTime.now() },
    });

    return promise;
  }

  return { completeBadge };
}
