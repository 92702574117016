<script setup>
import {
  useBrandingState,
  useCurrentAccountState,
  useCurrentUserState,
  useTeamworkFeaturesState,
  useLaunchDarkly,
} from '@/api';
import { useI18n } from '@/util';
import AppShellError from './AppShellError.vue';
import AppShellMain from './AppShellMain.vue';
import AppShellLoading from './AppShellLoading.vue';

const { ready: ldClientReady } = useLaunchDarkly();
const { ready: localizationReady, error: localizationError } = useI18n();
const { item: branding, error: brandingError } = useBrandingState();
const { item: account, error: accountError } = useCurrentAccountState();
const { item: user, error: userError } = useCurrentUserState();
const { loaded: featuresLoaded, error: featuresError } = useTeamworkFeaturesState();

const isReady = computed(
  () =>
    ldClientReady.value &&
    localizationReady.value &&
    Boolean(branding.value) &&
    Boolean(account.value) &&
    Boolean(user.value) &&
    Boolean(featuresLoaded.value),
);

const hasError = computed(() =>
  // Report a localization error only once we have loaded the current user
  // because only then we can be sure that we used a valid language code.
  [Boolean(user.value) && localizationError, brandingError, accountError, userError, featuresError].some(
    (error) => error.value,
  ),
);
</script>

<template>
  <AppShellError v-if="hasError" />
  <AppShellMain v-else-if="isReady" />
  <AppShellLoading v-else />
</template>
