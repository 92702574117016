/**
 * Determines if more items can be loaded based on the provided axios response.
 */
export function getHasMore(response) {
  const responseMeta = response.data?.meta;

  // Handle cursor based pagination
  if (Number.isInteger(responseMeta?.limit)) {
    return responseMeta.nextCursor != null;
  }

  // Handle v3
  const pageMeta = responseMeta?.page;
  if (pageMeta) {
    const { hasMore } = pageMeta;
    if (typeof hasMore === 'boolean') {
      return hasMore;
    }

    const { pageOffset, pageSize, count } = pageMeta;
    if (Number.isInteger(pageOffset) && Number.isInteger(pageSize) && Number.isInteger(count)) {
      return count > (pageOffset + 1) * pageSize;
    }
  }

  // Handle v2 & v1
  const { headers } = response;
  const page = Number(headers['x-page']);
  const pages = Number(headers['x-pages']);
  if (Number.isInteger(page) && Number.isInteger(pages)) {
    return page < pages;
  }

  // Fallback
  if (import.meta.env.MODE !== 'test') {
    // eslint-disable-next-line no-console
    console.warn(`Could not determine if more items are available from the API response: ${response.config?.url}`);
  }
  return false;
}
