import { syncRef } from '@vueuse/core';
import { useCurrentProject } from './useCurrentProject';
import { useProjectBudgetsV3Loader } from '@/api';

/**
 * Loads a project budget for the current route.
 */
export function useCurrentProjectBudgetLoader({ budgetId: _budgetId } = {}) {
  const project = useCurrentProject();

  const count = computed(() => (project.value ? 1 : -1));
  const budgetId = shallowRef(_budgetId);
  const projectId = shallowRef();
  syncRef(
    projectId,
    computed(() => project.value?.id),
    { direction: 'rtl' },
  );

  const params = computed(() => {
    const paramsObj = {
      projectIds: projectId.value,
      include: 'notifications.users,notifications.teams,notifications.companies',
    };
    if (budgetId.value) {
      paramsObj.ids = budgetId.value;
    } else {
      paramsObj.status = 'active';
    }
    return paramsObj;
  });

  const state = useProjectBudgetsV3Loader({
    count,
    pageSize: 1,
    params,
  });

  return {
    item: computed(() => state.items.value[0] ?? (state.loaded.value ? null : undefined)),
    inSync: computed(() => state.inSync.value),
    loaded: computed(() => state.loaded.value),
    meta: computed(() => state.meta.value),
    error: computed(() => state.error.value),
    retry: state.retry,
  };
}
