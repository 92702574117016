<script setup>
import { refDebounced } from '@vueuse/core';
import { useQuickSearchV2Loader } from '@/api';
import { useCurrentProject } from '@/route';
import { useI18n } from '@/util';
import { useAppShellSidebar } from '@/appShell';
import { EntityAvatar } from '@/module/entity';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
  entity: {
    type: String,
    default: '',
  },
  shouldSearchCurrentProject: {
    type: Boolean,
    required: true,
  },
});

const entitySearchParamMap = Object.freeze({
  projects: 'searchProjects',
  tasks: 'searchTasks',
  tasklists: 'searchTasklists',
  milestones: 'searchMilestones',
  files: 'searchFiles',
  messages: 'searchMessages',
  notebooks: 'searchNotebooks',
  people: 'searchPeople',
  contacts: 'searchPeople',
  users: 'searchPeople',
  companies: 'searchCompanies',
  teams: 'searchTeams',
  links: 'searchLinks',
  comments: 'searchComments',
});

const project = useCurrentProject();

const { t } = useI18n();
const { searchTerm } = toRefs(props);
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();

const step = 20;
const pageSize = step;
const count = shallowRef(step);
const debouncedSearchTerm = refDebounced(searchTerm, 800);

// Get the entity type params for the search query.
// i.e. searchProjects, searchTasks, searchTasklists, etc.
const entitySearchParams = computed(() => {
  if (!props.entity || props.entity === 'everything') {
    return {};
  }
  const params = {};

  Object.entries(entitySearchParamMap).forEach(([entityType, paramKey]) => {
    // Only set the param if its not already set
    if (!params[paramKey]) {
      params[paramKey] = entityType === props.entity;
    }
  });

  return params;
});

// projectId to search for.
const shouldOnlySearchCurrentProject = computed(() => Boolean(props.shouldSearchCurrentProject && project.value));

const params = computed(() => ({
  searchTerm: debouncedSearchTerm.value,
  projectId: shouldOnlySearchCurrentProject.value ? project.value.id : 0,
  searchAllProjects: !shouldOnlySearchCurrentProject.value,
  ...entitySearchParams.value,
}));

const state = useQuickSearchV2Loader({
  params,
  count,
  pageSize,
});

const { items: searchResults } = state;

const advancedSearchRoute = computed(() => {
  if (searchTerm.value) {
    return `/search/${encodeURIComponent(searchTerm.value)}`;
  }
  return '/search';
});
</script>
<template>
  <div class="flex flex-col items-stretch gap-6 overflow-x-hidden">
    <WidgetLoadingState :state="state">
      <template #default>
        <template v-if="searchTerm">
          <div class="mx-6">
            <h4 class="text-body-1 font-semibold">{{ t('Search results') }}</h4>
          </div>
        </template>

        <VList class="pt-0">
          <VListItem
            v-for="searchResult in searchResults"
            :key="searchResult.id + searchResult.type"
            v-bind="VListItemSidebarSearchDrawer"
            v-memo="[searchResult.entity]"
            :to="`/${searchResult.url}`"
            data-identifier="app-nav__search-panel__results__list-item"
            @click="clearActiveDrawerPanelIfNotPinned"
          >
            <template #prepend>
              <LscAvatar
                v-if="searchResult.type === 'person'"
                :name="searchResult.title"
                :src="searchResult.extra"
                size="lg"
                class="mr-3"
              />
              <EntityAvatar v-else v-bind="searchResult.entity" size="40" class="mr-3" />
            </template>

            <div>
              <VListItemSubtitle class="text-body-2">
                {{ searchResult.details }}
              </VListItemSubtitle>
              <VListItemTitle
                class="!text-body-1 font-medium"
                :class="{ 'text-decoration-line-through': searchResult.completed }"
                :title="searchResult.title"
              >
                {{ searchResult.title }}
              </VListItemTitle>
            </div>
          </VListItem>
          <WidgetLoadingLazy v-model:count="count" :state="state" :step="step" />
        </VList>
      </template>
      <template #blank>
        <LscEmptyState
          class="h-full"
          :title="t('No results')"
          :message="t('Please try again with a different term, or refine your results with the advanced search')"
        >
          <template #actions>
            <LscLink v-if="searchTerm" variant="standalone" :to="advancedSearchRoute">
              {{ t('Advanced search') }}
            </LscLink>
          </template>
        </LscEmptyState>
      </template>
    </WidgetLoadingState>
  </div>
</template>
