<script setup>
import { ENTITY_COLOR_MAP } from './entity';
import EntityIcon from './EntityIcon.vue';

const props = defineProps({
  action: {
    type: String,
    default: '',
  },

  group: {
    type: String,
    default: '',
  },

  size: {
    type: [Number, String],
    default: 36,
  },
});

const styles = computed(() => {
  const backgroundColor = ENTITY_COLOR_MAP[props.group] || ENTITY_COLOR_MAP.default;
  const size = `${props.size}px`;

  return {
    width: size,
    height: size,
    backgroundColor,
  };
});

const entitySize = computed(() => Math.floor(props.size / 2.25));
</script>

<template>
  <div class="flex shrink-0 items-center justify-center rounded-full" :style="styles">
    <EntityIcon v-bind="props" :size="entitySize" />
  </div>
</template>
