<script setup>
import {
  CUSTOM_FIELDS_FEATURE_KEY,
  FINANCIAL_BUDGETS_FEATURE_KEY,
  INTAKE_FORMS_FEATURE_KEY,
  PROOFS_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_KEY,
  TIME_BUDGETS_FEATURE_KEY,
  WORKFLOWS_FEATURE_KEY,
} from '@/api';

const props = defineProps({
  feature: {
    type: String,
    required: true,
    validator: (value) =>
      [
        CUSTOM_FIELDS_FEATURE_KEY,
        FINANCIAL_BUDGETS_FEATURE_KEY,
        INTAKE_FORMS_FEATURE_KEY,
        PROOFS_FEATURE_KEY,
        TASKLIST_BUDGETS_FEATURE_KEY,
        TIME_BUDGETS_FEATURE_KEY,
        WORKFLOWS_FEATURE_KEY,
      ].includes(value),
  },
});

const emit = defineEmits(['close']);

const FeatureLimitUpgradeDialogPanel = defineAsyncComponent(() => import('./FeatureLimitUpgradeDialogPanel.vue'));

function emitClose() {
  emit('close');
}
</script>

<template>
  <WidgetDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <FeatureLimitUpgradeDialogPanel
        v-bind="props"
        @close="
          close();
          emitClose();
        "
      />
    </template>
  </WidgetDialog>
</template>
