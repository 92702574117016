<script setup>
import { useQuickViewRoute } from './useRoute';
import { provideBreadcrumbs } from './useBreadcrumbs';
import { useQuickView } from './useQuickView';
import RouteQuickViewDrawer from './RouteQuickViewDrawer.vue';

const quickViewRoute = useQuickViewRoute();
const { closeQuickView } = useQuickView();

const isQuickViewResizable = computed(() =>
  Boolean(quickViewRoute.meta?.isQuickViewResizable === undefined || quickViewRoute.meta?.isQuickViewResizable),
);

provideBreadcrumbs({
  dataIdentifierPrefix: `quick-view-breadcrumbs`,
});
</script>

<template>
  <RouteQuickViewDrawer :resizable="isQuickViewResizable" @close="closeQuickView">
    <RouterView :route="quickViewRoute" :isQuickView="true" />
  </RouteQuickViewDrawer>
</template>
