<script setup>
import { useI18n } from '@/util';

const props = defineProps({
  sequence: {
    type: Object,
    default: null,
  },
});

const { t } = useI18n();

const repeatText = computed(() => {
  const frequency = props.sequence?.frequency;
  const duration = props.sequence?.duration;

  const translations = {
    daily: t('This task repeats daily'),
    weekdays: t('This task repeats every weekday (Mon-Fri)'),
    selecteddays: t('This task repeats on selected days'),
    weekly: t('This task repeats weekly'),
    everyxdays: t('This task repeats every {n} days', { n: duration }),
    everyxdaysweekday: t('This task repeats every {n} days, only weekdays', { n: duration }),
    every2weeks: t('This task repeats every {n} weeks', { n: 2 }),
    every3weeks: t('This task repeats every {n} weeks', { n: 3 }),
    every4weeks: t('This task repeats every {n} weeks', { n: 4 }),
    every5weeks: t('This task repeats every {n} weeks', { n: 5 }),
    every6weeks: t('This task repeats every {n} weeks', { n: 6 }),
    monthly: t('This task repeats monthly'),
    every2months: t('This task repeats every {n} months', { n: 2 }),
    every3months: t('This task repeats every {n} months', { n: 3 }),
    every4months: t('This task repeats every {n} months', { n: 4 }),
    every6months: t('This task repeats every {n} months', { n: 6 }),
    yearly: t('This task repeats yearly'),
    default: t('This task does not repeat'),
  };
  return translations[frequency] || translations.default;
});
</script>

<template>
  <slot :title="repeatText">
    <VCardTitle class="mb-1 mt-0 flex items-center p-0 text-body-1 font-semibold">
      {{ t('Recurring tasks') }}
      <LscIcon v-LsdTooltip="repeatText" size="xs" class="ml-1 text-icon-subtle" icon="lsi-tooltip" />
    </VCardTitle>
  </slot>
</template>
