import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { normalizeMilestoneStatus, getOptimisticStatus } from './util';

function responseToItems({ data: { milestones, included } }) {
  const {
    projectCategories = {},
    companies = {},
    projects = {},
    tags = {},
    tasklists = {},
    tasks = {},
    users = {},
    tasklistTaskStats = {},
  } = included;

  /* eslint-disable no-param-reassign */
  milestones.forEach((milestone) => {
    if (milestone.project && projects[milestone.project.id]) {
      milestone.project = projects[milestone.project.id];
    }

    if (milestone.tags && milestone.tags.length && tags) {
      const tgs = [];
      milestone.tags.forEach((tg) => {
        tgs.push(tags[tg.id]);
      });
      milestone.tags = tgs;
    }

    if (milestone.tasklists && milestone.tasklists.length && Object.keys(tasklists).length) {
      const tskls = [];
      milestone.tasklists.forEach((tl) => {
        tasklists[tl.id].stats = tasklistTaskStats[tl.id] || {};
        tskls.push(tasklists[tl.id]);
      });
      milestone.tasklists = tskls;
    }

    if (milestone.tasks?.length) {
      const tsks = [];
      milestone.tasks.forEach((tsk) => {
        tsks.push(tasks[tsk.id]);
      });
      milestone.tasks = tsks;
    }

    if (milestone.project.categoryId && projectCategories[milestone.project.categoryId]) {
      milestone.category = projectCategories[milestone.project.categoryId];
    }

    if (milestone.project.companyId && companies[milestone.project.companyId]) {
      milestone.company = companies[milestone.project.companyId];
    }

    if (milestone.creatorUserId && users[milestone.creatorUserId]) {
      milestone.createdBy = users[milestone.creatorUserId];
    }

    if (milestone.updatedBy && users[milestone.updatedBy]) {
      milestone.updatedBy = users[milestone.updatedBy];
    }

    if (milestone.completedBy && users[milestone.completedBy]) {
      milestone.completedBy = users[milestone.completedBy];
    }

    if (milestone.deadline) {
      milestone.deadline = DateTime.fromISO(milestone.deadline.slice(0, 10));
    }

    if (milestone.originalDueDate) {
      milestone.originalDueDate = DateTime.fromISO(milestone.originalDueDate.slice(0, 10));
    }

    milestone.status = normalizeMilestoneStatus(milestone);

    if (milestone.responsibleParties && milestone.responsibleParties.length) {
      milestone.responsibleParties = milestone.responsibleParties.map((assignee) => {
        if (included[assignee.type]?.[assignee.id]) {
          return { ...included[assignee.type][assignee.id], assigneeType: assignee.type };
        }
        return assignee;
      });
    }
  });
  /* eslint-enable no-param-reassign */

  return milestones;
}

/**
 * Loads milestones from Teamwork v3 endpoints.
 */
export function useMilestonesV3Loader({ params, projectId: _projectId, count, pageSize }) {
  const projectId = shallowRef(_projectId);
  const url = projectId.value
    ? `/projects/api/v3/projects/${projectId.value}/milestones.json`
    : '/projects/api/v3/milestones.json';
  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
    type: 'milestone',
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'milestone') {
      if (event.action === 'update') {
        update(
          (milestones) =>
            milestones.map((milestone) => {
              if (milestone.id === event.milestone.id) {
                return getOptimisticStatus({ ...milestone, ...event.milestone });
              }
              return milestone;
            }),
          event.promise,
        );
      }
      if (event.action === 'delete') {
        update((milestones) => milestones.filter((milestone) => milestone.id !== event.milestone.id), event.promise);
      }
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'milestone') {
      refresh(event.milestoneId);
    } else if (event.type === 'task') {
      // Refresh only on certain cases when event is 'task'
      if (
        event.action === 'completed' ||
        event.action === 'reopened' ||
        event.action === 'deleted' ||
        event.action === 'new'
      ) {
        refresh();
      }
    }
  });

  return state;
}
