<script setup>
import { useI18n } from '@/util';
import { LscAlertLayouts, LscAlertVariants } from './constants';

const props = defineProps({
  /**
   * Whether the alert is closable.
   */
  closable: {
    type: Boolean,
    default: false,
  },
  /**
   * The text for the close button.
   */
  closeButtonText: {
    type: String,
    default: undefined,
  },
  /**
   * The message of the alert.
   */
  message: {
    type: String,
    default: undefined,
  },
  /**
   * The icon of the alert.
   * Note: This is overridden by the media slot.
   */
  icon: {
    type: String,
    default: undefined,
  },
  /**
   * The title of the alert.
   */
  title: {
    type: String,
    default: undefined,
  },
  /**
   * The data identifier prefix of the alert.
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * The layout of the alert.
   * - Alert: The alert is displayed inline with the content.
   * - Banner: The alert is displayed full-width as a banner above the content with no rounded corners.
   * @type {PropType<typeof LscAlertLayouts[number]>}
   */
  layout: {
    type: String,
    default: 'alert',
    validator: (value) => LscAlertLayouts.includes(value),
  },
  /**
   * The variant of the alert.
   * @type {PropType<typeof LscAlertVariants[number]>}
   */
  variant: {
    type: String,
    default: 'info',
    validator: (value) => LscAlertVariants.includes(value),
  },
});
const emit = defineEmits(['close']);

const { t } = useI18n();
const closeButtonText = computed(() => props.closeButtonText ?? t('Close'));

const iconMap = {
  info: 'lsi-tooltip',
  'info-subdued': 'lsi-tooltip',
  success: 'lsi-success',
  'success-subdued': 'lsi-success',
  warning: 'lsi-warning',
  'warning-subdued': 'lsi-warning',
  critical: 'lsi-alert',
  'critical-subdued': 'lsi-alert',
};

const icon = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  return iconMap[props.variant] ?? iconMap.info;
});

function close(event) {
  emit('close', event);
}

const alertVariantStyleConfig = tv({
  base: 'flex min-w-0 items-center justify-between gap-3 p-3',
  slots: {
    left: 'flex min-w-0 flex-auto items-start gap-2',
    leftIcon: 'mt-0.5 shrink-0 leading-3 text-icon-default',
    leftContent: 'min-w-0 flex-auto',
    titleWrapper: 'flex items-center gap-1',
    title: 'text-balance text-body-1 font-semibold text-default',
    message: 'text-pretty text-body-1 leading-3 text-default',
    right: 'flex shrink-0 items-center gap-2 self-center',
    closeButton: 'mt-0.5 size-[--lsds-c-button-size-xs] text-icon-default',
  },
  variants: {
    layout: {
      banner: {
        base: 'rounded-none',
      },
      alert: {
        base: 'rounded-md',
      },
    },
    variant: {
      info: {
        base: 'bg-[--lsds-c-alert-color-info-default]',
      },
      'info-subdued': {
        base: 'bg-[--lsds-c-alert-color-info-subdued]',
      },
      success: {
        base: 'bg-[--lsds-c-alert-color-success-default]',
      },
      'success-subdued': {
        base: 'bg-[--lsds-c-alert-color-success-subdued]',
      },
      warning: {
        base: 'bg-[--lsds-c-alert-color-warning-default]',
      },
      'warning-subdued': {
        base: 'bg-[--lsds-c-alert-color-warning-subdued]',
      },
      critical: {
        base: 'bg-[--lsds-c-alert-color-critical-default]',
      },
      'critical-subdued': {
        base: 'bg-[--lsds-c-alert-color-critical-subdued]',
      },
    },
  },
});
const classes = computed(() =>
  alertVariantStyleConfig({
    variant: props.variant,
    layout: props.layout,
  }),
);
</script>

<template>
  <div :class="classes.base()">
    <div :class="classes.left()">
      <!-- @slot media - The media of the alert. If not provided, the icon prop is used otherwise the default icon is used. -->
      <slot name="media">
        <LscIcon :class="classes.leftIcon()" :icon="icon" size="md" />
      </slot>
      <div :class="classes.leftContent()">
        <div v-if="title" :class="classes.titleWrapper()">
          <h4 :class="classes.title()">{{ title }}</h4>
          <!-- @slot infoTooltip - The tooltip to display when hovering over the title. -->
          <slot name="infoTooltip" />
        </div>

        <div v-if="message || $slots.default" :class="classes.message()">
          <!-- @slot default - The message of the alert. -->
          <slot>{{ message }}</slot>
        </div>
      </div>
    </div>
    <div :class="classes.right()">
      <!-- @slot action - The action of the alert. Prefer to LscAlertButton here -->

      <slot name="action" />
    </div>
    <button
      v-if="closable"
      type="button"
      :aria-label="closeButtonText"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-close` : undefined"
      @click="close"
    >
      <LscIcon size="sm" :class="classes.closeButton()" icon="lsi-close" />
    </button>
  </div>
</template>
