import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';

export function useIntegrationParagonAuthActions() {
  const { t } = useI18n();
  const api = useAxios();

  return {
    /**
     * @param {string} integrationName - integration name(salesforce)
     * @returns {Promise}
     */
    ensureAuth(integrationName) {
      return api
        .get(`/synthesis/api/v2/paragon/auth/ensure.json?integrationName=${integrationName}`, {
          errorMessage: t('Failed to ensure paragon auth'),
        })
        .then((response) => {
          return response;
        });
    },

    /**
     * @param {string} integrationName - integration name(salesforce)
     * @returns {Promise}
     */
    updateAPIKey(integrationName) {
      return api
        .patch(
          `/synthesis/api/v2/paragon/auth/apikey.json`,
          { integrationName },
          {
            errorMessage: t('Failed to update {paragon} API key', { paragon: 'Paragon' }),
          },
        )
        .then((response) => {
          return response;
        });
    },
  };
}
