<script setup>
import { useExperimentA2326 } from '@/api';
import { useI18n } from '@/util';
import { useOnboardingWizardCommonTemplateSelect } from './useOnboardingWizardCommonTemplateSelect.js';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

const props = defineProps({
  selectedTemplate: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['templateSelected']);

const { t } = useI18n();
const { trackExpA2326ShowMoreButtonClicked, trackExpA2326TemplateSelected } = useExperimentA2326();

const {
  sampleTemplateProjects,
  sampleTemplateProjectsInSync,
  templateCategories,
  recommendedTemplates,
  industryCategory,
} = useOnboardingWizardCommonTemplateSelect();

const { previousPage } = useOnboardingWizardTracking();

const allTemplatesDisplayed = shallowRef(false);
const showMoreButtonDisplayed = shallowRef(true);
const selectedTemplateId = shallowRef(undefined);

function handleShowMoreButtonClicked() {
  allTemplatesDisplayed.value = true;
  showMoreButtonDisplayed.value = false;
  trackExpA2326ShowMoreButtonClicked(previousPage.value);
}

function templateSelected(templateId) {
  const templateEmitted = sampleTemplateProjects.value.find((template) => template.id === templateId);
  if (selectedTemplateId.value !== undefined) {
    const localData = templateEmitted !== undefined ? templateEmitted : props.selectedTemplate;
    emit('templateSelected', localData);
  }
  return undefined;
}

watch(selectedTemplateId, (newValue) => {
  if (newValue !== undefined) {
    templateSelected(newValue);
  }
});

onMounted(() => {
  if (recommendedTemplates.value.length > 0 && selectedTemplateId.value === undefined) {
    selectedTemplateId.value = recommendedTemplates.value[0].id;
  }
  if (props.selectedTemplate.id !== undefined) {
    selectedTemplateId.value = props.selectedTemplate.id;
    allTemplatesDisplayed.value = true;
    showMoreButtonDisplayed.value = false;
  }
});
</script>

<template>
  <div
    :class="{
      'h-64 w-full overflow-auto pr-2': allTemplatesDisplayed,
    }"
  >
    <p class="mb-3 text-body-1 font-semibold">{{ t('Recommended for {industryCategory}', { industryCategory }) }}</p>
    <div v-if="sampleTemplateProjectsInSync">
      <LscChoiceList v-model="selectedTemplateId" :multiple="false">
        <LscChoice
          v-for="template in recommendedTemplates"
          :key="template.id"
          :value="template.id"
          hasCheck
          :label="template.name"
          icon="lsi-multiple-templates"
          @click.once="trackExpA2326TemplateSelected(template.name)"
        />
      </LscChoiceList>
    </div>
    <span v-else class="flex justify-center">
      <VProgressCircular indeterminate color="primary" :width="3" class="size-6" />
    </span>
    <LscButton
      v-if="showMoreButtonDisplayed && sampleTemplateProjectsInSync"
      :ariaLabel="t('Show more templates')"
      class="mt-5 text-on-secondary"
      variant="plain-secondary"
      size="lg"
      prependIcon="lsi-date-picker-month-selector-closed"
      @click="handleShowMoreButtonClicked"
    >
      {{ t('Show more templates') }}
    </LscButton>
    <div v-if="allTemplatesDisplayed && sampleTemplateProjectsInSync">
      <LscAccordion class="mt-4">
        <LscAccordionItem
          v-for="(category, categoryName) in templateCategories"
          :key="category.id"
          v-model="category.isVisible"
          :name="categoryName"
          :title="categoryName"
        >
          <LscChoiceList v-model="selectedTemplateId" :multiple="false">
            <LscChoice
              v-for="template in category.templates"
              :key="template.id"
              :value="template.id"
              hasCheck
              :label="template.name"
              icon="lsi-multiple-templates"
              @click.once="trackExpA2326TemplateSelected(template.name)"
            />
          </LscChoiceList>
        </LscAccordionItem>
      </LscAccordion>
    </div>
  </div>
</template>
