import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { ONBOARDING_EVENT_NAME as eventName } from '@/module/onboarding';

/**
 * Constants, computed properties and functions related to Experiment A-23-26
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-26-onboarding-templates/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/44770-a-23-26-personalised-templates-selection-in-onboarding-flow
 */

export function useExperimentA2326() {
  const { projectsOnboardingTemplatesExperimentEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = projectsOnboardingTemplatesExperimentEnabled;

  const ignoreInExperimentOnRuleMatch = true;
  const EXP_A23_26_COMMON_METRICS = ['account_id'];

  function track(metadata) {
    trackPendoEvent({
      eventName,
      commonMetrics: EXP_A23_26_COMMON_METRICS,
      launchDarklyFlagKey,
      metadata,
    });
  }

  function trackExperimentA2326() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackExpA2326ShowMoreButtonClicked(previousPage) {
    track({
      page_type: 'project_type_selection',
      previous_page: previousPage,
      event_action: 'show_more_templates_clicked',
    });
  }

  function trackExpA2326TemplateSelected(templateName) {
    track({
      event_action: 'onboarding_template_clicked',
      template_name: templateName.replaceAll(' ', '_').toLowerCase(),
    });
  }

  return {
    trackExperimentA2326,
    trackExpA2326ShowMoreButtonClicked,
    trackExpA2326TemplateSelected,
  };
}
