<script setup>
import { useCustomfieldsV3Loader } from '@/api';
import { useI18n } from '@/util';
import { useReportBuilderSettingsColumns } from '@/module/report';
import { customfieldTypes, customfieldUnits } from '../constants';
import { createCustomfieldFormatter } from './customfieldFormatter';
import CustomfieldAddOrEditDialogFormulaFieldSelect from './CustomfieldAddOrEditDialogFormulaFieldSelect.vue';

const props = defineProps({
  editing: {
    type: Boolean,
    required: false,
    default: false,
  },

  entity: {
    type: String,
    required: true,
  },
});

const modelValue = defineModel({
  type: String,
  default: null,
});

const modelValueArray = defineModel('modelValueArray', {
  type: Array,
  required: true,
});

const unitId = defineModel('unitId', {
  type: Number,
  default: null,
});

const { t } = useI18n();

const customfieldFormatter = createCustomfieldFormatter(t);

const leftRef = shallowRef();
const rightRef = shallowRef();
const operatorRef = shallowRef();

const title = computed(() => (props.editing ? t('Edit formula') : t('Create formula')));
const entityWithoutPrefix = computed(() => props.entity.replace('cr-', ''));
const infoText = t('The created formula will be available only in Reports');
const linkText = t('Learn more about formulas');
const formatAsLabel = t('Format as');

const settings = useReportBuilderSettingsColumns(entityWithoutPrefix);

const columns = computed(() => settings.value?.filter((item) => item.props.type === 'number') ?? []);

const selectedCustomfieldIds = shallowRef([]);
const selectedCustomfieldsIdsCount = computed(() => selectedCustomfieldIds.value.length || -1);

const { items: selectedCustomfieldsItems } = useCustomfieldsV3Loader({
  count: selectedCustomfieldsIdsCount,
  pageSize: selectedCustomfieldsIdsCount,
  params: computed(() => ({
    ids: selectedCustomfieldIds.value.map(({ left, right }) => left || right).join(','),
  })),
});

const { items: customfields, inSync: customfieldsInSync } = useCustomfieldsV3Loader({
  count: Infinity,
  pageSize: 500,
  params: computed(() => ({
    entities: entityWithoutPrefix.value,
    onlySiteLevel: true,
  })),
});

const selectedCustomfieldsMap = computed(() => {
  const selected = selectedCustomfieldsItems.value;

  return selected.reduce((acc, customfield) => {
    acc[customfield.id] = customfieldFormatter(customfield);

    return acc;
  }, {});
});

const operators = computed(() => [
  {
    value: '+',
    title: t('Add'),
    props: {
      icon: 'lsi-add',
    },
  },
  {
    value: '-',
    title: t('Subtract'),
    props: {
      icon: 'lsi-remove',
    },
  },
  {
    value: '*',
    title: t('Multiply'),

    props: {
      icon: 'lsi-close',
    },
  },
  {
    value: '/',
    title: t('Divide'),

    props: {
      icon: 'lsi-forward-slash',
    },
  },
]);

const units = computed(() => {
  const leftValue = leftRef.value;
  const rightValue = rightRef.value;
  const operatorValue = operatorRef.value;

  const isDivision = operatorValue?.value === '/';

  const leftIsCurrency = leftValue?.props?.unit === customfieldUnits.CURRENCY;
  const leftIsTime = leftValue?.props?.unit === customfieldUnits.TIME;

  const rightIsCurrency = rightValue?.props?.unit === customfieldUnits.CURRENCY;
  const rightIsTimet = rightValue?.props?.unit === customfieldUnits.TIME;

  return [
    {
      value: customfieldUnits.DEFAULT,
      title: t('Default format'),
    },
    {
      value: customfieldUnits.CURRENCY,
      title: t('Currency ($)'),
    },
    {
      value: customfieldUnits.TIME,
      title: t('Time'),
    },
    {
      value: customfieldUnits.CURRENCY_TIME,
      title: t('Currency ($) / Time'),
      disabled: !(isDivision && leftIsCurrency && rightIsTimet),
    },
    {
      value: customfieldUnits.TIME_CURRENCY,
      title: t('Time / Currency ($)'),
      disabled: !(isDivision && leftIsTime && rightIsCurrency),
    },
  ];
});

const numericCustomfields = computed(() => {
  const selectedItemsMap = selectedCustomfieldsMap.value;

  return customfields.value
    .filter((customfield) => customfield.type === customfieldTypes.NUMBER_INTEGER && !selectedItemsMap[customfield.id])
    .map(customfieldFormatter);
});

const availableColumns = computed(() => columns.value.concat(numericCustomfields.value));

const leftItems = computed(() => {
  const selectedItemsMap = selectedCustomfieldsMap.value;
  const selectedItem = selectedItemsMap[leftRef.value?.value];

  const items = availableColumns.value;

  if (selectedItem) {
    return [selectedItem, ...items];
  }

  return items;
});

const rightItems = computed(() => {
  const selectedItemsMap = selectedCustomfieldsMap.value;
  const selectedItem = selectedItemsMap[rightRef.value?.value];

  const items = availableColumns.value;

  if (selectedItem) {
    return [selectedItem, ...items];
  }

  return items;
});

const fieldRegex = /(FIELDBYID|FIELD)\(([0-9a-z\s"]+)\)/g;

function matchField(target) {
  try {
    const match = target.matchAll(fieldRegex);

    const value = match.next().value[2];
    const casted = Number(value);

    if (Number.isNaN(casted)) {
      return value.replaceAll('"', '');
    }

    return casted;
  } catch (error) {
    return null;
  }
}

function setOperatorFromModel(model) {
  if (model) {
    const operator = model.match(/[+-/*]/g)[0];
    const operatorValue = operators.value.find(({ value }) => value === operator);

    if (operatorValue) {
      operatorRef.value = operatorValue;
      return;
    }
  }

  operatorRef.value = operators.value[0];
}

function setLeftAndRightFromModel(model) {
  if (!model) {
    leftRef.value = null;
    rightRef.value = null;
    return;
  }

  const [left, right] = model.split(/[+-/*]/g);

  const leftValue = matchField(left);
  const rightValue = matchField(right);

  const selectedIds = [];

  const columnsValue = columns.value;

  if (typeof leftValue === 'string') {
    leftRef.value = toRaw(columnsValue.find(({ value }) => value === leftValue));
  } else {
    selectedIds.push({ left: leftValue });
  }

  if (typeof rightValue === 'string') {
    rightRef.value = toRaw(columnsValue.find(({ value }) => value === rightValue));
  } else {
    selectedIds.push({ right: rightValue });
  }

  selectedCustomfieldIds.value = selectedIds;
}

const stopModelWatcher = watch(
  () => (settings.value?.length ? modelValue.value : undefined),
  (model, prevModel) => {
    if (prevModel) {
      stopModelWatcher();
      return;
    }

    setOperatorFromModel(model);
    setLeftAndRightFromModel(model);
  },
  {
    immediate: true,
  },
);

const stopCustomfieldsWatcher = watch(
  [selectedCustomfieldsItems, settings],
  ([selected, _settings]) => {
    if (!(selected.length && _settings?.length)) {
      return;
    }

    stopCustomfieldsWatcher();

    const leftOption = selectedCustomfieldIds.value.find(({ left }) => left);

    if (leftOption) {
      const left = selected.find(({ id }) => id === leftOption.left);
      leftRef.value = customfieldFormatter(left);
    }

    const rightOption = selectedCustomfieldIds.value.find(({ right }) => right);

    if (rightOption) {
      const right = selected.find(({ id }) => id === rightOption.right);

      rightRef.value = customfieldFormatter(right);
    }
  },
  {
    immediate: true,
  },
);

function updateModel() {
  const [left, operator, right] = [leftRef.value, operatorRef.value, rightRef.value];

  if (!(left && operator && right)) {
    return;
  }

  const updated = [
    left.props?.standard ? `FIELD("${left.value.trim('"')}")` : `FIELDBYID(${left.value})`,
    operator.value,
    right.props?.standard ? `FIELD("${right.value.trim('"')}")` : `FIELDBYID(${right.value})`,
  ].join(' ');

  modelValue.value = updated;
  modelValueArray.value = [left, operator, right].map((value) => ({ ...toRaw(value) }));
}

function handleUpdateOperator(value) {
  operatorRef.value = value;
  updateModel();
}

function handleUpdateLeftRef(value) {
  leftRef.value = value;
  updateModel();
}

function handleUpdateRightRef(value) {
  rightRef.value = value;
  updateModel();
}
</script>

<template>
  <div class="flex flex-col gap-y-3">
    <h5 class="flex items-center text-subtitle-2 font-semibold">
      {{ title }}
    </h5>
    <div class="inline-flex flex-wrap gap-2 text-body-2">
      <span>{{ infoText }}</span>

      <LscLink href="https://support.teamwork.com/projects/custom-fields/formula-fields" target="_blank">
        {{ linkText }}
      </LscLink>
    </div>

    <div class="flex items-center gap-3">
      <CustomfieldAddOrEditDialogFormulaFieldSelect
        :modelValue="leftRef"
        :items="leftItems"
        :loading="!customfieldsInSync"
        @update:modelValue="handleUpdateLeftRef"
      />

      <VSelect
        class="w-2/12 [&_.v-field]:pr-2"
        returnObject
        :modelValue="operatorRef"
        :menuProps="{ width: 'auto' }"
        :items="operators"
        @update:modelValue="handleUpdateOperator"
      >
        <template #item="{ item, props: itemProps }">
          <VListItem v-bind="itemProps" density="compact" :active="operatorRef.value === item.value" class="min-w-32">
            <template #prepend>
              <LscIcon :icon="item.props.icon" size="md" class="mr-2 text-subtle" />
            </template>
          </VListItem>
        </template>
        <template #selection="{ item }">
          <LscIcon :icon="item.props.icon" size="md" class="shrink-0 text-subtle" />
        </template>
      </VSelect>

      <CustomfieldAddOrEditDialogFormulaFieldSelect
        :modelValue="rightRef"
        :items="rightItems"
        :loading="!customfieldsInSync"
        @update:modelValue="handleUpdateRightRef"
      />
    </div>

    <VSelect
      v-model="unitId"
      :label="formatAsLabel"
      :returnObject="false"
      :menuProps="{ width: 'auto' }"
      :items="units"
      class="w-[--formula-field-format-as-input]"
    >
      <template #item="{ item: unitItem, props: itemProps }">
        <VListItem
          v-bind="itemProps"
          density="compact"
          :disabled="unitItem.raw.disabled"
          :active="unitId === unitItem.value"
        />
      </template>
    </VSelect>
  </div>
</template>
