<script setup>
import { useI18n } from '@/util';
import { useAssigneePicker } from './useAssigneePicker';
import LswAssigneePickerItem from './LswAssigneePickerItem.vue';
import LscAvatar from '../../../components/media/avatar/LscAvatar.vue';
import LswAssigneePickerGroupHeader from './LswAssigneePickerGroupHeader.vue';

const { t } = useI18n();
const { teams, selectedTeamIds, toggleTeam } = useAssigneePicker();
</script>

<template>
  <LswAssigneePickerGroupHeader v-if="teams.length">
    {{ t('Teams') }}
  </LswAssigneePickerGroupHeader>
  <LswAssigneePickerItem
    v-for="team in teams"
    :key="`assignee-picker-team-${team.id}`"
    :assignTooltip="t('Assign team')"
    :unassignTooltip="t('Unassign team')"
    :active="selectedTeamIds.includes(team.id)"
    @toggle="toggleTeam(team, $event)"
  >
    <template #prepend="{ avatarSize }">
      <LscAvatar v-bind="getLsAvatarProps({ team })" :size="avatarSize" />
    </template>
    <template #append="appendProps">
      <slot name="append" v-bind="appendProps" :team="team" />
    </template>
    <template #default>
      {{ team.name }}
    </template>
  </LswAssigneePickerItem>
</template>
