import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useI18n } from '@/util';

export function useCustomReportActions() {
  const api = useAxios();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const toast = useLsToast();
  const { t } = useI18n();

  function createCustomReport(customReport) {
    const promise = api
      .post(
        `/projects/api/v3/reporting/custom.json`,
        { customReport },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (
              error.response?.status === 400 &&
              error.response?.data?.errors[0]?.detail === 'date window cannot be greater than the allowed limit'
            ) {
              return t('The date range for a custom report is limited to two years.');
            }
            if (error.response?.status === 403) {
              return t('The maximum number of custom reports has been reached, please contact your administrator.');
            }
            if (error.response?.status === 409) {
              return t('A custom report with the same name aleady exists.');
            }
            return t('Error generating custom report');
          },
        },
      )
      .then((response) => {
        toast.success(t('Generating custom report'));

        emitRealTimeUpdate({
          type: 'customReport',
          action: 'new',
        });

        return response;
      });

    emitOptimisticUpdate({
      type: 'customReport',
      action: 'create',
      promise,
    });

    return promise;
  }

  function patchCustomReport(id, customReport) {
    const promise = api
      .patch(
        `/projects/api/v3/reporting/custom/${id}.json`,
        { customReport },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Cannot save report'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate({
          type: 'customReport',
          action: 'updated',
          customReport: {
            id,
          },
        });

        return response;
      });

    emitOptimisticUpdate({
      type: 'customReport',
      action: 'update',
      promise,
      ...customReport,
    });

    return promise;
  }

  function deleteCustomReport(id) {
    const promise = api
      .delete(`/projects/api/v3/reporting/custom/${id}.json`, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Error deleting custom report'),
      })
      .then(() => {
        toast.success(t('Custom report removed successfully'));
        emitRealTimeUpdate({
          type: 'customReport',
          action: 'deleted',
          customReport: {
            id,
          },
        });
      });

    emitOptimisticUpdate({
      type: 'customReport',
      action: 'delete',
      promise,
      id,
    });

    return promise;
  }

  function refreshCustomReport(id) {
    const promise = api
      .get(`/projects/api/v3/reporting/custom/enqueue/${id}.json`, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Error getting existing custom report data'),
      })
      .then(() => {
        emitRealTimeUpdate({
          type: 'customReport',
          action: 'enqueued',
          customReport: {
            id,
          },
        });
      });

    emitOptimisticUpdate({
      type: 'customReport',
      action: 'enqueue',
      promise,
      customReport: {
        id,
      },
    });

    return promise;
  }

  return {
    createCustomReport,
    deleteCustomReport,
    refreshCustomReport,
    patchCustomReport,
  };
}
