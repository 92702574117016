import { DateTime } from 'luxon';
import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

/* eslint-disable no-param-reassign */
function responseToItem(response) {
  const { project } = response.data;

  const {
    activities = {},
    companies = {},
    customfieldProjects: customFieldProjects = {},
    customfields: customFields = {},
    portfolioBoards = {},
    portfolioCards = {},
    portfolioColumns = {},
    projectBudgets = {},
    projectCategories = {},
    projectEmailDropboxes = {},
    projectTaskStats = {},
    projectUpdates = {},
    tags = {},
    users = {},
  } = response.data.included;
  const { permissions = {} } = response.data.meta;

  project.startDate = project.startDate ? DateTime.fromISO(project.startDate) : null;
  project.endDate = project.endDate ? DateTime.fromISO(project.endDate) : null;
  project.createdAt = project.createdAt ? DateTime.fromISO(project.createdAt) : null;
  project.updatedAt = project.updatedAt ? DateTime.fromISO(project.updatedAt) : null;
  project.lastWorkedOn = project.lastWorkedOn ? DateTime.fromISO(project.lastWorkedOn) : null;

  if (project.category && projectCategories[project.category.id]) {
    project.category = projectCategories[project.category.id];
  }

  if (project.company && companies[project.company.id]) {
    project.company = companies[project.company.id];
  }

  if (project.customFieldValues) {
    project.customFieldValues.forEach((customFieldValue, index) => {
      if (customFieldProjects[customFieldValue.id]) {
        project.customFieldValues[index] = customFieldProjects[customFieldValue.id];
      }
    });
  }

  if (project.latestActivity && activities[project.latestActivity.id]) {
    project.latestActivity = activities[project.latestActivity.id];
  }

  if (project.ownerId && users[project.ownerId]) {
    project.owner = { ...users[project.ownerId], entityType: 'user' };
  }

  if (project.projectOwnerId && users[project.projectOwnerId]) {
    project.projectOwner = { ...users[project.projectOwnerId], entityType: 'user' };
  }

  if (project.updatedBy && users[project.updatedBy]) {
    project.updatedBy = { ...users[project.updatedBy], entityType: 'user' };
  }

  if (project.createdBy && users[project.createdBy]) {
    project.createdBy = { ...users[project.createdBy], entityType: 'user' };
  }

  if (permissions[project.id]) {
    project.permissions = permissions[project.id];
  }

  if (project.portfolioCards) {
    project.portfolioCards.forEach((portfolioCard, index) => {
      if (portfolioCards[portfolioCard.id]) {
        project.portfolioCards[index] = portfolioCards[portfolioCard.id];
      }
    });
  }

  if (project.tags?.length) {
    project.tags.forEach((tag, index) => {
      if (tags[tag.id]) {
        project.tags[index] = tags[tag.id];
      }
    });
  }

  if (projectTaskStats[project.id]) {
    project.taskStats = projectTaskStats[project.id];
  }

  const budgetId = project.timeBudgetId || project.financialBudgetId;
  if (projectBudgets[budgetId]) {
    project.budget = projectBudgets[budgetId];
  }

  if (project.updateId && projectUpdates[project.updateId]) {
    project.update = projectUpdates[project.updateId];
  }

  Object.values(activities).forEach((activity) => {
    activity.datetime = activity.datetime ? DateTime.fromISO(activity.datetime) : null;

    if (activity.user && users[activity.user.id]) {
      activity.user = users[activity.user.id];
    }
  });

  Object.values(customFieldProjects).forEach((customFieldProject) => {
    if (customFieldProject.customfield) {
      customFieldProject.customField = customFieldProject.customfield;
      delete customFieldProject.customfield;
    }
    if (customFieldProject.customfieldId) {
      customFieldProject.customFieldId = customFieldProject.customfieldId;
      delete customFieldProject.customfieldId;
    }

    if (customFieldProject.customField && customFields[customFieldProject.customField.id]) {
      customFieldProject.customField = customFields[customFieldProject.customField.id];
    }
  });

  Object.values(portfolioCards).forEach((portfolioCard) => {
    if (portfolioCard.column && portfolioColumns[portfolioCard.column.id]) {
      portfolioCard.column = portfolioColumns[portfolioCard.column.id];
    }

    if (portfolioCard.board && portfolioBoards[portfolioCard.board.id]) {
      portfolioCard.board = portfolioBoards[portfolioCard.board.id];
    }
  });

  Object.values(projectCategories).forEach((projectCategory) => {
    if (projectCategory.parent && projectCategories[projectCategory.parent.id]) {
      projectCategory.parent = projectCategories[projectCategory.parent.id];
    }
  });

  Object.values(projectUpdates).forEach((projectUpdate) => {
    projectUpdate.createdAt = projectUpdate.createdAt ? DateTime.fromISO(projectUpdate.createdAt) : null;
    projectUpdate.updatedAt = projectUpdate.updatedAt ? DateTime.fromISO(projectUpdate.updatedAt) : null;

    if (users[projectUpdate.createdBy]) {
      projectUpdate.creator = users[projectUpdate.createdBy];
    }
  });

  const projectEmailDropbox = Object.values(projectEmailDropboxes).find((dropbox) => dropbox.project.id === project.id);

  if (projectEmailDropbox) {
    project.projectEmailDropbox = projectEmailDropbox;
  }

  return project;
}
/* eslint-enable no-param-reassign */

/**
 * Loads the specificed project.
 */
export function useProjectV3Loader({ projectId: _projectId, params, loadNow } = {}) {
  const projectId = shallowRef(_projectId);
  const { state, refresh } = useItemLoader({
    url: computed(() => projectId.value && `/projects/api/v3/projects/${projectId.value}.json`),
    params,
    responseToItem,
    loadNow,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'project') {
      refresh();
    }
  });

  return state;
}
