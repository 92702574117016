import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { parseDefaultFilterCustomFields } from './savedfiltersUtil';

export function useSavedfiltersV3Loader({ count, params, pageSize } = {}) {
  function computeDisplayOrder(savedfilter, savedfilters) {
    if (savedfilter.positionAfterTaskId !== 0) {
      const previousTask = savedfilters.find(({ id }) => id === savedfilter.positionAfterTaskId);
      if (previousTask) {
        return previousTask.displayOrder - 0.5;
      }
      return Number.MIN_SAFE_INTEGER;
    }
    return Number.MAX_SAFE_INTEGER;
  }

  function responseToItems({ data: { savedfilters, included = {} } }) {
    return savedfilters.map((savedfilter) => {
      const parameters = { ...savedfilter.parameters };
      let customFieldsObj = {};
      if (parameters.customFields) {
        // Parsing the string provided by the backend into a usable format.
        customFieldsObj = parseDefaultFilterCustomFields(parameters.customFields);
      }
      return {
        ...savedfilter,
        parameters: {
          ...parameters,
          ...customFieldsObj,
        },
        // The standard approach is to use the `included` data to resolve IDs
        // within the main data (`savedfilter` in this case), however, in this particular case
        // it makes more sense to simply expose `included` instead because:
        // - Keeping `savedfilter.parameters` in the format expected by the API is nice and clean.
        //   I don't want to lose this advantage just because some components need the included data.
        // - The set of parameters containing IDs is open-ended in this case.
        //   I don't want to pollute the loader with all of them.
        included,
      };
    });
  }

  const { state, update, refresh } = useListLoader({
    url: '/projects/api/v3/me/savedfilters.json',
    count,
    params,
    pageSize,
    responseToItems,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'savedfilter') {
      return;
    }
    update((savedfilters) => {
      if (event.action === 'create') {
        return savedfilters.concat(event.savedfilter);
      }
      if (event.action === 'update') {
        if (event.savedfilter.positionAfterTaskId != null) {
          return savedfilters
            .map((savedfilter) => {
              if (savedfilter.id === event.savedfilter.id) {
                const newSavedfilter = { ...savedfilter, ...event.savedfilter };
                newSavedfilter.displayOrder = computeDisplayOrder(newSavedfilter, savedfilters);
                return newSavedfilter;
              }
              return savedfilter;
            })
            .sort((a, b) => {
              return a.displayOrder - b.displayOrder;
            });
        }
        return savedfilters.map((savedfilter) =>
          savedfilter.id === event.savedfilter.id ? { ...savedfilter, ...event.savedfilter } : savedfilter,
        );
      }
      if (event.action === 'delete') {
        return savedfilters.filter((savedfilter) => savedfilter.id !== event.savedfilter.id);
      }
      return savedfilters;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'savedfilter') {
      refresh();
    }
  });

  return state;
}
