<script setup>
import { useLscDateStepper } from './useLscDateStepper';

const { size } = useLscDateStepper();

const dateStepperButtonVariantStyleConfig = tv({
  base: [
    'flex items-center justify-center rounded-xl border border-bold',
    'hover:border-strong',
    'active:border-focus-primary',
    'focus-visible:border-focus-primary',
  ],
  variants: {
    size: {
      sm: ['h7 px-3 text-body-2'],
      lg: ['h-8 px-4 text-body-1'],
    },
  },
});

const classes = computed(() =>
  dateStepperButtonVariantStyleConfig({
    size: size.value,
  }),
);
</script>

<template>
  <button type="button" :class="classes">
    <slot />
  </button>
</template>
