<script setup>
import { useI18n } from '@/util';
import PreviewAvatar from './OnboardingWizardCommonPreviewAvatar.vue';

const props = defineProps({
  data: {
    type: Object,
    default: undefined,
  },
  dataFromTemplate: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();

const transformedData = computed(() => {
  if (!props.data || !Array.isArray(props.data.boardColumns)) {
    return [
      {
        id: '1',
        color: 'var(--lsds-a-color-decorative-4)',
        tasks: [{ id: '1' }, { id: '2' }, { id: '3' }],
      },
      {
        id: '2',
        color: 'var(--lsds-a-color-decorative-6)',
        tasks: [{ id: '4' }],
      },
      {
        id: '3',
        color: 'var(--lsds-a-color-decorative-7)',
        tasks: [{ id: '5' }],
      },
    ];
  }

  const data = [];
  const tmpLookupObj = {};

  for (const column of props.data.boardColumns) {
    tmpLookupObj[column.id] = data.length;
    data.push({
      ...column,
      color: props.dataFromTemplate ? `#${column.color}` : column.color,
      tasks: [],
    });
  }

  for (const tasklist of props.data.tasklists) {
    for (const task of tasklist.tasks) {
      const columnIndex = tmpLookupObj[task.columnId];
      if (task.columnId && columnIndex !== undefined) {
        data[columnIndex].tasks = [
          ...data[columnIndex].tasks,
          { ...task, id: task.identifier, tasklistName: tasklist.name },
        ];
      }
    }
  }

  return data;
});
</script>
<template>
  <div class="flex max-h-full gap-2 rounded-sm pt-4">
    <!-- Column -->
    <div
      v-for="column in transformedData"
      :key="column.id"
      class="flex min-h-96 w-40 shrink-0 flex-col rounded-sm border-t-4 bg-surface-default px-0.5 pb-1 text-body-2 shadow-1"
      :style="{ borderColor: column.color }"
    >
      <div class="px-2 py-3">
        <LscOverflowEllipsis v-if="column.name" class="block font-semibold text-default">
          {{ column.name }}
        </LscOverflowEllipsis>
        <div v-else class="flex h-[--lsds-a-typography-body-2-regular-line-height] items-center">
          <LscSkeleton :animation="false" class="h-[--lsds-a-typography-body-2-regular-font-size] w-28" />
        </div>
      </div>

      <!-- Tasks container -->
      <div
        class="flex flex-1 flex-col gap-1 overflow-y-auto"
        :style="{ scrollbarColor: 'var(--lsds-c-scrollbar-color-default) transparent' }"
      >
        <div
          v-for="task in column.tasks"
          :key="task.id"
          class="mx-0.5 flex flex-col gap-2 rounded-sm bg-default p-2 shadow-1"
        >
          <div class="flex gap-2">
            <div>
              <LscIcon icon="lsi-teams-check-circle" size="sm" class="mt-0.5 text-icon-subtle" />
            </div>
            <div class="flex flex-col gap-1 overflow-hidden">
              <Component
                :is="dataFromTemplate ? 'div' : LscOverflowEllipsis"
                v-if="task.name"
                class="block text-default"
              >
                {{ task.name }}
              </Component>
              <div v-else class="flex h-[--lsds-a-typography-body-2-regular-line-height] items-center">
                <LscSkeleton :animation="false" class="h-[--lsds-a-typography-body-2-regular-font-size] w-28" />
              </div>

              <div v-if="task.due && task.name" class="text-subtle">
                {{ t('Due: {date}', { date: task.due }) }}
              </div>
              <LscOverflowEllipsis v-else-if="task.tasklistName" class="text-subtle">
                {{ t('List: {tasklistName}', { tasklistName: task.tasklistName }) }}
              </LscOverflowEllipsis>
              <div v-else class="flex h-[--lsds-a-typography-body-2-regular-line-height] items-center">
                <LscSkeleton :animation="false" class="h-[--lsds-a-typography-body-2-regular-font-size] w-20" />
              </div>
            </div>
          </div>

          <div class="flex gap-2">
            <PreviewAvatar v-if="task.identifier && task.name" :id="task.identifier" />
            <div v-else class="size-5 rounded-full bg-action-secondary-default" />
            <LscIcon v-if="task.priority" icon="lsi-priority-selected" class="text-icon-subtle" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
