import { useItemLoader } from '../base/useItemLoader.js';

function responseToItem(response) {
  return response.data;
}

export function useIntegrationParagonAuthV1Loader() {
  const { state } = useItemLoader({
    url: '/synthesis/api/v2/paragon/auth.json',
    responseToItem,
  });

  return state;
}
