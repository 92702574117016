import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { useI18n } from '../i18n/useI18n';

function ZodValidation() {
  const { t } = useI18n();

  // TODO: Make validation error messages more user friendly
  // See https://digitalcrew.teamwork.com/app/tasks/23779961
  function getMessage(issue) {
    switch (issue.code) {
      case z.ZodIssueCode.invalid_type:
        return t('Required');
      case z.ZodIssueCode.invalid_literal:
        return t('Invalid literal value');
      case z.ZodIssueCode.unrecognized_keys:
        return t('Unrecognized key(s) in object');
      case z.ZodIssueCode.invalid_union:
        return t('Invalid input');
      case z.ZodIssueCode.invalid_union_discriminator:
        return t('Invalid discriminator value');
      case z.ZodIssueCode.invalid_enum_value:
        return t('Invalid enum value');
      case z.ZodIssueCode.invalid_arguments:
        return t('Invalid function arguments');
      case z.ZodIssueCode.invalid_return_type:
        return t('Invalid function return type');
      case z.ZodIssueCode.invalid_date:
        return t('Invalid date');
      case z.ZodIssueCode.invalid_string:
        return t('Invalid input');
      case z.ZodIssueCode.too_small:
        if (issue.type === 'array') {
          return t('Array must contain a minimum number of elements');
        }
        if (issue.type === 'string') {
          return t('String must contain a minimum number of characters');
        }
        if (issue.type === 'number') {
          return t('Number must meet minimum requirements');
        }
        if (issue.type === 'date') {
          return t('Date must meet minimum requirements');
        }
        return t('Invalid input');
      case z.ZodIssueCode.too_big:
        if (issue.type === 'array') {
          return t('Array must contain a maximum number of elements');
        }
        if (issue.type === 'string') {
          return t('String must contain a maximum number of characters');
        }
        if (issue.type === 'number') {
          return t('Number must meet maximum requirements');
        }
        if (issue.type === 'date') {
          return t('Date must meet maximum requirements');
        }
        return t('Invalid input');
      case z.ZodIssueCode.custom:
        return t('Invalid input');
      case z.ZodIssueCode.invalid_intersection_types:
        return t('Intersection results could not be merged');
      case z.ZodIssueCode.not_multiple_of:
        return t('Number must be a multiple of a specific value');
      case z.ZodIssueCode.not_finite:
        return t('Number must be finite');
      default:
        // eslint-disable-next-line no-case-declarations
        const warningMessage = `Missing Zod validation message for issue code: ${issue.code}`;

        // eslint-disable-next-line no-console
        console.warn(warningMessage);

        Sentry.captureMessage(warningMessage, {
          level: 'warning',
          extra: { issue },
        });

        return t('Invalid');
    }
  }

  function customErrorMap(issue) {
    return { message: getMessage(issue) };
  }

  z.setErrorMap(customErrorMap);
}

/**
 * @param {import('vue').App} app
 * @type {ZodValidation}
 */
export const zodValidationPlugin = {
  install() {
    ZodValidation();
  },
};
