<script setup>
import { useElementSize } from '@/util';
import AppShellSidebarProjectsList from './AppShellSidebarProjectsList.vue';

defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
  shouldLoadOnScroll: {
    type: Boolean,
    default: true,
  },
  useNewList: {
    type: Boolean,
    default: true,
  },
  noProjectsMessage: {
    type: String,
    default: '',
  },
  projectListType: {
    type: String,
    default: undefined,
  },
});

const el = shallowRef(null);
const { height } = useElementSize(el);
const currentHeight = computed(() => `${height.value}px`);
</script>

<template>
  <div class="overflow-hidden transition-all duration-300" :style="{ height: currentHeight }">
    <div ref="el">
      <AppShellSidebarProjectsList v-if="show" :step="step" :state="state" :projectListType="projectListType">
        <template #loading>
          <div class="flex flex-col items-center gap-4 py-1">
            <VProgressCircular indeterminate color="primary" :width="1" :size="20" />
          </div>
        </template>
        <template #blank>
          <VList density="compact" class="m-0 p-0">
            <VListItem v-bind="VListItemSidebarProject">
              <VListItemSubtitle class="text-body-2">
                {{ noProjectsMessage }}
              </VListItemSubtitle>
            </VListItem>
          </VList>
        </template>
      </AppShellSidebarProjectsList>
    </div>
  </div>
</template>
