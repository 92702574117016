<script setup>
import { useI18n } from '@/util';

defineProps({
  text: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['apply']);

const { t } = useI18n();

const AIEditorOptions = defineAsyncComponent(() => import('./AIEditorOptions.vue'));

const modelValue = defineModel({ type: Boolean, default: false });

function onApply(textFromAI) {
  emit('apply', textFromAI);
  modelValue.value = false;
}
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <WidgetDialogPanel :title="t('AI assistant')" @close="close">
        <AIEditorOptions :text="text" class="-mx-6 -mt-6" @apply="onApply" />
      </WidgetDialogPanel>
    </template>
  </WidgetDialog>
</template>
