<!-- eslint-disable lightspeed/prefer-shallow-ref -->
<script setup>
import { useI18n } from '@/util';
import { useCurrentUser, usePreferences } from '@/api';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonUserRatesRow from '../common/OnboardingWizardCommonUserRatesRow.vue';
import { ProjectAddPeopleDialog } from '@/module/project';
import { useCompositeProjectUsersAndRatesLoader } from './useCompositeProjectUsersAndRatesLoader';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import { useOnboardingWizardUserRates } from './useOnboardingWizardUserRates';
import { useOnboardingWizard } from '../useOnboardingWizard';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';
import {
  PREVIEW_TAB_TABLE,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_PEOPLE,
  PREVIEW_TAB_FINANCE,
  STEP_INVITE_USERS_AND_SET_TEAM_RATES,
} from '../constants';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  nextButtonText: {
    type: String,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['nextStep', 'previousStep', 'close']);

const { [STEP_INVITE_USERS_AND_SET_TEAM_RATES]: stateData } = props.state;

const currentUser = useCurrentUser();
const { t } = useI18n();
const { sampleUserIds } = usePreferences();
const { currentProject } = useOnboardingWizard();
const {
  currencyId,
  currencySymbol,
  getPreviewData,
  automaticCostRatesCalculationAlertVisible,
  automaticCostRatesCalculationEnabled,
  showAutomaticCostRatesCalculationAlert,
  hasClearValidationStatus,
  defaultRate,
  defaultRateEl,
  showValidationError,
  handleDefaultRateInputBlurred,
  billableRates,
  costRates,
  v$,
} = useOnboardingWizardUserRates(props.state);

const contextProject = shallowRef({});

const { items: projectUsersAndRates, inSync: projectUsersAndRatesInSync } = useCompositeProjectUsersAndRatesLoader({
  projectId: computed(() => contextProject.value.id),
  count: Infinity,
});

const { trackGoalBasedAddTeamMemberClicked } = useOnboardingWizardTracking();

const originalBillableRates = ref([]);
const originalCostRates = ref([]);
const projectUsers = ref([]);

const projectUsersState = ref({
  users: [],
  billableRates: [],
  costRates: [],
  addUser(user) {
    if (!projectUsersState.value.users.find((u) => u.id === user.id)) {
      projectUsersState.value.users.push(user);
    }
  },
});

const showProjectAddPeopleDialog = shallowRef(false);
const previewData = shallowRef(null);
const emptyRatesIndexes = shallowRef([]);
const hasAddedUsers = shallowRef(false);
const initialUserSet = shallowRef(false);
const automaticCostRatesCalculationAlertText = shallowRef('');

let initialUsersListLength = 0;

const projectName = computed(() => contextProject.value.name);

const firstPersonIndexWithBothRatesSet = computed(() =>
  [...Array(projectUsers.value.length).keys()].findIndex((i) => billableRates.value[i] && costRates.value[i]),
);

const previewFinanceData = computed(() => {
  return {
    ...previewData.value,
    currencyId: currencyId.value,
    showProjectProfitabilityInformation: true,
  };
});

const updatedUsers = computed(() =>
  projectUsers.value.reduce((acc, user, index) => {
    const currentBillableRate = projectUsersState.value.billableRates[index];
    const currentCostRate = projectUsersState.value.costRates[index];
    const originalBillableRate = originalBillableRates.value[index];
    const originalCostRate = originalCostRates.value[index];

    if (currentBillableRate !== originalBillableRate || currentCostRate !== originalCostRate) {
      acc.push({
        userId: user.user.id ?? user.id,
        billableRate: currentBillableRate,
        costRate: currentCostRate,
        projectId: contextProject.value.id,
        billableRateChanged: currentBillableRate !== originalBillableRate,
        costRateChanged: currentCostRate !== originalCostRate,
      });
    }
    return acc;
  }, []),
);

const contextProjectUserIds = computed(() => contextProject.value.users.map((user) => user.id));

function skipStep() {
  emit('nextStep', !stateData || !stateData?.skipped, { skipped: true });
}

function nextStep() {
  v$.value.$touch();
  if (!hasClearValidationStatus.value || v$.value.$error) {
    showValidationError.value = true;
    return;
  }

  if (initialUsersListLength < projectUsers.value.length) {
    hasAddedUsers.value = true;
  }

  const newData = {
    defaultRate: defaultRate.value,
    billableRates: updatedUsers.value.map((user) => user.billableRate),
    costRates: updatedUsers.value.map((user) => user.costRate),
    invitedUsers: updatedUsers,
    hasAddedUsers: hasAddedUsers.value,
    hasModifiedRates: updatedUsers.value.length > 0,
    projectId: contextProject.value.id,
    numberOfNewBillableRates: updatedUsers.value.filter((user) => user.billableRateChanged).length,
    numberOfNewCostRates: updatedUsers.value.filter((user) => user.costRateChanged).length,
    numberOfInvitedUsers:
      projectUsers.value.length === initialUsersListLength ? 0 : projectUsers.value.length - initialUsersListLength,
  };

  const hasDataChanged = updatedUsers.value.length > 0 || hasAddedUsers.value;

  emit('nextStep', hasDataChanged, newData);
}

function displayInviteUsersModal() {
  trackGoalBasedAddTeamMemberClicked(contextProject.value.id);
  showProjectAddPeopleDialog.value = true;
}

function handleBillableRateUpdate(index, value) {
  if (value === '' && originalBillableRates.value[index] === null) {
    billableRates.value[index] = null;
    projectUsersState.value.billableRates[index] = null;
    return;
  }
  billableRates.value[index] = value;
  projectUsersState.value.billableRates[index] = billableRates.value[index];
}

function handleCostRateUpdate(index, value) {
  if (value === '' && originalCostRates.value[index] === null) {
    costRates.value[index] = null;
    projectUsersState.value.costRates[index] = null;
    return;
  }
  costRates.value[index] = value;
  projectUsersState.value.costRates[index] = costRates.value[index];
}

function setCostRatesCalculationAlertText(isCustomRate) {
  const message = isCustomRate
    ? t('We have set the cost rate to 60% of the billable rate, you can adjust this rate to reflect your project costs')
    : t("The default rate will be applied to all users on the project who haven't had a rate set up previously");

  automaticCostRatesCalculationAlertText.value = message;
}

watch(currentProject, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    contextProject.value = newValue;
  }
});

watch(projectUsersAndRates, (newValues) => {
  const filteredNewValues = newValues.filter((user) => contextProjectUserIds.value.includes(user.id));
  if (filteredNewValues.length > 0) {
    const currentUserIndex = filteredNewValues.findIndex((user) => user.id === currentUser.value.id);

    if (currentUserIndex !== -1) {
      const currentUserObject = filteredNewValues[currentUserIndex];
      filteredNewValues.splice(currentUserIndex, 1);
      // place current user at the top of the list in the UI
      filteredNewValues.unshift(currentUserObject);
    }

    const nonSampleUsersList = filteredNewValues.filter((person) => !sampleUserIds.value.includes(person.id));

    const initialBillableRates = [];
    const initialCostRates = [];

    nonSampleUsersList.forEach((user) => {
      let adjustedBillableRate = null;
      let adjustedCostRate = null;

      const billableRate = user.effectiveRate;
      if (billableRate != null) {
        adjustedBillableRate = Number.isInteger(billableRate) ? billableRate / 100 : billableRate;
      }

      const costRate = user.costRate?.cost;
      if (costRate != null) {
        adjustedCostRate = Number.isInteger(costRate) ? costRate / 100 : costRate;
      }

      initialBillableRates.push(adjustedBillableRate);
      initialCostRates.push(adjustedCostRate);
    });

    projectUsers.value = nonSampleUsersList;

    originalBillableRates.value = initialBillableRates;
    originalCostRates.value = initialCostRates;
  }
});

watch(defaultRate, (newDefaultRate) => {
  if (!newDefaultRate.length) {
    billableRates.value = [...originalBillableRates.value];
    costRates.value = [...originalCostRates.value];
  }
});

watch(projectUsers, () => {
  const updatedBillableRates = [];
  const updatedCostRates = [];
  const emptyIndexes = [];

  const usersWithEditableRates = projectUsers.value.filter((user) => user.canEditRates);

  projectUsers.value.forEach((user, index) => {
    if (!usersWithEditableRates.includes(user)) {
      updatedBillableRates[index] = null;
      updatedCostRates[index] = null;
      return;
    }

    const billableRate = user.effectiveRate;
    const costRate = user.costRate?.cost;

    let adjustedBillableRate = null;
    if (billableRate != null) {
      adjustedBillableRate = Number.isInteger(billableRate) ? billableRate / 100 : billableRate;
    }
    updatedBillableRates[index] = adjustedBillableRate;

    let adjustedCostRate = null;
    if (costRate != null) {
      adjustedCostRate = Number.isInteger(costRate) ? costRate / 100 : costRate;
    }
    updatedCostRates[index] = adjustedCostRate;

    if (adjustedBillableRate === null && adjustedCostRate === null) {
      emptyIndexes.push(index);
    }
  });

  billableRates.value = updatedBillableRates;
  costRates.value = updatedCostRates;
  emptyRatesIndexes.value = emptyIndexes;
});

watch(
  () => [billableRates.value, costRates.value, firstPersonIndexWithBothRatesSet.value],
  () => {
    if (firstPersonIndexWithBothRatesSet.value !== undefined && firstPersonIndexWithBothRatesSet.value !== -1) {
      previewData.value = getPreviewData(firstPersonIndexWithBothRatesSet.value);
    }
  },
  { deep: true },
);

watch(
  () => projectUsers.value,
  (newUsers) => {
    if (newUsers.length > 0) {
      newUsers.forEach((user) => {
        if (!projectUsersState.value.users.find((u) => u.id === user.id)) {
          projectUsersState.value.addUser(user);
          if (!initialUserSet.value) {
            initialUsersListLength = newUsers.length;
            initialUserSet.value = true;
          }
        }
      });
    }
  },
  { immediate: true },
);

watch(
  () => projectUsersState.value.users,
  (newValues) => {
    const updatedBillableRates = [...projectUsersState.value.billableRates];
    const updatedCostRates = [...projectUsersState.value.costRates];

    const usersWithEditableRates = projectUsersState.value.users.filter((user) => user.canEditRates);

    newValues.forEach((user, index) => {
      if (!usersWithEditableRates.includes(user)) {
        updatedBillableRates[index] = null;
        updatedCostRates[index] = null;
        return;
      }

      const billableRate = user.effectiveRate;
      const costRate = user.costRate?.cost;

      let adjustedBillableRate = null;
      if (billableRate != null) {
        adjustedBillableRate = Number.isInteger(billableRate) ? billableRate / 100 : billableRate;
      }

      if (updatedBillableRates[index] == null) {
        projectUsersState.value.billableRates[index] = adjustedBillableRate;
      }

      let adjustedCostRate = null;
      if (costRate != null) {
        adjustedCostRate = Number.isInteger(costRate) ? costRate / 100 : costRate;
      }

      if (updatedCostRates[index] == null) {
        projectUsersState.value.costRates[index] = adjustedCostRate;
      }
    });
  },
  { deep: true },
);

watch(defaultRate, (newDefaultRate) => {
  automaticCostRatesCalculationAlertText.value = t(
    "The default rate will be applied to all users on the project who haven't had a rate set up previously",
  );
  showAutomaticCostRatesCalculationAlert();
  if (newDefaultRate === '') {
    billableRates.value = originalBillableRates.value.slice();
    costRates.value = originalCostRates.value.slice();
    projectUsersState.value.billableRates = originalBillableRates.value.slice();
    projectUsersState.value.costRates = originalCostRates.value.slice();
    return;
  }

  const parsedRate = Number(newDefaultRate.toFixed(2));

  emptyRatesIndexes.value.forEach((index) => {
    billableRates.value[index] = parsedRate;
    projectUsersState.value.billableRates[index] = parsedRate;
  });

  if (automaticCostRatesCalculationEnabled.value) {
    const newCostRate = (parsedRate * 0.6).toFixed(2);
    emptyRatesIndexes.value.forEach((personIndex) => {
      costRates.value[personIndex] = Number(newCostRate);
      projectUsersState.value.costRates[personIndex] = Number(newCostRate);
    });
  }
});

watchEffect(() => {
  if (defaultRate.value) {
    showAutomaticCostRatesCalculationAlert();
  }
});

onMounted(() => {
  if (!defaultRate.value) {
    defaultRateEl.value.focus();
  }
});
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Invite your team & set rates')"
    :description="
      t(
        'Invite your team to a specific individual project and set their rates. Adding billable and cost rates allows you to track profitability and progress against financial budgets. You can update these later.',
      )
    "
  >
    <div class="grid grid-cols-3 items-start gap-2">
      <div class="col-start-3 flex items-center gap-1">
        <label class="text-body-1 font-semibold" for="defaultRate">{{ t('Default rate') }}</label>
        <LscIcon
          v-LsdTooltip="
            t(
              '(Optional) Set the default hourly rate you charge your client for this project. This rate can be customized per user. It\'s visible to the site owner and the owner and client company admins.',
            )
          "
          class="cursor-help text-icon-default focus:outline-none"
          size="sm"
          icon="lsi-tooltip"
        />
      </div>

      <VTextField
        id="defaultRate"
        ref="defaultRateEl"
        v-model.number="defaultRate"
        variant="outlined"
        min="0"
        max="9999999"
        type="number"
        class="col-start-3"
        :label="t('Add rate')"
        :placeholder="t('Not set')"
        :errorMessages="v$.defaultRate.$errors.map((error) => error.$message)"
        @blur="handleDefaultRateInputBlurred"
      >
        <template #prepend-inner>
          <div class="mr-2 text-subtle">
            {{ currencySymbol }}
          </div>
        </template>
      </VTextField>

      <div class="relative col-span-3">
        <div class="my-6 border-b" />
        <Transition
          enterFromClass="opacity-0"
          enterToClass="opacity-100"
          enterActiveClass="transition-opacity duration-300"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
          leaveActiveClass="transition-opacity duration-300"
        >
          <LscAlert
            v-if="automaticCostRatesCalculationAlertVisible"
            class="z-10 mb-3 xl:absolute xl:left-full xl:top-12 xl:ml-4 xl:w-72"
            :message="automaticCostRatesCalculationAlertText"
          />
        </Transition>

        <div class="mb-1 grid grid-cols-3 items-start gap-2">
          <div class="text-body-1 font-semibold">{{ t('Individual rates') }}</div>
          <div class="flex items-center gap-1">
            <div class="text-body-1 font-semibold">{{ t('Billable rate') }}</div>
            <LscIcon
              v-LsdTooltip="
                t(
                  'The hourly rate you charge your client for each user. This rate can be customised per project. It\'s visible to the site owner and owner and client company admins.',
                )
              "
              class="cursor-help text-icon-default focus:outline-none"
              size="sm"
              icon="lsi-tooltip"
            />
          </div>
          <div class="flex items-center gap-1">
            <div class="text-body-1 font-semibold">{{ t('Cost rate') }}</div>
            <LscIcon
              v-LsdTooltip="
                t(
                  'The hourly cost to your company to employ this user. This is visible to the site owner and owner company admins.',
                )
              "
              class="cursor-help text-icon-default focus:outline-none"
              size="sm"
              icon="lsi-tooltip"
            />
          </div>
        </div>
        <div class="max-h-56 w-full overflow-auto pr-2">
          <OnboardingWizardCommonUserRatesRow
            v-for="(projectUser, personIndex) of projectUsersState.users"
            :key="personIndex"
            :personIndex="personIndex"
            :projectUser="projectUser"
            :currencySymbol="currencySymbol"
            :billableRate="projectUsersState.billableRates[personIndex]"
            :costRate="projectUsersState.costRates[personIndex]"
            :showAutomaticCostRatesCalculationAlert="showAutomaticCostRatesCalculationAlert"
            :originalBillableRate="originalBillableRates[personIndex]"
            :originalCostRate="originalCostRates[personIndex]"
            :showValidationError="showValidationError"
            :isOnboardingGoalContext="true"
            @update:billableRate="handleBillableRateUpdate(personIndex, $event)"
            @update:costRate="handleCostRateUpdate(personIndex, $event)"
            @update:automaticCostRatesCalculationEnabled="automaticCostRatesCalculationEnabled = $event"
            @update:costRateMessage="setCostRatesCalculationAlertText($event)"
          />
        </div>
        <span v-if="!projectUsersAndRatesInSync" class="mt-2 flex justify-center">
          <VProgressCircular indeterminate color="primary" :width="3" class="size-6" />
        </span>
      </div>
    </div>

    <div class="mt-5">
      <LscButton variant="plain-primary" size="md" prependIcon="lsi-add" @click="displayInviteUsersModal">
        {{ t('Add team member') }}
      </LscButton>
    </div>
    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :preselectedTab="PREVIEW_TAB_FINANCE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_PEOPLE, PREVIEW_TAB_FINANCE]"
        :tabsClickable="false"
        :financeData="previewFinanceData"
      />
    </template>
    <ProjectAddPeopleDialog v-model="showProjectAddPeopleDialog" :project="contextProject" />
  </OnboardingWizardCommonStep>
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :nextStep="nextStep"
    :skipButtonText="t('Skip')"
    :showSkipButton="true"
    :skipStep="skipStep"
  />
</template>
