<script setup>
import { useRouter } from 'vue-router';
import { useExperimentE2401, usePermissions, usePreferences } from '@/api';
import { useI18n } from '@/util';
import { useFeedback } from '@/module/feedback';
import { Fire } from '@/module/featureTrialBundle';
import { ContactFormDialog } from '@/module/contact';

const { t, formatCurrency } = useI18n();
const router = useRouter();
const { isExpE2401Variation1, isExpE2401Variation2, trackEventE2401 } = useExperimentE2401();
const { isOwnerAdmin: canAccessCheckout } = usePermissions();
const { showContactForm, isContactFormOpen, contactFormProps } = useFeedback();
const { shouldShowJan2024SaleBanner } = usePreferences();

const showBanner = computed(
  () => (isExpE2401Variation1.value || isExpE2401Variation2.value) && shouldShowJan2024SaleBanner.value,
);

const variationOneText = computed(() =>
  t('For one more day, we are offering {growDiscount} for 3 months, and {deliverDiscount} for 3 months'),
);
const variationTwoText = computed(() =>
  t(
    "For one more day, we are offering {growDiscount} or {deliverDiscount} for 3 months; that's a saving of {growSavings} on a standard {grow} plan",
  ),
);

const variantText = computed(() => (isExpE2401Variation1.value ? variationOneText.value : variationTwoText.value));

const contactFormPayload = {
  formType: 'checkoutExcludedUser',
  title: t('Contact Us'),
  subtitle: t(
    'Simply fill out your details here to make changes to your subscription, and your Customer Success representative will contact you to figure out the best solution for your needs.',
  ),
  entryPoint: 'Jan2024Sale',
};

function handleCTAClicked() {
  trackEventE2401({ eventAction: 'redeem_now_clicked' });
  return canAccessCheckout.value ? router.push({ path: '/redirect/checkout' }) : showContactForm(contactFormPayload);
}

function closeBanner() {
  shouldShowJan2024SaleBanner.value = false;
  trackEventE2401({ eventAction: 'close_icon_clicked' });
}

onMounted(() => {
  trackEventE2401({ eventAction: 'jan_sale_banner_displayed' });
});
</script>
<template>
  <LscAlert
    v-if="showBanner"
    layout="banner"
    closable
    variant="info-subdued"
    :title="t('January Sale ends today! Get up to 50% OFF our most popular plans')"
    @close="closeBanner"
  >
    <template #media>
      <Fire class="min-w-9" />
    </template>
    <p>
      <LscReplace :text="variantText">
        <template #growDiscount>
          <span class="font-semibold">{{ t('50% off our {grow} plan', { grow: 'Grow' }) }}</span>
        </template>
        <template #deliverDiscount>
          <span class="font-semibold">{{ t('25% off our {deliver} plan', { deliver: 'Deliver' }) }}</span>
        </template>
        <template #growSavings>
          <span class="font-semibold">{{ formatCurrency(149.95) }}</span>
        </template>
        <template #grow><span class="font-semibold">Grow</span></template>
      </LscReplace>
    </p>
    <template #action>
      <LscAlertButton @click="handleCTAClicked">
        {{ canAccessCheckout ? t('Redeem now') : t('Contact us') }}
      </LscAlertButton>
    </template>
  </LscAlert>
  <ContactFormDialog v-bind="contactFormProps" v-model="isContactFormOpen" />
</template>
