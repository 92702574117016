<script setup>
import { useI18n } from '@/util';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import SpacesDrawerAZ from './SpacesDrawerAZ.vue';
import SpacesDrawerCategory from './SpacesDrawerCategory.vue';
import SpacesDrawerRecent from './SpacesDrawerRecent.vue';
import SpacesDrawerStarred from './SpacesDrawerStarred.vue';
import SpacesDrawerSearch from './SpacesDrawerSearch.vue';
import { useSpacesDrawer } from './useSpacesDrawer';
import SpacesSearchDrawerMenu from './SpacesSearchDrawerMenu.vue';

const { selectedTab, userIsGettingStarted, searchTerm } = useSpacesDrawer();
const { activeDrawerPanel, clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { t } = useI18n();

const selectedSearchEntity = shallowRef('all');
const searchEntities = computed(() => [
  { entity: 'all', name: t('Search all titles'), shortName: t('all'), icon: 'lsi-search-all' },
  {
    entity: 'spaces',
    name: t('Search {productName} titles', { productName: 'Spaces' }),
    shortName: 'spaces',
    icon: 'lsi-spaces-search-space',
  },
  { entity: 'pages', name: t('Search page titles'), shortName: t('pages'), icon: 'lsi-spaces-search-page' },
  { entity: 'content', name: t('Search page content'), shortName: t('content'), icon: 'lsi-spaces-search-page' },
]);

const showTabs = computed(() => !userIsGettingStarted.value && searchTerm.value === '');
</script>

<template>
  <AppShellDrawerPanel v-if="activeDrawerPanel === 'spaces'" data-identifier="side-nav-jump-to-panel">
    <template #header>
      <AppShellDrawerTitle :title="t('Spaces')">
        <template #append-title>
          <LscLabel class="ml-2">{{ t('Beta') }}</LscLabel>
        </template>
      </AppShellDrawerTitle>
    </template>
    <template #content>
      <div class="m-0 flex h-full flex-col overflow-hidden p-0">
        <!-- Search section -->
        <Transition>
          <VTextField
            v-if="!userIsGettingStarted"
            v-model.trim="searchTerm"
            v-bind="VTextFieldPanelSearch"
            autofocus
            class="z-10 mx-6 h-[42px] grow-0"
            :placeholder="t('Search')"
          >
            <template #append-inner>
              <SpacesSearchDrawerMenu v-model="selectedSearchEntity" :options="searchEntities" />
            </template>
          </VTextField>
        </Transition>

        <!-- Tabs section -->
        <Transition>
          <div v-if="showTabs" class="h-[56px] grow-0 px-6 pt-2">
            <VTabs v-bind="VTabsUnderline" v-model="selectedTab">
              <VTab value="recent" data-identifier="spaces-panel-tab-recent">{{ t('Recent') }}</VTab>
              <VTab value="starred" data-identifier="spaces-panel-tab-starred">{{ t('Starred') }}</VTab>
              <VTab value="az" data-identifier="spaces-panel-tab-az">{{ t('All') }}</VTab>
              <VTab value="category" data-identifier="spaces-panel-tab-category">{{ t('Categories') }}</VTab>
            </VTabs>
          </div>
        </Transition>

        <div class="h-full overflow-y-auto">
          <KeepAlive>
            <SpacesDrawerSearch
              v-if="searchTerm"
              :searchTerm="searchTerm"
              :selectedSearchEntity="selectedSearchEntity"
            />
            <SpacesDrawerStarred v-else-if="selectedTab === 'starred'" />
            <SpacesDrawerRecent
              v-else-if="selectedTab === 'recent'"
              v-model:userIsGettingStarted="userIsGettingStarted"
            />
            <SpacesDrawerCategory v-else-if="selectedTab === 'category'" />
            <SpacesDrawerAZ v-else v-model:userIsGettingStarted="userIsGettingStarted" />
          </KeepAlive>
        </div>
      </div>
    </template>
    <template #footer>
      <Transition>
        <div v-if="!userIsGettingStarted" class="my-7 flex flex-col gap-4">
          <RouterLink
            to="/spaces/home"
            class="flex w-full items-center gap-2 text-body-1 font-semibold text-primary no-underline"
            @click="clearActiveDrawerPanelIfNotPinned"
          >
            <LscIcon icon="lsi-app-spaces" />
            {{ t('{productName} home', { productName: 'Spaces' }) }}
            <LscIcon class="ml-auto" icon="lsi-open-panel" />
          </RouterLink>
          <div class="h-px w-full border-t border-separator-on-surface-default" />
          <RouterLink
            to="/spaces/templates"
            class="flex w-full gap-2 text-body-1 font-semibold text-subtle no-underline hover:text-default"
            @click="clearActiveDrawerPanelIfNotPinned"
          >
            <LscIcon icon="lsi-multiple-templates" />
            {{ t('{productName} templates', { productName: 'Spaces' }) }}
            <LscIcon class="ml-auto" icon="lsi-open-panel" />
          </RouterLink>
        </div>
      </Transition>
    </template>
  </AppShellDrawerPanel>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition:
    height 0.2s ease-out,
    opacity 1s;
}

.v-enter-from,
.v-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
