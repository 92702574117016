<script setup>
import { getInitials } from '@/util';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['md', 'lg'].includes(value),
  },
  preview: {
    type: Object,
    default: null,
  },
});

const logoIcon = computed(() => {
  return props.preview?.logoIcon || props.project.logoIcon;
});

const bgColorsPalette = ['#94c7fb', '#bba1ff', '#97e8f0', '#ffa785', '#95e1c1', '#ffa0dd'];

function getIconBGColor(project) {
  if (project.logoColor) {
    return project.logoColor;
  }
  return project?.category?.color || bgColorsPalette[project.id % bgColorsPalette.length];
}
</script>

<template>
  <div
    class="mr-3 flex items-center justify-center rounded-sm text-[#000]"
    :class="{ 'size-6': size === 'md', 'size-8': size === 'lg' }"
    :style="{ backgroundColor: preview?.logoColor || getIconBGColor(project) }"
  >
    <template v-if="logoIcon">
      <template v-if="logoIcon.startsWith('lsi-')">
        <LscIcon :icon="logoIcon" :size="size === 'md' ? 16 : 20" class="align-middle" />
      </template>
      <template v-else>
        {{ logoIcon }}
      </template>
    </template>
    <template v-else>
      {{ getInitials(project.name, 1) }}
    </template>
  </div>
</template>
