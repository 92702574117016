import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { updates } = response.data;

  updates.forEach((update) => {
    // eslint-disable-next-line no-param-reassign
    update.updateTime = DateTime.fromISO(update.date);
  });
  return updates;
}

function responseToMeta(response) {
  return {
    totalCount: Number(response.headers['x-records']),
  };
}

export function useProductUpdatesV1Loader({ count, pageSize = 25, params }) {
  const { state } = useListLoader({
    url: '/updates/latest.json',
    count,
    pageSize,
    params,
    responseToItems,
    responseToMeta,
  });
  return state;
}
