<script setup>
defineProps({
  colors: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: undefined,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({ type: String, default: null });
</script>
<template>
  <WidgetMenu class="LscColorPickerMenu" offset="8">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LscSheet class="flex flex-col gap-4" rounded>
      <h4 v-if="$slots.title || title" class="text-body-1 text-default">
        <slot name="title">{{ title }}</slot>
      </h4>
      <LscColorPicker v-model="modelValue" :colors="colors" :required="required" />
    </LscSheet>
  </WidgetMenu>
</template>
