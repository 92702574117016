import { getLastChildPath } from '../lastChildPath';

export const reportsRoutes = [
  {
    path: '/reports',
    component: () => import('./RouteReports.vue'),
    meta: {
      sampleProjectsBannerVisible: true,
    },
    children: [
      {
        path: '',
        redirect() {
          return `/reports/${getLastChildPath('reports', 'gallery')}`;
        },
      },
      {
        path: 'gallery',
        component: () => import('./RouteReportsGallery.vue'),
      },
      {
        path: 'financial-insights',
        component: () => import('./RouteReportsFinancialInsights.vue'),
      },
      {
        path: 'health',
        component: () => import('./RouteReportsProjectHealth.vue'),
        children: [
          {
            path: '',
            component: () => import('./RouteReportsProjectHealthDefault.vue'),
          },
          {
            path: 'ai-assistant',
            component: () => import('./RouteReportsProjectHealthAIAssistant.vue'),
            meta: {
              breadcrumbsEnabled: false,
            },
          },
        ],
      },
      {
        path: 'plannedvsactual',
        component: () => import('./RouteReportsPlannedVsActual.vue'),
        children: [
          {
            path: '',
            redirect() {
              return `/reports/plannedvsactual/${getLastChildPath('reports/plannedvsactual', 'tasks')}`;
            },
          },
          {
            path: 'tasks',
            component: () => import('./RouteReportsPlannedVsActualTasks.vue'),
          },
          {
            path: 'milestones',
            component: () => import('./RouteReportsPlannedVsActualMilestones.vue'),
          },
        ],
      },
      {
        path: 'profitability',
        component: () => import('./RouteReportsProfitability.vue'),
        children: [
          {
            path: '',
            redirect() {
              return `/reports/profitability/${getLastChildPath('reports/profitability', 'projects')}`;
            },
          },
          {
            path: 'projects',
            component: () => import('./RouteReportsProfitabilityProjects.vue'),
          },
          {
            path: 'users',
            component: () => import('./RouteReportsProfitabilityUsers.vue'),
          },
          {
            path: 'ai-assistant',
            component: () => import('./RouteReportsProfitabilityAIAssistant.vue'),
            meta: {
              breadcrumbsEnabled: false,
            },
          },
        ],
      },
      {
        path: 'time',
        component: () => import('./RouteReportsTime.vue'),
      },
      {
        path: 'utilization',
        component: () => import('./RouteReportsUtilization.vue'),
      },
      {
        path: ':customReport(\\d+)',
        component: () => import('./RouteReportsCustomReport.vue'),
        props: (route) => ({
          reportId: Number(route.params.customReport) || null,
        }),
      },
      {
        path: 'projectstime',
        component: () => import('./RouteReportsProjectLoggedTime.vue'),
      },
      {
        path: 'usertime',
        component: () => import('./RouteReportsUserLoggedTime.vue'),
      },
      {
        path: 'tasktime',
        component: () => import('./RouteReportsTaskTime.vue'),
      },
      {
        path: 'usertaskcompletion',
        component: () => import('./RouteReportsUserTaskCompletion.vue'),
      },
    ],
  },
  // Separate as we do not want breadcrums on the quickview
  {
    path: '/reports/forecasted-utilization-by-user',
    component: () => import('./RouteReportsForecastedUtilizationByUser.vue'),
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: true,
    },
  },
  {
    path: '/reports/past-utilization-by-user',
    component: () => import('./RouteReportsPastUtilizationByUser.vue'),
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: true,
    },
  },
  {
    path: '/reports/past-profitability-for-all-clients',
    component: () => import('./RouteReportsPastProfitabilityAllClients.vue'),
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: true,
    },
  },
  {
    path: '/reports/forecasted-revenue-and-profitability-for-all-clients',
    component: () => import('./RouteReportsForecastedRevenueAndProfitabilityAllClients.vue'),
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: true,
    },
  },
  {
    path: '/reports/forecasted-cost-vs-revenue-for-all-clients',
    component: () => import('./RouteReportsForecastedCostVsRevenueAllClients.vue'),
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: true,
    },
  },
  {
    path: '/reports/top-10-projects-over-budget',
    component: () => import('./RouteReportsTop10ProjectsOverBudget.vue'),
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: true,
    },
  },
];
