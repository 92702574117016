<script setup>
import { useI18n } from '@/util';
import NotificationDrawerEmptyStateMuted from './assets/NotificationDrawerEmptyStateMuted.svg';

const emit = defineEmits(['unmute']);

const { t } = useI18n();
</script>

<template>
  <LscEmptyState class="h-full" :title="t('Notifications muted')" :message="t('You have muted notifications')">
    <template #image>
      <NotificationDrawerEmptyStateMuted />
    </template>

    <template #actions>
      <LscButton variant="primary" @click="emit('unmute')">{{ t('Unmute') }}</LscButton>
    </template>
  </LscEmptyState>
</template>
