<script setup>
import { useLscDateStepper } from './useLscDateStepper';

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  showDropDownIcon: {
    type: Boolean,
    default: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
});

const { size } = useLscDateStepper();
</script>

<template>
  <LscButton
    ref="labelButton"
    variant="plain-secondary"
    :appendIcon="showDropDownIcon ? 'lsi-dropdown' : undefined"
    :size="size"
    :dataIdentifierPrefix="`${dataIdentifierPrefix}-date-stepper-label`"
  >
    <slot />
  </LscButton>
</template>
