import { createWebHistory, createRouter } from 'vue-router';
import { embedRoutes } from './embed';

export function createEmbedRouter() {
  const router = createRouter({
    history: createWebHistory('/app/embed'),
    routes: embedRoutes,
  });

  return router;
}
