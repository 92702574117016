<script setup>
import { useProjectV2Loader } from '@/api';
import { provideCurrentProjectState } from '@/route';
import ProjectJumpToDrawerProjectsListProjectNavSetupProjectPermissions from './ProjectJumpToDrawerProjectsListProjectNavSetupProjectPermissions.vue';
import ProjectJumpToDrawerProjectsListProjectNavSetupProjectNav from './ProjectJumpToDrawerProjectsListProjectNavSetupProjectNav.vue';
import ProjectJumpToDrawerProjectsListProjectNavLoader from './ProjectJumpToDrawerProjectsListProjectNavLoader.vue';

const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
});

provideCurrentProjectState(
  useProjectV2Loader({
    projectId: props.projectId,
    params: {
      getPermissions: true,
      getNotificationSettings: true,
      getActivePages: true,
      getDateInfo: true,
      getEmailAddress: true,
      formatMarkdown: false,
      includeProjectOwner: true,
      includeCustomFields: true,
    },
  }),
);
</script>

<template>
  <ProjectJumpToDrawerProjectsListProjectNavSetupProjectPermissions>
    <ProjectJumpToDrawerProjectsListProjectNavSetupProjectNav>
      <ProjectJumpToDrawerProjectsListProjectNavLoader />
    </ProjectJumpToDrawerProjectsListProjectNavSetupProjectNav>
  </ProjectJumpToDrawerProjectsListProjectNavSetupProjectPermissions>
</template>
