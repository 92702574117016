import { DateTime } from 'luxon';
import { getEntity, isValidId } from '@/util';

function getExtraInfo(extraInfo) {
  if (!extraInfo) {
    return undefined;
  }

  try {
    const info = typeof extraInfo === 'string' ? JSON.parse(extraInfo) : { ...extraInfo };

    if (info.data) {
      info.data = typeof info.data === 'string' ? JSON.parse(info.data) : { ...info.data };
    }

    return info;
  } catch (error) {
    return undefined;
  }
}

export function normalizeNotification(notification, userId, v3 = true) {
  if (!(typeof notification === 'object' && isValidId(userId))) {
    throw new Error('normalizeNotification must be called with valid notification and userId parameters.');
  }

  const id = parseInt(v3 ? notification.id : notification.twimEventId, 10) || undefined;
  const read = Boolean(v3 ? notification.read : notification.wasRead);
  const avatar = v3 ? notification.user?.avatar_url : notification.userImage;
  const description = notification.description?.trim() || '';
  const user = v3 ? notification.user?.firstname : notification.userFName;
  const project = v3 ? notification.project?.name.trim() : notification.projectName?.trim();
  const projectId = v3 ? notification.project?.id : notification.projectId;
  const link = `/${v3 ? notification.link?.toLowerCase() : notification.itemLink?.toLowerCase()}`;
  const type = v3 ? notification.item?.type.toLowerCase() : notification.itemType?.toLowerCase();
  const action = v3 ? notification.action?.toLowerCase() : notification.actionType?.toLowerCase();
  const reaction = v3 ? notification.extraDescription?.toLowerCase() : notification.extraDesc?.toLowerCase();
  const date = v3
    ? DateTime.fromISO(notification.date)
    : DateTime.fromISO(new DateTime(notification.eventTimeUTC), { zone: 'utc' });

  const info = getExtraInfo(notification.extraInfo);

  const assigneeIds = String(info?.data?.taskAssignedToUserId || '')
    .split(',')
    .map(Number)
    .filter(isValidId);

  const entity = getEntity({
    userId,
    assigneeIds,
    link,
    type,
    reaction,
    action,
  });

  // Restoring missing properties present in TWA structure until https://digitalcrew.teamwork.com/app/tasks/20318056
  const extra = {
    description: v3 ? notification.extraDescription.trim() : notification.extraDesc.trim(),
    info,
    link: typeof notification.extraLink === 'string' ? notification.extraLink.toLowerCase() : undefined,
  };

  return {
    id,
    read,
    date,
    avatar,
    description,
    entity,
    project,
    projectId,
    user,
    extra,
  };
}
