export const LscButtonVariants = /** @type {const} */ ([
  'primary',
  'secondary',
  'tertiary',
  'critical',
  'plain-primary',
  'plain-secondary',
  'critical-secondary',
]);
export const LscButtonSizes = /** @type {const} */ (['sm', 'md', 'lg']);
export const LscButtonTypes = /** @type {const} */ (['button', 'submit', 'reset']);
export const LscButtonAriaHasPopupOptions = /** @type {const} */ (['menu', 'listbox', 'dialog']);
export const LscIconButtonSizes = /** @type {const} */ (['xs', 'sm', 'md', 'lg']);
export const LscIconButtonVariants = /** @type {const} */ (['primary', 'secondary', 'plain-secondary']);
export const LscSplitButtonSizes = /** @type {const} */ (['sm', 'md', 'lg']);
export const LscSplitButtonVariants = /** @type {const} */ (['primary', 'secondary', 'tertiary']);
