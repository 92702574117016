<script setup>
import { useI18n } from '@/util';
import { useLoadingCount } from '@/api';
import { useCurrentProjectPermissions } from '@/route';
import LscEmptyState from '../../../components/infodisplay/emptyState/LscEmptyState.vue';
import WidgetLoadingLazy from '../../../components/infodisplay/loading/WidgetLoadingLazy.vue';
import WidgetLoadingState from '../../../components/infodisplay/loading/WidgetLoadingState.vue';
import { useAssigneePicker } from './useAssigneePicker';
import { useAssigneePickerInvitePrompt } from './useAssigneePickerInvitePrompt';
import LswAssigneePickerAnyone from './LswAssigneePickerAnyone.vue';
import LswAssigneePickerInvitePeoplePrompt from './LswAssigneePickerInvitePeoplePrompt.vue';
import LswAssigneePickerItemSkeleton from './LswAssigneePickerItemSkeleton.vue';
import LswAssigneePickerPeople from './LswAssigneePickerPeople.vue';
import LswAssigneePickerSelected from './LswAssigneePickerSelected.vue';
import LswAssigneePickerTeams from './LswAssigneePickerTeams.vue';
import LswAssigneePickerCompanies from './LswAssigneePickerCompanies.vue';

const emit = defineEmits(['invitePeople', 'addPeople']);

const LswAssigneePickerEmptyStateImage = defineAsyncComponent(() => import('./LswAssigneePickerEmptyStateImage.svg'));

const { t } = useI18n();
const {
  assignees,
  clearable,
  clearAssignees,
  count,
  modelValue,
  emptyStateMessage,
  mode,
  multiple,
  projectId,
  searchPlaceholder,
  searchTerm,
  showAnyone,
  showClearButton,
  state,
} = useAssigneePicker();
const { shouldShowInvitePrompt } = useAssigneePickerInvitePrompt();
const { canManagePeople } = useCurrentProjectPermissions();
const showAddUserToProject = computed(() => projectId.value && canManagePeople.value);

searchTerm.value = '';
count.value = 10;

const loadingCount = useLoadingCount({ state, count, minCount: 15 });

function invitePeople() {
  emit('invitePeople');
  modelValue.value = false;
}

function addUser() {
  emit('addPeople');
  modelValue.value = false;
}

onUnmounted(() => {
  count.value = -1;
  searchTerm.value = '';
});
</script>

<template>
  <LscSheet class="flex w-80 flex-col !p-0">
    <div class="m-4 mb-2 flex flex-col items-stretch gap-4">
      <div v-if="assignees.length || $slots.header" class="flex items-center justify-between">
        <LswAssigneePickerSelected v-if="assignees.length" :max="$slots.header ? 2 : undefined" />

        <slot name="header" />
      </div>

      <LscSearchBar
        v-model="searchTerm"
        :clearable="true"
        data-identifier="ls-people-picker-popover-search"
        :placeholder="searchPlaceholder"
        autofocus
      />
    </div>

    <div class="flex size-full flex-col items-stretch overflow-hidden" role="listbox">
      <WidgetLoadingState :state="state">
        <template #defaultOrLoading>
          <div class="max-h-80 flex-auto overflow-y-auto px-2 pb-2">
            <ul class="flex flex-col items-stretch gap-1" role="listbox">
              <LswAssigneePickerAnyone v-if="showAnyone && clearable && !loadingCount" />
              <slot name="additionalItems" />
              <LswAssigneePickerPeople>
                <template #append="appendProps">
                  <slot name="userItemAppend" v-bind="appendProps" />
                </template>
              </LswAssigneePickerPeople>
              <LswAssigneePickerTeams>
                <template #append="appendProps">
                  <slot name="teamItemAppend" v-bind="appendProps" />
                </template>
              </LswAssigneePickerTeams>
              <LswAssigneePickerCompanies>
                <template #append="appendProps">
                  <slot name="companyItemAppend" v-bind="appendProps" />
                </template>
              </LswAssigneePickerCompanies>
              <LswAssigneePickerItemSkeleton v-for="i in loadingCount" :key="i" />
            </ul>
            <WidgetLoadingLazy v-model:count="count" :state="state" margin="300px" :minCount="10" />
          </div>
          <div class="mx-4 my-3 flex items-center empty:hidden">
            <LscButton v-if="showClearButton" variant="plain-primary" @click="clearAssignees">
              {{ t('Clear') }}
            </LscButton>
            <span v-if="multiple && mode === 'reassign'" class="ml-auto text-body-2 text-subtle">
              {{ t('Click + to select multiple') }}
            </span>
          </div>
          <LswAssigneePickerInvitePeoplePrompt
            v-if="shouldShowInvitePrompt && !searchTerm"
            class="mt-4 shrink border-t border-separator pb-2 pt-2"
            @invitePeople="invitePeople"
          />
          <div v-if="$slots.footer" class="flex items-center truncate border-t border-subtle px-4 py-2">
            <slot name="footer" />
          </div>
        </template>
        <template #blank>
          <LscEmptyState
            size="sm"
            class="my-4"
            :title="emptyStateMessage"
            :message="showAddUserToProject ? t('Can’t find who you’re looking for?') : undefined"
          >
            <template #image>
              <LswAssigneePickerEmptyStateImage />
            </template>
            <template v-if="showAddUserToProject" #actions>
              <LscButton
                data-identifier="ls-people-picker-add-people-dialog"
                variant="primary"
                size="md"
                @click="addUser"
              >
                {{ t('Add user') }}
              </LscButton>
            </template>
          </LscEmptyState>
        </template>
      </WidgetLoadingState>
    </div>
  </LscSheet>
</template>
