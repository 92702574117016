<script setup>
import { useCurrentAccount, useCurrentUser, useJobRoleActions, usePermissions, useFeatures } from '@/api';
import { useI18n } from '@/util';
import { useAssigneePicker } from './useAssigneePicker';
import LswAssigneePickerItem from './LswAssigneePickerItem.vue';
import LswAssigneePickerGroupHeader from './LswAssigneePickerGroupHeader.vue';
import LscAvatar from '../../../components/media/avatar/LscAvatar.vue';
import LswRolePicker from '../role/LswRolePicker.vue';

const { t } = useI18n();
const currentUser = useCurrentUser();
const account = useCurrentAccount();
const {
  people: _people,
  preventParentMenuClose,
  selectedUserIds,
  selectedTeamIds,
  selectedCompanyIds,
  togglePerson,
  toggleCompany,
  includeCompanies,
  showAssignRoles,
  includeRoles,
} = useAssigneePicker();
const { peopleJobRolesPageEnabled } = useFeatures();
const { canManagePeople } = usePermissions();

const { attachUsersToJobRole } = useJobRoleActions();
const isCurrentUserFirst = computed(() => _people.value[0]?.id === currentUser.value.id);

function isCompanySelected(company = {}) {
  return selectedCompanyIds.value.includes(company.id);
}

function isMemberOfAssignedCompany(user) {
  return user.company && isCompanySelected(user.company);
}

function isMemberOfAssignedTeam(user) {
  return user.teams?.some((team) => selectedTeamIds.value.includes(team.id));
}

const people = computed(() =>
  _people.value.map((person) => {
    let existingMemberTooltip;
    if (isMemberOfAssignedTeam(person)) {
      existingMemberTooltip = t('{user} is already part of a selected team', { user: person.firstName });
    }
    if (isMemberOfAssignedCompany(person)) {
      existingMemberTooltip = t('{user} is already part of a selected company', { user: person.firstName });
    }
    return {
      ...person,
      existingMemberTooltip,
    };
  }),
);

function assignRole(person, roles = []) {
  attachUsersToJobRole(roles[0], [person]);
}
</script>

<template>
  <template v-for="(person, index) in people" :key="person.id">
    <LswAssigneePickerGroupHeader
      v-if="
        (index === 0 && !isCurrentUserFirst) ||
        (index === 1 && isCurrentUserFirst) ||
        (index > 0 && people[index - 1].company.id !== person.company.id)
      "
    >
      {{ person.company.name }}

      <template #action>
        <LscIconButton
          v-if="includeCompanies"
          v-LsdTooltip="account.useClientView ? t('Select client') : t('Select company')"
          :ariaLabel="account.useClientView ? t('Select client') : t('Select company')"
          size="sm"
          :icon="isCompanySelected(person.company) ? 'lsi-selected' : 'lsi-add'"
          :ariaPressed="isCompanySelected(person.company)"
          @click="toggleCompany(person.company, { event: $event, removeOthers: false })"
        />
      </template>
    </LswAssigneePickerGroupHeader>
    <LswAssigneePickerItem
      :assignTooltip="t('Assign user')"
      :unassignTooltip="t('Unassign user')"
      :active="selectedUserIds.includes(Number(person.id))"
      :shouldShowToggleButton="!Boolean(person.existingMemberTooltip)"
      @toggle="togglePerson(person, $event)"
    >
      <template #prepend="{ avatarSize }">
        <LscAvatar v-bind="getLsAvatarProps({ user: person })" :size="avatarSize" />
      </template>
      <template #default>
        {{ `${person.firstName} ${person.lastName} ${Number(person.id) === currentUser.id ? `(${t('Me')})` : ''}` }}
      </template>
      <template #secondary>
        <template v-if="peopleJobRolesPageEnabled && includeRoles">
          <template v-if="person.jobRoles?.length">
            {{ person.jobRoles?.[0]?.name }}
          </template>
          <template v-else-if="showAssignRoles && canManagePeople">
            <LswRolePicker
              :people="[person]"
              @update:roles="assignRole(person, $event)"
              @afterEnter="preventParentMenuClose = true"
              @afterLeave="preventParentMenuClose = false"
            >
              <template #activator="activator">
                <LscButton v-bind="activator.props" size="sm" variant="plain-primary">
                  {{ t('Assign role') }}
                </LscButton>
              </template>
            </LswRolePicker>
          </template>
        </template>
      </template>
      <template #append="appendProps">
        <LscIcon
          v-if="person.existingMemberTooltip"
          v-LsdTooltip="person.existingMemberTooltip"
          size="sm"
          icon="lsi-assignees"
          class="shrink-0 text-icon-subtle"
        />
        <slot name="append" v-bind="appendProps" :user="person" />
      </template>
    </LswAssigneePickerItem>
  </template>
</template>
