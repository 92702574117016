<script setup>
import { DateTime } from 'luxon';
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import LscDatePicker from '../../picker/date/LscDatePicker.vue';

const props = defineProps({
  /**
   * The minimum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  minDate: {
    type: Object,
    default: undefined,
    validator: (value) => value === undefined || DateTime.isDateTime(value),
    required: false,
  },
  /**
   * The maximum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  maxDate: {
    type: Object,
    default: undefined,
    validator: (value) => value === undefined || DateTime.isDateTime(value),
    required: false,
  },
  /**
   * The label of the date field.
   * @type {PropType<string>}
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * Whether the date field is required.
   * @type {PropType<boolean>}
   */
  required: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the date field is disabled.
   * @type {PropType<boolean>}
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the date field is clearable.
   * @type {PropType<boolean>}
   */
  clearable: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the date field has an error.
   * @type {PropType<boolean>}
   */
  error: {
    type: Boolean,
    default: undefined,
  },
  /**
   * A list of error messages.
   * @type {PropType<string[]>}
   */
  errorMessages: {
    type: Array,
    default: undefined,
    validator: (value) => !value || value.every((item) => typeof item === 'string'),
  },
  /**
   * The id of the date field.
   * @type {PropType<string>}
   */
  id: {
    type: String,
    default: undefined,
  },
  /**
   * The data identifier of the date field.
   * @type {PropType<string>}
   */
  dataIdentifier: {
    type: String,
    default: undefined,
  },
  /**
   * Whether weekends should be enabled.
   * @type {PropType<boolean>}
   */
  enableWeekends: {
    type: Boolean,
    default: true,
  },
  /**
   * A function that returns a tooltip for a disabled day.
   * @type {PropType<(day: DateTime) => String | object>}
   */
  disabledDayTooltipFunction: {
    type: Function,
    default: undefined,
  },
});

const emit = defineEmits(['blur']);

/**
 * The date value.
 * @type {PropType<DateTime|undefined>}
 */
const modelValue = defineModel({
  default: undefined,
  validator: (value) => {
    return value == null || (DateTime.isDateTime(value) && value.isValid);
  },
});

const { parseDate, formatDate, t } = useI18n();
const { betweenDates, requiredIf } = useValidators();

const isOpen = shallowRef(false);
const isFocused = shallowRef(false);
const inputRef = shallowRef(null);

const rules = computed(() => ({
  modelValue: {
    required: requiredIf(() => !props.clearable),
    betweenDates: betweenDates(props.minDate, props.maxDate),
  },
}));
// This should be automatically inherited from the parent component
// https://vuelidate-next.netlify.app/advanced_usage.html#nested-validations
const v$ = useVuelidate(rules, { modelValue });
const computedError = computed(() => props.error ?? v$.value.modelValue.$error);
const computedErrorMessages = computed(
  () => props.errorMessages ?? v$.value.$errors.map((error) => error.$message) ?? [],
);

const formattedLocalModelValue = shallowRef(modelValue.value ? formatDate(modelValue.value) : '');
const localModelValue = computed(() => {
  if (!formattedLocalModelValue.value && props.clearable) {
    return null;
  }
  const parsedDate = parseDate(formattedLocalModelValue.value);

  if (parsedDate.isValid && parsedDate !== modelValue.value) {
    return parsedDate;
  }

  return modelValue.value;
});

function focus() {
  isFocused.value = true;
}

function blur() {
  isFocused.value = false;
  modelValue.value = localModelValue.value;
  formattedLocalModelValue.value = modelValue.value ? formatDate(modelValue.value) : '';
  emit('blur');
  v$.value.modelValue.$touch();
}

function removeFocus() {
  if (isFocused.value) {
    inputRef.value.blur();
    isOpen.value = false;
  }
}

watch(modelValue, () => {
  if (isFocused.value) {
    return;
  }
  formattedLocalModelValue.value = modelValue.value ? formatDate(modelValue.value) : '';
});
function togglePickerMenu(shouldOpen) {
  if (isFocused.value && shouldOpen) {
    isOpen.value = true;
  } else if (!isFocused.value) {
    isOpen.value = false;
  }
}

watch(isOpen, (value) => {
  if (!value) {
    emit('blur');
  }
});

function clear() {
  formattedLocalModelValue.value = '';
  modelValue.value = null;
  inputRef.value.blur();
}

function open() {
  isOpen.value = true;
}
defineExpose({
  open,
});
</script>

<template>
  <div>
    <VTextField
      :id="id"
      ref="inputRef"
      v-model="formattedLocalModelValue"
      :label="label"
      :data-identifier="dataIdentifier"
      :required="required"
      prependInnerIcon="lsi-date"
      :disabled="disabled"
      :error="computedError"
      :errorMessages="computedErrorMessages"
      @focus="focus"
      @blur="blur"
      @keypress.prevent.enter="removeFocus"
    >
      <template #append-inner>
        <LscIconButton
          v-if="modelValue && clearable"
          :ariaLabel="t('Clear date')"
          class="-mr-1"
          size="xs"
          icon="lsi-close"
          @click.stop="clear"
        />
      </template>
    </VTextField>
    <LscDatePicker
      :date="localModelValue"
      :modelValue="isOpen"
      activator="parent"
      :clearable="clearable"
      :minDate="minDate"
      :maxDate="maxDate"
      :disabled="disabled"
      :enableWeekends="enableWeekends"
      :disabledDayTooltipFunction="disabledDayTooltipFunction"
      offset="8px"
      @update:date="modelValue = $event"
      @update:modelValue="togglePickerMenu"
    />
  </div>
</template>
