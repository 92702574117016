import { usePendo } from '../features/usePendo';
import { useFeatures } from '../features/useFeatures';
import { useCohort } from '../cohort/useCohort';

/**
 * Constants, computed properties and functions related to Experiment A-23-62
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-62-add-client-creation-to-onboarding-flow/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/51069-a-23-62-add-client-creation-to-onboarding-flow
 */

export function useExperimentA62() {
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const { isICP, isCompanySizeAboveTen } = useCohort();
  const { addClientCreationToOnboardingFlowEnabled } = useFeatures();
  const { launchDarklyFlagKey, defaultValue } = addClientCreationToOnboardingFlowEnabled;

  const ignoreInExperimentOnRuleMatch = true;
  const eventName = 'CLIENT_ONBOARDING_EVENT';

  const appLevelTargeting = computed(() => isICP.value && !isCompanySizeAboveTen.value);
  const isExpA62Variation = computed(() => addClientCreationToOnboardingFlowEnabled.value && appLevelTargeting.value);

  function trackAddClientStepEvents(eventAction, clientProjectPendoValue) {
    trackPendoEvent({
      eventName,
      metadata: {
        event_action: eventAction,
        project_type: clientProjectPendoValue,
      },
    });
  }

  function trackExperimentA62() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting,
    });
  }

  return {
    trackExperimentA62,
    trackAddClientStepEvents,
    isExpA62Variation,
  };
}
