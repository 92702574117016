import { useCurrentUser, usePricePlan, usePeopleV3Loader } from '@/api';

export function useAssigneePickerInvitePrompt() {
  const currentUser = useCurrentUser();
  const { isInFreeTrial } = usePricePlan();
  const count = computed(() => (isInFreeTrial.value ? 2 : -1));

  const { loaded, items } = usePeopleV3Loader({
    count,
    pageSize: 2,
    params: { skipCounts: false },
  });

  const shouldShowInvitePrompt = computed(
    () =>
      isInFreeTrial.value &&
      loaded.value &&
      items.value.length === 1 &&
      (currentUser.value?.administrator || currentUser.value?.permissions.canManagePeople),
  );

  return {
    shouldShowInvitePrompt,
  };
}
