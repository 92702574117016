const inboxFiltersSymbol = Symbol('inbox-filter');

export const readPickerData = [
  { name: 'All', id: 0, key: undefined },
  { name: 'Unread only', id: 1, key: false },
  { name: 'Read only', id: 2, key: true },
];

export const archivedPickerData = [
  {
    name: 'Active only',
    id: 0,
    key: false,
  },
  {
    name: 'Archived Only',
    id: 1,
    key: true,
  },
  { name: 'All', id: 2, key: undefined },
];

function inboxFilters() {
  const defaultParams = {
    projects: [],
    notificationTypes: [],
    searchTerm: '',
    sourceTypes: [],
    archived: archivedPickerData[0],
    read: readPickerData[0],
  };

  const filterMeta = {
    section: 'inbox',
    include: 'projects,notificationTypes,sourceTypes,archived,read,searchTerm',
  };

  const params = ref({ ...defaultParams });

  const activeNumber = computed(() => {
    let result = 0;

    result += params.value.projects.length;
    result += params.value.notificationTypes.length;
    result += params.value.sourceTypes.length;

    if (params.value.archived.key !== defaultParams.archived.key) {
      result += 1;
    }

    if (params.value.read.key !== defaultParams.read.key) {
      result += 1;
    }

    return result;
  });

  const hasActiveFilter = computed(() => {
    return activeNumber.value > 0;
  });

  const hasSearchTerm = computed(() => {
    return Boolean(params.value.searchTerm);
  });

  function clearAdvanced() {
    params.value = {
      ...params.value,
      projects: defaultParams.projects,
      notificationTypes: defaultParams.notificationTypes,
      sourceTypes: defaultParams.sourceTypes,
      archived: defaultParams.archived,
      read: defaultParams.read,
    };
  }

  function clearSearchTerm() {
    params.value = {
      ...params.value,
      searchTerm: defaultParams.searchTerm,
    };
  }

  const chips = computed(() => {
    return [];
  });

  function removeChip(id, type) {
    params.value[type] = params.value[type].filter((item) => item.id !== id);
  }

  const queryParams = computed(() => {
    const projectIDs = params.value?.projects?.map((i) => i.id).join(',') || undefined;

    const searchTerm = params.value?.searchTerm;

    const entities = params.value?.notificationTypes?.map((i) => i.id).join(',') || undefined;

    const sources = params.value.sourceTypes.map((i) => i.id).join(',') || undefined;

    const isRead = params.value?.read?.key;

    const isArchived = params.value?.archived?.key;

    const params$ = {
      projectIDs,
      searchTerm,
      entities,
      sources,
      isRead,
      isArchived,
    };

    return Object.keys(params$).reduce((acc, key) => {
      if (typeof params$[key] !== 'undefined') {
        acc[key] = params$[key];
      }

      return acc;
    }, {});
  });

  return {
    params,
    defaultParams,
    clearAdvanced,
    clearSearchTerm,
    activeNumber,
    hasActiveFilter,
    hasSearchTerm,
    chips,
    removeChip,
    queryParams,
    filterMeta,
  };
}

export function provideInboxFilters() {
  const filters = inboxFilters();
  provide(inboxFiltersSymbol, filters);
  return filters;
}

export function useInboxFilters() {
  return inject(inboxFiltersSymbol);
}
