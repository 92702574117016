import './VBadge.variants.css';

export const VBadgeDot = {
  class: 'VBadgeDot',
  dot: true,
};

export const VBadgeEntity = {
  class: 'VBadgeEntity',
  location: 'bottom right',
  offsetX: 4,
  offsetY: 4,
};
