<script setup>
import { useNotifications, useNotificationsActions } from '@/api';
import NotificationDrawerList from './NotificationDrawerList.vue';
import NotificationDrawerUnreadBlankSlate from './NotificationDrawerUnreadBlankSlate.vue';

const { unreadNotifications } = useNotifications();
const { markNotificationRead } = useNotificationsActions();

async function onToggleRead(id) {
  await markNotificationRead({
    id,
    read: true,
  });
}
unreadNotifications.loadMore();

const count = shallowRef(20);

watch(
  count,
  () => {
    unreadNotifications?.loadMore();
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <NotificationDrawerList v-model:count="count" :state="unreadNotifications.state" @toggleRead="onToggleRead">
    <template #blank>
      <NotificationDrawerUnreadBlankSlate class="h-full" />
    </template>
  </NotificationDrawerList>
</template>
