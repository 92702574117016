<script setup>
import { useI18n } from '@/util';
import { useSpacesV1Loader, useSpacesSearchV1Loader } from '@/api';
import SpacesDrawerSpacesList from './SpacesDrawerSpacesList.vue';
import SpacesDrawerSearchBlankSlate from './SpacesDrawerSearchBlankSlate.vue';
import SpacesDrawerSearchPagesBlankSlate from './SpacesDrawerSearchPagesBlankSlate.vue';
import SpacesDrawerPagesList from './SpacesDrawerPagesList.vue';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
  selectedSearchEntity: {
    type: String,
    default: 'all',
  },
});

const { t } = useI18n();

const pageSize = 50;

// Search spaces - but only if spaces search selected
const shouldSearchSpaces = computed(() => ['all', 'spaces'].includes(props.selectedSearchEntity));
const spacesListCountRaw = shallowRef(pageSize);
const spacesListCount = computed({
  get() {
    // This count of -1 is done to prevent searching spaces when pages selected
    if (!shouldSearchSpaces.value) {
      return -1;
    }
    return spacesListCountRaw.value;
  },
  set(newValue) {
    spacesListCountRaw.value = newValue;
  },
});
const spacesListState = useSpacesV1Loader({
  count: spacesListCount,
  pageSize,
  params: computed(() => ({
    orderBy: 'title',
    orderMode: 'ASC',
    'filter[title]': props.searchTerm,
  })),
});

// Search pages - but only if pages search selected
const shouldSearchPages = computed(() => ['all', 'pages', 'content'].includes(props.selectedSearchEntity));
const pagesListCountRaw = shallowRef(pageSize);
const pagesListCount = computed({
  get() {
    if (!shouldSearchPages.value) {
      return -1;
    }
    return pagesListCountRaw.value;
  },
  set(newValue) {
    pagesListCountRaw.value = newValue;
  },
});
const pagesListState = useSpacesSearchV1Loader({
  count: pagesListCount,
  pageSize,
  params: computed(() => ({
    orderBy: 'title',
    orderMode: 'ASC',
    q: props.searchTerm,
    fields: props.selectedSearchEntity === 'content' ? 'title,content' : 'title',
    include: 'spaces',
  })),
});
</script>

<template>
  <SpacesDrawerSpacesList
    v-if="shouldSearchSpaces"
    v-model:count="spacesListCount"
    :step="pageSize"
    :state="spacesListState"
  >
    <template #blank>
      <SpacesDrawerSearchBlankSlate v-if="!(shouldSearchPages && pagesListState.items.value.length !== 0)" />
      <!--- Empty value needed for slot -->
      <template v-else><div>&nbsp;</div></template>
    </template>
  </SpacesDrawerSpacesList>

  <SpacesDrawerPagesList
    v-if="shouldSearchPages"
    v-model:count="pagesListCount"
    :step="pageSize"
    :state="pagesListState"
    :resultsTitle="shouldSearchSpaces ? t('Matching pages') : undefined"
  >
    <template #blank>
      <SpacesDrawerSearchPagesBlankSlate v-if="!shouldSearchSpaces" />
      <!--- Empty value needed for slot -->
      <template v-else><div>&nbsp;</div></template>
    </template>
  </SpacesDrawerPagesList>
</template>
