import { DRAWERS, useAppDrawer } from '@/module/app';

const symbol = Symbol('useDeskDrawer');

function DeskDrawer() {
  const { isOpen, open, close } = useAppDrawer(DRAWERS.desk);
  const task = ref(null);
  const ticket = ref(null);
  const createTicket = shallowRef(false);

  function openCreateTicket(newTask) {
    ticket.value = null;
    if (newTask?.value?.id || newTask?.id) {
      task.value = unref(newTask);
    } else {
      createTicket.value = true;
    }
  }

  function openTicket(newTicket) {
    task.value = null;
    ticket.value = unref(newTicket);
  }

  watch([task, ticket, createTicket], ([newTask, newTicket, shouldCreateTicket]) => {
    if (newTask || newTicket || shouldCreateTicket) {
      open();
    } else {
      close();
    }
  });

  watch(isOpen, (newIsOpen) => {
    if (!newIsOpen) {
      task.value = null;
      ticket.value = null;
      createTicket.value = false;
    }
  });

  return {
    isOpen,
    openCreateTicket,
    openTicket,
    task,
    ticket,
    createTicket,
  };
}

export function provideDeskDrawer() {
  const drawer = DeskDrawer();
  provide(symbol, drawer);
  return drawer;
}

export function useDeskDrawer() {
  return inject(symbol);
}
