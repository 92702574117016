<script setup>
import { useCurrentProjectPermissions } from '@/route';
import { useI18n } from '@/util';
import { useCurrentBudgetFormat } from '../useCurrentBudgetFormat';
import { useLegacyBridge } from '@/module/legacy';
import { usePermissions } from '@/api';

const props = defineProps({
  projectBudget: {
    type: Object,
    required: true,
  },
  projectId: {
    type: Number,
    required: true,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { projectBudget, projectId } = toRefs(props);
const { canManageProjectBudget } = useCurrentProjectPermissions();
const { canManageFixedFeeBudgets } = usePermissions();
const { showLegacyModal } = useLegacyBridge();

const { totalCapacityInfoLabel, capacityLabelLong, capacityUsedLabelLong, capacityRemainingLabelLong, dateRangeLabel } =
  useCurrentBudgetFormat(projectBudget);

const canEditCurrentBudget = computed(() => {
  if (!canManageProjectBudget.value || !props.canEdit) {
    return false;
  }
  return projectBudget.value.budgetCategory === 'fixedfee'
    ? canManageFixedFeeBudgets.value
    : canManageProjectBudget.value;
});

function openEditBudgetModal() {
  showLegacyModal({
    modalName: 'ProjectBudgetModal',
    params: {
      budgetId: projectBudget.value.id,
      projectId: projectId.value,
      openSource: 'budget_project_info',
    },
  });
}
</script>

<template>
  <WidgetMenu>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <LscSheet>
      <div class="-mt-1 flex flex-row-reverse">
        <LscButton
          v-if="canEditCurrentBudget"
          variant="plain-primary"
          size="sm"
          prependIcon="lsi-edit"
          @click="openEditBudgetModal"
        >
          {{ t('Edit') }}
        </LscButton>
      </div>
      <div class="mt-1 grid grid-cols-2 justify-start gap-x-3 gap-y-1 text-body-1 text-default">
        <div class="col-span-full grid grid-cols-subgrid">
          <span class="font-semibold">{{ totalCapacityInfoLabel }}</span>
          <span class="text-right">{{ capacityLabelLong }}</span>
        </div>
        <div class="col-span-full grid grid-cols-subgrid">
          <span class="font-semibold">{{ t('Used') }}</span>
          <span class="text-right">{{ capacityUsedLabelLong }}</span>
        </div>
        <div class="col-span-full grid grid-cols-subgrid">
          <span class="font-semibold">{{ t('Remaining') }}</span>
          <span class="text-right">{{ capacityRemainingLabelLong }}</span>
        </div>
        <div v-if="dateRangeLabel" class="col-span-full grid grid-cols-subgrid">
          <span class="font-semibold">{{ t('Dates') }}</span>
          <span class="text-right">{{ dateRangeLabel }}</span>
        </div>
      </div>
    </LscSheet>
  </WidgetMenu>
</template>
