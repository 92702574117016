<script setup>
import { refDebounced } from '@vueuse/core';
import { DateTime } from 'luxon';

import { useCustomfieldsV3Loader } from '@/api';
import { useI18n } from '@/util';
import { customfieldTypeIcons } from '@/module/customfield';

import FilterCustomFieldMenu from './customField/FilterCustomFieldMenu.vue';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  projectId: {
    type: Number,
    default: 0,
  },
  entities: {
    type: String,
    required: true,
  },
});

const { t, formatDate } = useI18n();
const { params, chips, count } = useFilter();

const customFieldMenuOpen = shallowRef(false);
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const state = useCustomfieldsV3Loader({
  projectId: computed(() => props.projectId),
  params: computed(() => ({
    projectId: props.projectId,
    entities: props.entities,
    onlySiteLevel: false,
    searchTerm: debouncedSearchTerm.value,
  })),
  count: Infinity,
});

const { items: customFields, inSync } = state;

const noCustomFields = computed(() => customFields.value.length === 0 && debouncedSearchTerm.value === '');

function parseOperator({ type, operator, value }) {
  if (type === 'text-short') {
    if (operator === 'like') {
      if (value.startsWith('%') && value.endsWith('%')) {
        return t('contains');
      }
      if (value.endsWith('%')) {
        return t('starts with');
      }
      if (value.startsWith('%')) {
        return t('ends with');
      }
    }
    if (operator === 'eq') {
      return t('matches');
    }
    if (operator === 'not-like') {
      return t('does not contain');
    }
  } else if (type === 'number-integer') {
    if (operator === 'eq') {
      return '=';
    }
    if (operator === 'lt') {
      return '<';
    }
    if (operator === 'gt') {
      return '>';
    }
  } else if (type === 'url') {
    if (operator === 'like') {
      return t('contains');
    }
    if (operator === 'not-like') {
      return t('does not contain');
    }
  } else if (type === 'dropdown' || type === 'status') {
    if (operator === 'eq') {
      return '=';
    }
    if (operator === 'any') {
      return t('any of');
    }
    if (operator === 'not') {
      return t('none of');
    }
  } else if (type === 'checkbox') {
    if (value) {
      return t('is checked');
    }
    return t('is not checked');
  } else if (type === 'date') {
    if (operator === 'lte') {
      return t('from');
    }
    if (operator === 'gte') {
      return t('to');
    }
  }
  return null;
}

// A range can only be applied to dates and numbers
function parseRangeOperator(customfields) {
  // the type and name of the fields within the array will always be the same
  const { name, type } = customfields[0];
  let fromValue = customfields.find(({ key }) => key.includes('gt'))?.value;
  let toValue = customfields.find(({ key }) => key.includes('lt'))?.value;
  if (type === 'date') {
    fromValue = formatDate(DateTime.fromISO(fromValue), 'short');
    toValue = formatDate(DateTime.fromISO(toValue), 'short');
  }
  return `${name}: ${t('from')} ${fromValue} ${t('to')} ${toValue}`;
}

const appliedCustomFields = computed(() => {
  const appliedCfs = [];
  const allCfsKeys = Object.keys(params.value).filter((key) => key.includes('customField['));
  allCfsKeys.forEach((key) => {
    if (params.value[key] || typeof params.value[key] === 'boolean') {
      const match = key.match(/\[(.*?)\]/g);
      const splitKey = match.map((matched) => matched.slice(1, -1));
      appliedCfs.push({
        id: Number(splitKey[0]),
        key,
        operator: splitKey[1],
        value: params.value[key],
        name: customFields.value.find(({ id }) => id === Number(splitKey[0]))?.name,
        type: customFields.value.find(({ id }) => id === Number(splitKey[0]))?.type,
      });
    }
  });
  return appliedCfs;
});

function parseCustomFieldName(data) {
  const isArray = Array.isArray(data);
  if (isArray) {
    return parseRangeOperator(data);
  }
  return `${data.name} ${parseOperator(data)} ${typeof data.value === 'string' ? data.value.replaceAll('%', '') : ''}`;
}

function createChipArray(ids) {
  const newChips = [];
  ids.forEach(({ id, containsRange = false }) => {
    if (containsRange) {
      const currentCustomFields = appliedCustomFields.value.filter(({ id: fieldId }) => fieldId === id);
      newChips.push({
        id,
        name: parseCustomFieldName(currentCustomFields),
        icon: customfieldTypeIcons[currentCustomFields[0].type],
        delete: () => {
          const newParams = { ...params.value };
          currentCustomFields.forEach((field) => {
            newParams[field.key] = null;
          });
          params.value = { ...newParams };
        },
      });
    } else {
      const customField = appliedCustomFields.value.find(({ id: fieldId }) => fieldId === id);
      newChips.push({
        id,
        name: parseCustomFieldName(customField),
        icon: customfieldTypeIcons[customField.type],
        delete: () => {
          params.value = { ...params.value, [customField.key]: null };
        },
      });
    }
  });
  return newChips;
}

useFilterChips(
  computed(() => {
    // If the search term is present, return the chips as they are
    // This is to prevent the chips showing applied custom fields name `undefined`
    // If they are not in the search results
    if (debouncedSearchTerm.value) {
      return chips.value?.filter(({ id }) => appliedCustomFields.value.some(({ id: fieldId }) => fieldId === id));
    }
    // Combine CFs which target the same custom field ID into one chip
    const customFieldIds = [];
    appliedCustomFields.value.forEach(({ id }) => {
      const existingFieldIndex = customFieldIds.findIndex(({ id: fieldId }) => fieldId === id);
      if (existingFieldIndex !== -1) {
        // Add data to the existing chip object.
        customFieldIds[existingFieldIndex].containsRange = true;
      } else {
        customFieldIds.push({ id });
      }
    });
    return createChipArray(customFieldIds);
  }),
);

useFilterCount(computed(() => [...new Set(appliedCustomFields.value.map(({ id }) => id))].length));

useFilterNormalize(/^customField\[/, (value) => value);

useFilterClear(/^customField\[/, undefined);

// Make sure the custom field menu is closed when the filter count changes
// This is to prevent the menu from staying open when a quick filter is applied or user clicks clear all
watch(count, () => {
  customFieldMenuOpen.value = false;
});
</script>

<template>
  <FilterCustomFieldMenu
    v-model="customFieldMenuOpen"
    v-model:searchTerm="searchTerm"
    :closeOnContentClick="false"
    location="bottom left"
    :projectId="projectId"
    :customFields="customFields"
    :noCustomFields="noCustomFields"
    :searchLoading="!inSync"
    @update:modelValue="searchTerm = ''"
  >
    <template #activator="activator">
      <LscButton
        variant="plain-secondary"
        v-bind="activator.props"
        class="my-1 w-fit justify-start"
        prependIcon="lsi-add"
      >
        {{ t('Add custom field filter') }}
      </LscButton>
    </template>
  </FilterCustomFieldMenu>
</template>
