<script setup>
import { DateTime } from 'luxon';

const props = defineProps({
  initialAssignees: {
    type: Array,
    default: () => [],
  },
  initialProject: {
    type: Object,
    default: undefined,
  },
  /**
   * @type {PropType<DateTime>}
   */
  initialStartDate: {
    type: Object,
    default: null,
    validator: (value) => value === null || DateTime.isDateTime(value),
  },
  /**
   * @type {PropType<DateTime>}
   */
  initialDueDate: {
    type: Object,
    default: null,
    validator: (value) => value === null || DateTime.isDateTime(value),
  },
  initialDescription: {
    type: String,
    default: '',
  },
  pendoSource: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['createTask']);

const TaskAddDialogPanel = defineAsyncComponent(() => import('./TaskAddDialogPanel.vue'));
</script>

<template>
  <WidgetDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <TaskAddDialogPanel v-bind="props" @close="close" @createTask="emit('createTask', $event)" />
    </template>
  </WidgetDialog>
</template>
