import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data: { branding } }) {
  // eslint-disable-next-line no-param-reassign
  branding.baseHref = branding.BaseHref;
  return branding;
}

/**
 * Loads the branding information.
 */
export function useBrandingV1Loader({ params } = {}) {
  const { state, update, refresh } = useItemLoader({
    url: '/v/1/settings/branding.json',
    params,
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'branding') {
      refresh();
    }
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'branding') {
      update((branding) => {
        return { ...branding, ...event.branding };
      }, event.promise);
    }
  });

  return state;
}
