import { useAxiosResponseInterceptor } from './useAxiosResponseInterceptor';
import { publicShareToken } from './publicShareToken';

const symbol = Symbol('useHandle401');

// Indicates if we have just been redirected from Launchpad.
// `document.referrer` does not meet our needs because it stays the same after reloading the app.
const redirectedFromLaunchpad = (() => {
  const url = new URL(window.location.href);
  const result = url.searchParams.get('redirectedFromLaunchpad') === 'true';
  url.searchParams.delete('redirectedFromLaunchpad');
  window.history.replaceState(window.history.state, null, url);
  return result;
})();

function Handle401() {
  const got401 = shallowRef(false);

  useAxiosResponseInterceptor(null, (error) => {
    const { response } = error;

    // do not handle desk api errors
    if (/desk\/api\//.test(response?.request?.url)) {
      return Promise.reject(error);
    }

    if (response && response.status === 401) {
      got401.value = true;

      if (window.location.pathname.startsWith('/app/shared/') && publicShareToken) {
        // eslint-disable-next-line no-console
        console.warn('Request failed with status code 401 - not redirecting to launchpad on /app/shared/');
      } else if (window.location.pathname.startsWith('/app/embed/')) {
        // eslint-disable-next-line no-console
        console.warn('Request failed with status code 401 - not redirecting to launchpad on /app/embed/');
      } else if (import.meta.env.DEV && !window.location.hostname.endsWith('dev.stg.teamworkops.com')) {
        // eslint-disable-next-line no-console
        console.warn(`Request failed with status code 401 - not redirecting to launchpad on localhost`);
      } else if (performance.now() < 10000 && redirectedFromLaunchpad) {
        // If Launchpad redirected the user to Lightspeed and we get a 401 within 10 seconds after loading the app,
        // then there's a high chance that there's something seriously wrong with the authentication.
        // Redirecting back to the login page would likely result in an infinite redirection loop between
        // Launchpad and Lightspeed. We avoid this problem by redirecting to the logout page instead.
        window.location = '/launchpad/logout/projects?r=authFail';
        // eslint-disable-next-line no-console
        console.warn(`401 within 10s, fallback to launchpad`, response);
      } else {
        const url = new URL(window.location.href);
        url.searchParams.set('redirectedFromLaunchpad', 'true');
        window.location = `/launchpad/login/projects?continue=${encodeURIComponent(url.toString())}`;
      }
    }

    return Promise.reject(error);
  });

  return { got401 };
}

export function provideHandle401() {
  const handle401 = Handle401();
  provide(symbol, handle401);
  return handle401;
}

export function useHandle401() {
  return inject(symbol);
}
