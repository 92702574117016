<script setup>
import { syncRef } from '@vueuse/core';
import { useI18n, useItemCache } from '@/util';
import { useTagsV3Loader } from '@/api';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterClear, useFilterChips, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'include',
  },
});

const { t } = useI18n();

const { params, activeFilter, dataIdentifierPrefix } = useFilter();
const count = shallowRef(10);
const filterType = 'tags';

const projectTagsParams = computed(() => {
  const ids = [0];
  if (props.projectId) {
    ids.push(props.projectId);
  }
  return {
    projectIds: ids.join(','),
  };
});

const pageSize = 20;
const { items: projectTags } = useTagsV3Loader({
  params: projectTagsParams,
  count,
  pageSize,
});

const missingTagIds = shallowRef([]);
const missingTagsState = useTagsV3Loader({
  params: computed(() => ({
    ...projectTagsParams.value,
    ids: missingTagIds.value.join(','),
  })),
  count: computed(() => missingTagIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  projectTags,
  missingTagsState.items,
);

const tagIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const tags = computeAll(tagIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(tagIds), missingTagIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    tags.value.map((tag) => ({
      // color, name, etc
      name: tag.name,
      color: props.mode === 'exclude' ? null : tag.color,
      type: props.mode === 'exclude' ? 'excludeTag' : 'tag',
      delete: () => {
        tagIds.value = tagIds.value.filter((id) => id !== tag.id);
      },
    })),
  ),
);

useFilterCount(computed(() => tagIds.value.length));

function updateTagIds(newTags) {
  tagIds.value = newTags.map((tag) => tag.id);
}
</script>
<template>
  <LswTagPicker
    :closeOnContentClick="false"
    location="bottom left"
    offset="12"
    :tags="tags"
    :projectId="projectId"
    @update:tags="updateTagIds"
  >
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="tags.length > 0"
        icon="lsi-tag"
        :data-identifier="`${dataIdentifierPrefix}-tags-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
  </LswTagPicker>
</template>
