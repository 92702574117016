<script setup>
import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { useI18n } from '@/util';

defineProps({
  chip: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
</script>
<template>
  <VChip
    :color="chip.color || TOKENS.LsdsAColorBackgroundDefault"
    size="small"
    v-bind="VChipFilter"
    :class="{
      'border text-default': chip.type !== 'tag',
    }"
  >
    <span class="inline-flex items-center gap-2">
      <LscAvatar v-if="chip.type === 'assignee' && chip.id > 0" size="xs" :src="chip.icon" :name="chip.name" />
      <LscAvatar v-else-if="chip.type === 'assignee' && chip.id === 0" size="xs" />
      <LscAvatar
        v-else-if="chip.type === 'assignee-desk'"
        size="xs"
        v-bind="getLsAvatarProps({ deskAgent: chip.agent })"
      />
      <LscAvatar v-else-if="chip.type === 'teams'" v-bind="getLsAvatarProps({ team: chip.team })" size="xs" />
      <LscIcon v-else-if="chip.icon" :icon="chip.icon" :color="chip.iconColor" size="sm" />
      <LscOverflowEllipsis
        v-if="chip.type === 'assignee-desk'"
        :class="{ 'text-subtle': chip.agent?.state === 'archived' }"
      >
        {{ chip.name }}
      </LscOverflowEllipsis>
      <LscOverflowEllipsis v-else class="max-w-48">
        {{ chip.name }}
      </LscOverflowEllipsis>
    </span>
    <template #append>
      <LscIconButton
        variant="plain-secondary"
        :class="{ 'text-inherit': chip.type === 'tag' }"
        icon="lsi-close"
        size="sm"
        :ariaLabel="t('Remove filter')"
        @click="chip.delete"
      />
    </template>
  </VChip>
</template>
