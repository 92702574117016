<script setup>
import { useEventListener } from '@vueuse/core';
import LscDropzoneOverlay from './LscDropzoneOverlay.vue';
import LscDropzonePlaceholder from './LscDropzonePlaceholder.vue';
import { provideLscDropzone } from './useLscDropzone';

const props = defineProps({
  /**
   * The file extensions that the dropzone will accept.
   * @type {PropType<string[]>}
   */
  extensions: {
    type: Array,
    default: () => [],
  },
  /**
   * The maximum size of the file that the dropzone will accept.
   */
  maxFileSize: {
    type: Number,
    default: Infinity,
  },
  /**
   * Allow the dropzone to accept multiple files.
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * The size of the dropzone.
   * @type {PropType<'sm' | 'md' | 'full'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'full'].includes(value),
  },
  /**
   * Custom error state for the dropzone. This will override the local error state.
   */
  error: {
    type: Boolean,
    default: false,
  },
  /**
   * Custom error message for the dropzone. This will override the error message inside the dropzone.
   */
  errorMessage: {
    type: String,
    default: undefined,
  },
  /**
   * The custom message to be displayed in the dropzone.
   * Otherwise it will display the formats accepted (if any).
   */
  message: {
    type: String,
    default: '',
  },
  /**
   * Controls the disabled state of the dropzone.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Enables the visibility of a hint text listing the supported file formats.
   */
  showMessage: {
    type: Boolean,
    default: true,
  },
});

const modelValue = defineModel({
  type: Array,
  default: () => [],
});

const dropzoneRef = shallowRef();

const { isOverDropZone, selectFiles } = provideLscDropzone({
  modelValue,
  dropzoneRef,
  maxFileSize: computed(() => props.maxFileSize),
  disabled: computed(() => props.disabled),
  error: computed(() => props.error),
  extensions: computed(() => props.extensions),
  errorMessage: computed(() => props.errorMessage),
  multiple: computed(() => props.multiple),
  showMessage: computed(() => props.showMessage),
  message: computed(() => props.message),
  size: computed(() => props.size),
});

useEventListener(document, 'paste', (event) => {
  if (event.clipboardData?.files.length > 0) {
    selectFiles(Array.from(event.clipboardData.files));
  }
});
</script>
<template>
  <div ref="dropzoneRef" class="relative h-full">
    <slot
      :isOverDropZone="isOverDropZone"
      :selectFiles="selectFiles"
      :modelValue="modelValue"
      :error="error"
      :errorMessage="errorMessage"
    >
      <LscDropzonePlaceholder v-if="!disabled" class="h-full" />
    </slot>
    <Transition
      appearFromClass="opacity-0"
      leaveToClass="opacity-0"
      appearActiveClass="transition-opacity"
      leaveActiveClass="transition-opacity"
    >
      <LscDropzoneOverlay v-if="isOverDropZone" />
    </Transition>
  </div>
</template>
