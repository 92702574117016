import { usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to Reports.
 * Measurement plans:
 * https://digitalcrew.teamwork.com/spaces/product-analytics/page/42288-reports-phase-i
 * https://digitalcrew.teamwork.com/spaces/product-analytics/page/57354-utilisation
 * https://digitalcrew.teamwork.com/spaces/product-analytics/page/52100-profitability-report-phase-i
 */

export function useReportTracking() {
  const {
    trackPendoEvent,
    EVENT_CATEGORIES: { DISCOVERY, ADVANCED_DISCOVERY, ACTIVATION },
  } = usePendo();

  const COMMON_METRICS = ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'];

  const categoryMap = {
    discovery: DISCOVERY,
    advanced: ADVANCED_DISCOVERY,
    activation: ACTIVATION,
  };

  function trackReportsEvent(pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'REPORTS_EVENT',
      commonMetrics: COMMON_METRICS,
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
      },
    };

    switch (eventAction) {
      case 'reports_viewed': {
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_opened':
      case 'custom_report_created':
      case 'request_a_demo_clicked':
      case 'first_time_experience_viewed':
      case 'start_using_report_clicked': {
        const [reportType] = args;
        trackingData.metadata.report_type = reportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'utilisation_report_viewed': {
        const [defaultTimePeriod, defaultColumns, columnOrder] = args;
        trackingData.metadata.default_time_period = defaultTimePeriod;
        trackingData.metadata.default_columns = defaultColumns;
        trackingData.metadata.column_order = columnOrder;
        trackPendoEvent(trackingData);
        break;
      }
      case 'this_period_clicked':
      case 'previous_period_clicked':
      case 'next_period_clicked':
      case 'time_period_opened':
      case 'export_opened':
      case 'search_submitted': {
        const [defaultTimePeriod] = args;
        trackingData.metadata.default_time_period = defaultTimePeriod;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [exportType] = args;
        trackingData.metadata.export_type = exportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'time_period_applied': {
        const [, timePeriod] = args;
        trackingData.metadata.export_type = timePeriod;
        trackPendoEvent(trackingData);
        break;
      }
      case 'column_sorted':
      case 'column_added':
      case 'column_removed': {
        const [columnName] = args;
        trackingData.metadata.column_name = columnName;
        trackPendoEvent(trackingData);
        break;
      }
      case 'user_row_interaction': {
        const [actionType] = args;
        trackingData.metadata.action_type = actionType;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackCustomReportEvent(pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'CUSTOM_REPORT_EVENT',
      commonMetrics: COMMON_METRICS,
      report_type: 'custom_reports',
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: eventCategory,
      },
    };

    switch (eventAction) {
      case 'create_custom_report_clicked':
      case 'custom_report_modal_viewed':
      case 'save_custom_report_clicked':
      case 'edit_custom_report_clicked':
      case 'delete_custom_report_clicked':
      case 'delete_custom_report_modal_viewed':
      case 'confirm_delete_clicked':
      case 'cancel_clicked':
      case 'edit_custom_report_modal_viewed':
      case 'export_custom_report_opened':
      case 'refresh_custom_report_clicked':
      case 'custom_report_searched':
      case 'schedule_custom_report_clicked':
      case 'custom_report_viewed':
      case 'create_new_report_clicked':
      case 'request_a_demo_clicked':
      case 'learn_more_clicked':
      case 'custom_report_report_type_viewed':
      case 'custom_report_add_columns_viewed':
      case 'custom_report_date_ranges_viewed':
      case 'custom_report_add_users_viewed': {
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_error_message_displayed': {
        const [errorMessage] = args;
        trackingData.metadata.error_message = errorMessage;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_exported': {
        const [fileType] = args;
        trackingData.metadata.file_type = fileType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [exportType, reportId] = args;
        trackingData.metadata.export_type = exportType;
        trackingData.metadata.report_id = reportId;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_created':
      case 'update_report_clicked': {
        const [reportType, dateRange, filtersApplied, usersAdded] = args;
        trackingData.metadata.report_type = reportType;
        trackingData.metadata.date_range = dateRange;
        trackingData.metadata.filters_applied = filtersApplied;
        trackingData.metadata.users_added = usersAdded;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_report_type_applied': {
        const [reportType] = args;
        trackingData.metadata.report_type = reportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_date_range_applied': {
        const [dateRange] = args;
        trackingData.metadata.date_range = dateRange;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_users_added': {
        const [usersAdded] = args;
        trackingData.metadata.users_added = usersAdded;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackProfitabilityEvent(viewType, viewPeriod, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'PROFITABILITY_EVENT',
      commonMetrics: COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
        page_type: 'profitability',
        view_type: viewType,
        view_period: viewPeriod,
      },
    };

    switch (eventAction) {
      case 'row_expanded':
      case 'project_name_clicked':
      case 'set_rates_clicked':
      case 'set_owner_clicked':
      case 'project_owner_set':
      case 'this_period_clicked':
      case 'previous_period_clicked':
      case 'next_period_clicked':
      case 'time_period_opened':
      case 'projects_cta_clicked':
      case 'users_cta_clicked':
      case 'profit_as_percent_clicked':
      case 'profit_as_number_clicked':
      case 'export_opened':
      case 'search_submitted':
      case 'schedule_report_clicked':
      case 'customise_view_displayed':
        trackPendoEvent(trackingData);
        break;
      case 'column_sort_applied': {
        const [columnName] = args;
        trackingData.metadata.column_name = columnName;
        trackPendoEvent(trackingData);
        break;
      }
      case 'time_period_applied': {
        const [prevViewPeriod, timePeriod] = args;
        trackingData.metadata.view_period = prevViewPeriod;
        trackingData.metadata.time_period = timePeriod;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [key] = args;
        const reportFormat = key === 'googleSheets' ? 'google_sheets' : key;
        trackingData.metadata.report_format = reportFormat;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackFeatureTrialEvent(eventAction) {
    trackPendoEvent({
      eventName: 'FEATURE_TRIAL_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        page_type: 'profitability_introduction',
        event_action: eventAction,
        fte_type: 'old_fte',
        trial_available: true,
      },
    });
  }

  function trackUpgradeSlateEvent(feature, eventAction, eventCategory) {
    trackPendoEvent({
      eventName: 'UPGRADE_SLATE_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        page_type: 'upgrade_slate',
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
        feature,
      },
    });
  }

  return {
    trackCustomReportEvent,
    trackReportsEvent,
    trackProfitabilityEvent,
    trackFeatureTrialEvent,
    trackUpgradeSlateEvent,
  };
}
