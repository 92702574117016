<script setup>
import {
  ONBOARDING_GOAL_ADD_BUDGET,
  ONBOARDING_GOAL_CREATE_PROJECT,
  ONBOARDING_GOAL_SAVE_AS_TEMPLATE,
  ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES,
  ONBOARDING_GOAL_ADD_AI_GENERATED_TASKS,
} from './constants';

const props = defineProps({
  goal: {
    type: String,
    required: true,
    validator: (value) =>
      [
        ONBOARDING_GOAL_ADD_BUDGET,
        ONBOARDING_GOAL_SAVE_AS_TEMPLATE,
        ONBOARDING_GOAL_CREATE_PROJECT,
        ONBOARDING_GOAL_ADD_TEAM_AND_SET_RATES,
        ONBOARDING_GOAL_ADD_AI_GENERATED_TASKS,
      ].includes(value),
  },
  projectId: {
    type: Number,
    required: true,
  },
});

const OnboardingWizardAcceleratorPanel = defineAsyncComponent(() => import('./OnboardingWizardAcceleratorPanel.vue'));
</script>

<template>
  <WidgetDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <OnboardingWizardAcceleratorPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
