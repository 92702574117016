<script setup>
import { useI18n } from '@/util';

defineProps({
  location: {
    type: String,
    default: 'bottom end',
  },
  icon: {
    type: String,
    default: 'lsi-options',
  },
});
const { t } = useI18n();
</script>
<template>
  <WidgetMenu offsetY :location="location" origin="auto">
    <template #activator="activator">
      <slot name="activator" v-bind="{ $attrs, ...activator }">
        <LscIconButton
          v-LsdTooltip="t('Options')"
          v-bind="{ ...$attrs, ...activator.props }"
          :ariaLabel="t('Options')"
          :icon="icon"
          size="sm"
          variant="primary"
          :ariaExpanded="activator.isActive"
          ariaHasPopup="menu"
        />
      </slot>
    </template>
    <VList v-bind="VListRounded" class="mt-3">
      <slot />
    </VList>
  </WidgetMenu>
</template>
