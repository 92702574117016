const entityComponentMap = {
  project: 'PreviewProject',
  projectupdate: 'PreviewProjectUpdate',
  task: 'PreviewTask',
  task_comment: 'PreviewTaskComment',
  milestone: 'PreviewMilestone',
  milestone_comment: 'PreviewMilestoneComment',
  link: 'PreviewLink',
  link_comment: 'PreviewLinkComment',
  notebook: 'PreviewNotebook',
  notebook_comment: 'PreviewNotebookComment',
  message: 'PreviewMessage',
  message_comment: 'PreviewMessageComment',
  file: 'PreviewFile',
  file_comment: 'PreviewFileComment',
};

export function inboxMapItemToPreview(inboxItem) {
  let entity = inboxItem.entity.type;

  // we must use a different component  if project update or user added to project event
  if (inboxItem.event.toLowerCase() === 'user-addedtoproject') {
    entity = 'project';
  }

  const component = entityComponentMap[entity];
  if (!component) {
    return undefined;
  }

  return {
    component,
    inboxItem,
  };
}
