<script setup>
import { useAssigneePicker } from './useAssigneePicker';
import { useI18n } from '@/util';

defineProps({
  max: {
    type: Number,
    default: undefined,
  },
});

const { t } = useI18n();

const { assignees, avatars, clearable } = useAssigneePicker();

const canClearAvatars = computed(() => clearable.value || assignees.value.length > 1);

function removeAssignee({ id }) {
  assignees.value = assignees.value.filter((assignee) => Number(assignee.id) !== Number(id));
}
</script>

<template>
  <LscAvatarStack
    :avatars="avatars"
    :clearable="canClearAvatars"
    size="md"
    :max="max"
    :removeTooltipFunction="(name) => t('Unassign {name}', { name })"
    @remove="removeAssignee"
  />
</template>
