<script setup>
defineProps({
  project: {
    type: Object,
    default: null,
  },
  budget: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['close']);
const modelValue = defineModel({ type: Boolean, default: false });

const BudgetAddOrEditDialogPanel = defineAsyncComponent(() => import('./BudgetAddOrEditDialogPanel.vue'));
</script>

<template>
  <WidgetDialog v-model="modelValue" :retainFocus="false">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <BudgetAddOrEditDialogPanel
        :project="project"
        :budget="budget"
        @close="
          close();
          emit('close');
        "
      />
    </template>
  </WidgetDialog>
</template>
