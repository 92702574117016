<script setup>
import { provideManageSubscription } from './useManageSubscription';

const props = defineProps({
  someProp: {
    type: String,
    default: null,
  },
});

provideManageSubscription();

const AccountManageSubscriptionDialogPanel = defineAsyncComponent(
  () => import('./AccountManageSubscriptionDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog fullscreen :scrim="false" transition="dialog-bottom-transition">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <AccountManageSubscriptionDialogPanel v-bind="props" @close="close" />
    </template>
  </WidgetDialog>
</template>
