<script setup>
import LswTagPickerMenu from './LswTagPickerMenu.vue';

const props = defineProps({
  projectId: {
    type: Number,
    default: undefined,
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const tags = defineModel('tags', {
  type: Array,
  default: () => [],
});

function handleClose() {
  if (props.closeOnSelect) {
    emit('update:modelValue', false);
  }
}
</script>

<template>
  <WidgetMenu :closeOnContentClick="false" offset="8" @update:modelValue="emit('update:modelValue', $event)">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LswTagPickerMenu v-model:tags="tags" :projectId="projectId" @close="handleClose" />
  </WidgetMenu>
</template>
