import { Duration } from 'luxon';

/**
 * @typedef {object} ParseDurationOptions
 * @property {number} maxHours - The maximum number of hours that can be represented in a human-readable duration.
 * @property {number=} minutesThreshold - The minimum integer in a string that can be inferred as minutes.
 */

const DEFAULT_MAX_HOURS = 999; // 999 hours

const TIME_DURATION_REGEX = /^\s*(\d+)?[ :;_/-]?([\d,.]+)?\s*$/;
const HUMAN_DURATION_REGEX =
  /^\s*(?<fullMatch>(?<durationAmount>[+-]?\d+(?:\.|,)?\d*|\d*(?:\.|,)?\d+)\s*((?<seconds>s|secs?|seconds?)?|(?<minutes>m|mins?|minutes?)?|(?<hours>h|hrs?|hours?)?|(?<days>d|day|days)?|(?<weeks>w|wks?|weeks?)?)?\s*)+$/i;

/**
 * Transforms a Duration or DurationLike object into a Duration object in minutes, and rounds the minutes value.
 * Our API is only accepts minutes as an integer.
 * @param {DurationLike} durationLikeObject
 * @returns {Duration}
 */
function normalizeDurationToMinutes(durationLikeObject) {
  if (!durationLikeObject) {
    return null;
  }
  return (
    Duration.fromDurationLike(durationLikeObject)
      .shiftTo('minutes')
      .mapUnits((value) => Math.round(value))
      // TODO: Remove this once we have a better way to handle this
      .reconfigure({ locale: 'en-US' })
  );
}

/**
 * @param {string|number}  duration Duration
 * @param {ParseDurationOptions} options
 * @returns {Duration|undefined} the Luxon duration object, always positive
 */
function parseTimeDuration(str, options) {
  const normalized = String(str)
    .replace(/[,.]+/g, '.') // replace commas and dots with a dot
    .replace(/[ :;_/-]+/g, ':') // 2;5 => 2:5
    .replace(/^:/g, ''); // :4:30 => 4:30 remove any colon at the start of the string
  const hms = normalized.split(':').map((part) => {
    const num = parseFloat(part);
    return Number.isNaN(num) ? 0 : num;
  });

  if (hms.length === 1) {
    // if the inputted number is an integer, then we're considering to be minutes
    // e.g 30 => 30 minutes
    if (options.minutesThreshold && Number.isInteger(hms[0]) && hms[0] >= options.minutesThreshold) {
      return normalizeDurationToMinutes({
        minutes: hms[0],
      });
    }
    // all other cases are considered to be hours
    // e.g 1.5 => 1 hour and 30 minutes
    return normalizeDurationToMinutes({
      hours: hms[0],
    });
  }

  if (hms.length > 1) {
    return normalizeDurationToMinutes({
      hours: hms[0],
      minutes: hms[1],
    });
  }

  return normalizeDurationToMinutes({
    hours: 0,
  });
}

/**
 * @param {string|number} inputDuration Input to parse
 * @param {ParseDurationOptions} options
 * @returns {Duration|null}
 */
function parseHumanDuration(inputDuration, options) {
  let input = inputDuration;
  let match = TIME_DURATION_REGEX.exec(input);

  if (match) {
    return parseTimeDuration(input, options);
  }

  let accumulated;
  input = String(input).replace(/[,.]+/g, '.');
  match = HUMAN_DURATION_REGEX.exec(input);

  // Go through regex matches and accumulate a duration
  while (match) {
    let duration;
    const value = Number(Math.abs(match.groups.durationAmount)); // converting all negative numbers to positive

    // Parse the value according to the group
    // If there was no group defined then parse the values as minutes
    // If `,` or `.` is used then parse as hours
    const isDecimalNoUnit = /^\d*\.\d*$/.exec(match.groups.fullMatch);

    if (match.groups.seconds) {
      duration = { minutes: Math.floor(value / 60) };
    } else if (match.groups.minutes) {
      duration = { minutes: value };
    } else if (match.groups.hours) {
      duration = { hours: value };
    } else if (match.groups.days) {
      duration = { days: value };
    } else if (match.groups.weeks) {
      duration = { weeks: value };
    } else {
      duration = { minutes: value };
    }

    if (!accumulated) {
      accumulated = Duration.fromObject({ minutes: 0 });
    }

    const durationToAppend = Duration.fromObject(duration);

    // shifting to minutes
    accumulated = normalizeDurationToMinutes(accumulated.plus(durationToAppend));

    // Move to the next match
    input = input.replace(match.groups.fullMatch, '');
    match = HUMAN_DURATION_REGEX.exec(input);
    if (match && input) {
      if (isDecimalNoUnit) {
        return null;
      }
    } else {
      return accumulated;
    }
  }

  return accumulated;
}

/**
 * Returns duration limited by the Max duration value
 * @param  {Duration} duration
 * @param  {number} maxHours
 * @returns {Duration} Duration
 */
function forceMaxDuration(duration, maxHours) {
  const maxHoursDuration = normalizeDurationToMinutes({ hours: maxHours });

  return duration.minutes > maxHoursDuration.minutes ? maxHoursDuration : duration;
}

/**
 * Returns Luxon duration object
 * Accepts either 'human' durations (e.g. 1h30minutes) or standard formats (e.g. 10:30:00).
 * @param  {string|number} input Duration string to parse
 * @param {ParseDurationOptions} options
 * @returns {Duration} Duration
 */
export function parseDuration(input, { maxHours = DEFAULT_MAX_HOURS, minutesThreshold = undefined } = {}) {
  const options = { minutesThreshold, maxHours };
  let duration;
  if (HUMAN_DURATION_REGEX.test(input)) {
    duration = parseHumanDuration(input, options);
  } else {
    duration = parseTimeDuration(input, options);
  }

  // Cap the duration to the maxHours
  return forceMaxDuration(duration, options.maxHours);
}

/**
 * Formats a Luxon duration object into a human-readable string
 * @param {Duration} duration Luxon duration object
 * @param {object} options
 * @param {boolean} options.showEmpty - whether to show zero minutes
 * @param {boolean} options.showAllEmptyUnits - whether to show all empty units (eg. 0h 0m)
 * @param {'short'|'narrow'|'long'} options.unitDisplay - style of unit formatting
 * @param {string[]} options.units - the units to show
 * @returns {string} Human-readable string representation of the duration
 */
export function formatDuration(
  duration,
  { showAllEmptyUnits = false, showEmpty = true, unitDisplay = 'narrow', units = ['hours', 'minutes'] } = {},
) {
  const durationInMinutes = normalizeDurationToMinutes(duration);
  const formatOptions = { unitDisplay, listStyle: 'narrow', type: 'unit' };

  // Rip out any empty units unless showAllEmptyUnits is true
  if (!showAllEmptyUnits && durationInMinutes.as(units[0]) === 0) {
    return showEmpty ? durationInMinutes.shiftTo(units[0]).toHuman(formatOptions) : '';
  }

  const durationInDHM = durationInMinutes.shiftTo(...units).toObject();
  const durationFromEntries = Object.entries(durationInDHM);
  const normalizedDuration = Duration.fromObject(
    Object.fromEntries(showAllEmptyUnits ? durationFromEntries : durationFromEntries.filter(([, v]) => v !== 0)),
    { locale: 'en-US' },
  );

  return normalizedDuration.toHuman(formatOptions) ?? '';
}

/**
 * Formats minutes into a human-readable string
 * @param {number} minutes Minutes represented as a integer
 * @param {Parameters<formatDuration>[1]} options
 * @returns {ReturnType<formatDuration>} Human-readable string representation of the duration
 */
export function formatMinutes(minutes, options) {
  return formatDuration(Duration.fromObject({ minutes }), options);
}

/**
 * Formats minutes into decimal string
 * @param {number} minutes Minutes represented as a integer
 * @returns {string} Human-readable string representation of the hours in decimal
 */
export function formatDecimalHours(minutes) {
  return (minutes / 60).toFixed(2);
}
