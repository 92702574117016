<script setup>
import { refDebounced } from '@vueuse/core';
import { usePeopleV3Loader } from '@/api';
import { useI18n } from '@/util';

const emit = defineEmits(['onClick']);
const { t } = useI18n();

const searchTerm = shallowRef('');
const count = shallowRef(10);
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const state = usePeopleV3Loader({
  params: computed(() => ({
    type: 'all',
    includeUsersWithoutProjects: false,
    includeObservers: false,
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
});
const { items: people } = state;
</script>
<template>
  <WidgetMenu :closeOnContentClick="false" location="bottom left" offset="12">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        icon="lsi-user"
        data-identifier="share-filter-users-btn"
        :disabled="false"
        class="mt-2 w-full"
      >
        {{ t('Search for users') }}
      </LswFilterOptionButton>
    </template>
    <LscSheet v-bind="VCardRounded" class="w-[--filter-dropdown-width]">
      <div class="pl-2 pr-2">
        <VTextField
          v-bind="VTextFieldFilter"
          v-model.trim="searchTerm"
          class="mt-2 !min-w-full"
          :placeholder="t('Search people')"
          prependInnerIcon="lsi-search"
          :active="Boolean(searchTerm)"
          autofocus
        />
      </div>
      <WidgetLoadingState :state="state" :blankTitle="t('No assignees found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem
              v-for="person in people"
              :key="person.id"
              data-identifier="share-filter-users-list"
              @click="emit('onClick', person)"
            >
              <div class="flex items-center justify-between truncate">
                <div class="flex">
                  <LscAvatar v-bind="getLsAvatarProps({ user: person })" />
                  <span class="ml-2 max-w-32 truncate text-body-1">{{ person.firstName }} {{ person.lastName }}</span>
                </div>
              </div>
            </VListItem>
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
      <WidgetLoadingLazy v-model:count="count" :state="state" :step="state.pageSize" />
    </LscSheet>
  </WidgetMenu>
</template>
