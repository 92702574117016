import { useCurrentAccount, useCurrentUser, useFeedbackActions } from '@/api';
import { useFeatureAccessEvent } from '@/module/featureLimit';
import { useI18n } from '@/util';

const salesExpansionInbox = 2749;
const sdrInbox = 2099;
const supportInbox = 1;
const formTypes = [
  // Scale Trials go to sales expansion inbox
  {
    type: 'scaleTrialActivated',
    request: 'Scale Trial - Book a Call',
    summary: 'Contact Form - Scale Trial - Book a Call',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
  // Generic book a call requests go to SDR inbox
  {
    type: 'sdrCall',
    request: 'Trial - Book a Call',
    summary: 'Contact Form - Trial - Book a Call',
    inboxId: sdrInbox,
    sendToHubspot: true,
  },
  // Experiment 42: variant[sales-driven] Scale Trial Request
  {
    type: 'scaleTrialRequest',
    request: 'Scale Demo Request',
    summary: 'Scale Plan Demo or Conversation Request',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
  // Stripe V2: Preventing customers from going to checkout
  {
    type: 'checkoutExcludedUser',
    request: 'Handle Subscription Changes Manually',
    summary: 'This customer is excluded from carrying out any self-service changes to their subscription',
    inboxId: supportInbox,
    sendToHubspot: true,
  },
  // Welcome Page: book callback
  {
    type: 'welcomePageSDRCall',
    request: 'Trial Demo Request',
    summary: 'Demo Request from New Business Trial',
    inboxId: sdrInbox,
    sendToHubspot: true,
  },
  // Checkout excluded user add users request
  {
    type: 'checkoutExcludedAddUsers',
    request: 'Add Users Request',
    summary:
      'This customer wants to add users but excluded from carrying out any self-service changes to their subscription',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
  {
    type: 'scaleUpgradeRequest',
    request: 'Scale Upgrade Request',
    summary: 'Scale Upgrade Request',
    inboxId: salesExpansionInbox,
    sendToHubspot: true,
  },
];

export function useFeedback() {
  const { postFeedback } = useFeedbackActions();
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const { t } = useI18n();
  const toast = useLsToast();
  const { trackFeatureAccessEvent } = useFeatureAccessEvent();

  const isContactFormOpen = shallowRef(false);
  const contactFormProps = shallowRef({});
  const isUpgradeRequestFormOpen = shallowRef(false);
  const upgradeRequestFormProps = shallowRef({});

  async function messageDesk(formEmail, phone, msg, formType, toastMsg) {
    const formFields = formTypes.find((ft) => ft.type === formType);
    if (!formFields) {
      toast.critical(t('Error sending your message.'));
      return;
    }

    const payload = {
      feedback: {
        source: 'Teamwork Projects',
        type: formFields.request,
        summary: formFields.summary,
        installationId: account.value.id,
        email: formEmail ?? user.value.emailAddress,
        telephone: phone,
        inboxId: formFields.inboxId,
        sendToHubspot: formFields.sendToHubspot,
        message: `
          Name: ${user.value.firstName} ${user.value.lastName}  \n
          Installation: ${account.value.id} \n
          Email: ${formEmail ?? user.value.emailAddress} \n
          Phone Number: ${phone ?? 'N/A'} \n
          Message Content: ${msg ?? 'N/A'} \n
        `,
      },
    };

    const successMessage = toastMsg ?? {
      title: t('Form Submitted'),
      message: t('Thank you for contacting us, {firstName}. We will contact you as soon as possible.', {
        firstName: user.value.firstName,
      }),
    };

    await postFeedback(payload).then(() => {
      toast.success(successMessage);
    });
  }

  function showContactForm({ title, subtitle, entryPoint = '', formSubmittedAction = null, formType = 'sdrCall' }) {
    const defaultDialogProps = {
      title,
      subtitle,
      formType,
      entryPoint,
      formSubmittedAction,
    };

    contactFormProps.value = { ...defaultDialogProps };
    isContactFormOpen.value = true;

    if (entryPoint === 'FeatureLimitUpgradeModal') {
      trackFeatureAccessEvent('contact_us_modal_displayed');
    }
  }

  function showRequestUpgradeForm({ planId, feature, message, entryPoint = '', featureLimitReached = true }) {
    const defaultDialogProps = {
      planId,
      feature,
      message,
      entryPoint,
      featureLimitReached,
    };

    upgradeRequestFormProps.value = { ...defaultDialogProps };

    isUpgradeRequestFormOpen.value = true;

    if (entryPoint === 'FeatureLimitUpgradeModal') {
      trackFeatureAccessEvent('contact_us_to_upgrade_clicked');
    }
  }

  return {
    showContactForm,
    isContactFormOpen,
    contactFormProps,
    messageDesk,
    showRequestUpgradeForm,
    isUpgradeRequestFormOpen,
    upgradeRequestFormProps,
  };
}
