<script setup>
import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { getAccessibleTextColor } from '@/util';
import { LscLabelVariants, LscLabelColors, LscLabelSizes } from './constants';

const props = defineProps({
  /**
   * The variant of the label.
   * @type {PropType<typeof LscLabelVariants[number]>}
   */
  variant: {
    type: String,
    default: 'emphasis',
    validator: (value) => LscLabelVariants.includes(value),
  },
  /**
   * The size of the label.
   * @type {PropType<typeof LscLabelSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (value) => LscLabelSizes.includes(value),
  },
  /**
   * The prependIcon to render inside the 'flag' label.
   * @type {PropType<LscIcon>}
   */
  prependIcon: {
    type: String,
    default: '',
    validator(value, { variant }) {
      if (variant !== 'flag' && value) {
        // eslint-disable-next-line no-console
        console.warn("Only variant='flag' should have 'prependIcon'.");
        return false;
      }
      return true;
    },
  },
  /**
   * Select an available option or choose a custom color for the 'flag' variant.
   * @type {PropType<string|[typeof LscLabelColors[number]]>}
   */
  color: {
    type: String,
    default: undefined,
    validator(value, { variant }) {
      if (variant !== 'flag' && value) {
        // eslint-disable-next-line no-console
        console.warn("Only variant='flag' should have 'color'.");
        return false;
      }
      return true;
    },
  },
});

const color = computed(() => props.color ?? 'yellow');

const labelColorMap = {
  yellow: TOKENS.LsdsAColorDecorative9,
  purple: TOKENS.LsdsAColorDecorative10,
  blue: TOKENS.LsdsAColorDecorative11,
  teal: TOKENS.LsdsAColorDecorative12,
  orange: TOKENS.LsdsAColorDecorative13,
  green: TOKENS.LsdsAColorDecorative14,
  red: TOKENS.LsdsAColorDecorative15,
  grey: TOKENS.LsdsAColorDecorative16,
};

const style = computed(() => {
  const bgColor = LscLabelColors.includes(color.value) ? labelColorMap[color.value] : color.value;
  const textColor = getAccessibleTextColor(bgColor);
  return {
    '--flag-label-bg-color': bgColor,
    '--flag-label-text-color': textColor,
  };
});

const LscLabelVariantStyleConfig = tv({
  base: 'inline-flex items-center gap-1 rounded-md text-overline',
  variants: {
    size: {
      sm: 'p-1',
      lg: 'p-2',
    },
    variant: {
      flag: 'bg-[--flag-label-bg-color] text-[--flag-label-text-color]',
      emphasis: 'bg-[--lsds-c-label-color-background-emphasis-default] text-on-emphasis',
      highlight: 'bg-[--lsds-c-label-color-background-highlight-default] text-[--lsds-a-color-text-success]',
    },
  },
  compoundVariants: [
    {
      variant: 'flag',
      size: 'sm',
      class: 'h-5',
    },
    {
      variant: 'flag',
      size: 'lg',
      class: 'h-6.5',
    },
  ],
});

const classes = computed(() => LscLabelVariantStyleConfig(props));
</script>

<template>
  <span :class="classes" :style="style">
    <slot name="prepend">
      <LscIcon v-if="variant === 'flag'" :icon="prependIcon" size="xs" />
    </slot>
    <slot />
  </span>
</template>
