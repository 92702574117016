const symbol = Symbol('LscDateStepper');
export function provideLscDateStepper({ size: _size } = {}) {
  const size = shallowRef(_size);
  provide(symbol, { size });
}
export function useLscDateStepper() {
  const context = inject(symbol);
  if (!context) {
    throw new Error('useLscDateStepper must be used within a LscDateStepperProvider');
  }
  return context;
}
