<script setup>
import { provideDocumentTitle, useI18n } from '@/util';
import { useHandle401 } from '@/api';
import AppShellError from './AppShellError.vue';
import AppShellLoading from './AppShellLoading.vue';
import AppShellNotFound from './AppShellNotFound.vue';
import AppShellUnauthenticated from './AppShellUnauthenticated.vue';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';

const { pathname } = window.location;
const { ready, error } = useI18n();
const ProofToolMain = defineAsyncComponent(() => import('@/module/proof').then((m) => m.ProofToolMain));
const ProofToolError = defineAsyncComponent(() => import('@/module/proof').then((m) => m.ProofToolError));

provideDocumentTitle();

const { got401 } = useHandle401();
</script>

<template>
  <AppShellError v-if="error" />
  <AppShellLoading v-else-if="!ready" />
  <AppShellUnauthenticated v-else-if="got401" />
  <template v-else-if="pathname.startsWith('/app/shared/proofs')">
    <template v-if="got401">
      <ProofToolError />
    </template>
    <template v-else>
      <ProofToolMain />
    </template>
  </template>
  <AppShellNotFound v-else />
  <AppShellToastContainer />
</template>
