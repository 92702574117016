<script setup>
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number, Object],
    default: '',
  },
});

const modelValue = defineModel('modelValue', {
  type: [Boolean, Array],
  default: false,
});

const indeterminate = defineModel('indeterminate', {
  type: [Boolean, Array],
  default: false,
});

const checkboxVariantStyleConfig = tv({
  base: ['group/LscCheckbox inline-flex cursor-pointer items-start gap-2'],
  slots: {
    input: 'peer sr-only',
    icon: 'absolute text-icon-on-primary',
    label: '-mt-1 text-body-1 peer-disabled:cursor-default peer-disabled:text-disabled',
    checkbox: [
      'relative flex size-4 shrink-0 items-center justify-center rounded-sm',
      'border-2 border-form-default bg-default',
      'group-hover/LscCheckbox:border-form-hover',
      'peer-focus-visible:border-form-active',
      'peer-checked:border-transparent peer-checked:bg-action-primary-default',
      'peer-checked:peer-disabled:bg-action-primary-disabled',
      'peer-indeterminate:border-transparent peer-indeterminate:bg-action-primary-default',
      'peer-indeterminate:peer-disabled:bg-action-primary-disabled',
      'peer-disabled:cursor-default peer-disabled:border-form-disabled',
      'transition duration-100 ease-in-out',
    ],
  },
});

const classes = computed(() => checkboxVariantStyleConfig(props));
</script>

<template>
  <label :class="classes.base()">
    <input
      :id="id"
      v-model="modelValue"
      type="checkbox"
      :indeterminate="indeterminate"
      :value="value"
      :disabled="disabled"
      :class="classes.input()"
    />
    <div :class="classes.checkbox()">
      <LscIcon
        v-if="indeterminate || modelValue"
        size="xs"
        :icon="indeterminate ? 'lsi-remove' : 'lsi-selected'"
        :class="classes.icon()"
      />
    </div>
    <span v-if="$slots.label || label" :class="classes.label()">
      <slot name="label">
        {{ label }}
      </slot>
    </span>
  </label>
</template>
