import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  return response.data.tags;
}

/**
 * Loads a list of all project tags.
 *
 */
export function useTagsV3Loader({ params, pageSize = 40, count = 40 }) {
  const { state, refresh } = useListLoader({
    url: '/projects/api/v3/tags.json',
    params,
    responseToItems,
    pageSize,
    count,
    type: 'tag',
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'tag') {
      refresh(event.tagId);
    }
  });

  return state;
}
