import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

export function useExperimentA2411() {
  const { projectsWorkerBeeTrainingProjectEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const { isOwnerAdmin } = usePermissions();
  const { launchDarklyFlagKey: EXP_A2411_FLAG_KEY, defaultValue } = projectsWorkerBeeTrainingProjectEnabled;

  const ignoreInExperimentOnRuleMatch = true;
  const EXP_A2411_COMMON_METRICS = ['plan_name', 'page', 'user_role'];
  const EXP_A2411_EVENT_NAME = 'WORKER_BEE_PROJECT_EVENT';

  const expA2411AppLevelTargeting = computed(() => isOwnerAdmin?.value);
  const isExpA2411Variation = computed(
    () => projectsWorkerBeeTrainingProjectEnabled.value && expA2411AppLevelTargeting.value,
  );
  const isExpA2411Control = computed(
    () => !projectsWorkerBeeTrainingProjectEnabled.value && expA2411AppLevelTargeting.value,
  );

  function trackExperimentA2411() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_A2411_FLAG_KEY,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA2411AppLevelTargeting.value,
    });
  }

  function trackEventA2411({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: EXP_A2411_EVENT_NAME,
      commonMetrics: EXP_A2411_COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExpA2411Variation,
    isExpA2411Control,
    trackExperimentA2411,
    trackEventA2411,
  };
}
