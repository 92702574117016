<script setup>
import LscAvatar from './LscAvatar.vue';
import { LscAvatarSizes } from './constants';

/**
 * @typedef {import('./constants').LscAvatar} LscAvatar
 */

const props = defineProps({
  /**
   * The size of the avatars in the stack.
   * @type {PropType<typeof LscAvatarSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (v) => LscAvatarSizes.includes(v),
  },
  /**
   * The avatars to display.
   * @type {PropType<LscAvatar[]>}
   */
  avatars: {
    type: Array,
    default: () => [],
  },
  /**
   * The maximum number of avatars to display.
   */
  max: {
    type: Number,
    default: 3,
    validator: (v) => v > 0,
  },
  /**
   * Whether the avatar is clearable.
   */
  clearable: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to show tooltips on the avatars.
   * This is useful when tooltips are controlled by a parent component.
   */
  showTooltips: {
    type: Boolean,
    default: true,
  },
  /**
   * A function to generate the tooltip text for the removing the avatar.
   * @type {PropType<(name:string)=>string>}
   */
  removeTooltipFunction: {
    type: Function,
    default: undefined,
  },
});

const emit = defineEmits(['remove']);

const displayedAvatars = computed(() => props.avatars.slice(0, props.max));
const remainingAvatars = computed(() => props.avatars.slice(props.max, props.avatars.length));

const remainingCount = computed(() =>
  remainingAvatars.value.length > 99 ? '99+' : `+${remainingAvatars.value.length}`,
);

const remainingAvatarTooltip = computed(() =>
  props.showTooltips
    ? remainingAvatars.value
        ?.map((item) => item?.name)
        .filter(Boolean)
        .join('\n')
    : undefined,
);

const avatarStackVariantStyleConfig = tv({
  base: 'relative flex -space-x-1',
  slots: {
    avatar: 'hover:z-10',
    showMore: 'cursor-pointer',
  },
  variants: {
    size: {
      md: {
        base: '-space-x-2',
      },
      lg: {
        base: '-space-x-2',
      },
      xl: {
        base: '-space-x-3',
      },
    },
    hasRing: {
      true: {
        avatar: 'ring-1 ring-reversed',
      },
    },
  },
});

const classes = computed(() =>
  avatarStackVariantStyleConfig({
    size: props.size,
    hasRing: props.avatars.length > 1,
  }),
);
</script>

<template>
  <div v-if="displayedAvatars.length" :class="classes.base()">
    <LscAvatar
      v-for="(avatar, index) in displayedAvatars"
      :key="index"
      :class="classes.avatar()"
      :size="size"
      :removeTooltipFunction="removeTooltipFunction"
      :showTooltip="showTooltips"
      :clearable="clearable"
      v-bind="avatar"
      @remove="emit('remove', avatar)"
    />
    <LscAvatar
      v-if="remainingAvatars.length"
      v-LsdTooltip="remainingAvatarTooltip"
      :class="[classes.avatar(), classes.showMore()]"
      bgColor="var(--lsds-a-color-surface-dark)"
      iconColor="var(--lsds-a-color-text-on-dark)"
      :size="props.size"
      :removeTooltipFunction="removeTooltipFunction"
      :showTooltip="false"
    >
      {{ remainingCount }}
    </LscAvatar>
  </div>
</template>
