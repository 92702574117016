import { useAxios } from '../base/useAxios';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { useCurrentUser } from '../user/useCurrentUser';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const symbol = Symbol('useNotificationsUnreadCount');

function shouldAddNewNotificationFromRealTimeUpdate(currentUser, rawEvent) {
  if (!rawEvent) {
    return false;
  }

  if (!rawEvent?.eventInfo || !rawEvent?.eventInfo?.popup) {
    return false;
  }

  // Only handle notification not originating from this user
  if (parseInt(rawEvent?.eventInfo?.userId, 10) === parseInt(currentUser.value.id, 10)) {
    return false;
  }

  return true;
}

function NotificationsUnreadCountService() {
  const api = useAxios();
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();

  const optimisticUpdates = new Set();

  const count = ref(0);

  function decrement() {
    const result = count.value - 1;
    count.value = result < 0 ? 0 : result;
  }

  function increment() {
    count.value += 1;
  }

  function set(countValue) {
    count.value = countValue;
  }

  function clear() {
    set(0);
  }

  async function fetch() {
    try {
      const response = await api.get('/projects/api/v3/notifications/unreadcount.json');
      set(response.data.notifications.unread);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  function optimisticIncrement(promise) {
    increment();
    promise.catch(decrement);
  }

  function optimisticDecrement(promise) {
    decrement();
    promise.catch(increment);
  }

  function optimisticClear(promise) {
    const oldCount = count.value;
    clear();
    const rollback = () => set(oldCount);
    promise.catch(rollback);
  }

  useOptimisticUpdates((event) => {
    if (!(event.type === 'notification' && event.action === 'update')) {
      return;
    }

    if (event.notification.id) {
      optimisticUpdates.add(event.notification.id);
      if (event.notification.read) {
        optimisticDecrement(event.promise);
      } else {
        optimisticIncrement(event.promise);
      }
    } else {
      optimisticClear(event.promise);
    }
  });

  useRealTimeUpdates((event, rawEvent) => {
    if (!currentAccount.value?.realTimeNotifications) {
      return;
    }
    if (!rawEvent) {
      return;
    }

    if (optimisticUpdates.has(rawEvent.eventInfo.twimEventId)) {
      optimisticUpdates.delete(rawEvent.eventInfo.twimEventId);
      return;
    }

    if (event.detail === 'notification-updated-read') {
      decrement();
      return;
    }

    if (event.detail === 'notification-updated-unread') {
      increment();
      return;
    }

    if (event.detail === 'notification-mark-all-read') {
      clear();
      return;
    }

    if (shouldAddNewNotificationFromRealTimeUpdate(currentUser, rawEvent)) {
      increment();
    }
  });

  fetch();

  return count;
}

export function provideNotificationsUnreadCount() {
  provide(symbol, NotificationsUnreadCountService());
}

/**
 * @type {NotificationsUnreadCountService}
 */
export function useNotificationsUnreadCount() {
  return inject(symbol);
}
