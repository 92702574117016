import {
  usePendo,
  useFeatures,
  usePreferences,
  usePermissions,
  useEnabledApps,
  useCurrentAccount,
  useCurrentUser,
  useCohort,
  useExperimentA2413c,
  useExperimentA2350,
} from '@/api';
import { useCurrentProject, useRoute } from '@/route';
import { useI18n, formatKeyboardShortcut, useLocalStorage } from '@/util';
import { usePlanTrial } from '@/module/planTrial';
import { useAppShellSidebarTracking } from './useAppShellSidebarTracking';

const symbol = Symbol('useAppShellSidebar');

function AppShellSidebar() {
  const allowAccessPermission = computed(() => true);
  const project = useCurrentProject();
  const account = useCurrentAccount();
  const route = useRoute();
  const { t } = useI18n();
  const { trackPendoEvent } = usePendo();
  const { sidebarPinnedItems } = usePreferences();
  const { lightspeedProjectsInSidebar, lightspeedSpacesPanelEnabled, commandCenterEnabled } = useFeatures();
  const {
    isAccount,
    isSiteAdmin,
    canViewCalendar,
    canAccessPeopleCompaniesTeams,
    canViewFormsPanel,
    canViewProofs,
    canViewReports,
    canViewProjectTemplates,
  } = usePermissions();
  const { isSpacesAvailable } = useEnabledApps();
  const isSpacesPanelAllowed = computed(() => isSpacesAvailable.value && lightspeedSpacesPanelEnabled.value);
  const isMyShortcutsOpen = shallowRef(false);
  const isProductUpdatesOpen = shallowRef(false);
  const isSuccessPlannerOpen = shallowRef(false);
  const activeDrawerPanelState = shallowRef(null);
  const isQuickAddOpen = shallowRef(false);
  const isUserMenuMoreItemsOpen = shallowRef(false);
  const isUserMenuSwitchAccountsOpen = shallowRef(false);
  const isSidebarOpenOnMobile = shallowRef(false);
  const isSampleTemplatePreviewDialogOpen = shallowRef(false);
  const user = useCurrentUser();
  const { isClientUser, isCollaborator } = useCohort();
  const { isTrialActive } = usePlanTrial();
  const { isExpA2413cVariation } = useExperimentA2413c();
  const { isExpA2350Variation } = useExperimentA2350();

  const canViewWelcomePage = computed(() => !(isClientUser.value || isCollaborator.value));

  const sidebarNavItems = [
    {
      itemKey: 'home',
      path: '/home',
      text: t('Home'),
      icon: 'lsi-sidenav-home',
      permission: allowAccessPermission,
      fixed: true,
      pendoLabel: 'home',
      dataIdentifier: 'side-nav-home-button',
    },
    {
      itemKey: 'search',
      path: null,
      text: t('Search'),
      icon: 'lsi-sidenav-quick-search',
      drawerPanel: commandCenterEnabled.value ? undefined : 'search',
      tooltip: {
        text: t('Search'),
        subtext: commandCenterEnabled.value ? formatKeyboardShortcut('Cmd K') : formatKeyboardShortcut('F'),
      },
      permission: allowAccessPermission,
      fixed: true,
      pendoLabel: 'search',
      dataIdentifier: 'app-nav__search',
      dialogName: commandCenterEnabled.value ? 'CommandCenterDialog' : undefined,
      keyboardShortcut: commandCenterEnabled.value ? 'COMMAND K' : 'F',
      keyboardShortcutActiveOnInput: commandCenterEnabled.value,
    },
    {
      itemKey: 'welcome',
      path: isExpA2350Variation.value ? '/welcome/set-up-teamwork' : '/welcome/the-fundamentals',
      text: t('Welcome'),
      icon: 'lsi-sidenav-welcome',
      permission: canViewWelcomePage,
      pendoLabel: 'welcome',
      dataIdentifier: 'app-nav__welcome',
      isActive: () => route.path.startsWith('/welcome'),
    },
    {
      itemKey: 'jumpTo',
      path: null,
      text: isExpA2413cVariation.value ? t('Recent') : t('Jump to'),
      tooltip: {
        text: t('Jump to'),
        subtext: formatKeyboardShortcut('S'),
      },
      icon: 'lsi-sidenav-jump-to',
      drawerPanel: 'jumpTo',
      permission: allowAccessPermission,
      fixed: true,
      pendoLabel: 'jump_to',
      dataIdentifier: 'side-nav-jump-to-button',
      keyboardShortcut: 'S',
    },
    {
      itemKey: 'clients',
      path: '/clients',
      text: t('Clients'),
      icon: 'lsi-sidenav-clients',
      permission: computed(() => {
        return account.value.useClientView && canAccessPeopleCompaniesTeams.value;
      }),
      pendoLabel: 'clients',
      dataIdentifier: 'side-nav-clients-button',
      onClick() {
        trackPendoEvent({
          eventName: 'CLIENTS_NAVIGATION_EVENT',
        });
      },
    },
    {
      itemKey: 'projects',
      path: '/projects',
      text: t('Projects'),
      icon: 'lsi-sidenav-projects',
      permission: allowAccessPermission,
      isActive: () => ['/projects'].some((path) => route.path.startsWith(path)) || Boolean(project.value),
      pendoLabel: 'projects',
      dataIdentifier: 'side-nav-projects-button',
    },
    {
      itemKey: 'templates',
      path: null,
      text: t('Templates'),
      icon: 'lsi-sidenav-templates',
      drawerPanel: 'templates',
      permission: canViewProjectTemplates,
      pendoLabel: 'templates',
      dataIdentifier: 'side-nav-templates-button',
    },
    {
      itemKey: 'spaces',
      path: null,
      text: t('Spaces'),
      icon: 'lsi-sidenav-spaces',
      drawerPanel: 'spaces',
      permission: isSpacesPanelAllowed,
      pendoLabel: 'spaces',
      dataIdentifier: 'side-nav-spaces-button',
    },
    {
      itemKey: 'planning',
      path: '/planning',
      text: isExpA2413cVariation.value ? t('Resourcing') : t('Planning'),
      icon: 'lsi-sidenav-planning',
      permission: allowAccessPermission,
      pendoLabel: 'planning',
      dataIdentifier: 'side-nav-planning-button',
    },
    {
      itemKey: 'budgets',
      path: '/budgets',
      text: t('Budgets'),
      icon: 'lsi-budget',
      permission: isSiteAdmin,
      pendoLabel: 'budgets',
      dataIdentifier: 'side-nav-budgets-button',
    },
    {
      itemKey: 'reports',
      path: '/reports',
      text: isExpA2413cVariation.value ? t('Reporting') : t('Reports'),
      icon: 'lsi-sidenav-reports',
      permission: computed(() => canViewReports.value || user.value.permissions.canAccessCustomReports),
      pendoLabel: 'reports',
      dataIdentifier: 'side-nav-reports-button',
    },
    {
      itemKey: 'time',
      path: isTrialActive.value ? '/time/user' : '/time',
      text: t('Time'),
      icon: 'lsi-sidenav-time',
      permission: isAccount,
      pendoLabel: 'time',
      dataIdentifier: 'side-nav-time-button',
    },
    {
      itemKey: 'proofs',
      path: '/proofs',
      text: t('Proofs'),
      icon: 'lsi-sidenav-proofs',
      permission: canViewProofs,
      pendoLabel: 'proofs',
      dataIdentifier: 'side-nav-proofs-button',
    },
    {
      itemKey: 'forms',
      path: null,
      text: t('Forms'),
      icon: 'lsi-sidenav-forms',
      drawerPanel: 'forms',
      permission: canViewFormsPanel,
      pendoLabel: 'forms',
      dataIdentifier: 'side-nav-forms-button',
    },
    {
      itemKey: 'everything',
      path: '/everything',
      text: t('Everything'),
      icon: 'lsi-sidenav-everything',
      permission: isAccount,
      pendoLabel: 'everything',
      dataIdentifier: 'side-nav-everything-button',
    },
    {
      itemKey: 'calendar',
      path: '/calendar',
      text: t('Calendar'),
      icon: 'lsi-sidenav-calendar',
      permission: canViewCalendar,
      pendoLabel: 'calendar',
      dataIdentifier: 'side-nav-calendar-button',
    },
    {
      itemKey: 'people',
      path: '/people',
      text: t('People'),
      icon: 'lsi-sidenav-people',
      permission: canAccessPeopleCompaniesTeams,
      pendoLabel: 'people',
      dataIdentifier: 'side-nav-people-button',
      isActive: () => ['/people', '/teams', '/companies'].some((path) => route.path.startsWith(path)),
    },
  ];

  const appShellSidebarPanelState = useLocalStorage(
    'teamwork/useAppShellSidebar',
    {
      isSidebarExpanded: true,
      activeDrawerPanel: null,
      isDrawerPanelPinned: false,
    },
    {
      listenToStorageChanges: false,
    },
  );

  function isItemPinned(key) {
    return Boolean(sidebarPinnedItems.value[key]?.docked);
  }

  function setItemPinned(key, pinned) {
    sidebarPinnedItems.value = {
      ...sidebarPinnedItems.value,
      [key]: {
        ...sidebarPinnedItems.value[key],
        docked: pinned,
      },
    };
  }

  if (canViewProofs.value) {
    trackPendoEvent({
      eventName: 'PROOFS_FEATURE_ENABLED',
    });
  }

  const isSidebarExpanded = computed({
    get() {
      return appShellSidebarPanelState.value.isSidebarExpanded;
    },
    set(val) {
      appShellSidebarPanelState.value.isSidebarExpanded = val;
    },
  });

  const activeDrawerPanel = computed({
    get() {
      if (appShellSidebarPanelState.value.activeDrawerPanel) {
        activeDrawerPanelState.value = appShellSidebarPanelState.value.activeDrawerPanel;
      }

      return activeDrawerPanelState.value;
    },
    set(val) {
      if (appShellSidebarPanelState.value.isDrawerPanelPinned && val !== null) {
        appShellSidebarPanelState.value.isDrawerPanelPinned = val;
        appShellSidebarPanelState.value.activeDrawerPanel = val;
      }

      activeDrawerPanelState.value = val;
    },
  });

  const sidebarWidth = computed(() => {
    if (lightspeedProjectsInSidebar.value && isSidebarExpanded.value) {
      // Once the `lightspeedProjectsInSidebar` flag is removed,
      // we must define this width value in `designsystem/design-tokens/properties/default/components/sidebar.json5`
      // as `--lsds-c-sidebar-drawer-width-expanded`.
      return '288px';
    }
    return isSidebarExpanded.value
      ? 'var(--lsds-c-sidebar-drawer-width-expanded)'
      : 'var(--lsds-c-sidebar-drawer-width-collapsed)';
  });

  const activeDrawerPanelWidth = computed(() => {
    switch (activeDrawerPanel.value) {
      case 'help-center-wide':
        return 'var(--help-center-wide-panel-width)';
      case 'chat':
      case 'help-center':
      case 'notifications':
      case 'search':
        return 'var(--lsds-c-sidebar-drawer-panel-width-lg)';
      case 'forms':
      case 'jumpTo':
      case 'spaces':
      case 'templates':
        return 'var(--lsds-c-sidebar-drawer-panel-width-sm)';
      default:
        return 'var(--lsds-c-sidebar-drawer-panel-width-sm)';
    }
  });

  const isDrawerPanelPinned = computed({
    get() {
      return appShellSidebarPanelState.value.isDrawerPanelPinned;
    },
    set(val) {
      if (val === activeDrawerPanelState.value) {
        appShellSidebarPanelState.value.activeDrawerPanel = val;
      }

      if (val === null) {
        appShellSidebarPanelState.value.activeDrawerPanel = null;
      }

      appShellSidebarPanelState.value.isDrawerPanelPinned = val;
    },
  });

  const { trackLeftNavigationHiding } = useAppShellSidebarTracking({ isSidebarExpanded });

  function toggleSidebarExpansion() {
    isSidebarExpanded.value = !isSidebarExpanded.value;
    trackLeftNavigationHiding();
  }

  function setActiveDrawerPanel(val) {
    activeDrawerPanel.value = val;
  }

  function clearActiveDrawerPanel() {
    appShellSidebarPanelState.value.activeDrawerPanel = null;
    activeDrawerPanelState.value = null;
  }

  function clearActiveDrawerPanelIfNotPinned() {
    if (!isDrawerPanelPinned.value) {
      clearActiveDrawerPanel();
    }
  }

  function toggleActiveDrawerPanel(panel) {
    if (activeDrawerPanel.value === panel) {
      clearActiveDrawerPanel();
    } else {
      setActiveDrawerPanel(panel);
    }
  }

  function isItemActive(item) {
    return item.isActive?.() ?? route.path.startsWith(item.path);
  }

  const dockedNavItems = computed(() => {
    return sidebarNavItems.filter((item) => item.permission.value && (item.fixed || isItemPinned(item.itemKey)));
  });

  const moreNavItems = computed(() => {
    return sidebarNavItems.filter((item) => item.permission.value && !(item.fixed || isItemPinned(item.itemKey)));
  });

  function closeSidebarOnMobile() {
    isSidebarOpenOnMobile.value = false;
  }

  onMounted(() => {
    const activeNavItem = sidebarNavItems.find(({ itemKey }) => itemKey === activeDrawerPanel.value);
    if (!activeNavItem) {
      return;
    }

    if (!activeNavItem.permission.value) {
      clearActiveDrawerPanel();

      if (isDrawerPanelPinned.value) {
        setItemPinned(activeDrawerPanel.value, false);
      }
    }
  });

  return {
    closeSidebarOnMobile,
    clearActiveDrawerPanel,
    clearActiveDrawerPanelIfNotPinned,
    activeDrawerPanel,
    activeDrawerPanelWidth,
    dockedNavItems,
    isItemActive,
    isItemPinned,
    isSidebarOpenOnMobile,
    isSidebarExpanded,
    isMyShortcutsOpen,
    isProductUpdatesOpen,
    isQuickAddOpen,
    isDrawerPanelPinned,
    isSuccessPlannerOpen,
    isUserMenuMoreItemsOpen,
    isUserMenuSwitchAccountsOpen,
    isSampleTemplatePreviewDialogOpen,
    moreNavItems,
    setItemPinned,
    setActiveDrawerPanel,
    sidebarWidth,
    sidebarNavItems,
    toggleActiveDrawerPanel,
    toggleSidebarExpansion,
    canViewWelcomePage,
  };
}

/**
 * @type {AppShellSidebar}
 */
export function provideAppShellSidebar() {
  const appShellSidebar = AppShellSidebar();
  provide(symbol, appShellSidebar);
  return appShellSidebar;
}

/**
 * @type {AppShellSidebar}
 */
export function useAppShellSidebar() {
  return inject(symbol);
}
