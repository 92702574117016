import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { useCurrentAccount } from '../account/useCurrentAccount';

/**
 * Constants, computed properties and functions related to Experiment E-23-01
 * https://app.launchdarkly.com/default/production/features/projects-exp-e-23-01-craic-dealer-maxp/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/41319-e-23-01-craic-dealer-on-maxprojects
 */
export function useExperimentE2301() {
  const { projectsCraicDealerMaxProjects } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const account = useCurrentAccount();

  const EXP_E23_01_FLAG_KEY = 'projects-exp-e-23-01-craic-dealer-maxp';
  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;
  const MAXP_PLAN_IDS = [58, 57, 56, 9, 74, 75, 76];

  const expE2301AppLevelTargeting = computed(() => Boolean(MAXP_PLAN_IDS.includes(account.value?.pricePlanId)));
  const isExpE2301Variation = computed(() => projectsCraicDealerMaxProjects.value && expE2301AppLevelTargeting.value);
  const isExpE2301Control = computed(() => !isExpE2301Variation.value && expE2301AppLevelTargeting.value);

  function trackExperimentE2301() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_E23_01_FLAG_KEY,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expE2301AppLevelTargeting.value,
    });
  }

  return {
    isExpE2301Variation,
    isExpE2301Control,
    trackExperimentE2301,
    projectsCraicDealerMaxProjects,
  };
}
