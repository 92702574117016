import { exponentialBackOff } from './exponentialBackOff';

/**
 * Manages retry delays for processes that can fail and be retried.
 */
export function useBackOff({
  /**
   * A function which determines how long to wait in milliseconds before retrying.
   * It takes a 0-based retry attempt number and returns the required delay.
   */
  retryDelay = exponentialBackOff(),
} = {}) {
  const handle = shallowRef(undefined);
  const retryAttempt = ref(-1);

  function stop() {
    if (handle.value !== undefined) {
      clearTimeout(handle.value);
      handle.value = undefined;
    }
  }

  function start() {
    stop();
    retryAttempt.value += 1;
    handle.value = setTimeout(stop, retryDelay(retryAttempt.value));
  }

  function reset() {
    stop();
    retryAttempt.value = -1;
  }

  onUnmounted(stop);

  return {
    /**
     * Increments retryAttempt and starts the back-off.
     */
    start,
    /**
     * Stops the back-off and resets retryAttempt.
     */
    reset,
    /**
     * Indicates if a back-off is currently active.
     */
    active: computed(() => handle.value !== undefined),
    /**
     * The 0-based retry attempt number. It is -1 when not retrying.
     */
    retryAttempt,
  };
}
