import useVuelidate from '@vuelidate/core';
import { useValidators } from '@/util';
import { CURRENCY_OPTIONS } from '../constants';
import { useCurrentAccount, useExperimentA2301 } from '@/api';

export function useOnboardingWizardUserRates(state) {
  const currentAccount = useCurrentAccount();
  const { trackDefaultRateEntered } = useExperimentA2301();
  const { minValue, maxValue, decimal } = useValidators();

  const billableRates = ref(
    state?.stateData?.billableRates ??
      new Array((state?.invitePeople?.numberOfEmailsIntroduced ?? 0) + 1).fill(undefined),
  );
  const costRates = ref(
    state?.stateData?.costRates ?? new Array((state?.invitePeople?.numberOfEmailsIntroduced ?? 0) + 1).fill(undefined),
  );

  const currencyId = shallowRef(
    state?.stateData?.currencyId ??
      state?.setBudget?.currencyId ??
      CURRENCY_OPTIONS.find((currency) => currency.id === currentAccount.value?.currency?.id)?.id ??
      CURRENCY_OPTIONS[0].id,
  );

  const hasClearValidationStatus = shallowRef(true);
  const defaultRate = shallowRef(state?.stateData?.defaultRate);
  const defaultRateEl = shallowRef();
  const showValidationError = shallowRef(false);

  const automaticCostRatesCalculationAlertDisplayed = shallowRef(
    Boolean(state?.stateData?.automaticCostRatesCalculationAlertDisplayed),
  );
  const automaticCostRatesCalculationAlertVisible = shallowRef(false);
  const automaticCostRatesCalculationEnabled = shallowRef(
    state?.stateData?.automaticCostRatesCalculationEnabled ?? true,
  );

  const billingType = state?.billingType?.type;

  const rules = {
    defaultRate: {
      decimal,
      minValue: minValue(0),
      maxValue: maxValue(9999999),
    },
  };

  const v$ = useVuelidate(
    rules,
    {
      defaultRate,
    },
    { $autoDirty: false },
  );

  const currencySymbol = computed(() => CURRENCY_OPTIONS.find((currency) => currency.id === currencyId.value).symbol);

  function getPreviewData(firstPersonWithRates) {
    if (firstPersonWithRates === undefined) {
      return {
        totalCost: undefined,
        billableTotal: undefined,
        profit: undefined,
      };
    }

    const totalCost = costRates.value[firstPersonWithRates] * 10;
    const billableTotal = billableRates.value[firstPersonWithRates] * 10;
    const profit = billableTotal - totalCost;

    return {
      totalCost,
      billableTotal,
      profit,
    };
  }

  function showAutomaticCostRatesCalculationAlert() {
    if (automaticCostRatesCalculationAlertDisplayed.value) {
      return;
    }

    automaticCostRatesCalculationAlertVisible.value = true;
    automaticCostRatesCalculationAlertDisplayed.value = true;

    setTimeout(() => {
      automaticCostRatesCalculationAlertVisible.value = false;
    }, 10000);
  }

  function handleDefaultRateInputBlurred() {
    if (!defaultRate.value) {
      return;
    }

    if (Number.isInteger(defaultRate)) {
      return;
    }

    if (billingType) {
      trackDefaultRateEntered(billingType);
    }

    defaultRate.value = Number(defaultRate.value.toFixed(2));
  }

  return {
    currencyId,
    currencySymbol,
    getPreviewData,
    automaticCostRatesCalculationAlertDisplayed,
    automaticCostRatesCalculationAlertVisible,
    automaticCostRatesCalculationEnabled,
    showAutomaticCostRatesCalculationAlert,
    hasClearValidationStatus,
    defaultRate,
    defaultRateEl,
    showValidationError,
    billableRates,
    costRates,
    handleDefaultRateInputBlurred,
    v$,
  };
}
