<script setup>
import { useI18n } from '@/util';
import {
  Filter,
  FilterCategoryPicker,
  FilterCheckbox,
  FilterCompanyPicker,
  FilterCustomFields,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterStatusTypes,
  FilterTagPicker,
} from '@/module/filter';
import { useCurrentAccount } from '@/api';
import { useReportBuilderFilters } from '../useReportBuilderFilters';

const { t } = useI18n();
const account = useCurrentAccount();
const { filterParams } = useReportBuilderFilters();
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter"
    section="reportBuilderFilter"
    :allowSaveFilters="false"
    class="flex flex-col"
  >
    <template #menuFilters>
      <FilterProjectPicker :title="t('Projects')" name="projectIds" />
      <FilterTagPicker v-if="account.tagsEnabled" :title="t('Tags')" name="tagIds" />
      <FilterPeoplePicker
        :title="t('Owner')"
        name="userIds"
        :loaderParams="{ includeClients: false, includeCollaborators: false }"
      />
      <FilterCompanyPicker :title="t('Companies')" name="companyIds" />
      <FilterCategoryPicker :title="t('Categories')" name="categoryIds" />
      <FilterStatusTypes :title="t('Project status')" name="status" :status="['all', 'late']" />
      <FilterCustomFields :title="t('Custom fields')" entities="project" />
    </template>
    <template #menuOptions>
      <FilterCheckbox
        name="includeCompletedProjects"
        :label="t('Include completed projects')"
        :defaultValue="filterParams.includeCompletedProjects"
      />
      <FilterCheckbox name="onlyStarredProjects" :label="t('Show starred projects only')" />
      <FilterCheckbox name="includeArchivedProjects" :label="t('Include data from archived projects')" />
      <FilterCheckbox name="projectsWithActivityOnly" :label="t('Show projects with activity only')" />
    </template>
  </Filter>
</template>
