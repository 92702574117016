<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useTasklistsV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const count = shallowRef(10);
const filterType = 'tasklists';
const baseParams = {
  'fields[projects]': 'id,name',
  include: 'projects',
  skipCounts: false,
};

const pageSize = 20;
const state = useTasklistsV3Loader({
  params: computed(() => ({
    ...baseParams,
    ...props.loaderParams,
    orderBy: 'project',
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});

const { items } = state;

const missingTasklistIds = shallowRef([]);
const missingTasklistsState = useTasklistsV3Loader({
  params: computed(() => ({
    ...baseParams,
    ...props.loaderParams,
    ids: missingTasklistIds.value.join(','),
  })),
  count: computed(() => missingTasklistIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  items,
  missingTasklistsState.items,
);

const tasklistIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const tasklists = computeAll(tasklistIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(tasklistIds), missingTasklistIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    tasklists.value.map((tasklist) => ({
      name: tasklist.name,
      icon: 'lsi-tasklist',
      type: filterType,
      delete: () => {
        tasklistIds.value = tasklistIds.value.filter((id) => id !== tasklist.id);
      },
    })),
  ),
);

useFilterCount(computed(() => tasklistIds.value.length));

function onClick({ id }) {
  if (tasklistIds.value.includes(id)) {
    tasklistIds.value = tasklistIds.value.filter((taskId) => taskId !== id);
  } else {
    tasklistIds.value = [...tasklistIds.value, id];
  }
}

function icon(id) {
  return tasklistIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}
</script>
<template>
  <WidgetMenu :closeOnContentClick="false" location="bottom left" offset="12">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="tasklistIds.length > 0"
        icon="lsi-tasklist"
        :data-identifier="`${dataIdentifierPrefix}-tasklist-picker-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <VCard v-bind="VCardRounded" class="w-60">
      <div class="pl-2 pr-2">
        <VTextField
          v-model.trim="searchTerm"
          v-bind="VTextFieldFilter"
          class="mt-2 !min-w-full"
          :placeholder="t('Search task lists')"
          prependInnerIcon="lsi-search"
          :active="Boolean(searchTerm)"
          autofocus
        />
      </div>
      <WidgetLoadingState :state="state" :blankTitle="t('No task lists found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <template v-for="(tasklist, index) in items" :key="tasklist.id">
              <div
                v-if="index === 0 || tasklist.projectId !== items[index - 1].projectId"
                class="mt-4 flex items-center justify-between"
              >
                <LscOverflowEllipsis class="ml-2 max-w-48 font-semibold">
                  {{ tasklist.project.name }}
                </LscOverflowEllipsis>
              </div>
              <VListItem
                :active="tasklistIds.includes(tasklist.id)"
                class="my-1 rounded-sm"
                :data-identifier="`${dataIdentifierPrefix}-tasklist-picker-item`"
                @click="onClick(tasklist)"
              >
                <div class="flex items-center justify-between text-body-1">
                  <LscOverflowEllipsis class="ml-2 max-w-32 text-body-1">
                    {{ tasklist.name }}
                  </LscOverflowEllipsis>
                  <LscIcon :icon="icon(tasklist.id)" class="text-icon-subtle" size="sm" />
                </div>
              </VListItem>
            </template>
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
      <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
    </VCard>
  </WidgetMenu>
</template>
