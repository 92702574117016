<script setup>
const props = defineProps({
  /**
   * The size of the switch.
   * @type {PropType<'sm' | 'md' | 'lg'>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  /**
   * Whether the switch is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * A unique identifier for the switch.
   */
  id: {
    type: String,
    default: '',
  },
  /**
   * The label for the switch.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * The state of the switch.
   */
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const switchVariantStyleConfig = tv({
  base: 'relative inline-flex cursor-pointer items-center',
  slots: {
    input: ['peer sr-only'],
    switch: [
      'inline-flex shrink-0 items-center overflow-hidden rounded-full bg-[--lsds-c-switch-color-background-off] outline-none transition-colors',
      'after:transition-shadows after:absolute after:rounded-full after:bg-[--lsds-c-switch-color-handle] after:transition-transform',
      'peer peer-checked:bg-[--lsds-c-switch-color-background-on] peer-checked:after:translate-x-full',
      'peer-focus-visible:ring-2 peer-focus-visible:ring-focus-secondary',
      'peer-enabled:after:shadow-2',
      'peer-disabled:cursor-not-allowed peer-disabled:bg-[--lsds-c-switch-color-background-disabled]',
    ],
    label: ['ml-2 inline-flex items-center gap-2 text-body-1 text-default'],
  },
  variants: {
    size: {
      sm: {
        switch: 'h-4 w-6 after:left-0.5 after:h-3 after:w-3 peer-checked:after:-left-0.5',
      },
      md: {
        switch: 'h-5 w-8 after:left-0.5 after:h-4 after:w-4 peer-checked:after:-left-0.5',
      },
      lg: {
        switch: 'h-7 w-12 after:left-1 after:h-5 after:w-5',
      },
    },
  },
});

const classes = computed(() => switchVariantStyleConfig(props));
</script>

<template>
  <label :class="classes.base()">
    <input
      :aria-labelledby="id"
      type="checkbox"
      role="switch"
      :disabled="disabled"
      :checked="modelValue"
      :class="classes.input()"
      @change="emit('update:modelValue', !modelValue)"
    />
    <div :class="classes.switch()" />
    <span v-if="$slots.label || label" :id="id" :class="classes.label()">
      <!-- @slot The label -->
      <slot name="label">
        {{ label }}
      </slot>
    </span>
  </label>
</template>
