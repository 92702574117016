<script setup>
import { providePreferences } from '../preferences/usePreferences';
import { provideInboxFilters } from '../inbox/useInboxFilters';
import ApiSetup6 from './ApiSetup6.vue';

providePreferences();
provideInboxFilters();
</script>

<template>
  <ApiSetup6>
    <slot />
  </ApiSetup6>
</template>
