<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('What do boards do:') }}
    </p>
    <p>
      {{ t("Boards organize tasks into columns and are great for visualizing a project's workflow.") }}
    </p>
    <p class="pt-6">
      {{ t('Why use boards?') }}
    </p>
    <ul class="mb-4 ml-4">
      <li>{{ t('Associate tasks with a status') }}</li>
      <li>{{ t('Visualize a project based on a workflow') }}</li>
      <li>{{ t('Add task level automation with triggers and actions') }}</li>
    </ul>
    <p class="pt-6">{{ t("Plus, they're easy to set up and use!") }}</p>
  </div>
</template>
