import { useCurrentProjectBudget } from '@/route';
import { useI18n } from '@/util';
import { useBudgetFormat } from './useBudgetFormat';

export function useCurrentBudgetFormat(_budget) {
  const { t } = useI18n();
  const budgetOverride = ref(_budget);
  const currentProjectBudget = useCurrentProjectBudget();
  const budget = computed(() => budgetOverride.value ?? currentProjectBudget.value);
  const { formatBudgetCapacity, getBudgetCapacityColor, formatBudgetDateRange } = useBudgetFormat();

  const currentBudgetCapacityFormat = computed(() => formatBudgetCapacity(budget.value));

  /**
   * @param {number} capacity
   */
  function formatCapacity(capacity) {
    return currentBudgetCapacityFormat.value(capacity);
  }

  /**
   * @param {number} capacity
   */
  function formatCapacityLong(capacity) {
    return currentBudgetCapacityFormat.value(capacity, 'standard');
  }

  const capacityLabel = computed(() => {
    if (!budget.value) {
      return '';
    }
    return formatCapacity(budget.value.capacity);
  });

  const capacityLabelLong = computed(() => {
    if (!budget.value) {
      return '';
    }
    return formatCapacityLong(budget.value.capacity);
  });

  const capacityUsed = computed(() => {
    if (!budget.value) {
      return 0;
    }
    return budget.value.capacityUsed;
  });

  const capacityUsedLabel = computed(() => {
    if (!budget.value) {
      return '';
    }
    return formatCapacity(budget.value.capacityUsed);
  });

  const capacityUsedLabelLong = computed(() => {
    if (!budget.value) {
      return '';
    }
    return formatCapacityLong(budget.value.capacityUsed);
  });

  const capacityUsedPercent = computed(() => {
    if (!budget.value) {
      return 0;
    }
    return Math.floor((budget.value.capacityUsed / budget.value.capacity) * 100);
  });

  const capacityAsPercentageWidth = computed(() => `${Math.min(Math.max(capacityUsedPercent.value, 0), 100)}%`);

  const capacityRemaining = computed(() => {
    if (!budget.value) {
      return 0;
    }
    return Math.max(0, budget.value.capacity - budget.value.capacityUsed);
  });

  const capacityRemainingLabel = computed(() => {
    if (!budget.value) {
      return '';
    }
    return formatCapacity(capacityRemaining.value);
  });

  const capacityRemainingLabelLong = computed(() => {
    if (!budget.value) {
      return '';
    }
    return formatCapacityLong(capacityRemaining.value);
  });

  const capacityRemainingPercent = computed(() => Math.max(0, 100 - capacityUsedPercent.value));

  const capacityOverflow = computed(() => {
    if (!budget.value || budget.value.capacityUsed <= budget.value.capacity) {
      return 0;
    }

    return Math.abs(budget.value.capacity - budget.value.capacityUsed);
  });

  const capacityOverflowLabel = computed(() => {
    if (!capacityOverflow.value) {
      return '';
    }
    return formatCapacity(capacityOverflow.value);
  });

  const capacityOverflowLabelLong = computed(() => {
    if (!capacityOverflow.value) {
      return '';
    }
    return formatCapacityLong(capacityOverflow.value);
  });

  const color = computed(() => getBudgetCapacityColor(capacityUsedPercent.value));

  const dateRangeLabel = computed(() => formatBudgetDateRange(budget.value));

  const totalCapacityInfoLabel = computed(() => {
    if (budget.value.type.toLowerCase() === 'time') {
      return t('Total time');
    }
    return t('Total fee');
  });

  const repeatLabel = computed(() => {
    if (!budget.value || !budget.value?.isRepeating) {
      return '';
    }
    const { repeatPeriod, repeatUnit } = budget.value;
    if (repeatUnit === 'day') {
      return t('Budget repeats daily | Budget repeats every {n} days', { n: repeatPeriod });
    }
    if (repeatUnit === 'week') {
      return t('Budget repeats weekly | Budget repeats every {n} weeks', { n: repeatPeriod });
    }
    return t('Budget repeats monthly | Budget repeats every {n} months', { n: repeatPeriod });
  });

  return {
    capacityAsPercentageWidth,
    capacityLabel,
    capacityLabelLong,
    capacityOverflow,
    capacityOverflowLabel,
    capacityOverflowLabelLong,
    capacityRemaining,
    capacityRemainingLabel,
    capacityRemainingLabelLong,
    capacityRemainingPercent,
    capacityUsed,
    capacityUsedLabel,
    capacityUsedLabelLong,
    capacityUsedPercent,
    color,
    totalCapacityInfoLabel,
    dateRangeLabel,
    repeatLabel,
    formatCapacity,
  };
}
