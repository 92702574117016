/**
 * Creates a function which implements the exponential back-off strategy with fixed configuration.
 * @param minDelay The min delay.
 * @param maxDelay The max delay.
 * @param delayFactor How much the delay should grow for each subsequentry retry attempt.
 * @returns A function which implements the exponential back-off strategy.
 */
export const exponentialBackOff =
  ({ minDelay = 1000, maxDelay = 5 * 60 * 1000, delayFactor = 2 } = {}) =>
  (retryAttempt) =>
    Math.max(minDelay, Math.min(maxDelay, Math.floor(minDelay * delayFactor ** retryAttempt)));
