import { useAxios } from '../base/useAxios';
import { useI18n } from '@/util';

export function useAIActions() {
  const api = useAxios();
  const { t } = useI18n();

  function askAI(
    prompt,
    {
      errorMessage = (error) =>
        error.response?.status === 429 ? t('Reached the AI request limit') : t('Failed to process the AI request'),
    } = {},
  ) {
    const promise = api
      .post(
        '/ai/ask.json',
        { prompt },
        {
          errorMessage,
        },
      )
      .then(({ headers, data: responseData }) => {
        const choice = responseData?.data.choices[0];
        return {
          quota: {
            remaining: Number(headers['x-quota-remaining']),
            limit: Number(headers['x-quota-limit']),
          },
          content: choice?.message.content,
        };
      });

    return promise;
  }

  // https://platform.openai.com/docs/guides/structured-outputs
  function askStructuredAI(
    prompt,
    responseFormat,
    {
      errorMessage = (error) =>
        error.response?.status === 429 ? t('Reached the AI request limit') : t('Failed to process the AI request'),
    } = {},
  ) {
    const promise = api
      .post(
        '/ai/ask.json',
        { prompt, responseFormat },
        {
          errorMessage,
        },
      )
      .then(({ headers, data: responseData }) => {
        const choice = responseData?.data.choices[0];
        return {
          quota: {
            remaining: Number(headers['x-quota-remaining']),
            limit: Number(headers['x-quota-limit']),
          },
          content: choice?.message.content,
          structured: choice?.message.structured,
        };
      });

    return promise;
  }

  return { askAI, askStructuredAI };
}
