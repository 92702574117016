<script setup>
import { useI18n } from '@/util';
import LscDropzoneEmptyState from './LscDropzoneEmptyState.svg';
import { useLscDropzone } from './useLscDropzone';

const { size, multiple } = useLscDropzone();
const { t } = useI18n();
</script>

<template>
  <div class="absolute inset-0 z-50 bg-default">
    <div
      class="sticky left-0 top-0 flex h-full max-h-[100dvh] w-full shrink-0 flex-col items-center justify-center rounded-sm border border-dashed border-form-active bg-default text-default"
      :class="[size === 'sm' ? 'gap-1' : 'gap-2']"
    >
      <LscDropzoneEmptyState
        :class="{
          'h-7': size === 'sm',
          'h-12': size === 'md',
          'h-20': size === 'full',
        }"
      />
      <div
        class="font-medium"
        :class="{
          'text-body-1': size === 'full',
          'text-body-2': size === 'sm' || size === 'md',
        }"
      >
        {{ multiple ? t('Drop your files here') : t('Drop your file here') }}
      </div>
    </div>
  </div>
</template>
