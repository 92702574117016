import { useInterval } from '@vueuse/core';
import { DateTime, Duration } from 'luxon';
import { useTimersV3Loader } from '@/api';

const symbol = Symbol('useTimer');

export function provideTimer() {
  const timerDisplays = ref({});
  const activeTimerDisplay = ref(null);

  const {
    counter,
    resume: resumeCounter,
    pause: pauseCounter,
  } = useInterval(500, { controls: true, immediate: false });

  const { items: timers } = useTimersV3Loader({
    params: {
      include: 'projects,tasks,tasks.tasklists,tasks.parentTasks',
      'fields[tasks]': 'tasklistId,parentTaskId,name',
      'fields[projects]': 'name',
    },
    count: Infinity,
  });

  const userHasTimers = computed(() => timers.value?.length > 0);

  const activeTimer = computed(() => {
    return timers.value.find((timer) => timer.running);
  });

  const lastActiveTimer = computed(() => {
    return timers.value.reduce((a, b) => (a.lastStartedAt > b.lastStartedAt ? a : b), 0);
  });

  const shownTimer = computed(() => {
    return activeTimer.value || lastActiveTimer.value;
  });

  const pausedTimers = computed(() => {
    return timers.value.filter(({ id }) => id !== shownTimer.value.id);
  });

  /**
   * Calculates the total duration of a timer.
   * @param {Object} timer - The timer object.
   * @param {boolean} timer.running - Indicates if the timer is currently running.
   * @param {number} timer.duration - The initial duration of the timer.
   * @param {DateTime} timer.lastStartedAt - The timestamp when the timer was last started.
   * @returns {number} - The total duration of the timer in seconds.
   */
  function getTimerTotalDuration({ running, duration, lastStartedAt }) {
    return running ? duration + Math.floor(DateTime.now().diff(lastStartedAt).as('seconds')) : duration;
  }

  /**
   * Returns the formatted timer display.
   * @param {Object} timer - The timer object.
   * @returns {string} The formatted timer display in the format 'hh:mm:ss'.
   */
  function getTimerDisplay(timer) {
    return Duration.fromObject({ seconds: getTimerTotalDuration(timer) }).toFormat('hh:mm:ss');
  }

  watch(activeTimer, (timer) => {
    if (timer) {
      activeTimerDisplay.value = getTimerDisplay(timer);
      resumeCounter();
    } else {
      pauseCounter();
    }
  });

  watch(timers, (allTimers) => {
    timerDisplays.value = {};
    allTimers.forEach((timer) => {
      timerDisplays.value[timer.id] = getTimerDisplay(timer);
    });
  });

  watch(counter, () => {
    if (activeTimer.value) {
      const time = getTimerDisplay(activeTimer.value);
      timerDisplays.value[activeTimer.value.id] = time;
      activeTimerDisplay.value = time;
    }
  });

  provide(symbol, {
    timerDisplays,
    activeTimerDisplay,
    timers,
    userHasTimers,
    activeTimer,
    lastActiveTimer,
    shownTimer,
    pausedTimers,
    getTimerDisplay,
  });
}

export function useTimer() {
  return inject(symbol);
}
