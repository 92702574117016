// DEPRECATED: This code needs to be refactored and the file removed.
// See https://digitalcrew.teamwork.com/app/tasks/20318056

const ENTITY_GROUP_MAP = {
  billinginvoice: 'invoice',
  comment: 'comment',
  dashboard: 'dashboard',
  event: 'event',
  file: 'file',
  fileversion: 'file',
  form: 'form',
  integration: 'integration',
  invoice: 'invoice',
  link: 'link',
  message: 'message',
  message_comment: 'message_comment',
  milestone: 'milestone',
  notebook: 'notebook',
  person: 'person',
  project: 'project',
  projectupdate: 'projectupdate',
  status: 'status',
  statusrequest: 'status',
  statusupdate: 'status',
  task: 'task',
  subtask: 'subtask',
  tasklist: 'tasklist',
  company: 'company',
  file_comment: 'comment',
  link_comment: 'comment',
  milestone_comment: 'comment',
  notebook_comment: 'comment',
  task_comment: 'comment',
  team: 'team',
  user: 'user',
};

const ENTITY_GROUP_REACTION = [
  ENTITY_GROUP_MAP.comment,
  ENTITY_GROUP_MAP.message,
  ENTITY_GROUP_MAP.message_comment,
  ENTITY_GROUP_MAP.projectupdate,
];

const ENTITY_ACTION_MAP = {
  completed: 'completed',
  deleted: 'deleted',
  edited: 'edited',
  new: 'new',
  newForYou: 'newForYou',
  reopened: 'reopened',
  viewed: 'viewed',
  reacted: 'reacted',
  assigned: 'assigned',
  assignedToYou: 'assignedToYou',
  liked: 'liked',
  disliked: 'disliked',
  loved: 'loved',
  frowned: 'frowned',
  laughed: 'laughed',
};

const ENTITY_REACTION_ACTION_MAP = {
  like: ENTITY_ACTION_MAP.liked,
  dislike: ENTITY_ACTION_MAP.disliked,
  heart: ENTITY_ACTION_MAP.loved,
  frown: ENTITY_ACTION_MAP.frowned,
  joy: ENTITY_ACTION_MAP.laughed,
};

const ENTITY_GROUP_I18N = {
  comment: (t) => t('View comment'),
  company: (t) => t('View company'),
  file: (t) => t('View file'),
  form: (t) => t('View form'),
  invoice: (t) => t('View invoice'),
  link: (t) => t('View link'),
  message: (t) => t('View message'),
  milestone: (t) => t('View milestone'),
  notebook: (t) => t('View notebook'),
  person: (t) => t('View person'),
  project: (t) => t('View project'),
  task: (t) => t('View task'),
  tasklist: (t) => t('View task list'),
  team: (t) => t('View team'),
  user: (t) => t('View user'),
  event: (t) => t('View event'),
};

const ENTITY_GROUP_ACTION_I18N_SOMEONE = {
  company: {
    completed: (t, someone) => t('{someone} completed a company', { someone }),
    deleted: (t, someone) => t('{someone} deleted a company', { someone }),
    edited: (t, someone) => t('{someone} edited a company', { someone }),
    new: (t, someone) => t('{someone} added a company', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a company', { someone }),
    reopened: (t, someone) => t('{someone} reopened a company', { someone }),
    viewed: (t, someone) => t('{someone} viewed a company', { someone }),
  },
  comment: {
    completed: (t, someone) => t('{someone} completed a comment', { someone }),
    deleted: (t, someone) => t('{someone} deleted a comment', { someone }),
    edited: (t, someone) => t('{someone} edited a comment', { someone }),
    new: (t, someone) => t('{someone} added a comment', { someone }),
    reopened: (t, someone) => t('{someone} reopened a comment', { someone }),
    viewed: (t, someone) => t('{someone} viewed a comment', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a comment', { someone }),
    liked: (t, someone) => t('{someone} liked a comment', { someone }),
    disliked: (t, someone) => t('{someone} disliked a comment', { someone }),
    loved: (t, someone) => t('{someone} loved a comment', { someone }),
    frowned: (t, someone) => t('{someone} frowned at a comment', { someone }),
    laughed: (t, someone) => t('{someone} laughed at a comment', { someone }),
  },
  project: {
    completed: (t, someone) => t('{someone} completed a project', { someone }),
    deleted: (t, someone) => t('{someone} deleted a project', { someone }),
    edited: (t, someone) => t('{someone} edited a project', { someone }),
    new: (t, someone) => t('{someone} added a project', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a project', { someone }),
    reopened: (t, someone) => t('{someone} reopened a project', { someone }),
    viewed: (t, someone) => t('{someone} viewed a project', { someone }),
  },
  projectupdate: {
    deleted: (t, someone) => t('{someone} deleted a project update', { someone }),
    edited: (t, someone) => t('{someone} edited a project update', { someone }),
    new: (t, someone) => t('{someone} added a project update', { someone }),
    viewed: (t, someone) => t('{someone} viewed a project update', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a project update', { someone }),
    liked: (t, someone) => t('{someone} liked a project update', { someone }),
    disliked: (t, someone) => t('{someone} disliked a project update', { someone }),
    loved: (t, someone) => t('{someone} loved a project update', { someone }),
    frowned: (t, someone) => t('{someone} frowned at a project update', { someone }),
    laughed: (t, someone) => t('{someone} laughed at a project update', { someone }),
  },
  task: {
    completed: (t, someone) => t('{someone} completed a task', { someone }),
    deleted: (t, someone) => t('{someone} deleted a task', { someone }),
    edited: (t, someone) => t('{someone} edited a task', { someone }),
    new: (t, someone) => t('{someone} added a task', { someone }),
    newForYou: (t, someone) => t('{someone} added a task for you', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a task', { someone }),
    reopened: (t, someone) => t('{someone} reopened a task', { someone }),
    viewed: (t, someone) => t('{someone} viewed a task', { someone }),
    assigned: (t, someone) => t('{someone} assigned a task', { someone }),
    assignedToYou: (t, someone) => t('{someone} assigned a task to you', { someone }),
    reminder: (t) => t('Task reminder'),
  },
  message: {
    completed: (t, someone) => t('{someone} completed a message', { someone }),
    deleted: (t, someone) => t('{someone} deleted a message', { someone }),
    edited: (t, someone) => t('{someone} edited a message', { someone }),
    new: (t, someone) => t('{someone} added a message', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a message', { someone }),
    reopened: (t, someone) => t('{someone} reopened a message', { someone }),
    viewed: (t, someone) => t('{someone} viewed a message', { someone }),
    liked: (t, someone) => t('{someone} liked a message', { someone }),
    disliked: (t, someone) => t('{someone} disliked a message', { someone }),
    loved: (t, someone) => t('{someone} loved a message', { someone }),
    frowned: (t, someone) => t('{someone} frowned at a message', { someone }),
    laughed: (t, someone) => t('{someone} laughed at a message', { someone }),
  },
  message_comment: {
    completed: (t, someone) => t('{someone} completed a reply', { someone }),
    deleted: (t, someone) => t('{someone} deleted a reply', { someone }),
    edited: (t, someone) => t('{someone} edited a reply', { someone }),
    new: (t, someone) => t('{someone} replied', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a reply', { someone }),
    reopened: (t, someone) => t('{someone} reopened a reply', { someone }),
    viewed: (t, someone) => t('{someone} viewed a reply', { someone }),
    liked: (t, someone) => t('{someone} liked a reply', { someone }),
    disliked: (t, someone) => t('{someone} disliked a reply', { someone }),
    loved: (t, someone) => t('{someone} loved a reply', { someone }),
    frowned: (t, someone) => t('{someone} frowned at a reply', { someone }),
    laughed: (t, someone) => t('{someone} laughed at a reply', { someone }),
  },
  file: {
    completed: (t, someone) => t('{someone} completed a file', { someone }),
    deleted: (t, someone) => t('{someone} deleted a file', { someone }),
    edited: (t, someone) => t('{someone} edited a file', { someone }),
    new: (t, someone) => t('{someone} added a file', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a file', { someone }),
    reopened: (t, someone) => t('{someone} reopened a file', { someone }),
    viewed: (t, someone) => t('{someone} viewed a file', { someone }),
  },
  form: {
    completed: (t, someone) => t('{someone} completed a form', { someone }),
    deleted: (t, someone) => t('{someone} deleted a form', { someone }),
    edited: (t, someone) => t('{someone} edited a form', { someone }),
    new: (t, someone) => t('{someone} added a form', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a form', { someone }),
    reopened: (t, someone) => t('{someone} reopened a form', { someone }),
    viewed: (t, someone) => t('{someone} viewed a form', { someone }),
  },
  invoice: {
    completed: (t, someone) => t('{someone} completed an invoice', { someone }),
    deleted: (t, someone) => t('{someone} deleted an invoice', { someone }),
    edited: (t, someone) => t('{someone} edited an invoice', { someone }),
    new: (t, someone) => t('{someone} added an invoice', { someone }),
    reacted: (t, someone) => t('{someone} reacted to an invoice', { someone }),
    reopened: (t, someone) => t('{someone} reopened an invoice', { someone }),
    viewed: (t, someone) => t('{someone} viewed an invoice', { someone }),
  },
  link: {
    completed: (t, someone) => t('{someone} completed a link', { someone }),
    deleted: (t, someone) => t('{someone} deleted a link', { someone }),
    edited: (t, someone) => t('{someone} edited a link', { someone }),
    new: (t, someone) => t('{someone} added a link', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a link', { someone }),
    reopened: (t, someone) => t('{someone} reopened a link', { someone }),
    viewed: (t, someone) => t('{someone} viewed a link', { someone }),
  },
  milestone: {
    completed: (t, someone) => t('{someone} completed a milestone', { someone }),
    deleted: (t, someone) => t('{someone} deleted a milestone', { someone }),
    edited: (t, someone) => t('{someone} edited a milestone', { someone }),
    new: (t, someone) => t('{someone} added a milestone', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a milestone', { someone }),
    reopened: (t, someone) => t('{someone} reopened a milestone', { someone }),
    viewed: (t, someone) => t('{someone} viewed a milestone', { someone }),
    reminder: (t) => t('Milestone reminder'),
  },
  notebook: {
    completed: (t, someone) => t('{someone} completed a notebook', { someone }),
    deleted: (t, someone) => t('{someone} deleted a notebook', { someone }),
    edited: (t, someone) => t('{someone} edited a notebook', { someone }),
    new: (t, someone) => t('{someone} added a notebook', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a notebook', { someone }),
    reopened: (t, someone) => t('{someone} reopened a notebook', { someone }),
    viewed: (t, someone) => t('{someone} viewed a notebook', { someone }),
  },
  person: {
    completed: (t, someone) => t('{someone} completed a person', { someone }),
    deleted: (t, someone) => t('{someone} deleted a person', { someone }),
    edited: (t, someone) => t('{someone} edited a person', { someone }),
    new: (t, someone) => t('{someone} added a person', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a person', { someone }),
    reopened: (t, someone) => t('{someone} reopened a person', { someone }),
    viewed: (t, someone) => t('{someone} viewed a person', { someone }),
  },

  proof: {
    updated: (t, someone, extra) => {
      if (extra.info.data?.approvalState === 'approved') {
        return t('Proof fully approved');
      }

      return t('{someone} edited a proof', { someone });
    },
  },

  proofversion: {
    updated: (t, someone, extra) => {
      if (extra.info.data.role === 'approver') {
        return t('{someone} requested approval', { someone });
      }

      if (extra.info.data.role === 'reviewer') {
        return t('{someone} requested a review', { someone });
      }

      return t('{someone} added a version', { someone });
    },
  },

  proofrole: {
    created: (t, someone, extra) => {
      const text =
        extra.info.data.role === 'approver'
          ? t('{someone} requested approval', { someone })
          : t('{someone} requested a review', { someone });

      return text;
    },

    updated: (t, someone, extra) => {
      const text =
        extra.info.data.role === 'approver'
          ? t('{someone} requested approval', { someone })
          : t('{someone} requested a review', { someone });

      return text;
    },
  },

  prooffeedback: {
    created: (t, someone, extra) => {
      if (extra.info.data.parentId) {
        return extra.info.data.isUserExternal ? t('Reply added') : t('{someone} added a reply', { someone });
      }

      return extra.info.data.isUserExternal ? t('Comment added') : t('{someone} added a comment', { someone });
    },
  },

  proofdecision: {
    created: (
      t,
      someone,
      {
        info: {
          data: { isUserExternal, data },
        },
      },
    ) => {
      if (isUserExternal) {
        if (data.decision === 'approved') {
          return t('Approval received');
        }

        return t('Changes requested');
      }

      if (data.decision === 'approved') {
        return t('{someone} has approved', { someone });
      }

      return t('{someone} requested changes', { someone });
    },
  },

  tasklist: {
    completed: (t, someone) => t('{someone} completed a task list', { someone }),
    deleted: (t, someone) => t('{someone} deleted a task list', { someone }),
    edited: (t, someone) => t('{someone} edited a task list', { someone }),
    new: (t, someone) => t('{someone} added a task list', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a task list', { someone }),
    reopened: (t, someone) => t('{someone} reopened a task list', { someone }),
    viewed: (t, someone) => t('{someone} viewed a task list', { someone }),
  },
  team: {
    completed: (t, someone) => t('{someone} completed a team', { someone }),
    deleted: (t, someone) => t('{someone} deleted a team', { someone }),
    edited: (t, someone) => t('{someone} edited a team', { someone }),
    new: (t, someone) => t('{someone} added a team', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a team', { someone }),
    reopened: (t, someone) => t('{someone} reopened a team', { someone }),
    viewed: (t, someone) => t('{someone} viewed a team', { someone }),
  },
  user: {
    completed: (t, someone) => t('{someone} completed a user', { someone }),
    deleted: (t, someone) => t('{someone} deleted a user', { someone }),
    edited: (t, someone) => t('{someone} edited a user', { someone }),
    new: (t, someone) => t('{someone} added a user', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a user', { someone }),
    reopened: (t, someone) => t('{someone} reopened a user', { someone }),
    viewed: (t, someone) => t('{someone} viewed a user', { someone }),
  },
  integration: {
    completed: (t, someone) => t('{someone} completed an integration', { someone }),
    deleted: (t, someone) => t('{someone} deleted an integration', { someone }),
    edited: (t, someone) => t('{someone} edited an integration', { someone }),
    new: (t, someone) => t('{someone} added an integration', { someone }),
    reacted: (t, someone) => t('{someone} reacted to an integration', { someone }),
    reopened: (t, someone) => t('{someone} reopened an integration', { someone }),
    viewed: (t, someone) => t('{someone} viewed an integration', { someone }),
  },
  event: {
    completed: (t, someone) => t('{someone} completed a event', { someone }),
    deleted: (t, someone) => t('{someone} deleted a event', { someone }),
    edited: (t, someone) => t('{someone} edited a event', { someone }),
    new: (t, someone) => t('{someone} added a event', { someone }),
    reacted: (t, someone) => t('{someone} reacted to a event', { someone }),
    reopened: (t, someone) => t('{someone} reopened a event', { someone }),
    viewed: (t, someone) => t('{someone} viewed a event', { someone }),
    reminder: (t) => t('Event reminder'),
  },
  timereminder: {
    manualreminder: (t) => t('Time reminder'),
    weeklyreminder: (t) => t('Time reminder'),
    dailyreminder: (t) => t('Time reminder'),
  },
};

const ENTITY_GROUP_ACTION_I18N_DESCRIPTION = {
  timereminder: {
    manualreminder: (t) => t('Don’t forget! Log your time'),
    weeklyreminder: (t) => `👀 ${t('Logged your time yet?')}`,
    dailyreminder: (t) => `👀 ${t('Logged your time yet?')}`,
  },
};

const ENTITY_GROUP_ACTION_I18N_SECONDARY = {
  timereminder: {
    manualreminder: (t) => t('A gentle reminder to review your timesheet'),
    weeklyreminder: (t) => t('Don’t forget to review your timesheet!'),
    dailyreminder: (t) => t('Don’t forget to log your time before you sign off!'),
  },
};

const ENTITY_GROUP_ACTION_I18N_YOU = {
  company: {
    completed: (t) => t('You completed a company'),
    deleted: (t) => t('You deleted a company'),
    edited: (t) => t('You edited a company'),
    new: (t) => t('You added a company'),
    reacted: (t) => t('You reacted to a company'),
    reopened: (t) => t('You reopened a company'),
    viewed: (t) => t('You viewed a company'),
  },
  comment: {
    completed: (t) => t('You completed a comment'),
    deleted: (t) => t('You deleted a comment'),
    edited: (t) => t('You edited a comment'),
    new: (t) => t('You added a comment'),
    reopened: (t) => t('You reopened a comment'),
    viewed: (t) => t('You viewed a comment'),
    reacted: (t) => t('You reacted to a comment'),
    liked: (t) => t('You liked a comment'),
    disliked: (t) => t('You disliked a comment'),
    loved: (t) => t('You loved a comment'),
    frowned: (t) => t('You frowned at a comment'),
    laughed: (t) => t('You laughed at a comment'),
  },
  project: {
    completed: (t) => t('You completed a project'),
    deleted: (t) => t('You deleted a project'),
    edited: (t) => t('You edited a project'),
    new: (t) => t('You added a project'),
    reacted: (t) => t('You reacted to a project'),
    reopened: (t) => t('You reopened a project'),
    viewed: (t) => t('You viewed a project'),
  },
  projectupdate: {
    deleted: (t) => t('You deleted a project update'),
    edited: (t) => t('You edited a project update'),
    new: (t) => t('You added a project update'),
    viewed: (t) => t('You viewed a project update'),
    reacted: (t) => t('You reacted to a project update'),
    liked: (t) => t('You liked a project update'),
    disliked: (t) => t('You disliked a project update'),
    loved: (t) => t('You loved a project update'),
    frowned: (t) => t('You frowned at a project update'),
    laughed: (t) => t('You laughed at a project update'),
  },
  task: {
    completed: (t) => t('You completed a task'),
    deleted: (t) => t('You deleted a task'),
    edited: (t) => t('You edited a task'),
    new: (t) => t('You added a task'),
    reacted: (t) => t('You reacted to a task'),
    reopened: (t) => t('You reopened a task'),
    viewed: (t) => t('You viewed a task'),
    assigned: (t) => t('You assigned a task'),
    reminder: (t) => t('Task reminder'),
  },
  message: {
    completed: (t) => t('You completed a message'),
    deleted: (t) => t('You deleted a message'),
    edited: (t) => t('You edited a message'),
    new: (t) => t('You added a message'),
    reacted: (t) => t('You reacted to a message'),
    reopened: (t) => t('You reopened a message'),
    viewed: (t) => t('You viewed a message'),
    liked: (t) => t('You liked a message'),
    disliked: (t) => t('You disliked a message'),
    loved: (t) => t('You loved a message'),
    frowned: (t) => t('You frowned at a message'),
    laughed: (t) => t('You laughed at a message'),
  },
  message_comment: {
    completed: (t) => t('You completed a reply'),
    deleted: (t) => t('You deleted a reply'),
    edited: (t) => t('You edited a reply'),
    new: (t) => t('You replied'),
    reacted: (t) => t('You reacted to a reply'),
    reopened: (t) => t('You reopened a reply'),
    viewed: (t) => t('You viewed a reply'),
    liked: (t) => t('You liked a reply'),
    disliked: (t) => t('You disliked a reply'),
    loved: (t) => t('You loved a reply'),
    frowned: (t) => t('You frowned at a reply'),
    laughed: (t) => t('You laughed at a reply'),
  },
  file: {
    completed: (t) => t('You completed a file'),
    deleted: (t) => t('You deleted a file'),
    edited: (t) => t('You edited a file'),
    new: (t) => t('You added a file'),
    reacted: (t) => t('You reacted to a file'),
    reopened: (t) => t('You reopened a file'),
    viewed: (t) => t('You viewed a file'),
  },
  form: {
    completed: (t) => t('You completed a form'),
    deleted: (t) => t('You deleted a form'),
    edited: (t) => t('You edited a form'),
    new: (t) => t('You added a form'),
    reacted: (t) => t('You reacted to a form'),
    reopened: (t) => t('You reopened a form'),
    viewed: (t) => t('You viewed a form'),
  },
  invoice: {
    completed: (t) => t('You completed an invoice'),
    deleted: (t) => t('You deleted an invoice'),
    edited: (t) => t('You edited an invoice'),
    new: (t) => t('You added an invoice'),
    reacted: (t) => t('You reacted to an invoice'),
    reopened: (t) => t('You reopened an invoice'),
    viewed: (t) => t('You viewed an invoice'),
  },
  link: {
    completed: (t) => t('You completed a link'),
    deleted: (t) => t('You deleted a link'),
    edited: (t) => t('You edited a link'),
    new: (t) => t('You added a link'),
    reacted: (t) => t('You reacted to a link'),
    reopened: (t) => t('You reopened a link'),
    viewed: (t) => t('You viewed a link'),
  },
  milestone: {
    completed: (t) => t('You completed a milestone'),
    deleted: (t) => t('You deleted a milestone'),
    edited: (t) => t('You edited a milestone'),
    new: (t) => t('You added a milestone'),
    reacted: (t) => t('You reacted to a milestone'),
    reopened: (t) => t('You reopened a milestone'),
    viewed: (t) => t('You viewed a milestone'),
    reminder: (t) => t('Milestone reminder'),
  },
  notebook: {
    completed: (t) => t('You completed a notebook'),
    deleted: (t) => t('You deleted a notebook'),
    edited: (t) => t('You edited a notebook'),
    new: (t) => t('You added a notebook'),
    reacted: (t) => t('You reacted to a notebook'),
    reopened: (t) => t('You reopened a notebook'),
    viewed: (t) => t('You viewed a notebook'),
  },
  person: {
    completed: (t) => t('You completed a person'),
    deleted: (t) => t('You deleted a person'),
    edited: (t) => t('You edited a person'),
    new: (t) => t('You added a person'),
    reacted: (t) => t('You reacted to a person'),
    reopened: (t) => t('You reopened a person'),
    viewed: (t) => t('You viewed a person'),
  },
  tasklist: {
    completed: (t) => t('You completed a task list'),
    deleted: (t) => t('You deleted a task list'),
    edited: (t) => t('You edited a task list'),
    new: (t) => t('You added a task list'),
    reacted: (t) => t('You reacted to a task list'),
    reopened: (t) => t('You reopened a task list'),
    viewed: (t) => t('You viewed a task list'),
  },
  team: {
    completed: (t) => t('You completed a team'),
    deleted: (t) => t('You deleted a team'),
    edited: (t) => t('You edited a team'),
    new: (t) => t('You added a team'),
    reacted: (t) => t('You reacted to a team'),
    reopened: (t) => t('You reopened a team'),
    viewed: (t) => t('You viewed a team'),
  },
  user: {
    completed: (t) => t('You completed a user'),
    deleted: (t) => t('You deleted a user'),
    edited: (t) => t('You edited a user'),
    new: (t) => t('You added a user'),
    reacted: (t) => t('You reacted to a user'),
    reopened: (t) => t('You reopened a user'),
    viewed: (t) => t('You viewed a user'),
  },
  integration: {
    completed: (t) => t('You completed an integration'),
    deleted: (t) => t('You deleted an integration'),
    edited: (t) => t('You edited an integration'),
    new: (t) => t('You added an integration'),
    reacted: (t) => t('You reacted to an integration'),
    reopened: (t) => t('You reopened an integration'),
    viewed: (t) => t('You viewed an integration'),
  },
  event: {
    completed: (t) => t('You completed a event'),
    deleted: (t) => t('You deleted a event'),
    edited: (t) => t('You edited a event'),
    new: (t) => t('You added a event'),
    reacted: (t) => t('You reacted to a event'),
    reopened: (t) => t('You reopened a event'),
    viewed: (t) => t('You viewed a event'),
    reminder: (t) => t('Event reminder'),
  },
};

export function getEntityGroup({ type, link }) {
  if (!type) {
    return '';
  }

  /**
   * Handle message comments (replies)
   */
  if (type === ENTITY_GROUP_MAP.comment && link?.includes('messages/')) {
    return ENTITY_GROUP_MAP.message_comment;
  }

  if (ENTITY_GROUP_MAP[type]) {
    return ENTITY_GROUP_MAP[type];
  }

  if (type.includes(ENTITY_GROUP_MAP.comment)) {
    return ENTITY_GROUP_MAP.comment;
  }

  if (type.includes(ENTITY_GROUP_MAP.project)) {
    return ENTITY_GROUP_MAP.project;
  }

  return type;
}

export function getEntityText({ group }) {
  return ENTITY_GROUP_I18N[group] || (() => group);
}

export function getEntitySomeoneText({ group, action }) {
  return ENTITY_GROUP_ACTION_I18N_SOMEONE[group]?.[action] || (() => `${group} - ${action}`);
}

export function getEntityDescriptionText({ group, action }) {
  return ENTITY_GROUP_ACTION_I18N_DESCRIPTION[group]?.[action] || (() => undefined);
}

export function getEntitySecondaryText({ group, action }) {
  return ENTITY_GROUP_ACTION_I18N_SECONDARY[group]?.[action] || (() => undefined);
}

export function getEntityYouText({ group, action }) {
  return ENTITY_GROUP_ACTION_I18N_YOU[group]?.[action] || (() => `${group} - ${action}`);
}

export function getEntityGroupAction({ group, action, reaction, userId, assigneeIds }) {
  if (!(group && action)) {
    return action || '';
  }

  /**
   * Handle comment reactions.
   */
  if (action === ENTITY_ACTION_MAP.reacted && ENTITY_GROUP_REACTION.includes(group)) {
    return ENTITY_REACTION_ACTION_MAP[reaction] || ENTITY_ACTION_MAP.reacted;
  }

  /**
   * Handle task assignments.
   */
  if (
    assigneeIds?.length > 0 &&
    group === ENTITY_GROUP_MAP.task &&
    [ENTITY_ACTION_MAP.new, ENTITY_ACTION_MAP.edited].includes(action)
  ) {
    /**
     * When user_id is an assignee, map "new" to "newForYou" and "edited" to "assignedToYou"
     * When user_id is not an assignee, map "edited" to "assigned".
     */
    if (assigneeIds.includes(userId)) {
      if (action === ENTITY_ACTION_MAP.new) {
        return ENTITY_ACTION_MAP.newForYou;
      }

      return ENTITY_ACTION_MAP.assignedToYou;
    }

    if (ENTITY_ACTION_MAP.edited) {
      return ENTITY_ACTION_MAP.assigned;
    }
  }

  return action;
}

function getEntityLink({ action, link, userId }) {
  return action === 'deleted' ? undefined : link?.replace(':user_id_replacement', userId);
}

export function getEntity({ type, action, reaction, link, userId, assigneeIds }) {
  const resolvedGroup = getEntityGroup({ type, link });
  const resolvedAction = getEntityGroupAction({ group: resolvedGroup, action, reaction, userId, assigneeIds });
  const resolvedLink = getEntityLink({ action: resolvedAction, link, userId });

  return {
    group: resolvedGroup,
    action: resolvedAction,
    link: resolvedLink,
    id: type.id,
  };
}
