<script setup>
defineProps({
  projectBudget: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const FinanceBudgetTasklistDialogPanel = defineAsyncComponent(() => import('./FinanceBudgetTasklistDialogPanel.vue'));

const modelValue = defineModel({ type: Boolean, default: false });
</script>

<template>
  <WidgetDialog v-model="modelValue" :retainFocus="false">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceBudgetTasklistDialogPanel
        :projectBudget="projectBudget"
        @close="
          close();
          emit('close');
        "
      />
    </template>
  </WidgetDialog>
</template>
