import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useAxios } from '../base/useAxios';
import { useI18n } from '@/util';

export function useBrandingActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  return {
    updateBrandingColor({ color, useBackgroundColorOnLogin = false } = {}) {
      const promise = api
        .put(
          '/colourthemes/current.json',
          { color, useBackgroundColorOnLogin },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to update theme color'),
          },
        )
        .then(() => {
          emitRealTimeUpdate({ type: 'branding', action: 'edited' });
        });
      emitOptimisticUpdate({
        promise,
        type: 'branding',
        action: 'update',
        branding: { theme: { color } },
      });
      return promise;
    },
  };
}
