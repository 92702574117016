// eslint-disable-next-line no-restricted-imports
import { useLocalStorage as useLocalStorageFromVueUse, syncRef } from '@vueuse/core';

/**
 * Wrapper of '@vueuse/core' useLocalStorage with reactive key support.
 * @param {MaybeRefOrGetter<string>} key - The storage key
 * @param {MaybeRefOrGetter<object | number | string| boolean>=} defaultValue - Default value if key not found in storage
 * @param {import('@vueuse/core').UseStorageOptions=} options - Storage options
 *
 * @TODO - Remove `defaultValueRef` from the watcher and use it directly in the `useLocalStorageFromVueUse` once
 * this issue is resolved: https://github.com/vueuse/vueuse/issues/4318
 */
export function useLocalStorage(key, defaultValue, options = {}) {
  const { shallow = false, deep = true } = options; // Setting 'shallow' and 'deep' to their default values if not provided
  let scope = null;
  const keyRef = shallowRef(key);
  const defaultValueRef = shallowRef(defaultValue);
  const value = shallow ? shallowRef() : ref(); // eslint-disable-line lightspeed/prefer-shallow-ref

  watch(
    [keyRef, defaultValueRef],
    () => {
      scope?.stop();
      scope = effectScope(true);
      scope.run(() => {
        const localStorageValue = useLocalStorageFromVueUse(keyRef.value, defaultValueRef.value, options);
        syncRef(localStorageValue, value, { deep });
      });
    },
    { immediate: true },
  );

  onScopeDispose(() => scope?.stop());

  return value;
}
