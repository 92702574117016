<script setup>
import { useLscTable } from './useLscTable';

defineProps({
  highlighted: {
    type: Boolean,
    default: false,
  },
});

const { columns } = useLscTable();
</script>

<template>
  <tr class="group/LscTableFooter relative col-span-full -mt-px grid grid-cols-subgrid">
    <td
      v-for="column in columns"
      :key="column.id"
      :data-highlighted="highlighted"
      :data-sticky-first-column="column.stickyFirstColumn"
      :data-sticky-last-column="column.stickyLastColumn"
      :data-shadow-start="column.applyShadowStart"
      :data-shadow-end="column.applyShadowEnd"
      :data-align="column.align"
      :class="[
        'group/LscTableCell relative -mt-px inline-flex items-center overflow-hidden bg-default px-2 font-semibold',
        'border-t border-t-separator bg-default',
        // Dividers
        'after:pointer-events-none after:absolute after:inset-y-0 after:right-0 after:opacity-0',
        'after:border-r after:border-separator after:transition-opacity',
        // Density
        'group-data-[density=xs]/LscTable:h-[--lsds-c-table-row-density-xs]',
        'group-data-[density=sm]/LscTable:h-[--lsds-c-table-row-density-sm]',
        'group-data-[density=md]/LscTable:h-[--lsds-c-table-row-density-md]',
        'group-data-[density=lg]/LscTable:h-[--lsds-c-table-row-density-lg]',
        // Padding
        'group-data-[density=xs]/LscTable:py-[--lsds-c-table-cell-padding-xs]',
        'group-data-[density=sm]/LscTable:py-[--lsds-c-table-cell-padding-sm]',
        'group-data-[density=md]/LscTable:py-[--lsds-c-table-cell-padding-md]',
        'group-data-[density=lg]/LscTable:py-[--lsds-c-table-cell-padding-lg]',
        // Alignment
        'data-[align=left]:justify-start',
        'data-[align=center]:justify-center',
        'data-[align=right]:justify-end',
        // Highlighted
        'data-[highlighted=true]:bg-surface-emphasis-default',
        // First column sticky
        'data-[sticky-first-column=true]:sticky',
        'data-[sticky-first-column=true]:left-[--lsc-table-col-offset]',
        'data-[sticky-first-column=true]:z-[1]',
        'data-[sticky-first-column=true]:after:right-0',
        'group-data-[shadow-start=true]/LscTable:data-[shadow-start=true]:after:opacity-100',
        // Last column sticky
        'data-[sticky-last-column=true]:sticky',
        'data-[sticky-last-column=true]:right-[--lsc-table-col-offset]',
        'data-[sticky-last-column=true]:z-[1]',
        'data-[sticky-last-column=true]:after:right-auto',
        'data-[sticky-last-column=true]:after:left-0',
        'data-[sticky-last-column=true]:after:border-r-0',
        'data-[sticky-last-column=true]:after:border-l',
        'group-data-[shadow-end=true]/LscTable:data-[shadow-end=true]:after:opacity-100',
      ]"
      :style="{
        '--lsc-table-col-offset': `var(--lsc-table-col-offset-${column.index})`,
      }"
    >
      <slot :name="column.slot" :column="column" />
    </td>
  </tr>
</template>
