<script setup>
import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import { useReportBuilder } from '../useReportBuilder.js';
import ReportDateStepper, { ReportDateStepperRange } from '../../ReportDateStepper.vue';
import ReportBuilderOptionsColumns from './ReportBuilderOptionsColumns.vue';
import ReportBuilderOptionsMilestoneFilters from './ReportBuilderOptionsMilestoneFilters.vue';
import ReportBuilderOptionsProjectFilters from './ReportBuilderOptionsProjectFilters.vue';
import ReportBuilderOptionsTaskFilters from './ReportBuilderOptionsTaskFilters.vue';
import ReportBuilderOptionsUserFilters from './ReportBuilderOptionsUserFilters.vue';
import ReportBuilderOptionsPermissions from './ReportBuilderOptionsPermissions.vue';

defineProps({
  validation: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['handleCustomReportTrack']);

const { t } = useI18n();

const { currentReport, currentReportDateRange, reportTypes, advancedReportingEnabled, isAdvancedReporting } =
  useReportBuilder();
const ranges = [
  ReportDateStepperRange.day,
  ReportDateStepperRange.week,
  ReportDateStepperRange.month,
  ReportDateStepperRange.quarter,
];

const reportTypesList = Object.values(reportTypes).map(({ value: title, key: value, icon }) => ({
  title,
  value,
  icon,
}));

const minCustomDate = DateTime.now().minus({ years: 2 });

function handleItemClick(onClick, item) {
  onClick();
  handleCustomReportTrack('custom_reports', 'custom_report_report_type_applied', 'activation_event', item.value);
}

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <div class="h-full w-80">
    <div class="h-full overflow-scroll p-6">
      <VTextField
        v-model.trim="currentReport.name"
        :label="t('Report name')"
        variant="outlined"
        density="compact"
        class="mb-6"
        data-identifier="report-builder--report-name"
        required
        :errorMessages="validation.currentReport.name?.$errors.map((error) => error.$message)"
        autofocus
      />

      <VSelect
        class="mb-6"
        variant="outlined"
        density="compact"
        :modelValue="reportTypes[currentReport.type].key"
        :label="t('Type')"
        :items="reportTypesList"
        @update:modelValue="currentReport.type = $event"
        @click="
          emit('handleCustomReportTrack', 'custom_reports', 'custom_report_report_type_viewed', 'activation_event')
        "
      >
        <template #prepend-inner>
          <span class="mr-2 flex items-center">
            <LscIcon :icon="reportTypes[currentReport.type].icon" class="text-icon-subtle" size="sm" />
          </span>
        </template>

        <template #item="{ item, props: { onClick } }">
          <LscOptionsMenuItem
            :key="item.value"
            class="text-body-2"
            :text="item.title"
            :active="item.value === currentReport.type"
            @click="handleItemClick(onClick, item)"
          >
            <template #prepend>
              <LscIcon :icon="item.raw.icon" class="text-icon-subtle" size="md" />
            </template>
          </LscOptionsMenuItem>
        </template>
      </VSelect>

      <h6 class="mb-1 text-body-1 font-semibold">{{ t('Date and filters') }}</h6>

      <div class="mb-6 flex justify-between font-semibold text-subtle">
        <ReportDateStepper
          v-model:dates="currentReportDateRange"
          :ranges="ranges"
          hidePrepend
          :minDate="minCustomDate"
          @handleCustomReportTrack="handleCustomReportTrack"
        />

        <div class="flex items-center">
          <ReportBuilderOptionsMilestoneFilters v-if="currentReport.type === 'milestone'" />
          <ReportBuilderOptionsProjectFilters v-else-if="currentReport.type === 'project'" />
          <ReportBuilderOptionsTaskFilters v-else-if="currentReport.type === 'task'" />
          <ReportBuilderOptionsUserFilters v-else-if="currentReport.type === 'user'" />
        </div>
      </div>

      <h6 class="mb-2 text-body-1 font-semibold">{{ t('Columns') }}</h6>
      <ReportBuilderOptionsColumns
        :validation="validation.hasColumns"
        @handleCustomReportTrack="handleCustomReportTrack"
      />
      <div class="my-4 flex items-center">
        <LscSwitch v-model="currentReport.summary" :label="t('Show summary row')" class="mr-2 text-body-2" />
        <LscIcon
          v-LsdTooltip="t('Display totals/averages. Only columns with numeric values can be summed')"
          icon="lsi-tooltip"
          class="text-icon-subtle"
        />
      </div>
      <div v-if="advancedReportingEnabled" class="my-4 flex items-center">
        <LscSwitch v-model="isAdvancedReporting" :label="t('Default advanced reporting')" class="mr-2 text-body-2" />
        <LscIcon
          v-LsdTooltip="t('Include required columns to enable advanced reporting default views for this report')"
          icon="lsi-tooltip"
          class="text-icon-subtle"
        />
      </div>

      <h6 class="mb-2 text-body-1 font-semibold">{{ t('Permissions') }}</h6>
      <ReportBuilderOptionsPermissions @handleCustomReportTrack="handleCustomReportTrack" />
    </div>
  </div>
</template>
