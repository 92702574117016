import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useListLoader } from '../base/useListLoader';
import { normalizeTasklistDefaults } from './normalizeTasklistDefaults';

function responseToItems(response) {
  const {
    notifications = {},
    columns = {},
    companies = {},
    projects = {},
    tasklistBudgets = {},
    tasks = {},
    teams = {},
    users = {},
    tags = {},
  } = response.data.included;

  const tasklists = response.data.tasklists || [];
  const tasklistTaskStats = response.data.included?.tasklistTaskStats || [];

  /* eslint-disable no-param-reassign */
  tasklists.forEach((tasklist) => {
    tasklist.id = Number(tasklist.id);
    tasklist.projectId = Number(tasklist.projectId);
    tasklist.tasklistTaskStats = tasklistTaskStats[tasklist.id] || {};
    if (tasklist.defaultTaskId && tasks[tasklist.defaultTaskId]) {
      // set up task defaults
      tasklist.newTaskDefaults = normalizeTasklistDefaults({
        newTaskDefaults: tasks[tasklist.defaultTaskId],
        users,
        teams,
        companies,
        columns,
        tags,
      });
    }

    if (projects[tasklist.projectId]) {
      tasklist.project = projects[tasklist.projectId];
    }

    if (tasklist.tasklistBudget && tasklistBudgets[tasklist.tasklistBudget.id]) {
      const tasklistBudget = tasklistBudgets[tasklist.tasklistBudget.id];

      tasklistBudget.type = tasklistBudget.type.toLowerCase();

      const budgetNotifications = [];
      tasklistBudget.notifications?.forEach((_notification) => {
        if (notifications[_notification.id]) {
          const notification = notifications[_notification.id];
          const { capacityThreshold, notificationMedium } = notification;
          // Find other notification with the same capacity threshold
          const existingIndex = budgetNotifications.findIndex(
            (n) =>
              n.capacityThreshold === capacityThreshold && n.notificationMedium === notificationMedium.toLowerCase(),
          );

          notification.notificationMedium = notification.notificationMedium.toLowerCase();
          const notificationUsers = notification.userId ? [users[notification.userId]] : [];
          const notificationTeams = notification.teamId ? [teams[notification.teamId]] : [];
          const notificationCompanies = notification.companyId ? [companies[notification.companyId]] : [];

          if (existingIndex > -1) {
            // There is already a notification with this capacity and medium
            // We need to merge it
            budgetNotifications[existingIndex].users.push(...notificationUsers);
            budgetNotifications[existingIndex].companies.push(...notificationCompanies);
            budgetNotifications[existingIndex].teams.push(...notificationTeams);
          } else {
            notification.users = notificationUsers;
            notification.companies = notificationCompanies;
            notification.teams = notificationTeams;
            budgetNotifications.push(notification);
          }
        }
      });
      tasklistBudget.notifications = budgetNotifications;
      tasklist.tasklistBudget = tasklistBudget;
    }
  });
  /* eslint-enable no-param-reassign */
  return tasklists;
}

function orderActiveFirst(orderByFallback) {
  return (list1, list2) => {
    const list1Active = list1.status !== 'completed';
    const list2Active = list2.status !== 'completed';

    if (list1Active !== list2Active) {
      return list1Active ? -1 : 1;
    }

    return orderByFallback(list1, list2);
  };
}

function orderByDisplayOrder(list1, list2) {
  return list1.displayOrder - list2.displayOrder;
}

function orderByStatusAsc(list1, list2) {
  return list1.status.localeCompare(list2.status);
}

function orderByStatusDesc(list1, list2) {
  return list2.status.localeCompare(list1.status);
}

/**
 * Loads a list of task lists from the Teamwork v3 API.
 * @param {Object} options
 * @param {string} options.projectId The project from which to load task lists.
 */
export function useTasklistsV3Loader({ projectId: _projectId, params, count, pageSize = 50, cache = true }) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => {
    if (projectId.value) {
      return `/projects/api/v3/projects/${projectId.value}/tasklists.json`;
    }
    return '/projects/api/v3/tasklists.json';
  });

  const order = computed(() => {
    const { orderBy, orderMode, sortActiveListsFirst } = unref(params);

    if (orderMode === 'asc' && orderBy === 'status') {
      return orderByStatusAsc;
    }

    if (orderMode === 'desc' && orderBy === 'status') {
      return orderByStatusDesc;
    }

    return sortActiveListsFirst ? orderActiveFirst(orderByDisplayOrder) : orderByDisplayOrder;
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    order,
    pageSize,
    cache,
    type: 'tasklist',
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'tasklist') {
      return;
    }

    update((tasklists) => {
      if (event.action === 'reposition') {
        return tasklists.map((tasklist) => {
          if (tasklist.id === event.tasklist.id) {
            const { positionBeforeId, positionAfterId } = event.tasklist;
            const referenceTasklistId = positionAfterId || positionBeforeId;
            const referenceTasklist = tasklists.find(({ id }) => id === referenceTasklistId);
            if (referenceTasklist) {
              const positionOffset = positionAfterId ? 0.5 : -0.5;
              return { ...tasklist, displayOrder: referenceTasklist.displayOrder + positionOffset };
            }
          }
          return tasklist;
        });
      }

      if (event.action === 'delete') {
        return tasklists.filter((tasklist) => tasklist.id !== event.tasklist.id);
      }

      return tasklists;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all task lists.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // If filtering by projectId, it must match event.projectId.
    if (projectId.value && projectId.value !== event.projectId) {
      return;
    }

    if (event.action === 'reposition') {
      refresh();
      return;
    }

    if (event.type === 'milestone' && event.action === 'deleted') {
      refresh();
      return;
    }

    // Only events of these types can affect task list properties.
    if (event.type !== 'task' && event.type !== 'tasklist' && event.type !== 'tasklistTasks') {
      return;
    }

    // Specifally for when a task is moved from one tasklist to another.
    if (
      event.type === 'task' &&
      event.action === 'edited' &&
      event?.oldTasklistId &&
      event.oldTasklistId !== event.tasklistId
    ) {
      refresh(event.taskListId);
      return;
    }

    // Only specific task actions can affect a task list.
    if (
      event.type === 'task' &&
      event.action !== 'reopened' &&
      event.action !== 'completed' &&
      event.action !== 'new' &&
      event.action !== 'deleted'
    ) {
      return;
    }

    refresh(event.tasklistId);
  });

  return state;
}
