import { useCurrentAccount, usePermissions, useFeatures, useExperimentA2350, usePreferences } from '@/api';
import { useCurrentProject } from './useCurrentProject';
import { useCurrentProjectBudget } from './useCurrentProjectBudget';

const symbol = Symbol('useCurrentProjectPermissions');

function CurrentProjectPermissionsService() {
  const account = useCurrentAccount();
  const project = useCurrentProject();
  const projectBudget = useCurrentProjectBudget();
  const {
    projectFinancialBudgetsEnabled,
    projectTimeBudgetsEnabled,
    intakeFormsEnabled,
    riskRegisterEnabled,
    deskProjectTicketLinkEnabled,
    projectsBudgetProfitabilityPermissionEnabled,
    deskOnboardingFlowEnabled,
    projectsGoalBasedOnboardingProjectTabEnabled,
  } = useFeatures();
  const {
    isClientUser,
    isOwnerAdmin,
    isSiteAdmin,
    isCollaborator,
    ssoEnabledAutoStart,
    canManagePeople: _canManagePeople,
    canManageProjectTemplates,
    canViewProofs,
    isDeskEnabled,
  } = usePermissions();
  const { isExpA2350Variation } = useExperimentA2350();
  const { sampleProjectIds } = usePreferences();

  const isProjectAdmin = computed(() => Boolean(project.value?.permissions.projectAdministrator));
  const isSiteOrProjectAdmin = computed(() => Boolean(isOwnerAdmin.value || isProjectAdmin.value));

  const canAccessInvoiceTracking = computed(() =>
    Boolean(isSiteAdmin.value || project.value?.permissions.canAccessInvoiceTracking),
  );

  const canAddTasks = computed(() =>
    Boolean(project.value?.status === 'active' && (isProjectAdmin.value || project.value?.permissions.canAddTasks)),
  );
  const canAddTasklists = computed(() =>
    Boolean(
      project.value?.status === 'active' &&
        Boolean(isSiteOrProjectAdmin.value || project.value?.permissions.canAddTaskLists),
    ),
  );
  const canEditAllTasks = computed(() =>
    Boolean(project.value?.status === 'active' && (isProjectAdmin.value || project.value?.permissions.canEditAllTasks)),
  );
  const canAddMessages = computed(() =>
    Boolean(
      project.value?.status === 'active' &&
        Boolean(isSiteOrProjectAdmin.value || project.value?.permissions.canAddMessages),
    ),
  );
  const canAddNotebooks = computed(() =>
    Boolean(
      project.value?.status === 'active' &&
        Boolean(isSiteOrProjectAdmin.value || project.value?.permissions.canAddNotebooks),
    ),
  );
  const canAddUpdate = computed(() =>
    Boolean(
      project.value?.status === 'active' &&
        (isSiteOrProjectAdmin.value || project.value?.permissions.canAddProjectUpdate),
    ),
  );
  const canAddMilestones = computed(() =>
    Boolean(
      project.value?.status === 'active' && (isSiteOrProjectAdmin.value || project.value?.permissions.canAddMilestones),
    ),
  );
  const canLogTime = computed(() =>
    Boolean(project.value?.status === 'active' && project.value?.permissions.canLogTime),
  );

  const canAddReminders = computed(() =>
    Boolean(project.value?.status === 'active' && !isCollaborator.value && !project.value?.isTemplate),
  );

  const canSetPrivacy = computed(() => Boolean(project.value?.permissions?.canSetPrivacy));
  const canViewEstimatedTime = computed(() =>
    Boolean(!isCollaborator.value && project.value?.permissions.viewEstimatedTime),
  );

  const canManagePeople = computed(() => _canManagePeople.value || isProjectAdmin.value);

  /**
   * projectstimebudgets: enabled && projectfinancialbudgets: enabled
   * - users can edit all budgets
   * projectstimebudgets: enabled && projectfinancialbudgets: disabled
   * - users can edit a time budget but not edit a financial budget
   * projectstimebudgets: disabled && projectfinancialbudgets: disabled
   * - users cannot edit any budget
   */
  const canManageProjectBudget = computed(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _canManageProjectBudget = Boolean(project.value?.permissions?.canManageProjectBudget);

    if (!projectBudget.value) {
      return _canManageProjectBudget;
    }

    const canEditActiveBudget =
      projectBudget.value.type === 'time' ? projectTimeBudgetsEnabled.value : projectFinancialBudgetsEnabled.value;

    return Boolean(canEditActiveBudget && _canManageProjectBudget);
  });

  const canAddFiles = computed(() => {
    const readOnly = project.value?.type === 'projects-template' && !canManageProjectTemplates.value;
    return (
      Boolean(project.value?.permissions?.viewMessagesAndFiles && project.value?.permissions?.canAddFiles) && !readOnly
    );
  });
  const canViewRates = computed(() =>
    Boolean(
      project.value && (isProjectAdmin.value || (_canManagePeople.value && project.value?.permissions.canViewRates)),
    ),
  );
  const canManageRates = computed(() =>
    Boolean(
      project.value && (isProjectAdmin.value || (_canManagePeople.value && project.value?.permissions.canManageRates)),
    ),
  );

  const canUploadFiles = computed(() =>
    Boolean(account.value?.canUploadFilesDirectly && project.value?.directFileUploadsEnabled),
  );
  const canPreviewFiles = computed(() =>
    Boolean(project.value?.permissions?.viewMessagesAndFiles && account.value?.canPreviewFiles),
  );
  const canDownloadFiles = computed(() => Boolean(project.value?.permissions?.viewMessagesAndFiles));

  const canViewLoggedTime = computed(() => Boolean(isSiteAdmin.value || project.value?.permissions.viewTimeLog));

  const canViewTasksAndMilestones = computed(() => Boolean(project.value?.permissions.viewTasksAndMilestones));

  const canInviteUser = computed(() => Boolean(!ssoEnabledAutoStart.value && canManagePeople.value));

  const canManageProjectCustomfields = computed(() =>
    Boolean(project.value?.id && project.value?.permissions.canManageCustomFields),
  );

  const canViewProjectBudget = computed(() =>
    Boolean(projectTimeBudgetsEnabled.value && (isSiteAdmin.value || project.value?.permissions.canViewProjectBudget)),
  );

  const canViewProjectBudgetsTab = computed(() =>
    Boolean(project.value?.permissions.canViewProjectBudget && projectTimeBudgetsEnabled.value),
  );
  const canViewProjectBillingTab = computed(() => Boolean(project.value?.permissions.canAccessInvoiceTracking));

  const canViewProjectList = computed(() =>
    Boolean(project.value?.activePages.list && project.value?.permissions.viewTasksAndMilestones),
  );
  const canViewProjectTable = computed(() =>
    Boolean(project.value?.activePages.table && project.value?.permissions.viewTasksAndMilestones),
  );
  const canViewProjectBoards = computed(() =>
    Boolean(project.value?.activePages.board && project.value?.permissions.viewTasksAndMilestones),
  );
  const canViewProjectTask = computed(() =>
    Boolean(project.value?.activePages.tasks && canViewTasksAndMilestones.value),
  );
  const canViewProjectMessages = computed(() =>
    Boolean(project.value?.activePages.messages && project.value?.permissions.viewMessagesAndFiles),
  );
  const canViewProjectFiles = computed(() =>
    Boolean(project.value?.activePages.files && project.value?.permissions.viewMessagesAndFiles),
  );
  const canViewProjectNotebooks = computed(() =>
    Boolean(project.value?.activePages.notebooks && project.value?.permissions.viewNotebook),
  );
  const canViewProjectTime = computed(() =>
    Boolean(project.value?.activePages.time && project.value?.permissions.viewTimeLog),
  );
  const canViewProjectTickets = computed(() =>
    Boolean(
      !isClientUser.value &&
        ((project.value?.activePages.tickets && isDeskEnabled.value) || deskOnboardingFlowEnabled.value) &&
        (deskProjectTicketLinkEnabled.value || !project.value?.company?.isOwner),
    ),
  );
  const canViewProjectDashboard = computed(() => true);
  const canViewProjectMilestones = computed(() =>
    Boolean(project.value?.activePages.milestones && project.value?.permissions.viewTasksAndMilestones),
  );
  const canViewProjectComments = computed(() => project.value?.activePages.comments);
  const canViewProjectRisks = computed(() =>
    Boolean(
      project.value?.activePages.riskRegister &&
        project.value?.permissions.viewRiskRegister &&
        riskRegisterEnabled.value,
    ),
  );
  const canViewProjectLinks = computed(() =>
    Boolean(project.value?.activePages.links && project.value?.permissions.viewLinks),
  );
  const canViewProjectFinance = computed(() =>
    Boolean(project.value?.activePages.finance && (canViewProjectBudgetsTab.value || canViewProjectBillingTab.value)),
  );
  const canViewProjectGantt = computed(() =>
    Boolean(
      project.value?.activePages.gantt &&
        !project.value?.isTemplate &&
        project.value?.permissions.viewTasksAndMilestones,
    ),
  );
  const canViewProjectPeople = computed(() =>
    Boolean(project.value?.permissions.canViewProjectMembers || project.value?.permissions.projectAdministrator),
  );
  const canViewProjectForms = computed(() =>
    Boolean(
      project.value?.activePages.forms &&
        project.value?.permissions.canViewForms &&
        !isClientUser.value &&
        intakeFormsEnabled.value,
    ),
  );

  const canViewProjectSettings = computed(() =>
    Boolean(project.value?.permissions.projectAdministrator && project.value?.status === 'active'),
  );
  const canViewProjectReport = computed(() => project.value?.activePages.tasks && canViewTasksAndMilestones.value);
  const canViewProjectProofs = computed(() => Boolean(project.value?.activePages.proofs && canViewProofs.value));

  const canAddOrEditProjectMessages = computed(() => Boolean(canViewProjectMessages.value && canAddMessages.value));
  const canAddOrEditProjectNotebooks = computed(() => Boolean(canViewProjectNotebooks.value && canAddNotebooks.value));

  const canAccessGoogleDrive = computed(() => project.value?.id && project.value?.permissions.canAccessGoogleDocs);
  const canAccessDropbox = computed(() => project.value?.id && project.value?.permissions.canAccessDropbox);
  const canAccessOneDrive = computed(() => project.value?.id && project.value?.permissions.canAccessOneDrive);
  const canAccessOneDriveBusiness = computed(
    () => project.value?.id && project.value?.permissions.canAccessOneDriveBusiness,
  );
  const canAccessSharePoint = computed(() => project.value?.id && project.value?.permissions.canAccessSharePoint);
  const canAccessBox = computed(() => project.value?.id && project.value?.permissions.canAccessBox);

  const canManageProjectWorkflows = computed(
    () => isSiteOrProjectAdmin.value || (project.value?.id && project.value?.permissions.canEditWorkflows),
  );

  const canViewProjectProfitability = computed(() =>
    Boolean(
      isOwnerAdmin.value ||
        (projectsBudgetProfitabilityPermissionEnabled.value && project.value?.permissions.canViewProjectProfitability),
    ),
  );

  const canViewProjectSetup = computed(() =>
    Boolean(
      isExpA2350Variation.value &&
        projectsGoalBasedOnboardingProjectTabEnabled.value &&
        !project.value?.isTemplate &&
        !sampleProjectIds.value.includes(project.value?.id),
    ),
  );

  return {
    isSiteOrProjectAdmin,
    isProjectAdmin,
    canAccessInvoiceTracking,
    canAddTasklists,
    canAddTasks,
    canEditAllTasks,
    canAddMessages,
    canAddUpdate,
    canAddMilestones,
    canLogTime,
    canAddReminders,
    canManageProjectBudget,
    canManageRates,
    canSetPrivacy,

    canAddFiles,
    canUploadFiles,
    canPreviewFiles,
    canDownloadFiles,

    canManageProjectCustomfields,
    canManagePeople,

    canViewEstimatedTime,
    canViewLoggedTime,
    canViewProjectBudget,
    canViewRates,
    canInviteUser,

    canViewProjectList,
    canViewProjectTable,
    canViewProjectBoards,
    canViewProjectTask,
    canViewProjectMessages,
    canViewProjectFiles,
    canViewProjectNotebooks,
    canViewProjectTime,
    canViewProjectTickets,
    canViewProjectDashboard,
    canViewProjectMilestones,
    canViewProjectComments,
    canViewProjectRisks,
    canViewProjectLinks,
    canViewProjectFinance,
    canViewProjectGantt,
    canViewProjectPeople,
    canViewProjectForms,
    canViewProjectSettings,
    canViewProjectReport,
    canViewProjectProofs,

    canAddOrEditProjectMessages,
    canAddOrEditProjectNotebooks,

    canAccessGoogleDrive,
    canAccessDropbox,
    canAccessOneDrive,
    canAccessOneDriveBusiness,
    canAccessSharePoint,
    canAccessBox,

    canManageProjectWorkflows,
    canViewProjectProfitability,
    canViewProjectSetup,
  };
}

export function provideCurrentProjectPermissions() {
  provide(symbol, CurrentProjectPermissionsService());
}

/**
 * Provides global & project-level user permissions.
 * @type {CurrentProjectPermissionsService}
 */
export function useCurrentProjectPermissions() {
  return inject(symbol, {
    canAccessGoogleDrive: computed(() => undefined),
    canAccessDropbox: computed(() => undefined),
    canAccessOneDrive: computed(() => undefined),
    canAccessOneDriveBusiness: computed(() => undefined),
    canAccessSharePoint: computed(() => undefined),
    canAccessBox: computed(() => undefined),
  });
}
