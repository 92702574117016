<script setup>
import { useI18n } from '@/util';

const props = defineProps({
  /**
   * The placeholder text for the search bar.
   */
  placeholder: {
    type: String,
    default: undefined,
  },
  /**
   * Determines if the search bar is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Determines if the search bar has a clear button.
   */
  clearable: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the search bar should autofocus.
   */
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: String,
  default: '',
});

const { t } = useI18n();
const searchInputRef = shallowRef(null);

function clearText() {
  modelValue.value = '';
}

onMounted(async () => {
  if (props.autofocus) {
    await nextTick();
    setTimeout(() => {
      searchInputRef.value?.focus();
    }, 100);
  }
});

const searchBarVariantStyleConfig = tv({
  base: 'relative inline-flex items-center',
  slots: {
    icon: 'absolute inset-y-0 left-3 my-auto text-icon-subtle',
    input: [
      'h-8 w-full rounded-full px-8 align-middle text-body-1 leading-5 outline-none',
      'border border-form-default placeholder:text-placeholder',
      'hover:border-form-hover focus-visible:border-focus-primary',
      'disabled:border-form-disabled disabled:text-disabled',
    ],
    clearButton: 'align-center absolute inset-y-0 right-3 flex text-icon-default',
  },
  variants: {
    disabled: {
      true: {
        icon: 'text-icon-disabled',
        input: 'border-form-disabled placeholder:text-disabled',
      },
    },
    active: {
      true: {
        input: 'border-form-active text-default',
      },
    },
  },
});

const classes = computed(() =>
  searchBarVariantStyleConfig({
    disabled: props.disabled,
    active: Boolean(modelValue.value),
  }),
);
</script>

<template>
  <div :class="classes.base()">
    <LscIcon icon="lsi-search" size="sm" :class="classes.icon()" />
    <input
      ref="searchInputRef"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="classes.input()"
      @input="modelValue = $event.target.value"
    />
    <div v-if="modelValue && clearable" :class="classes.clearButton()">
      <LscIconButton icon="lsi-close" size="sm" variant="plain-secondary" :ariaLabel="t('Clear')" @click="clearText" />
    </div>
  </div>
</template>
