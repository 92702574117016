import { useIntervalFn, useThrottleFn } from '@vueuse/core';
import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { usePendo } from '../features/usePendo';
import { useLocalStorage } from '@/util';

const SYNC_STATUSES = Object.freeze({
  IDLE: 'IDLE',
  EXECUTING: 'EXECUTING',
  EXPIRED: 'EXPIRED',
  REQUESTED: 'REQUESTED',
});

const SYNC_STATUS_ERROR_CODES = Object.freeze({
  AUTH_ERROR: 'E010',
  YOXEL_AUTH_ERROR: 'E009',
  // Default error code for 500 sync errors
  SYNC_DEFAULT_ERROR: 'E103',
});

const SYNC_STALLED_LIMIT = 5 * 60 * 1000;

function responseToItem({ data }) {
  return data;
}

export function useCalendarSyncStatusV2Loader() {
  const { trackPendoEvent } = usePendo();

  const { state, refresh } = useItemLoader({
    url: '/synthesis/api/v2/yoxelcalendarsync/status.json',
    responseToItem,
  });
  const { item, error, inSync, loaded } = state;

  const isLoading = computed(() => {
    return !loaded.value;
  });

  const isSyncRemoved = computed(() => error.value?.response?.data?.code === SYNC_STATUS_ERROR_CODES.YOXEL_AUTH_ERROR);
  const isSyncExpired = computed(
    () =>
      item.value?.syncState === SYNC_STATUSES.EXPIRED ||
      error.value?.response?.data?.code === SYNC_STATUS_ERROR_CODES.AUTH_ERROR,
  );
  const isSyncFailed = computed(() => Boolean(error.value) && !isSyncExpired.value && !isSyncRemoved.value);
  const isSynced = computed(
    () => inSync.value && Boolean(item.value?.userSyncEnabled) && !isSyncExpired.value && !isSyncRemoved.value,
  );
  const isSyncStalled = computed(
    () =>
      item.value?.syncState === SYNC_STATUSES.REQUESTED &&
      new Date() - new Date(item.value?.lastRequest) > SYNC_STALLED_LIMIT,
  );
  // This gets reset when a sync is successfull so save the last request before it stalled.
  let lastRequestBeforeStalled = '';

  const hasSyncError = computed(() => error.value?.response?.data?.code === SYNC_STATUS_ERROR_CODES.SYNC_DEFAULT_ERROR);

  // localStorage flag to determine if sync was initiated
  // As `REQUESTED` or `EXECUTING` status will be true for any req in process by server/yoxel
  const isSyncInProgress = useLocalStorage('teamwork/myCalendar/syncingGoogleCalendar', false);
  // Check if server processing req
  const isProcessing = computed(
    () => !error.value && [SYNC_STATUSES.REQUESTED, SYNC_STATUSES.EXECUTING].includes(item.value?.syncState),
  );

  // Refresh sync status every 5s while syncing as we don't have real-time events
  const { pause, resume } = useIntervalFn(
    () => {
      if (isSyncInProgress.value && isProcessing.value) {
        refresh();
        return;
      }
      pause();
      isSyncInProgress.value = false;
    },
    5 * 1000,
    { immediate: isSyncInProgress.value },
  );

  const refreshThrottled = useThrottleFn(refresh, 5 * 1000 * 60);

  useRealTimeUpdates((event) => {
    if (event.type === 'calendarEvent') {
      refreshThrottled();
    }

    if (event.type === 'calendar') {
      // Google calendar synced
      if (event.action === 'edited' && !isSynced.value) {
        isSyncInProgress.value = true;
        refresh();
        resume();
      }

      if (event.action === 'unsynced') {
        isSyncInProgress.value = false;
        refresh();
        pause();
      }
    }
  });

  watch(isSyncStalled, () => {
    if (isSyncStalled.value) {
      lastRequestBeforeStalled = item.value?.lastRequest;
    }

    let minutesStalled = 0;
    if (lastRequestBeforeStalled) {
      minutesStalled = Math.floor((new Date() - new Date(lastRequestBeforeStalled)) / (1000 * 60));
    }

    trackPendoEvent({
      eventName: 'CALENDAR_EVENT',
      commonMetrics: ['user_role', 'plan_name', 'page'],
      metadata: {
        page_type: 'my_calendar',
        event_action: isSyncStalled.value ? 'calendar_sync_stalled' : 'calendar_sync_stalled_recovered',
        minutes_stalled: minutesStalled,
      },
    });
  });

  watch(isSyncExpired, () => {
    if (isSyncExpired.value) {
      trackPendoEvent({
        eventName: 'CALENDAR_EVENT',
        commonMetrics: ['user_role', 'plan_name', 'page'],
        metadata: {
          page_type: 'my_calendar',
          event_action: 'calendar_sync_expired',
        },
      });
    }
  });

  return {
    isSyncInProgress,
    isSynced,
    isSyncExpired,
    isSyncFailed,
    isSyncStalled,
    isLoading,
    hasSyncError,
  };
}
