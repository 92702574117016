import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';
import { useCurrentAccount } from '../account/useCurrentAccount';

export function useExperimentE2304() {
  const { isOwnerAdmin } = usePermissions();
  const account = useCurrentAccount();
  const { projectsBlackFridayDeliver, projectsBlackFridayGrow } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const DELIVER_PLAN_IDS = [21, 41, 47, 64];
  const GROW_PLAN_IDS = [22, 42, 48, 65, 69];

  const feature = computed(() => {
    if (DELIVER_PLAN_IDS.includes(account.value.pricePlanId)) {
      return {
        featureFlagValue: projectsBlackFridayDeliver.value,
        ldFlagKey: 'projects-exp-r-23-04-black-friday-deliver',
      };
    }
    if (GROW_PLAN_IDS.includes(account.value.pricePlanId)) {
      return {
        featureFlagValue: projectsBlackFridayGrow.value,
        ldFlagKey: 'projects-exp-r-23-04-black-friday-grow',
      };
    }

    return {
      featureFlagValue: false,
      ldFlagKey: '',
    };
  });

  const isTargetPlan = computed(() => DELIVER_PLAN_IDS.concat(GROW_PLAN_IDS).includes(account.value.pricePlanId));
  const expE2304AppLevelTargeting = computed(() => isTargetPlan.value && isOwnerAdmin?.value);

  const EXP_E2304_COMMON_METRICS = ['plan_name', 'page', 'user_role'];
  const EXP_E2304_EVENT_NAME = 'BLACK_FRIDAY_EVENT';

  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;

  const isExp2304Variation = computed(() => feature.value?.featureFlagValue && expE2304AppLevelTargeting.value);
  const isExp2304Control = computed(() => !feature.value?.featureFlagValue && expE2304AppLevelTargeting.value);

  function trackExperimentE2304() {
    trackExperimentInPendo({
      launchDarklyFlagKey: feature.value?.ldFlagKey,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expE2304AppLevelTargeting.value,
    });
  }

  function trackEventE2304({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: EXP_E2304_EVENT_NAME,
      commonMetrics: EXP_E2304_COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExp2304Variation,
    isExp2304Control,
    EXP_E2304_COMMON_METRICS,
    EXP_E2304_EVENT_NAME,
    EXP_E2304_FLAG_KEY: feature.value.ldFlagKey,
    trackExperimentE2304,
    trackEventE2304,
  };
}
