/**
 * @type {Object<string, LscIcon>}
 */
export const ENTITY_ICON_MAP = {
  activate: 'lsi-success',
  budget: 'lsi-billable',
  comment: 'lsi-comment-text',
  company: 'lsi-assignee',
  create: 'lsi-add',
  customreport: 'lsi-reports',
  event: 'lsi-event',
  file: 'lsi-file',
  default: 'lsi-notification-placeholder',
  billinginvoice: 'lsi-budget',
  invoice: 'lsi-budget',
  link: 'lsi-link',
  link_comment: 'lsi-reply',
  message: 'lsi-email',
  message_comment: 'lsi-reply',
  milestone: 'lsi-milestone',
  milestone_comment: 'lsi-reply',
  notebook: 'lsi-notebook',
  notebook_comment: 'lsi-reply',
  pause: 'lsi-pause',
  project: 'lsi-project',
  projectupdate: 'lsi-project',
  proof: 'lsi-proofs',
  proofrole: 'lsi-proofs',
  prooffeedback: 'lsi-proofs',
  proofdecision: 'lsi-proofs',
  proofversion: 'lsi-proofs',
  task: 'lsi-task',
  tasklist: 'lsi-tasklist',
  task_comment: 'lsi-reply',
  team: 'lsi-group',
  timelog: 'lsi-time',
  update: 'lsi-edit',
  form: 'lsi-text-field',
  timereminder: 'lsi-notifications',
  calendar: 'lsi-calendar',
  clients: 'lsi-client',
  everything: 'lsi-sidenav-everything',
  home: 'lsi-home',
  people: 'lsi-assignees',
  planning: 'lsi-planning',
  settings: 'lsi-settings',
  subscription: 'lsi-subscription',
  templates: 'lsi-multiple-templates',
  timer: 'lsi-start-timer',
  toggleSidebar: 'lsi-collapse',
  welcome: 'lsi-sidenav-welcome',
  file_comment: 'lsi-comment-text',
};

export const ENTITY_COLOR_MAP = {
  activate: '#95E1C1',
  budget: '#e1e6ee',
  comment: '#95e1c1',
  company: '#3DADB8',
  customreport: '#bda3ff',
  create: '#94C7FB',
  calendar: '#97E8F0',
  event: '#bba2ff',
  everything: '#FFDC87',
  file: '#a1e9f0',
  form: '#dfa275',
  home: '#C2E9FF',
  billinginvoice: '#8F9BB3',
  invoice: '#8F9BB3',
  default: '#E1E6EE',
  link: '#ffe29b',
  link_comment: '#ffe29b',
  message: '#ffa0dd',
  message_comment: '#ffa0dd',
  milestone: '#8f9fe7',
  milestone_comment: '#8f9fe7',
  notebook: '#ed818e',
  notebook_comment: '#ed818e',
  pause: '#F5F7FA',
  planning: '#E12D42',
  people: '#3DADB8',
  project: '#94c7fb',
  projectupdate: '#94c7fb',
  proof: '#94C7FB',
  proofrole: '#94C7FB',
  prooffeedback: '#94C7FB',
  proofdecision: '#94C7FB',
  proofversion: '#94C7FB',
  reacted: '#95e1c1',
  settings: '#C5CAE0',
  status: '#e1e6ee',
  subscription: '#4ECD97',
  task: '#ffad8d',
  task_comment: '#ffad8d',
  tasklist: '#ffad8d',
  team: '#e1e6ee',
  templates: '#CA6419',
  update: '#FFDC87',
  user: '#e1e6ee',
  timer: '#ED7987',
  timelog: '#ED7987',
  timereminder: 'var(--lsds-g-palette-pink-30)',
  welcome: '#C5F2F2',
};
