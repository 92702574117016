/**
 * Returns a `computed` which provides a more accurate `totalCount` value then that
 * available through `state.meta.value.totalCount`.
 * In order to get correct results, `skipCounts: false` should be passed as a param to the loader
 * and `totalCount` should be added to `meta` using `reponseToMeta`.
 * @param {Object} state A state object returned by a list loader (`useListLoader`).
 */
export function useTotalCount(state) {
  return computed(() => {
    if (!isRef(state?.meta) || !isRef(state?.inSync) || !isRef(state?.hasMore) || !isRef(state?.items)) {
      // eslint-disable-next-line no-console
      console.warn(
        'useTotalCount: Invalid `state` parameter - provide a state object returned by a list loader (`useListLoader`).',
      );
      return 0;
    }

    const totalCount = state.meta.value?.totalCount;
    const isTotalCountValid = Number.isInteger(totalCount) && totalCount >= 0;

    if (!isTotalCountValid && state.inSync.value) {
      // eslint-disable-next-line no-console
      console.warn('useTotalCount: Use responseToMeta and skipCounts=false to retrieve totalCount data from the API.');
    }

    return isTotalCountValid && state.hasMore.value ? totalCount : state.items.value.length;
  });
}
