<script setup>
import { useCohort, useExperimentA30 } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { isPaid, isPaymentOverdue, isClientUser, isCollaborator, isSiteAdmin } = useCohort();
const { isExpA30Variation } = useExperimentA30();

const isICPOnboarding = computed(
  () =>
    ((isPaid.value || isPaymentOverdue.value) && !isClientUser.value && !isCollaborator.value && isSiteAdmin.value) ||
    isExpA30Variation.value,
);
</script>
<template>
  <div class="text-body-1">
    <p>{{ t('Report on your project progress and gain insights to help you manage your resources with ease.') }}</p>
    <p class="pt-6">{{ t('Over the next 2 minutes, discover how you can:') }}</p>
    <ul class="mx-10">
      <!-- Track team performance -->
      <li>{{ t('Track progress at a glance') }}</li>
      <li>{{ t("Maximize your team's capacity") }}</li>
      <li v-if="isICPOnboarding">{{ t('Report on what matters') }}</li>
    </ul>
  </div>
</template>
