<script setup>
import { InvoiceDialogOpenSources } from './constants';

defineProps({
  invoiceId: {
    type: Number,
    default: null,
  },
  projectId: {
    type: Number,
    default: null,
  },
  /**
   * @type {PropType<typeof InvoiceDialogOpenSources[number]>}
   */
  openSource: {
    type: String,
    default: undefined,
    validator: (value) => InvoiceDialogOpenSources.includes(value),
  },
});

const emit = defineEmits(['createInvoice']);

const modelValue = defineModel({ type: Boolean, default: false });

const FinanceInvoiceAddOrEditDialogPanel = defineAsyncComponent(
  () => import('./FinanceInvoiceAddOrEditDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceInvoiceAddOrEditDialogPanel
        :closing="!modelValue"
        :projectId="projectId"
        :invoiceId="invoiceId"
        :openSource="openSource"
        @close="close"
        @createInvoice="emit('createInvoice', $event)"
      />
    </template>
  </WidgetDialog>
</template>
