import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { normalizeTasks, normalizeParams, normalizeNewTask, computeDisplayOrder } from './normalizers';

import {
  orderAscByManual,
  orderDescByManual,
  orderAscByName,
  orderDescByName,
  orderAscByStartDate,
  orderDescByStartDate,
  orderAscByDueDate,
  orderDescByDueDate,
  orderAscByCreatedDate,
  orderDescByCreatedDate,
  orderAscByCreatedBy,
  orderDescByCreatedBy,
  orderAscByPriority,
  orderDescByPriority,
  orderAscByProject,
  orderDescByProject,
  orderAscByTaskListName,
  orderDescByTaskListName,
  orderAscByTasklistDisplayOrder,
  orderAscByCompletedOn,
  orderDescByCompletedOn,
  orderAscByEstimate,
  orderDescByEstimate,
  orderAscDateUpdated,
  orderDescDateUpdated,
} from './sortingHelpers';

// gets a list of tasks from an axios response
function responseToItems({ data }) {
  const { tasks, included } = data;
  return normalizeTasks(tasks, included);
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

function orderByDefault() {
  return 0;
}

/**
 * Loads a list of tasks from the Teamwork v3 API.
 */
export function useTasksV3Loader({
  /**
   * The task from which to load subtasks.
   */
  taskId: _taskId,
  /**
   * The task list from which to load tasks.
   */
  tasklistId: _tasklistId,
  /**
   * The project from which to load tasks.
   */
  projectId: _projectId,
  /**
   * Filtering and sorting params for the request.
   */
  params: _params,
  /**
   * The number of tasks to load.
   */
  count,
  /**
   * The number of tasks to load per request.
   */
  pageSize,
}) {
  const taskId = shallowRef(_taskId);
  const tasklistId = shallowRef(_tasklistId);
  const projectId = shallowRef(_projectId);

  const params = computed(() => {
    const normalizedParams = normalizeParams(_params);
    const { orderBy, orderMode } = normalizedParams;

    if (!orderBy) {
      normalizedParams.orderBy = 'manual';
    }

    if (!orderMode) {
      normalizedParams.orderMode = 'asc';
    } else if (orderMode !== 'asc' && orderMode !== 'desc') {
      // eslint-disable-next-line no-console
      console.warn(`useTasksV3Loader: unknown params.orderMode: ${orderMode}`);
      normalizedParams.orderMode = 'asc';
    }

    return normalizedParams;
  });

  const url = computed(() => {
    if (taskId.value) {
      return `/projects/api/v3/tasks/${taskId.value}/subtasks.json`;
    }
    if (tasklistId.value) {
      return `/projects/api/v3/tasklists/${tasklistId.value}/tasks.json`;
    }
    if (projectId.value) {
      return `/projects/api/v3/projects/${projectId.value}/tasks.json`;
    }
    return '/projects/api/v3/tasks.json';
  });

  const order = computed(() => {
    const { orderBy, orderMode } = params.value;
    const desc = orderMode === 'desc';

    switch (orderBy.toLowerCase()) {
      case 'manual':
        return orderAscByManual;
      case 'projectmanual':
        return desc ? orderDescByManual : orderAscByManual;
      case 'taskname':
        return desc ? orderDescByName : orderAscByName;
      case 'duedate':
        return desc ? orderDescByDueDate : orderAscByDueDate;
      case 'startdate':
        return desc ? orderDescByStartDate : orderAscByStartDate;
      case 'priority':
        return desc ? orderDescByPriority : orderAscByPriority;
      case 'tasklistname':
        return desc ? orderDescByTaskListName : orderAscByTaskListName;
      case 'tasklistdisplayorder':
        return orderAscByTasklistDisplayOrder;
      case 'createdby':
        return desc ? orderDescByCreatedBy : orderAscByCreatedBy;
      case 'project':
        return desc ? orderDescByProject : orderAscByProject;
      case 'dateadded':
        return desc ? orderDescByCreatedDate : orderAscByCreatedDate;
      case 'active':
        return desc ? orderDescByCompletedOn : orderAscByCompletedOn;
      case 'estimatedtime':
        return desc ? orderDescByEstimate : orderAscByEstimate;
      case 'dateupdated':
        return desc ? orderDescDateUpdated : orderAscDateUpdated;
      default:
        // eslint-disable-next-line no-console
        console.warn(`useTasksV3Loader: unknown params.orderBy: ${orderBy}`);
        return orderByDefault;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    order,
    pageSize,
    type: 'task',
  });

  /**
   * Determines if the specified `task` matches the filter params passed into `useTasksV3Loader`.
   * The implementation is not 100% accurate because it is not currently necessary and
   * would require a lot of extra complexity. In other words, for some combinations of tasks and filters,
   * the result will be incorrect. If that causes issues in the UI, then `matchesFilter` should be adjusted.
   *
   * @params avoidFalsePositives Determines if false positives should be avoided. Defaults to false.
   * If false:
   * - false positives do happen in some situations
   * - false nagatives do not happen
   * If true:
   * - false positives may still happen
   * - false negatives do happen in some situations
   */
  function matchesFilter(task, avoidFalsePositives) {
    if (
      avoidFalsePositives &&
      params.value &&
      (params.value.filterText ||
        params.value['responsible-party-ids'] ||
        params.value.tagIds ||
        params.value.filter === 'completed')
    ) {
      return false;
    }

    // Required for Project > Tasks.
    if (!params.value?.getSubTasks && !taskId.value && task.parentTaskId) {
      return false;
    }

    // Required for Project > Tasks.
    if (taskId.value && taskId.value !== task.parentTaskId) {
      return false;
    }

    // Required for Project > Tasks.
    if (tasklistId.value && tasklistId.value !== task.tasklistId) {
      return false;
    }

    // Required for Project > Tasks.
    if (projectId.value && projectId.value !== task.projectId) {
      return false;
    }

    // Required for My Work > Tasks.
    if (params.value && params.value.ignoreStartDates) {
      const today = DateTime.now().startOf('day');
      const { dueDate } = task;

      switch (params.value.taskFilter) {
        case 'upcoming':
          if (!dueDate) {
            return false;
          }
          if ((params.value.includeToday ?? true) ? dueDate < today : dueDate < today.plus({ days: 1 })) {
            return false;
          }
          break;
        case 'today':
          if (!dueDate) {
            return false;
          }
          if (!dueDate.isSame(today)) {
            return false;
          }
          break;
        case 'overdue':
          if (!dueDate) {
            return false;
          }
          if (dueDate.isSameOrAfter(today)) {
            return false;
          }
          break;
        case 'noduedate':
          if (dueDate) {
            return false;
          }
          break;
        default:
          break;
      }
    }

    return true;
  }

  useOptimisticUpdates((event) => {
    if (event.type === 'task') {
      update((tasks) => {
        if (event.action === 'create') {
          if (matchesFilter(event.task, true)) {
            return tasks.concat([normalizeNewTask(event.task, tasks)]);
          }
          return tasks;
        }
        if (event.action === 'update') {
          // Remove the task, if it does not match the current filter.
          if (!matchesFilter(event.task)) {
            return tasks.filter((task) => task.id !== event.task.id);
          }

          // Update the existing task.
          if (tasks.some((task) => task.id === event.task.id)) {
            return tasks.map((task) => {
              if (task.id === event.task.id) {
                const newTask = { ...task, ...event.task };
                newTask.displayOrder = computeDisplayOrder(newTask, tasks);
                return newTask;
              }
              return task;
            });
          }

          // Improve UX for task drag and drop.
          if (
            (event.task.previousParentTaskId != null && event.task.previousParentTaskId !== event.task.parentTaskId) ||
            (event.task.previousTaskListId != null && event.task.previousTaskListId !== event.task.taskListId) ||
            (event.task.previousProjectId != null && event.task.previousProjectId !== event.task.projectId)
          ) {
            return tasks.concat([normalizeNewTask(event.task, tasks)]);
          }

          return tasks;
        }
        if (event.action === 'delete') {
          return tasks.filter((task) => task.id !== event.task.id);
        }
        return tasks;
      }, event.promise);
    }

    // Respond to tag updates.
    if (event.type === 'tag' && event.action === 'update') {
      update((tasks) => {
        return tasks.map((task) => ({
          ...task,
          tags: task.tags?.map((tag) => (tag.id === event.tag.id ? { ...tag, ...event.tag } : tag)),
        }));
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all tasks.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // Rare occasion a task that has yet been saved updates something that may effect other tasks.
    // For example updating a tag's name / colour in the task creation flow.
    if (event.type === 'task' && event.taskId === 0) {
      refresh();
      return;
    }

    if (event.type === 'task' && event.action === 'bulkEditTasks') {
      refresh();
      return;
    }

    // When a task moves to a different task list
    if (event.type === 'task' && event.previousTaskListId && event.previousTaskListId !== event.tasklistId) {
      refresh();
      return;
    }

    // If a tag was changed, reload all.
    // TODO: Send a RTU instead of looking through all tasks.
    if (event.type === 'tag' && event.action === 'edited') {
      const tasksWithTag = state.items.value.filter((task) => task.tagIds?.includes(event.tagId));
      if (tasksWithTag.length) {
        refresh();
      }
    }

    // If filtering by projectId, it must match event.projectId.
    if (projectId.value && projectId.value !== event.projectId) {
      return;
    }

    // If filtering by tasklistId, it must match event.tasklistId or event.previousTaskListId.
    if (tasklistId.value && tasklistId.value !== event.tasklistId && tasklistId.value !== event.previousTaskListId) {
      return;
    }

    // If a task was changed, reload it.
    if (event.type === 'task') {
      refresh(event.taskId);
      return;
    }

    // If many tasks in a task list changed, reload all.
    if (event.type === 'tasklistTasks') {
      refresh();
      return;
    }

    // If a task list changed.
    if (event.type === 'tasklist') {
      // If a side-loaded task list name might have changed, then reload all tasks.
      if (event.action === 'edited') {
        refresh();
      }
      return;
    }

    // Only events of these types can affect task properties.
    if (event.type !== 'task' && event.type !== 'comment' && event.type !== 'time' && event.type !== 'reminder') {
      return;
    }

    // Only added and deleted comments can affect task properties.
    if (event.type === 'comment' && event.action === 'edited') {
      return;
    }

    // If a subtask is added, deleted, completed or reopened,
    // then reload the parent task as its number of subtasks changed.
    if (
      event.type === 'task' &&
      event.parentTaskId &&
      (event.action === 'reopened' ||
        event.action === 'completed' ||
        event.action === 'new' ||
        event.action === 'deleted')
    ) {
      refresh(event.parentTaskId);
    }

    // If loading only one level from the tasks hierarchy...
    if (!params.value.getSubTasks) {
      // If filtering by taskId, it must match event.parentTaskId.
      if (taskId.value && taskId.value !== event.parentTaskId && taskId.value !== event.previousParentTaskId) {
        return;
      }

      // If not loading subtasks, skip subtask changes.
      if (!taskId.value && event.parentTaskId && event.previousParentTaskId) {
        return;
      }
    }

    // Reload the task.
    refresh(event.taskId);
  });

  return state;
}
