<script setup>
const SubscriptionAddSeatsDialogPanel = defineAsyncComponent(() => import('./SubscriptionAddSeatsDialogPanel.vue'));
</script>

<template>
  <WidgetDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <SubscriptionAddSeatsDialogPanel @close="close" />
    </template>
  </WidgetDialog>
</template>
