<script setup>
import AppShellMain1 from './AppShellMain1.vue';
import { provideThemeColor } from '@/module/theme';
import { provideTimer } from '@/module/time';
import { provideDocumentTitle } from '@/util';
import { provideAppShellSidebar } from './sidebar/useAppShellSidebar';
import { provideAppDrawerState } from '@/module/app';
import { provideTheaterMode, provideEmbeddedMode } from '@/route';
import { provideBadge } from '@/module/badge';
import { provideHarvest } from '@/module/harvest';
import { provideCalendarGoogleSyncStatus, provideCalendarGoogleSyncAuthPopup } from '@/module/calendar';
import { LegacySetup } from '@/module/legacy';
import { provideHubspot } from '@/api';

provideThemeColor();
provideTimer();
provideDocumentTitle();
provideAppShellSidebar();
provideAppDrawerState();
provideTheaterMode();
provideEmbeddedMode();
provideBadge();
provideHarvest();
provideCalendarGoogleSyncStatus();
provideCalendarGoogleSyncAuthPopup();
provideHubspot();
</script>

<template>
  <LegacySetup>
    <AppShellMain1 />
  </LegacySetup>
</template>
