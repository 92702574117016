<script setup>
import { useI18n } from '@/util';
import ReportCellTotal from '../cell/ReportCellTotal.vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const { formatPercentage, formatCurrency, t, formatMinutes } = useI18n();

const loggedTimeVsEstimatedTime = computed(() => formatMinutes(Math.abs(props.item.loggedTimeVsEstimatedTime)));
</script>

<template>
  <LscTableFooter>
    <template #projectName>
      <LscOverflowEllipsis class="font-semibold">{{ t('Summary') }}</LscOverflowEllipsis>
    </template>
    <template #milestoneName>
      <LscOverflowEllipsis class="font-semibold">{{ t('Summary') }}</LscOverflowEllipsis>
    </template>
    <template #userName>
      <LscOverflowEllipsis class="font-semibold">{{ t('Summary') }}</LscOverflowEllipsis>
    </template>
    <template #taskName>
      <LscOverflowEllipsis class="font-semibold">{{ t('Summary') }}</LscOverflowEllipsis>
    </template>
    <template #activeprojects>
      <ReportCellTotal :value="item.activeProjects" totalType="total" />
    </template>
    <template #actualutilization>
      <ReportCellTotal :value="formatPercentage(item.actualUtilization / 100)" totalType="avg" />
    </template>
    <template #assignedtasks>
      <ReportCellTotal :value="item.assignedTasks" totalType="total" />
    </template>
    <template #availabletime>
      <ReportCellTotal :value="formatMinutes(item.availableTime)" totalType="total" />
    </template>
    <template #billabletime>
      <ReportCellTotal :value="formatMinutes(item.billableTime)" totalType="total" />
    </template>
    <template #billabletotal>
      <ReportCellTotal :value="formatCurrency(item.billableTotal)" totalType="total" />
    </template>
    <template #billableutilization>
      <ReportCellTotal :value="formatPercentage(item.billableUtilization / 100)" totalType="total" />
    </template>
    <template #completedtasks>
      <ReportCellTotal :value="item.completedTasks" totalType="total" />
    </template>
    <template #difference>
      <!-- TODO: add  -->
    </template>
    <template #estimatedtime>
      <ReportCellTotal :value="formatMinutes(item.estimatedTime)" totalType="total" />
    </template>
    <template #estimatedutilization>
      <ReportCellTotal :value="formatPercentage(item.estimatedUtilization / 100)" totalType="avg" />
    </template>
    <template #loggedtime>
      <ReportCellTotal :value="formatMinutes(item.loggedTime)" totalType="total" />
    </template>
    <template #loggedtimevsestimatedtime>
      <ReportCellTotal :value="loggedTimeVsEstimatedTime" totalType="total" />
    </template>
    <template #nonbillabletime>
      <ReportCellTotal :value="formatMinutes(item.nonBillableTime)" totalType="total" />
    </template>
    <template #nonbillableutilization>
      <ReportCellTotal :value="formatPercentage(item.nonBillableUtilization / 100)" totalType="total" />
    </template>
    <template #overduetasks>
      <ReportCellTotal :value="item.overdueTasks" totalType="total" />
    </template>
    <template #percentage="{ column }">
      <ReportCellTotal :value="formatPercentage((item[column.itemKey] || 0) / 100)" totalType="avg" />
    </template>
    <template #time="{ column }">
      <ReportCellTotal :value="formatMinutes(item[column.itemKey])" totalType="total" />
    </template>
    <template #percentagetaskscompleted>
      <!-- TODO: add  -->
    </template>
    <template #progress>
      <!-- TODO: add  -->
    </template>
    <template #profitnumber>
      <ReportCellTotal :value="formatCurrency(item.profitNumber)" totalType="total" />
    </template>
    <template #taskcompletion>
      <!-- TODO: add  -->
    </template>
    <template #tasktotal>
      <ReportCellTotal :value="item.taskTotal" totalType="total" />
    </template>
    <template #timeestimate>
      <ReportCellTotal :value="formatMinutes(item.timeEstimate)" totalType="total" />
    </template>
    <template #timelogged>
      <ReportCellTotal :value="formatMinutes(item.timeLogged)" totalType="total" />
    </template>
    <template #totalcost>
      <ReportCellTotal :value="formatCurrency(item.totalCost)" totalType="total" />
    </template>
    <template #unavailabletime>
      <ReportCellTotal :value="formatMinutes(item.unavailableTime)" totalType="total" />
    </template>
    <template #forecastprofitnumber>
      {{ formatCurrency(item.forecastProfitNumber || 0) }}
    </template>
    <template #forecastbillabletotal>
      {{ formatCurrency(item.forecastBillableTotal || 0) }}
    </template>
    <template #forecastcost>
      {{ formatCurrency(item.forecastCost || 0) }}
    </template>
  </LscTableFooter>
</template>
