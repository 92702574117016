<script setup>
// This component supports opening of certain dialogs by navigating to specific URLs.
// This functionality is needed for onboarding, so that we could direct users to specific
// dialogs from emails, etc.
// See https://digitalcrew.teamwork.com/app/tasks/22981598

// It also support opening dialogs from the legacy app through `useLegacyBridge`.

import { useRouter } from 'vue-router';
import { useFeatures } from '@/api';
import { AccountManageSubscriptionDialog } from '@/module/account';
import { AIEditorOptionsDialog } from '@/module/ai';
import { CustomfieldAddOrEditDialog } from '@/module/customfield';
import { FeatureLimitUpgradeDialog } from '@/module/featureLimit';
import { FeatureTrialUpgradeDialog } from '@/module/featureTrial';
import { JobRolesAddDialog } from '@/module/jobRoles';
import { useLegacyBridge } from '@/module/legacy';
import { BudgetAddOrEditDialog } from '@/module/budget';
import { OnboardingWistiaVideoDialog, OnboardingWizardAcceleratorDialog } from '@/module/onboarding';
import { ProjectPortfolioManageBoardsDialog } from '@/module/project';
import { ContactFormDialog, ContactUpgradeRequestFormDialog } from '@/module/contact';
import { ProofAddOrEditDialog } from '@/module/proof';
import { ReactivationDialog } from '@/module/reactivation';
import { SubscriptionAddSeatsDialog } from '@/module/subscription';
import { TaskAddDialog, useTaskEditTracking } from '@/module/task';
import { TimeTimelogAddOrEditDialog, TimeTimerAddOrEditDialog } from '@/module/time';
import { UserAddYourTeamDialog, UserInviteDialog } from '@/module/user';
import {
  FinanceExpenseAddOrEditDialog,
  FinanceInvoiceAddOrEditDialog,
  FinanceInvoiceMoveExpenseOrTimelogDialog,
  FinanceBudgetTasklistDialog,
  FinanceInvoiceSummaryDialog,
} from '@/module/finance';
import { useRoute } from '@/route';

const route = useRoute();
const router = useRouter();

const {
  peopleJobRolesPageEnabled,
  expensesV2Enabled,
  invoiceSummaryEnabled,
  newAddOrEditInvoiceDialogEnabled,
  newMoveExpenseOrTimelogToInvoiceDialogEnabled,
  projectsNewTasklistBudgetsEnabled,
  projectsNewBudgetAddOrEdit,
} = useFeatures();
const {
  dialogName,
  dialogProps,
  isDialogOpen,
  updateLegacyEditorContent,
  showLegacyModal: _showLegacyModal,
  isLegacyConnected,
} = useLegacyBridge();
const { trackTaskEditActivationEvent } = useTaskEditTracking(computed(() => dialogProps.value.modalContext));

function removeDialogQueryParams() {
  const query = {};
  Object.entries(route.query).forEach(([key, value]) => {
    if (key === 'dialog' || key.startsWith('dialog-')) {
      return;
    }
    query[key] = value;
  });
  router.replace({ query });
}

function showLegacyModal({ modalName, params }) {
  const unwatch = watch(
    isLegacyConnected,
    () => {
      if (!isLegacyConnected.value) {
        return;
      }

      _showLegacyModal({ modalName, params });
      unwatch();
    },
    { immediate: true },
  );
}

function openDialogFromRoute() {
  if (route.query.dialog === 'task-add') {
    dialogName.value = 'TaskAddDialog';
    dialogProps.value = {};

    const projectId = Number(route.query['dialog-projectId']);

    if (Number.isSafeInteger(projectId)) {
      // If project ID is provided, then show that project
      dialogProps.value.initialProject = { id: projectId };
    }

    isDialogOpen.value = true;
  } else if (route.query.dialog === 'user-invite') {
    dialogName.value = 'UserInviteDialog';
    dialogProps.value = {};
    isDialogOpen.value = true;
  } else if (route.query.dialog === 'time-log') {
    dialogName.value = 'TimeTimelogAddOrEditDialog';
    dialogProps.value = {};
    isDialogOpen.value = true;
  } else if (route.query.dialog === 'budget-add') {
    showLegacyModal({
      modalName: 'ProjectBudgetModal',
      params: { projectId: parseInt(route.params.projectId, 10) ?? undefined },
    });
  } else if (route.query.dialog === 'automation-add') {
    showLegacyModal({
      modalName: 'AutomationsModal',
      params: { projectId: parseInt(route.params.projectId, 10) ?? undefined },
    });
  } else if (route.query.dialog === 'project-add') {
    showLegacyModal({
      modalName: 'addOrEditProjectWizard',
      params: {
        preselectedTemplateId: Number(route.query['dialog-templateId']),
        preselectedTemplateCode: route.query['dialog-templateCode'],
      },
    });
  }

  // TODO: Add more dialog types here
}

function trackTaskDetailsDialogEvent() {
  if (dialogProps.value.source === 'taskDetails') {
    if (dialogProps.value.isEditing === true) {
      trackTaskEditActivationEvent('edit_time_log');
    } else {
      trackTaskEditActivationEvent('log_time');
    }
  }
}

// Open dialogs based on query params
watch(
  () => route.query,
  () => {
    if (route.query.dialog && !route.meta.ignoreDialogQueryParam) {
      openDialogFromRoute();
      removeDialogQueryParams();
    }
  },
  { immediate: true },
);
</script>

<template>
  <LscSlotSwitch :name="dialogName">
    <template #TaskAddDialog>
      <TaskAddDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #OnboardingWistiaVideoDialog>
      <OnboardingWistiaVideoDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #AccountManageSubscriptionDialog>
      <AccountManageSubscriptionDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #SubscriptionAddSeatsDialog>
      <SubscriptionAddSeatsDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #FeatureLimitUpgradeDialog>
      <FeatureLimitUpgradeDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #ProofAddOrEditDialog>
      <ProofAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #TimeTimelogAddOrEditDialog>
      <TimeTimelogAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" @save="trackTaskDetailsDialogEvent" />
    </template>
    <template v-if="projectsNewTasklistBudgetsEnabled" #FinanceBudgetTasklistDialog>
      <FinanceBudgetTasklistDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #TimeTimerAddOrEditDialog>
      <TimeTimerAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #UserInviteDialog>
      <UserInviteDialog v-bind="dialogProps" v-model="isDialogOpen" panelSource="people_invite_user" />
    </template>
    <template #UserAddYourTeamDialog>
      <UserAddYourTeamDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #AIEditorOptionsDialog>
      <AIEditorOptionsDialog v-bind="dialogProps" v-model="isDialogOpen" @apply="updateLegacyEditorContent" />
    </template>
    <template #ReactivateSubscriptionModal>
      <ReactivationDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #FeatureTrialUpgradeDialog>
      <FeatureTrialUpgradeDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #ProjectPortfolioManageBoardsDialog>
      <ProjectPortfolioManageBoardsDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template v-if="projectsNewBudgetAddOrEdit" #BudgetAddOrEditDialog>
      <BudgetAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template v-if="peopleJobRolesPageEnabled" #JobRolesAddDialog>
      <JobRolesAddDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #CustomfieldAddOrEditDialog>
      <CustomfieldAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" :canAddFormula="true" />
    </template>
    <template #OnboardingWizardAcceleratorDialog>
      <OnboardingWizardAcceleratorDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template v-if="expensesV2Enabled" #FinanceExpenseAddOrEditDialog>
      <FinanceExpenseAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template v-if="invoiceSummaryEnabled" #FinanceInvoiceSummaryDialog>
      <FinanceInvoiceSummaryDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template v-if="newAddOrEditInvoiceDialogEnabled" #FinanceInvoiceAddOrEditDialog>
      <FinanceInvoiceAddOrEditDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template v-if="newMoveExpenseOrTimelogToInvoiceDialogEnabled" #FinanceInvoiceMoveExpenseOrTimelogDialog>
      <FinanceInvoiceMoveExpenseOrTimelogDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #ContactFormDialog>
      <ContactFormDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
    <template #ContactUpgradeRequestFormDialog>
      <ContactUpgradeRequestFormDialog v-bind="dialogProps" v-model="isDialogOpen" />
    </template>
  </LscSlotSwitch>
</template>
