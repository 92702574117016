<script setup>
import { useCurrentUser } from '@/api';
import { useCurrentProject } from '@/route';
import { ProjectIcon } from '@/module/project';
import { useAppShellSidebar } from '../../useAppShellSidebar';

defineProps({
  projects: {
    type: Array,
    required: true,
  },
  projectListType: {
    type: String,
    default: undefined,
  },
});

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const currentProject = useCurrentProject();

const user = useCurrentUser();
</script>

<template>
  <VListItem
    v-for="project in projects"
    :key="project.id"
    :to="`/projects/${project.id}`"
    :active="currentProject?.id === project.id"
    v-bind="VListItemSidebarProject"
    tabindex="0"
    :data-identifier="`side-nav-${projectListType}-project`"
    @click="clearActiveDrawerPanelIfNotPinned"
  >
    <template #prepend>
      <ProjectIcon :project="project" />
    </template>
    <VListItemTitle :title="project.name" class="text-body-1">
      {{ project.name }}
    </VListItemTitle>
    <VListItemSubtitle :title="project.company?.name" class="text-body-2">
      <template v-if="project.company?.name && user.companyId !== project.companyId">
        <div class="flex items-center gap-1">
          <LscIcon icon="lsi-sidenav-clients" :size="12" />
          <LscOverflowEllipsis>
            {{ project.company.name }}
          </LscOverflowEllipsis>
        </div>
      </template>
      <template v-else-if="project?.category">
        {{ project?.category?.name }}
      </template>
    </VListItemSubtitle>
  </VListItem>
</template>
