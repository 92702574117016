import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';

function responseToItems(response) {
  const usersList = response.data.people || [];

  usersList.forEach((user) => {
    /* eslint-disable no-param-reassign */
    user.id = Number(user.id);
    user.companyId = Number(user.companyId);
    user.createdAt = DateTime.fromISO(user.createdAt);
    user.userInvitedDate = user.userInvitedDate ? DateTime.fromISO(user.userInvitedDate) : null;
    /* eslint-enable no-param-reassign */
  });

  return usersList;
}

function responseToMeta(response) {
  const totalAccounts = Number(response.headers['x-users-accounts']);
  const totalClients = Number(response.headers['x-users-clients']);
  const totalCollaborators = Number(response.headers['x-users-collaborators']);
  const totalContacts = Number(response.headers['x-users-contacts']);
  const totalCount = Number(response.headers['x-records']);
  const totalObservers = Number(response.headers['x-users-observers']);

  return {
    totalAccounts,
    totalClients,
    totalCollaborators,
    totalContacts,
    totalCount,
    totalObservers,
  };
}

function orderAscByName(person1, person2) {
  return person1.firstName.localeCompare(person2.firstName);
}
function orderDescByName(person1, person2) {
  return person2.firstName.localeCompare(person1.firstName);
}
function orderAscByTitle(person1, person2) {
  return person1.title.localeCompare(person2.title);
}
function orderDescByTitle(person1, person2) {
  return person2.title.localeCompare(person1.title);
}
function orderAscByCompany(person1, person2) {
  return person1.companyName.localeCompare(person2.companyName);
}
function orderDescByCompany(person1, person2) {
  return person2.companyName.localeCompare(person1.companyName);
}
function orderAscByDateAdded(person1, person2) {
  return person1.createdAt - person2.createdAt;
}
function orderDescByDateAdded(person1, person2) {
  return person2.createdAt - person1.createdAt;
}
function orderByDefault() {
  return 0;
}

/**
 * Loads a list of people from the Teamwork v2 API.
 * Can fetch people generally or if a project id provided can target a project
 */
export function usePeopleV2Loader({ params: _params, projectId: _projectId, count, pageSize = 20 }) {
  const currentUser = useCurrentUser();
  const projectId = shallowRef(_projectId);
  const params = shallowRef(_params);
  const url = computed(() =>
    typeof projectId.value === 'number'
      ? `/projects/api/v2/projects/${projectId.value}/people.json`
      : '/projects/api/v2/people.json',
  );

  const order = computed(() => {
    const { sort = 'firstname', sortOrder = 'asc' } = params.value;
    const desc = sortOrder === 'desc';

    switch (sort.toLowerCase()) {
      case 'firstname':
        return desc ? orderDescByName : orderAscByName;
      case 'title':
        return desc ? orderDescByTitle : orderAscByTitle;
      case 'company':
        return desc ? orderDescByCompany : orderAscByCompany;
      case 'dateadded':
        return desc ? orderDescByDateAdded : orderAscByDateAdded;
      default:
        // eslint-disable-next-line no-console
        console.warn(`usePeopleV2Loader: unknown params.sort: ${sort}`);
        return orderByDefault;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    order,
    pageSize,
  });

  useOptimisticUpdates((event) => {
    if (!['person', 'userRate', 'userCost'].includes(event.type)) {
      return;
    }

    update((people) => {
      if (event.type === 'person' && event.person.id) {
        if (event.action === 'update') {
          return people.map((person) => (person.id === event.person.id ? { ...person, ...event.person } : person));
        }
        if (event.action === 'delete') {
          return people.filter((person) => person.id !== event.person.id);
        }
      }
      if (event.type === 'userRate' || event.type === 'userCost') {
        return people.map((person) => {
          if (event.userIds.includes(person.id)) {
            const { userCost, userRate } = event;
            return {
              ...person,
              ...(event.type === 'userCost' ? { userCost } : { userRate }),
            };
          }
          return person;
        });
      }
      return people;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'person':
        /*
          Decide to reload one user or a full reload.
          If the current user has been edited, then we need a full reload
          because their permissions may now allow them to see more or less (e.g. rates)
        */
        if (event.userId && event.userId !== currentUser.value.id) {
          refresh(event.userId);
        } else {
          refresh();
        }
        break;
      case 'project':
        if (event.projectId === projectId.value) {
          refresh();
        }
        break;
      case 'invite':
      case 'jobRole':
      case 'userRate':
      case 'team':
        refresh();
        break;
      default:
        break;
    }
  });

  return state;
}
