import { useFeatures, useExperimentA62, useExperimentA2301, useExperimentA41 } from '@/api';

export function useOnboardingWizardFeatures() {
  const {
    onboardingSignupSourceSurveyStepEnabled,
    projectsOnboardingTemplatesExperimentEnabled,
    projectsWorkflowsEnabled,
  } = useFeatures();
  const { isExpA2301Variation } = useExperimentA2301();
  const { isExpA41Variation1 } = useExperimentA41();
  const { isExpA62Variation } = useExperimentA62();

  const onboardingFeatures = computed(() => ({
    financeFlowEnabled: isExpA2301Variation.value,
    surveyFlowEnabled: onboardingSignupSourceSurveyStepEnabled.value,
    templatesFlowEnabled: projectsOnboardingTemplatesExperimentEnabled.value,
    workflowsEnabled: projectsWorkflowsEnabled.value,
    addClientCreationFlowEnabled: isExpA62Variation.value,
    integrationsFlowEnabled: isExpA41Variation1.value,
    someTestValue: true,
    gettingStartedRedesignEnabled: false,
  }));

  return {
    onboardingFeatures,
  };
}
