<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Make project planning simpler, more efficient, and more impactful. Over the next 2 minutes, discover how you can:',
        )
      }}
    </p>
    <ul class="mx-10">
      <li>{{ t('Track progress at a glance') }}</li>
      <li>{{ t('Allocate your resources') }}</li>
      <li>{{ t("Maximize your team's capacity") }}</li>
      <li>{{ t('Standardize project creation') }}</li>
    </ul>
  </div>
</template>
