import { provideLocal, injectLocal } from '@vueuse/core';
import { useProjectV3Loader } from '@/api';
import { STEP_LAST_STEP, ONBOARDING_GOAL_GETTING_STARTED } from './constants';
import { useOnboardingSteps } from './useOnboardingWizardSteps';

const symbol = Symbol('useOnboardingWizard');

function OnboardingWizard({ goal, projectId = undefined }) {
  const currentProjectId = shallowRef(projectId);
  const onboardingState = ref({
    goal,
    currentStep: undefined,
    currentProgressStep: undefined,
    stepHistory: [],
    stepHistoryIndex: 0,
    data: {},
  });

  if (projectId && projectId !== -1) {
    const projectState = useProjectV3Loader({
      projectId: computed(() => projectId),
      params: {
        include: 'companies,users',
      },
    });

    const { item: project } = projectState;

    onboardingState.value = { ...onboardingState.value, project };
  }

  // we have to pass the onboardingState to the current state to branch steps
  const { steps, firstStep, progressSteps, title } = useOnboardingSteps(onboardingState);

  const currentStep = computed({
    get() {
      return onboardingState.value.currentStep;
    },
    set(value) {
      if (steps[value] !== undefined) {
        // update current step value
        onboardingState.value.currentStep = steps[value];

        // update stepper value
        if (steps[value]?.progressStep) {
          onboardingState.value.currentProgressStep = steps[value].progressStep;
        }
      }
    },
  });

  const currentProject = computed(() => onboardingState.value.project);

  const hasPreviousStep = computed(() => onboardingState.value.stepHistoryIndex > 0);
  const nextStepIsLastStep = computed(() => currentStep.value.nextStep() === STEP_LAST_STEP);
  const hasNextStep = computed(() => ![undefined, STEP_LAST_STEP].includes(currentStep.value.nextStep()));
  const isLastStep = computed(() => currentStep.value.id === STEP_LAST_STEP);

  const currentGoal = computed(() => onboardingState.value.goal);

  const currentStepHistoryState = computed(() =>
    onboardingState.value.stepHistory.at(onboardingState.value.stepHistoryIndex),
  );

  const isGoalGettingStarted = computed(() => onboardingState.value.goal === ONBOARDING_GOAL_GETTING_STARTED);

  // merge incoming step data with either current state (if it's a new step) or with the history state
  function mergeStepStateData(newStateData, hasChanged = false) {
    if (hasChanged && currentStepHistoryState.value?.state) {
      onboardingState.value.data = { ...currentStepHistoryState.value?.state, ...newStateData };
    } else {
      onboardingState.value.data = { ...onboardingState.value.data, ...newStateData };
    }
  }

  function goToNextStep(hasChanged = false, stepState = {}, fullState = undefined) {
    mergeStepStateData({ ...fullState, [currentStep.value.id]: stepState }, hasChanged);

    if (hasNextStep.value || nextStepIsLastStep.value) {
      const nextStep = steps[currentStep.value.nextStep()];

      if (hasChanged) {
        // if item has changed, we want to remove any future history steps, as they might not be valid anymore
        onboardingState.value.stepHistory.splice(onboardingState.value.stepHistoryIndex, Infinity, {
          id: currentStep.value.id,
          state: { ...onboardingState.value.data },
        });
      }

      currentStep.value = nextStep.id;
      onboardingState.value.stepHistoryIndex += 1;
    }
  }

  function goToPreviousStep() {
    if (hasPreviousStep.value) {
      onboardingState.value.stepHistoryIndex -= 1;
      currentStep.value = currentStepHistoryState.value.id;
    }
  }

  return {
    title,
    firstStep,
    currentStep,
    progressSteps,
    hasPreviousStep,
    hasNextStep,
    isLastStep,
    isGoalGettingStarted,
    currentProject,
    currentProjectId,
    goToNextStep,
    goToPreviousStep,
    onboardingState,
    currentGoal,
  };
}

export function provideOnboardingWizard({ goal, projectId = undefined }) {
  const onboarding = OnboardingWizard({ goal, projectId });
  provideLocal(symbol, onboarding);
  return onboarding;
}

/** @type {() => ReturnType<OnboardingWizard>} */
export function useOnboardingWizard() {
  return injectLocal(symbol);
}
