<script setup>
import { useLscTable } from './useLscTable';

/**
 * Displays a single row within `LscTable` with the content provided through slots.
 *
 * If only the `default` slot is provided, it is rendered within a single cell which spans the width of the whole table,
 * otherwise named slots are rendered within the appropriate cells defined by the `LscTable` `columns` prop.
 */
defineProps({
  /**
   * If the first column in a row should be draggable.
   * @type {PropType<Boolean>}
   */
  draggable: {
    type: Boolean,
    default: false,
  },
  /**
   * If the row is interactive. Adds hover and click states.
   * @type {PropType<Boolean>}
   */
  interactive: {
    type: Boolean,
    default: false,
  },
  /**
   * Highlighted state of the row. Useful for multiple row selection.
   * @type {PropType<Boolean>}
   */
  selected: {
    type: Boolean,
    default: false,
  },
  /**
   * If the row is contextually nested within another row.
   * @type {PropType<Boolean>}
   */
  nested: {
    type: Boolean,
    default: false,
  },
  /**
   * Show an error state on the row.
   * @type {PropType<Boolean>}
   */
  error: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['dragstart', 'dragend', 'dragover', 'drop', 'dragenter', 'dragleave']);

const slots = defineSlots();
const { columns } = useLscTable();

const hasOnlyDefaultSlot = computed(() => {
  if (!slots.default) {
    return false; // Does not have the default slot.
  }

  // Using for-in for performance reasons.
  // eslint-disable-next-line no-restricted-syntax
  for (const name in slots) {
    if (name !== 'default') {
      return false; // Has at least one non-default slots.
    }
  }

  return true;
});
</script>

<template>
  <tr
    class="group/LscTableRow relative col-span-full grid grid-cols-subgrid"
    :data-error="error"
    :data-nested="nested"
    :data-interactive="interactive"
    :data-selected="selected"
  >
    <td v-if="hasOnlyDefaultSlot" class="col-span-full">
      <slot />
    </td>
    <template v-else>
      <td
        v-for="column in columns"
        :key="column.id"
        :role="column.name ? 'cell' : 'presentation'"
        :draggable="draggable"
        :data-align="column.align"
        :data-sticky-first-column="column.stickyFirstColumn"
        :data-sticky-last-column="column.stickyLastColumn"
        :data-shadow-start="column.applyShadowStart"
        :data-shadow-end="column.applyShadowEnd"
        :data-loading="column.spacer ? false : Boolean(column.slot ? !slots[column.slot] : !slots[columns[0].slot])"
        :class="[
          'group/LscTableCell relative flex min-w-0 items-center whitespace-nowrap px-2',
          // Dividers
          'border-b border-separator',
          'after:pointer-events-none after:absolute after:inset-y-0 after:opacity-0 after:transition-opacity',
          // Loading state
          'before:block before:h-4 before:w-full before:rounded-md before:bg-surface-default',
          'before:animate-duration-1000 before:animate-pulse',
          'data-[loading=false]:before:content-none',
          // Background
          'bg-default transition-colors',
          // Interactive
          'group-data-[interactive=true]/LscTableRow:cursor-pointer',
          'group-data-[interactive=true]/LscTableRow:group-hover/LscTableRow:bg-surface-emphasis-hover',
          // Nested
          'group-data-[nested=true]/LscTableRow:bg-surface-emphasis-default',
          // Selected
          'group-data-[selected=true]/LscTableRow:bg-surface-emphasis-selected',
          'group-data-[selected=true]/LscTableRow:hover:bg-surface-emphasis-selected',
          // Error
          'group-data-[error=true]/LscTableRow:bg-surface-critical-subdued',
          // Alignment
          'data-[align=left]:justify-start',
          'data-[align=center]:justify-center',
          'data-[align=right]:justify-end',
          // Height
          'group-data-[density=xs]/LscTable:h-[--lsds-c-table-row-density-xs]',
          'group-data-[density=sm]/LscTable:h-[--lsds-c-table-row-density-sm]',
          'group-data-[density=md]/LscTable:h-[--lsds-c-table-row-density-md]',
          'group-data-[density=lg]/LscTable:h-[--lsds-c-table-row-density-lg]',
          'group-data-[density=xs]/LscTable:py-[--lsds-c-table-cell-padding-xs]',
          'group-data-[density=sm]/LscTable:py-[--lsds-c-table-cell-padding-sm]',
          'group-data-[density=md]/LscTable:py-[--lsds-c-table-cell-padding-md]',
          'group-data-[density=lg]/LscTable:py-[--lsds-c-table-cell-padding-lg]',
          // Dividers
          'group-data-[dividers=true]/LscTable:after:opacity-100',
          'group-data-[dividers=true]/LscTable:after:border-r',
          'group-data-[dividers=true]/LscTable:after:right-0',
          // First column sticky
          'data-[sticky-first-column=true]:sticky',
          'data-[sticky-first-column=true]:left-[--lsc-table-col-offset]',
          'data-[sticky-first-column=true]:z-[1]',
          'data-[sticky-first-column=true]:after:right-0',
          'group-data-[shadow-start=true]/LscTable:data-[shadow-start=true]:after:right-0',
          'group-data-[shadow-start=true]/LscTable:data-[shadow-start=true]:after:border-r',
          'group-data-[shadow-start=true]/LscTable:data-[shadow-start=true]:after:opacity-100',
          // Last column sticky
          'data-[sticky-last-column=true]:sticky',
          'data-[sticky-last-column=true]:right-[--lsc-table-col-offset]',
          'data-[sticky-last-column=true]:z-[1]',
          'group-data-[shadow-end=true]/LscTable:data-[shadow-end=true]:after:left-0',
          'group-data-[shadow-end=true]/LscTable:data-[shadow-end=true]:after:border-l',
          'group-data-[shadow-end=true]/LscTable:data-[shadow-end=true]:after:opacity-100',
        ]"
        :style="{
          '--lsc-table-col-offset': `var(--lsc-table-col-offset-${column.index})`,
        }"
        @dragstart="emit('dragstart', $event)"
        @dragend="emit('dragend', $event)"
        @dragover="emit('dragover', $event)"
        @drop="emit('drop', $event)"
        @dragenter="emit('dragenter', $event)"
        @dragleave="emit('dragleave', $event)"
      >
        <slot :name="column.slot" :column="column" />
        <slot name="dragTargetIndicator" />
      </td>
    </template>
  </tr>
</template>
