import axios from 'axios';
import { Settings } from 'luxon';
import { useI18n } from '@/util';
import { fetchAdapter } from './fetchAdapter';
import { useVersion } from './useVersion';

const symbol = Symbol('useAxios');

/**
 * @param {import('axios').AxiosInstance} customAxios
 */
function Axios(customAxios) {
  if (customAxios) {
    return customAxios;
  }

  const { version } = useVersion();
  const toast = useLsToast();
  const { t } = useI18n();
  const defaultAxios = axios.create({
    headers: {
      // Asks the server to respond in JSON format.
      Accept: 'application/json',
      // Prevents the browser from showing the native login modal on 401 errors.
      'X-Requested-With': 'XMLHttpRequest',
      // Increases the rate limit from 300 to 1000 on v3 endpoints.
      // Tells the server the version number of the client.
      twProjectsVer: version,
      // The Teams feature must be explicitly requested, as it is off by default.
      // It's been done this way to keep the Teamwork HTTP API backwards compatible.
      // As a lof of endpoints can return or accept teams,
      // it is easiest to just add this header to all requests.
      // It would be nice to eventually enable teams by default in the Teamwork HTTP API.
      'Teams-Enabled': 'true',
      // Used for API usage analytics.
      'Sent-By': 'lightspeed',
      // Used for API usage analytics.
      'Triggered-By': 'user',
    },
    adapter: fetchAdapter,
  });

  defaultAxios.interceptors.request.use(
    (config) => {
      // Used for API usage analytics.
      // eslint-disable-next-line no-param-reassign
      config.headers['Referer-Path'] = window.location.pathname;

      if (Settings.defaultZone.isValid) {
        // The "Time-Zone" header must be added in an interceptor
        // because the required value might change over time.
        // eslint-disable-next-line no-param-reassign
        config.headers['Time-Zone'] = Settings.defaultZone.name;
      }
      return config;
    },
    null,
    { synchronous: true },
  );

  defaultAxios.interceptors.response.use(
    null,
    (error) => {
      if (error.config) {
        let { errorMessage } = error.config;
        if (typeof errorMessage === 'function') {
          errorMessage = errorMessage(error);
        }
        if (errorMessage === true || (errorMessage === undefined && error.config.method !== 'get')) {
          toast.createToast(t('Request failed'), 'critical');
        } else if (errorMessage) {
          toast.createToast(errorMessage, 'critical');
        }
      }
      throw error;
    },
    { synchronous: true },
  );

  return defaultAxios;
}

export const axiosPlugin = {
  install(app, customInstance) {
    app.provide(symbol, Axios(customInstance));
  },
};

/** @returns {import('axios').AxiosInstance} */
export function useAxios() {
  return inject(symbol);
}
