<script setup>
import { provideKeyboardShortcut } from '@/util';
import { provideDrawer } from './useDrawer';

defineProps({
  transition: {
    type: String,
    default: 'slide-x-reverse-transition',
  },
});

const modelValue = defineModel({ type: Boolean, default: false });
provideKeyboardShortcut(modelValue);

function close() {
  modelValue.value = false;
}

let clickOutsideEvent = null;

function handleClickOutside(event) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = null;
  });
}

const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    if (
      clickOutsideEvent?.target.closest('.LscToast') ||
      clickOutsideEvent?.target.closest('.tox-dialog-wrap') || // insert code dialog
      clickOutsideEvent?.target.closest('.tox-collection') || // autocomplete popover
      clickOutsideEvent?.target.closest('.desk-drawer') ||
      clickOutsideEvent?.target.closest('.TimeTimer') ||
      clickOutsideEvent?.target.closest('#pendo-base') ||
      clickOutsideEvent?.target.closest('[persistDrawer]')
    ) {
      // Do not close the drawer when a toast was clicked.
      return;
    }
    modelValue.value = value;
  },
});

provideDrawer({ close });
</script>

<template>
  <!-- eslint-disable-next-line lightspeed/no-restricted-component -->
  <VDialog
    v-model="modelValueFiltered"
    class="LscDrawer"
    :scrim="false"
    :transition="transition"
    :retainFocus="false"
    @click:outside="handleClickOutside"
  >
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <slot name="default" :close="close" />
  </VDialog>
</template>

<style>
.LscDrawer.v-overlay > .v-overlay__content {
  @apply right-0 m-0 h-full !max-h-full w-auto;
}
</style>
