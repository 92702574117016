import { useCustomReportsBuilderV3Loader } from '@/api';
import { customfieldUnits } from '@/module/customfield';
import { useReportTableColumns } from '../table/useReportTableColumns';

const ReportBuilderSettingsSymbol = Symbol('useReportBuilderSettings');

function ReportBuilderSettings() {
  const count = shallowRef(-1);
  const state = useCustomReportsBuilderV3Loader({
    params: computed(() => ({ customFieldsSiteLevelOnly: false })),
    count,
  });

  const { items } = state;

  function load() {
    count.value = 4;
  }

  return {
    count,
    items,

    state,
    load,
  };
}

export function provideReportBuilderSettings() {
  provide(ReportBuilderSettingsSymbol, ReportBuilderSettings());
}

export function useReportBuilderSettings() {
  return inject(ReportBuilderSettingsSymbol);
}

export function useReportBuilderSettingsByType(reportType) {
  const { load, items } = useReportBuilderSettings();

  load();

  return computed(() => {
    const reportTypeValue = reportType.value;
    const itemsValue = items.value;

    if (!(reportTypeValue && itemsValue?.length)) {
      return null;
    }

    return itemsValue.find((settings) => settings.baseType === reportTypeValue);
  });
}

export function useReportBuilderSettingsColumns(reportType) {
  const settings = useReportBuilderSettingsByType(reportType);
  const { items: columnMapping, columnTypeMap } = useReportTableColumns();

  function transformColumn(column, index, standard = false) {
    const columnName = column.name.toLowerCase();
    const { translation, icon, unit } = columnMapping[columnName] || {};
    const type = columnTypeMap[columnName] ?? 'text';

    return {
      title: translation ?? column.name,
      value: column.name,
      props: {
        unit: unit ?? customfieldUnits.DEFAULT,
        type,
        icon,
        index,
        standard,
        searchText: columnName,
      },
    };
  }

  function transformStandardColumn(column, index) {
    return transformColumn(column, index, true);
  }

  return computed(() => {
    const settingsValue = settings.value;

    if (!settingsValue) {
      return null;
    }

    return settingsValue.columns.map(transformStandardColumn);
  });
}
